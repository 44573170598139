import { toast } from 'react-hot-toast';

import ToastTypeEnum from '../enums/ToastTypeEnum';
import Toast from '../components/toast';

class ToastService {
  public static makeToast(type: ToastTypeEnum, message: string): void {
    toast.remove();
    if (type === ToastTypeEnum.SUCCESS) {
      toast.custom((toast) => (
        <Toast {...toast} message={message} type="success" />
      ));
    } else if (type === ToastTypeEnum.FAILURE) {
      toast.custom((toast) => (
        <Toast {...toast} message={message} type="failure" />
      ));
    } else {
      toast.custom((toast) => (
        <Toast {...toast} message={message} type="info" />
      ));
    }
  }
}

export default ToastService;
