import classNames from 'classnames';
import { useEffect, useState } from 'react';
import tafeLogo from '../shared/assets/images/tafelogodark.png';
import styles from './styles.module.css';

const Login: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const config = {
      always_render_login: true,
      signin_providers_only: true,
      css_url: '/web/css/embedded_signin_providers_only.css'
    };

    const catalyst = (window as any).catalyst;
    catalyst.auth.signIn('login', config);

    setTimeout(() => {
      setVisible(true);
    }, 80);
  }, []);

  return (
    <div className={styles['login-container']}>
      <div className={styles['layout-container']}>
        <div
          className={classNames(
            styles['tafe-logo'],
            visible && styles['small']
          )}
        >
          <img className="tage-img" src={tafeLogo} alt="Tafe Logo" />
        </div>
        <div
          className={classNames(
            styles['iframe-block'],
            visible && styles['visible']
          )}
        >
          <h3 className="text-16 font-600 color-black">
            Log in to your TAFE account
          </h3>
          <div id="login"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
