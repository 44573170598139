import toast from 'react-hot-toast';
import { useCallback } from 'react';
import classNames from 'classnames';

interface ToastProps {
  message: string;
  id: string;
  type: 'success' | 'info' | 'failure';
}

const Toast: React.FC<ToastProps> = ({ message, id, type }) => {
  const dismissToast = useCallback(() => {
    toast.remove(id);
  }, [id]);

  const toastClass = classNames('toast', {
    success: type === 'success',
    info: type === 'info',
    failure: type === 'failure'
  });

  return (
    <div className={toastClass}>
      <div className="toast-icon-block">
        <div className="toast-icon"></div>
      </div>
      <p>{message}</p>
      <div className="toast-close-icon" onClick={dismissToast}>
        ×
      </div>
    </div>
  );
};

export default Toast;
