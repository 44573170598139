import { Framework, IFieldActivity } from '../../types/global';
import { FieldActivitiesServiceParameters } from '../../types/parameters';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

export default class FieldActivitiesServices {
  public static async getPagedFieldActivitiesServices({
    page,
    signal,
    perPage,
    filters: { name },
    additionalFields = []
  }: FieldActivitiesServiceParameters.GetPagedFieldActivities): Promise<
    Framework.PagedResult<IFieldActivity>
  > {
    const handler = new RequestHandler(
      '/api/v1/field-activities',
      RequestMethod.GET
    );
    handler.addParam('page', page);
    handler.addParam('per_page', perPage);
    if (signal) {
      handler.setAbortSignal(signal);
    }
    if (name) {
      handler.addParam('name', name);
    }

    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<IFieldActivity>);
  }
}
