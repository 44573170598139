import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

import { CommonRequestResponse } from '../../types/common';
import { DSP, DspReportees, STAT, User } from '../../types/model';
import { UserServiceParameters } from '../../types/parameters';
import APIRequest from '../constants/APIRequest';
import DateProcessor from '../processor/DateProcessor';
import DateFormat from '../enums/DateFormat';
import { Framework } from '../../types/global';

export default class UserService {
  public static async getCurrentUser({
    additionalFields
  }: UserServiceParameters.GetCurrentUser.Parameters): Promise<User> {
    const reqHandler = new RequestHandler(APIRequest.USER, RequestMethod.GET);

    if (additionalFields && additionalFields.length) {
      reqHandler.addParam('additional_fields', additionalFields.join(','));
    }

    return reqHandler.execute().then((result) => {
      return (result.data as CommonRequestResponse.RecordRequestResponse<User>)
        .data;
    });
  }
  public static async getMyReporteesDsp({
    additionalFields,
    startDateTime,
    endDateTime
  }: UserServiceParameters.GetMyReporteesDsp.Parameters): Promise<
    CommonRequestResponse.ArrayRecordRequestResponse<DSP>
  > {
    const reqHandler = new RequestHandler(
      APIRequest.MY_DSP_REPORTEES,
      RequestMethod.GET
    );

    if (additionalFields?.length) {
      reqHandler.addParam('additional_fields', additionalFields.join(','));
    }
    if (startDateTime) {
      reqHandler.addParam(
        'stat_start_date',
        DateProcessor.format(startDateTime, DateFormat.ISO_DATE)
      );
    }
    if (endDateTime) {
      reqHandler.addParam(
        'stat_end_date',
        DateProcessor.format(endDateTime, DateFormat.ISO_DATE)
      );
    }

    try {
      const response = await reqHandler.execute();
      return response.data as CommonRequestResponse.ArrayRecordRequestResponse<DSP>;
    } catch (error) {
      console.error('Error fetching DSP reportees:', error);
      throw error;
    }
  }
  public static async getUserMyStats({
    startDateTime,
    endDateTime,
    signal
  }: UserServiceParameters.GetUserMyStats.Parameters): Promise<
    Framework.IResult<STAT>
  > {
    const handler = new RequestHandler(
      '/api/v1/user/my-stats',
      RequestMethod.GET
    );
    if (startDateTime) {
      handler.addParam(
        'start_date',
        DateProcessor.format(startDateTime, DateFormat.ISO_DATE)
      );
    }
    if (endDateTime) {
      handler.addParam(
        'end_date',
        DateProcessor.format(endDateTime, DateFormat.ISO_DATE)
      );
    }
    if (signal) {
      handler.setAbortSignal(signal);
    }
    return handler
      .execute()
      .then((result) => result.data as Framework.IResult<STAT>);
  }

  public static async getDspReportees({
    current_start_date_time,
    current_end_date_time,
    previous_start_date_time,
    previous_end_date_time,
    till_date_start_value
  }: UserServiceParameters.GetDspReportees.Parameters): Promise<
    DspReportees
  > {
    const reqHandler = new RequestHandler(
      '/api/v1/dsp-reportees-with-analytics-report',
      RequestMethod.GET
    );

    if (current_start_date_time) {
      reqHandler.addParam(
        'current_start_date_time',
        DateProcessor.format(current_start_date_time, DateFormat.ISO_DATE_TIME)
      );
    }

    if (current_end_date_time) {
      reqHandler.addParam(
        'current_end_date_time',
        DateProcessor.format(current_end_date_time, DateFormat.ISO_DATE_TIME)
      );
    }

    if (previous_start_date_time) {
      reqHandler.addParam(
        'previous_start_date_time',
        DateProcessor.format(previous_start_date_time, DateFormat.ISO_DATE_TIME)
      );
    }

    if (previous_end_date_time) {
      reqHandler.addParam(
        'previous_end_date_time',
        DateProcessor.format(previous_end_date_time, DateFormat.ISO_DATE_TIME)
      );
    }
    if (till_date_start_value) {
      reqHandler.addParam(
        'till_date_start_value',
        DateProcessor.format(till_date_start_value, DateFormat.ISO_DATE_TIME)
      );
    }

    try {
      const response = await reqHandler.execute();
      return (response.data as Record<string,unknown>).data as DspReportees ;
    } catch (error) {
      console.error('Error fetching DSP reportees:', error);
      throw error;
    }
  }

  public static async getDspPeerReportees({
    current_start_date_time,
    current_end_date_time,
    previous_start_date_time,
    previous_end_date_time,
    till_date_start_value
  }: UserServiceParameters.GetDspReportees.Parameters): Promise<
    DspReportees
  > {
    const reqHandler = new RequestHandler(
      '/api/v1/dsp-peers-with-analytics-report',
      RequestMethod.GET
    );

    if (current_start_date_time) {
      reqHandler.addParam(
        'current_start_date_time',
        DateProcessor.format(current_start_date_time, DateFormat.ISO_DATE_TIME)
      );
    }

    if (current_end_date_time) {
      reqHandler.addParam(
        'current_end_date_time',
        DateProcessor.format(current_end_date_time, DateFormat.ISO_DATE_TIME)
      );
    }

    if (previous_start_date_time) {
      reqHandler.addParam(
        'previous_start_date_time',
        DateProcessor.format(previous_start_date_time, DateFormat.ISO_DATE_TIME)
      );
    }

    if (previous_end_date_time) {
      reqHandler.addParam(
        'previous_end_date_time',
        DateProcessor.format(previous_end_date_time, DateFormat.ISO_DATE_TIME)
      );
    }
    if (till_date_start_value) {
      reqHandler.addParam(
        'till_date_start_value',
        DateProcessor.format(till_date_start_value, DateFormat.ISO_DATE_TIME)
      );
    }

    try {
      const response = await reqHandler.execute();
      return response.data as DspReportees;
    } catch (error) {
      console.error('Error fetching DSP reportees:', error);
      throw error;
    }
  }
  public static async getMyDspPeers({
    additionalFields,
    signal
  }: UserServiceParameters.GetMyDspPeers.Parameters): Promise<CommonRequestResponse.ArrayRecordRequestResponse<DSP>> {
      const reqHandler = new RequestHandler("/api/v1/user/my-dsp-peers", RequestMethod.GET);
      if(additionalFields?.length){
        reqHandler.addParam("additional_fields", additionalFields.join(","));
      }
      if(signal){
        reqHandler.setAbortSignal(signal);
      }
      return reqHandler
      .execute()
      .then((result) => result.data as CommonRequestResponse.ArrayRecordRequestResponse<DSP>)
  }
}
