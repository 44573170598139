import { useQueryStates } from 'nuqs';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IOptions } from '../../../../types/global';
import { UsePagedProspectsHook } from '../../../../types/hooks';
import CallTemp from '../../../components/call-contact';
import RecordCollection from '../../../components/record-collection';
import WhatsappTemp from '../../../components/whatsapp-contact';
import usePagedProspects from '../../../hooks/usePagedProspects';
import useScroll from '../../../hooks/useScroll';
import ViewLayout from '../../../layout/view-layout';
import { convertDateFormat } from '../../../utils/ConvertDateFormat';
import UserContext from '../../../context/UserContext';

const allDropDownOptions = [
  { value: 'contactName', label: 'Name' },
  { value: 'contactMobileNumber', label: 'Mobile Number' }
];

const EnquiryMaster = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  // Dropdown Search
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('contactName');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  // Url Params
  const [
    {
      stages,
      sogs,
      enquiry_classifications: enquiryClassifications,
      created_time_start_value: createdTimeStartValue,
      created_time_end_value: createdTimeEndValue,
      edd_end_value: eddEndValue,
      dealer_codes: dealerCodes,
      my_dsp_reportee_id: myDspReporteeId
    }
  ] = useQueryStates(
    {
      stages: {
        defaultValue: [],
        parse: (value) => (value ? value.split(',') : [])
      },
      sogs: {
        defaultValue: [],
        parse: (value) => (value ? value.split(',') : [])
      },
      enquiry_classifications: {
        defaultValue: [],
        parse: (value) => (value ? value.split(',') : [])
      },
      created_time_start_value: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      created_time_end_value: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      edd_end_value: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      dealer_codes: {
        defaultValue: [],
        parse: (value) => (value ? value.split(',') : [])
      },
      my_dsp_reportee_id: {
        defaultValue: '',
        parse: (value) => value || ''
      }
    },
    {
      history: 'replace'
    }
  );

  // Set Initial Filter and make api
  const [initialFilters] = useState<UsePagedProspectsHook.Filters>(() => {
    const initialFilters: UsePagedProspectsHook.Filters = {
      stages,
      sogs,
      enquiryClassifications,
      created_time_start_value: parseInt(createdTimeStartValue),
      created_time_end_value: parseInt(createdTimeEndValue),
      edd_end_value: parseInt(eddEndValue),
      dealer_codes: dealerCodes
    };
    return initialFilters;
  });

  const {
    data,
    isLoading,
    updateFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedProspects({
    page: 1,
    perPage: 50,
    myDspReporteeId,
    filters: initialFilters
  });

  //Common Filter starts
  // const { prospectData } = useContext(ProspectContext);
  // const { dealerData } = useContext(UserContext);

  // const [filterConfig, setFilterConfig] = useState<any[]>([
  //   {
  //     type: 'multiselect',
  //     label: 'Stages',
  //     placeholder: 'Select stages',
  //     name: 'stages',
  //     value: Array.isArray(stages)
  //       ? stages.map((item) => ({ label: item, value: item }))
  //       : [],
  //     defaultValue: Array.isArray(stages)
  //       ? stages.map((item) => ({ label: item, value: item }))
  //       : [],
  //     options: Array.isArray(prospectData)
  //       ? prospectData
  //           .filter((item) => item.name === 'stage')
  //           .flatMap((item) => item.options)
  //       : []
  //   },
  //   {
  //     type: 'multiselect',
  //     label: 'Sogs',
  //     placeholder: 'Select Sogs',
  //     name: 'sogs',
  //     value: Array.isArray(sogs)
  //       ? sogs.map((item) => ({ label: item, value: item }))
  //       : [],
  //     defaultValue: Array.isArray(sogs)
  //       ? sogs.map((item) => ({ label: item, value: item }))
  //       : [],
  //     options: Array.isArray(prospectData)
  //       ? prospectData
  //           .filter((item) => item.name === 'sog')
  //           .flatMap((item) => item.options)
  //       : []
  //   },
  //   {
  //     type: 'multiselect',
  //     label: 'Enquiry Classifications',
  //     placeholder: 'Select Enquiry Classifications',
  //     name: 'enquiry_classifications',
  //     value: Array.isArray(enquiryClassifications)
  //       ? enquiryClassifications.map((item) => ({ label: item, value: item }))
  //       : [],
  //     defaultValue: Array.isArray(enquiryClassifications)
  //       ? enquiryClassifications.map((item) => ({ label: item, value: item }))
  //       : [],
  //     options: Array.isArray(prospectData)
  //       ? prospectData
  //           .filter((item) => item.name === 'enquiry_classification')
  //           .flatMap((item) => item.options)
  //       : []
  //   },
  //   {
  //     type: 'multiselect',
  //     label: 'Dealer',
  //     placeholder: 'Select Dealer',
  //     name: 'dealer_codes',
  //     value: Array.isArray(dealerCodes)
  //       ? dealerCodes.map((item) => ({ label: item, value: item }))
  //       : [],
  //     defaultValue: Array.isArray(dealerCodes)
  //       ? dealerCodes.map((item) => ({ label: item, value: item }))
  //       : [],
  //     options: Array.isArray(dealerData)
  //       ? dealerData.map((item) => ({
  //           label:
  //             item.dealer_code +
  //             ' - ' +
  //             (item.first_name || '') +
  //             (item.last_name ? ' ' + item.last_name : ''),
  //           value: item.dealer_code
  //         }))
  //       : []
  //   }
  // ]);

  // const openFilter = useCallback(() => {
  //   CustomEventEmiterUtil.emit('openFilter');
  // }, []);
  // const applyFilter = useCallback(
  //   (filterData: any) => {
  //     setFilterConfig(filterData);

  //     const filters: any = {};

  //     filterData.forEach((item: any) => {
  //       if (item.name === 'stages') {
  //         const stagesArray = item.value;
  //         filters.stages = Array.isArray(stagesArray)
  //           ? stagesArray.map((item: any) => item.value)
  //           : [];
  //       } else if (item.name === 'sogs') {
  //         const sogsArray = item.value;
  //         filters.sogs = Array.isArray(sogsArray)
  //           ? sogsArray.map((item: any) => item.value)
  //           : [];
  //       } else if (item.name === 'enquiry_classifications') {
  //         const enquiryClassificationsArray = item.value;
  //         filters.enquiryClassifications = Array.isArray(
  //           enquiryClassificationsArray
  //         )
  //           ? enquiryClassificationsArray.map((item: any) => item.value)
  //           : [];
  //       } else if (item.name === 'created_time_start_value') {
  //         filters.created_time_start_value = item.value;
  //       } else if (item.name === 'created_time_end_value') {
  //         filters.created_time_end_value = item.value;
  //       } else if (item.name === 'edd_end_value') {
  //         filters.edd_end_value = item.value;
  //       } else if (item.name === 'dealer_codes') {
  //         filters.dealer_codes = item.value;
  //         const dealerCodes = item.value;
  //         filters.dealer_codes = Array.isArray(dealerCodes)
  //           ? dealerCodes.map((item: any) => item.value)
  //           : [];
  //       }
  //     });

  //     setParams({
  //       stages: filters.stages?.map((item: any) => item).join(',') || '',
  //       sogs: filters.sogs?.map((item: any) => item).join(',') || '',
  //       enquiry_classifications:
  //         filters.enquiry_classifications?.map((item: any) => item).join(',') ||
  //         '',
  //       created_time_start_value:
  //         filters.created_time_start_value?.toString() || '',
  //       created_time_end_value:
  //         filters.created_time_end_value?.toString() || '',
  //       edd_end_value: filters.edd_end_value?.toString() || '',
  //       dealer_codes:
  //         filters.dealer_codes?.map((item: any) => item).join(',') || ''
  //     });

  //     updateFilters(filters);
  //   },
  //   [setFilterConfig, updateFilters]
  // );
  // useCustomEventListener('applyFilter', applyFilter);
  //Common Filter ends

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedProspectsHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue('');
      setSearchLabel(value.label.toLowerCase());
      updateFilters(initialFilters);
    },
    [initialFilters, updateFilters]
  );

  return (
    <>
      <ViewLayout title="Prospects">
        <RecordCollection>
          <RecordCollection.DropdownSearchBox
            value={value}
            onChange={onNameChange}
            placeholder={'Search by ' + searchLabel}
            initialDropDownValue={allDropDownOptions[0]}
            dropDownOptions={allDropDownOptions}
            onDropDownChange={onDropDownChange}
          />
          <RecordCollection.List onScroll={onScrollEventListener}>
            {/* <div className="sticky-filter-block">
              <Filter filterData={filterConfig} />
            </div> */}
            {isLoading ? (
              <RecordCollection.ListLoader />
            ) : data.length === 0 ? (
              <RecordCollection.ListEmpty />
            ) : (
              data.map((item) => (
                <RecordCollection.ListItemWrapper
                  key={item.id}
                  to={`/${user.user_role}/prospect/${item.id}`}
                >
                  <RecordCollection.ListItemLink></RecordCollection.ListItemLink>
                  <RecordCollection.ListItem>
                    <RecordCollection.ListItemTitle title={item.contact.name} />
                    <RecordCollection.ListItemKeyValue
                      label={t('common.type')}
                      value={item.enquiry_classification}
                    />
                    <RecordCollection.ListItemKeyValue
                      label={t('common.status')}
                      value={item.enquiry_status}
                    />
                    <RecordCollection.ListItemKeyValue
                      label={t('common.modelInterest')}
                      value={item.interested_model_family}
                    />
                    <RecordCollection.ListItemKeyValue
                      label={t('common.edd')}
                      value={convertDateFormat(item.edd)}
                    />

                    <RecordCollection.ListItemKeyValue
                      label={t('common.contact')}
                      value={`+91 ${item.contact.mobile_number}`}
                    />
                    <RecordCollection.ListAction>
                      <CallTemp
                        className="prospect-contact"
                        phoneNumber={item.contact.mobile_number}
                      />
                      <WhatsappTemp phoneNumber={item.contact.mobile_number} />
                    </RecordCollection.ListAction>
                  </RecordCollection.ListItem>
                </RecordCollection.ListItemWrapper>
              ))
            )}
            {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
          </RecordCollection.List>
        </RecordCollection>
      </ViewLayout>
      {/* <button
        className="filter-primary-btn"
        style={{ bottom: '20px' }}
        onClick={openFilter}
      >
        <div className="dF align-center gap-6">
          <FilterSvg fill="white" />
          <p className="mt-2">Filter</p>
        </div>
      </button> */}
    </>
  );
};

export default EnquiryMaster;
