import classNames from 'classnames';
import React, { useCallback } from 'react';
import Select, { SingleValue } from 'react-select';
import { IOptions } from '../../../types/global';
import './index.css';

export interface SelectProps {
  label?: string;
  name?: string;
  options: IOptions[];
  defaultValue?: IOptions | null;
  error?: string;
  placeholder?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  classes?: string;
  mandatory?: boolean;
  onSelect?: (option?: IOptions, name?: string) => void;
}

const InputSelect: React.FC<SelectProps> = ({
  label,
  name,
  options,
  defaultValue,
  error,
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isClearable = false,
  isLoading = false,
  mandatory = false,
  classes,
  onSelect
}) => {
  const handleChange = useCallback(
    (newValue: SingleValue<IOptions>) => {
      if (onSelect) {
        if (name) {
          onSelect(newValue as IOptions, name);
        } else {
          onSelect(newValue as IOptions, undefined);
        }
      }
    },
    [onSelect, name]
  );

  return (
    <div className="w100p">
      <p className="text-14 font-500 mb-4">
        {label}
        {mandatory && <span className="color-error">*</span>}
      </p>
      <Select
        name={name}
        options={options}
        menuPosition="fixed"
        className={classNames(
          'defaultSelectBtn',
          classes && classes,
          error && 'invalid'
        )}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isLoading={isLoading}
        classNamePrefix="default-select"
        value={defaultValue || null}
        onChange={handleChange}
      />
      {error && <p className="text-12 color-error">{error}</p>}
    </div>
  );
};

export default InputSelect;
