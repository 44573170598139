import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const usePreferences = () => {
  const { i18n } = useTranslation();

  const [theme, __setTheme] = useState<string>(
    () => localStorage.getItem('data-theme') || 'default'
  );
  const [language, __setLanguage] = useState<string>(
    () => localStorage.getItem('data-language-name') || 'en'
  );

  useEffect(() => {
    const savedTheme = localStorage.getItem('data-theme') || 'default';
    __setTheme(savedTheme);

    const savedLanguage =
      localStorage.getItem('data-language-name') || 'english';
    __setLanguage(savedLanguage);
  }, []);

  const setTheme = useCallback((theme: string) => {
    __setTheme(theme);
    localStorage.setItem('data-theme', theme);
    document.getElementById('root')?.setAttribute('data-theme', theme);
  }, []);

  const setLanguage = useCallback(
    (language: string, value: string) => {
      __setLanguage(language);
      localStorage.setItem('data-language', value);
      localStorage.setItem('data-language-name', language);
      if (i18n.language !== value) {
        i18n.changeLanguage(value);
      }
    },
    [i18n]
  );

  return {
    theme,
    setTheme,
    language,
    setLanguage
  };
};

export default usePreferences;
