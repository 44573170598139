import { useState } from "react";

import { JCP } from "../../types/model";
import { UseJcPlanningByIdHook } from "../../types/hooks";
import useEffectAfterMount from "./useEffectAfterMount";
import JcPlanningService from "../services/JcPlanningService";

export default function useJcPlanningById({
  id,
  onFailure,
  additional_fields: additionalFields
}: UseJcPlanningByIdHook.Parameters): UseJcPlanningByIdHook.Result {
  const [data, setData] = useState<JCP | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    setIsLoading(true);
    JcPlanningService.getJcPlanningById({
      id,
      additionalFields
    })
      .then((data: JCP) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        if (onFailure) {
          onFailure.apply(null, [error]);
        }
      });
  }, [id, onFailure, additionalFields]);

  return {
    data,
    isLoading
  };
}
