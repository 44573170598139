import { useEffect, useRef } from 'react';

const useOnOutsideClick = <T = HTMLElement>(handler:()=>void) => {
  const ref = useRef<T|null>(null);
  useEffect(() => {
    function handleClickOutside(event:MouseEvent) {
      const element = (ref.current) as HTMLElement;

      if (!element || element.contains(event.target as Node)) {
        return;
      }
      handler();
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handler, ref]);

  return ref;
};

export default useOnOutsideClick;
