import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputSelect from '../../components/form-components/input-select';
import Loader from '../../components/loader';
import SuccessLayout from '../../layout/success-layout';
import ViewLayout from '../../layout/view-layout';
import { convertDateFormat } from '../../utils/ConvertDateFormat';
import { getFullName } from '../../utils/FullNameUtil';
import ToastUtil from '../../utils/ToastUtil';
import { IOptions, IProspectValidation } from '../../../types/global';
import UserContext from '../../context/UserContext';

const ProspectAssignment: React.FC = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [dsp, setDsp] = useState([]);
  const [dspOption, setDspOption] = useState([]);

  const { id } = useParams();
  const [isFetching, setIsFetching] = useState({
    tasks: true,
    dsp: true
  });
  const [data, setData] = useState<IProspectValidation | undefined>(undefined);
  const [showSuccessLayout, setShowSuccessLayout] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedDsp, setSelectedDsp] = useState<IOptions>();

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      tasks: true
    }));
    axios
      .get(`/api/v1/tasks/pending-prospect-assignment-tasks/${id}`)
      .then((response) => {
        setData(response.data.data);
        setIsFetching((prevState) => ({
          ...prevState,
          tasks: false
        }));
      });
  }, [id]);

  useEffect(() => {
    if (data?.prospect.dealer.dealer_code) {
      setIsFetching((prevState) => ({
        ...prevState,
        dsp: true
      }));
      axios
        .get(
          `/api/v1/user/my-dsp-reportees?dealer_codes=${data?.prospect.dealer.dealer_code}`
        )
        .then((response) => {
          const { data } = response.data;
          setDsp(data);

          const formattedData = data.map((item: any) => ({
            label: getFullName(item.first_name, item.last_name),
            value: item.email
          }));

          setDspOption(formattedData);
          setSelectedDsp(formattedData[0]);
          setIsFetching((prevState) => ({
            ...prevState,
            dsp: false
          }));
        });
    }
  }, [data?.prospect.dealer.dealer_code]);

  const goToHome = useCallback(() => {
    navigate(`/${user.user_role}/main/home`, {
      replace: true
    });
  }, [navigate]);

  const assignProspect = useCallback(() => {
    setSubmitting(true);
    axios
      .put(`/api/v1/prospect-assignment-tasks/${id}`, {
        email_id: selectedDsp?.label
      })
      .then(() => {
        setShowSuccessLayout(true);
        setSubmitting(false);
      })
      .catch((err) => {
        const { message } = err.response;
        ToastUtil.makeFailureToast(message);
      });
  }, [id]);

  const handleDspChange = (dsp: IOptions) => {
    setSelectedDsp(dsp as IOptions);
  };

  return (
    <>
      {showSuccessLayout ? (
        <SuccessLayout
          title="Congratulations!"
          desc="The prospect has been assigned successfully."
          button_name="Back to Home"
          onClick={goToHome}
        />
      ) : (
        <ViewLayout title="Prospect Assignment">
          {isFetching.dsp || isFetching.tasks ? (
            <Loader />
          ) : (
            <>
              <div className="tafe-form-body">
                <div className="tafe-card p-16 mb-20">
                  <div className="dF direction-column gap-8">
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">
                        Prospect Name :
                      </p>
                      <p className="text-14 font-600 color-black ls-028">
                        {data?.prospect.name}
                      </p>
                    </div>
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">
                        Contact :{' '}
                      </p>
                      <p className="text-14 color-black ls-028">
                        {data?.prospect.contact.mobile_number}
                      </p>
                    </div>
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">
                        Village :{' '}
                      </p>
                      <p className="text-14 color-black ls-028">
                        {data?.prospect.contact.village}
                      </p>
                    </div>
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">
                        Prospect Type :
                      </p>
                      <p className="text-14 color-black ls-028">
                        {data?.prospect.enquiry_classification}
                      </p>
                    </div>
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">
                        Model Interested :
                      </p>
                      <p className="text-14 color-black ls-028">
                        {data?.prospect.interested_model_family}
                      </p>
                    </div>
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">EDD : </p>
                      <p className="text-14 color-black ls-028">
                        {data?.prospect.edd
                          ? convertDateFormat(data?.prospect.edd)
                          : ''}
                      </p>
                    </div>
                    <div className="dF align-center gap-4">
                      <p className="text-14 color-black-70 ls-028">Dealer : </p>
                      <p className="text-14 color-black ls-028">
                        {data?.prospect.dealer.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="tafe-card p-16">
                  <InputSelect
                    label="Select DSP"
                    options={dspOption}
                    defaultValue={selectedDsp}
                    onSelect={(option) => {
                      if (option && !Array.isArray(option)) {
                        handleDspChange(option);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="tafe-form-footer">
                <button className="secondary-btn" onClick={goToHome}>
                  Reject
                </button>
                <button
                  className={classNames('primary-btn pR')}
                  disabled={submitting}
                  onClick={assignProspect}
                >
                  <p className={classNames(submitting && 'vH')}>Assign</p>
                  <div className={classNames('pA', !submitting && 'vH')}>
                    <div className="dot-flashing"></div>
                  </div>
                </button>
              </div>
            </>
          )}
        </ViewLayout>
      )}
    </>
  );
};

export default ProspectAssignment;
