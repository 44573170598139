import { Framework, IEnquiriesStat } from '../../types/global';
import { ProspectServiceParameters } from '../../types/parameters';
import APIRequest from '../constants/APIRequest';
import DateFormat from '../enums/DateFormat';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';
import DateProcessor from '../processor/DateProcessor';

export default class ProspectService {
  public static getEnquiryStat = ({
    params: { endDateTime, startDateTime },
    signal
  }: {
    params: {
      startDateTime?: number;
      endDateTime?: number;
    };
    signal: AbortSignal;
  }): Promise<Framework.IResult<IEnquiriesStat>> => {
    const reqHandler = new RequestHandler(
      APIRequest.PROSPECT_STATS,
      RequestMethod.GET
    );

    if (startDateTime !== undefined) {
      reqHandler.addParam(
        'start_date_time',
        DateProcessor.format(startDateTime, DateFormat.ISO_DATE_TIME)
      );
    }
    if (endDateTime !== undefined) {
      reqHandler.addParam(
        'end_date_time',
        DateProcessor.format(endDateTime, DateFormat.ISO_DATE_TIME)
      );
    }

    reqHandler.setAbortSignal(signal);

    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<IEnquiriesStat>);
  };

  private static async retrievePagedProspects({
    url,
    page,
    filters: {
      sogs,
      stages,
      contactName,
      edd_end_value,
      contactMobileNumber,
      enquiryClassifications,
      created_time_start_value,
      created_time_end_value,
      dealer_codes
    },
    perPage,
    additionalFields = [],
    signal
  }: ProspectServiceParameters.GetPagedProspect.Parameters & {
    url: string;
  }): Promise<ProspectServiceParameters.GetPagedProspect.ReturnType> {
    const json: Record<string, unknown> = {};
    const handler = new RequestHandler(url, RequestMethod.GET);
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);

    if (contactMobileNumber) {
      handler.addParam('contact_mobile', contactMobileNumber);
    }
    if (contactName) {
      handler.addParam('contact_name', contactName);
    }
    if (enquiryClassifications && enquiryClassifications.length > 0) {
      handler.addParam(
        'enquiry_classifications',
        enquiryClassifications.toLocaleString()
      );
    }
    if (sogs && sogs.length > 0) {
      handler.addParam('sogs', sogs.toLocaleString());
    }
    if (stages && stages.length > 0) {
      handler.addParam('stages', stages.toLocaleString());
    }
    handler.setBody(json);
    if (signal) {
      handler.setAbortSignal(signal);
    }
    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }
    if (created_time_start_value) {
      handler.addParam(
        'created_time_start_value',
        DateProcessor.format(created_time_start_value, DateFormat.ISO_DATE_TIME)
      );
    }
    if (created_time_end_value) {
      handler.addParam(
        'created_time_end_value',
        DateProcessor.format(created_time_end_value, DateFormat.ISO_DATE_TIME)
      );
    }
    if (edd_end_value) {
      handler.addParam(
        'edd_end_value',
        DateProcessor.format(edd_end_value, DateFormat.ISO_DATE)
      );
    }
    if (dealer_codes && dealer_codes.length > 0) {
      handler.addParam('dealer_codes', dealer_codes.toLocaleString());
    }

    return handler
      .execute()
      .then(
        (result) =>
          result.data as unknown as ProspectServiceParameters.GetPagedProspect.ReturnType
      );
  }

  public static async getPagedProspects(
    params: ProspectServiceParameters.GetPagedProspect.Parameters
  ): Promise<ProspectServiceParameters.GetPagedProspect.ReturnType> {
    return this.retrievePagedProspects({ url: '/api/v1/prospects', ...params });
  }

  public static async getPagedMyDspReporteeProspects({
    myDspReporteeId,
    ...params
  }: ProspectServiceParameters.GetPagedProspect.Parameters & {
    myDspReporteeId: string;
  }): Promise<ProspectServiceParameters.GetPagedProspect.ReturnType> {
    return this.retrievePagedProspects({
      url: `/api/v1/user/my-dsp-reportees/${myDspReporteeId}/prospects`,
      ...params
    });
  }
}
