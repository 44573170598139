import { useCallback, useState } from 'react';
import { IOptions } from '../../../../types/global';
import { UsePagedVillagesHook } from '../../../../types/hooks';
import RecordCollection from '../../../components/record-collection';
import usePagedVillages from '../../../hooks/usePagedVillages';
import useScroll from '../../../hooks/useScroll';
import ViewLayout from '../../../layout/view-layout';

const allDropDownOptions = [
  { value: 'name', label: 'Name' },
  { value: 'state', label: 'State' },
  { value: 'district', label: 'District' },
  { value: 'taluk', label: 'Taluk' }
];

const VillageMaster = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedVillages({
    page: 1,
    perPage: 200,
    filters: {}
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedVillagesHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue('');
      setSearchLabel(value.label.toLowerCase());
      setFilters({});
    },
    [setFilters]
  );

  return (
    <ViewLayout title="Villages">
      <RecordCollection>
        <RecordCollection.DropdownSearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={allDropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper key={item.id}>
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle
                    title={item.name}
                    color_code={item.color_code ? item.color_code : undefined}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="type"
                    value={item.type || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Taluka"
                    value={item.taluka || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="District"
                    value={item.district || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="State"
                    value={item.state}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="TIV"
                    value={item.tiv || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="BRYG"
                    value={item.color_code || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Latitude"
                    value={item.latitude}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Longitude"
                    value={item.longitude}
                  />
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default VillageMaster;
