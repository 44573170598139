import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import AppUrlConstants from '../shared/constants/AppUrlConstants';
import style from './index.module.css';
import { useTranslation } from 'react-i18next';
import JcpStatuses from '../../shared/enums/JcpStatuses';
import Loader from '../../shared/components/loader';
import usePagedJcPlannings from '../../shared/hooks/usePagedJcPlannings';
import FormComponents from '../../shared/components/form-components';
import { IOptions } from '../../types/global';
import EmptyData from '../../shared/components/empty-data';
import ViewLayout from '../../shared/layout/view-layout';
import AppUrlConstants from '../../shared/constants/AppUrlContanst';

const CheckInJcps: React.FC = () => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [initializeJcp, setInitializeJcp] = useState(true);
  const [month, setMonth] = useState<number>(currentMonth);
  const [year, setYear] = useState<number>(currentYear);
  const [selectedDate, setSelectedDate] = useState<Date>(currentDate);
  const [dates, setDates] = useState<Date[]>([]);
  const [filters] = useState({
    checkedIn: false,
    dateStartValue: Date.now(),
    dateEndValue: Date.now(),
    statuses: [JcpStatuses.APPROVED]
  });

  const { data, isLoading, updateFilters } = usePagedJcPlannings({
    page: 1,
    perPage: 200,
    filters
  });

  const monthArray: IOptions[] = Array.from({ length: 12 }, (_, index) => ({
    label: new Date(0, index).toLocaleString('default', { month: 'long' }),
    value: new Date(0, index).toLocaleString('default', { month: 'long' })
  }));

  const yearArray: IOptions[] = Array.from(
    { length: currentYear - 2014 },
    (_, index) => ({
      label: (2015 + index).toString(),
      value: (2015 + index).toString()
    })
  );

  useEffect(() => {
    updateDates(year, month);
  }, [year, month]);

  const updateDates = (year: number, month: number) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const newDates = Array.from(
      { length: daysInMonth },
      (_, index) => new Date(year, month, index + 1)
    );
    setDates(newDates);
    if(!initializeJcp){
      updateFilters({
        dateStartValue: new Date(year, month, 1).getTime(),
        dateEndValue: new Date(year, month, 1).getTime()
      })
      return;
    }
    setInitializeJcp(false);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    updateFilters({
      dateStartValue: date.getTime(),
      dateEndValue: date.getTime()
    })
  };

  const handleMonthChange = (option: IOptions | undefined) => {
    if(option){
      const selectedMonth = monthArray.find((m) => m.value === option.value);
      if (selectedMonth) {
        const newMonth = monthArray.indexOf(selectedMonth);
        setMonth(newMonth);
        setSelectedDate(new Date(year, newMonth, 1));
      }
    }
  };

  const handleYearChange = (option: IOptions | undefined) => {
    if(option){
      const newYear = Number(option.value);
      setYear(newYear);
      setSelectedDate(new Date(newYear, month, 1));
    }
  };

  return (
    <ViewLayout title={t('common.jcp')}>
      <div className="text-16 font-400 p-16-20 bg-white bb-black-10">{t('dsp.check_in_jcps.today_activity')}</div>
      <div
        className={`dF gap-20 m-15-20 ${style['checkin-jcps-dropdown']}`}
      >
        <FormComponents.InputSelect
          defaultValue={monthArray[month]}
          options={monthArray}
          onSelect={handleMonthChange}
        />
        <FormComponents.InputSelect
          defaultValue={yearArray.find((y) => y.value === year.toString())}
          options={yearArray}
          onSelect={handleYearChange}
        />
      </div>
      <div
        className={`${style['checkin-jcp-calendar']} dF align-center bg-white bb-black-10`}
      >
        {dates.map((item) => (
          <div
            key={item.toISOString()} // Use a unique key
            className={classNames(
              style['calendar-date'],
              selectedDate.toDateString() === item.toDateString() && `bg-primary color-white`,
              'text-16 font-400 color-black-40 p-10-12 br-circle dF align-center justify-center'
            )}
            onClick={() => handleDateChange(item)}
          >
            {item.getDate()}
          </div>
        ))}
      </div>
      <div className={`m-20 dF direction-column gap-6 ${style['checkin-jcp-villages']}`}>
        {isLoading ? (
            <Loader size='medium'/>
        ) : data.length === 0 ? (
            <EmptyData/>
        ) : (
          data.map((item) => (
            <Link
              className="p-16-20 bg-white br-12 dF direction-column gap-4"
              key={item?.id}
              to={`/${AppUrlConstants.DSP.BASE_NAME}/${AppUrlConstants.DSP.CHECKIN.BASE_NAME}/${AppUrlConstants.DSP.CHECKIN.JCP}/${item?.id}`}
            >
              <div className="text-16 font-600 color-black">
                {t('dsp.check_in_jcps.village_visit')} - {item?.village.name}
              </div>
              <div className='dF direction-column gap-2'>
                <div className="text-14 font-400 color-black">
                  <span className="color-black-55">
                    {t('dsp.check_in_jcps.tiv')} -{' '}
                  </span>
                  {item?.village.tiv ? item.village.tiv : `NA`}
                </div>
                <div className="text-14 font-400 color-black">
                  <span className="color-black-55">
                    {t('dsp.check_in_jcps.bryg')} -{' '}
                  </span>
                  <span className={`color-${item?.village.color_code}`}>
                    {item?.village.color_code ? item.village.color_code : `NA`}
                  </span>
                </div>
                <div className="text-14 font-400 color-black">
                  <span className="color-black-55">
                    {t('dsp.check_in_jcps.pdov')} -{' '}
                  </span>
                  {new Date(item?.date as string).toLocaleDateString()}
                </div>
                <div className="text-14 font-400 color-black">
                  <span className="color-black-55">
                    {t('dsp.check_in_jcps.taluka')} -{' '}
                  </span>
                  {item?.village.taluka}
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </ViewLayout>
  );
};

export default CheckInJcps;
