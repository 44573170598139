import { HtmlHTMLAttributes, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dummyUser from '../../../assets/images/dummy-user.png';
import UserContext from '../../../context/UserContext';
import ViewLayout from '../../../layout/view-layout';
import { getFullName } from '../../../utils/FullNameUtil';
import styles from './styles.module.css';
import useOnOutsideClick from '../../../hooks/useOnOutsideClick';
import useModal from '../../../hooks/useModal';
import classNames from 'classnames';
import usePreferences from '../../../hooks/usePreferences';
import { motion } from 'framer-motion';

const Settings: React.FC = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [selectedPreferences, setSelectedPreferences] = useState('');
  const [themes, setThemes] = useState([
    { name: 'default', selected: true },
    { name: 'dark-red', selected: false },
    { name: 'deep-teal', selected: false },
    { name: 'steel-blue', selected: false }
  ]);
  const [languages, setLanguages] = useState([
    { label: 'english', value: 'en', selected: true },
    { label: 'தமிழ்', value: 'ta', selected: false }
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    label: '',
    value: ''
  });

  const onSignout = () => {
    let { origin } = window.location;
    let catalystAuth = (window as any).catalyst.auth;
    catalystAuth.signOut(`${origin}/#/login`);
  };
  const { theme, setTheme, language, setLanguage } = usePreferences();

  const { open: showModal, openModal, closeModal } = useModal({});
  const modalRef = useOnOutsideClick<HTMLDivElement>(closeModal);

  const openTheme = useCallback(() => {
    openModal();
    setSelectedPreferences('theme');
  }, []);

  const openLanguage = useCallback(() => {
    openModal();
    setSelectedPreferences('language');
    setSelectedLanguage({ label: language, value: language });
  }, [language]);

  const selectTheme = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.currentTarget as HTMLElement;
    const name = element.getAttribute('data-name');
    setTheme(name || 'default');
    setThemes((prevThemes) =>
      prevThemes.map((theme) => ({
        ...theme,
        selected: theme.name === name
      }))
    );
  }, []);
  const selectLanguage = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const label = element.getAttribute('data-label') || '';
      const value = element.getAttribute('data-value') || '';
      setSelectedLanguage({ label: label, value: value });
      setLanguages((prevLanguages) =>
        prevLanguages.map((language) => ({
          ...language,
          selected: language.label === label
        }))
      );
    },
    []
  );
  const applyLanguage = useCallback(() => {
    if (selectedLanguage.label) {
      setLanguage(selectedLanguage.label, selectedLanguage.value);
      closeModal();
    }
  }, [selectedLanguage]);

  return (
    <>
      <div className="dN">
        <svg
          id="theme-icon"
          fill="#0000008C"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Layer 2">
            <g data-name="color-palette">
              <rect width="24" height="24" opacity="0" />
              <path d="M19.54 5.08A10.61 10.61 0 0 0 11.91 2a10 10 0 0 0-.05 20 2.58 2.58 0 0 0 2.53-1.89 2.52 2.52 0 0 0-.57-2.28.5.5 0 0 1 .37-.83h1.65A6.15 6.15 0 0 0 22 11.33a8.48 8.48 0 0 0-2.46-6.25zM15.88 15h-1.65a2.49 2.49 0 0 0-1.87 4.15.49.49 0 0 1 .12.49c-.05.21-.28.34-.59.36a8 8 0 0 1-7.82-9.11A8.1 8.1 0 0 1 11.92 4H12a8.47 8.47 0 0 1 6.1 2.48 6.5 6.5 0 0 1 1.9 4.77A4.17 4.17 0 0 1 15.88 15z" />
              <circle cx="12" cy="6.5" r="1.5" />
              <path d="M15.25 7.2a1.5 1.5 0 1 0 2.05.55 1.5 1.5 0 0 0-2.05-.55z" />
              <path d="M8.75 7.2a1.5 1.5 0 1 0 .55 2.05 1.5 1.5 0 0 0-.55-2.05z" />
              <path d="M6.16 11.26a1.5 1.5 0 1 0 2.08.4 1.49 1.49 0 0 0-2.08-.4z" />
            </g>
          </g>
        </svg>
        <svg
          id="language-icon"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.58 19.37L17.59 11.01C17.38 10.46 16.91 10.12 16.37 10.12C15.83 10.12 15.37 10.46 15.14 11.03L12.16 19.37C12.02 19.76 12.22 20.19 12.61 20.33C13 20.47 13.43 20.27 13.57 19.88L14.19 18.15H18.54L19.16 19.88C19.27 20.19 19.56 20.38 19.87 20.38C19.95 20.38 20.04 20.37 20.12 20.34C20.51 20.2 20.71 19.77 20.57 19.38L20.58 19.37ZM14.74 16.64L16.38 12.05L18.02 16.64H14.74ZM12.19 7.85C9.92999 11.42 7.89 13.58 5.41 15.02C5.29 15.09 5.16 15.12 5.04 15.12C4.78 15.12 4.53 14.99 4.39 14.75C4.18 14.39 4.3 13.93 4.66 13.73C6.75999 12.51 8.48 10.76 10.41 7.86H4.12C3.71 7.86 3.37 7.52 3.37 7.11C3.37 6.7 3.71 6.36 4.12 6.36H7.87V4.38C7.87 3.97 8.21 3.63 8.62 3.63C9.02999 3.63 9.37 3.97 9.37 4.38V6.36H13.12C13.53 6.36 13.87 6.7 13.87 7.11C13.87 7.52 13.53 7.86 13.12 7.86H12.18L12.19 7.85ZM12.23 15.12C12.1 15.12 11.97 15.09 11.85 15.02C11.2 14.64 10.57 14.22 9.97999 13.78C9.64999 13.53 9.58 13.06 9.83 12.73C10.08 12.4 10.55 12.33 10.88 12.58C11.42 12.99 12.01 13.37 12.61 13.72C12.97 13.93 13.09 14.39 12.88 14.75C12.74 14.99 12.49 15.12 12.23 15.12Z"
            fill="#0000008C"
          />
        </svg>
        <svg
          id="arrow-icon"
          fill="#000000"
          width="14px"
          height="14px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g data-name="Layer 2">
            <g data-name="arrow-ios-forward">
              <rect
                width="24"
                height="24"
                transform="rotate(-90 12 12)"
                opacity="0"
              />

              <path d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z" />
            </g>
          </g>
        </svg>
      </div>
      <ViewLayout title="Settings">
        <div className={styles['profile-box']}>
          <img
            className={styles['profile-img']}
            src={dummyUser}
            alt="User Image"
          />
          <p className="text-18 color-primary mt-10">
            {user && getFullName(user.data.first_name, user.data.last_name)}
          </p>
          <p className="text-14 color-black-70 mt-2">
            {user && user.data.email}
          </p>
        </div>
        <div className={styles['setting-container']}>
          <p className="text-14 color-black-70 font-500">Preferences</p>
          <div className={styles['setting-list']} onClick={openLanguage}>
            <div className="dF align-center gap-14">
              <div className={styles['setting-svg-box']}>
                <svg className={styles['setting-svg']}>
                  <use href="#language-icon"></use>
                </svg>
              </div>
              <p className="text-14 color-black-70">Language</p>
            </div>
            <div className="dF align-center gap-14">
              <p className="text-14 color-black text-capitalize">{language}</p>
              <svg
                className={styles['setting-svg']}
                style={{ marginTop: '7px' }}
              >
                <use href="#arrow-icon"></use>
              </svg>
            </div>
          </div>
          <div className={styles['setting-list']} onClick={openTheme}>
            <div className="dF align-center gap-14">
              <div className={styles['setting-svg-box']}>
                <svg className={styles['setting-svg']}>
                  <use href="#theme-icon"></use>
                </svg>
              </div>
              <p className="text-14 color-black-70">Theme</p>
            </div>
            <div className="dF align-center gap-14">
              <p className="text-14 color-black text-capitalize">{theme}</p>
              <svg
                className={styles['setting-svg']}
                style={{ marginTop: '6px' }}
              >
                <use href="#arrow-icon"></use>
              </svg>
            </div>
          </div>
        </div>
        <button className="primary-btn m-auto" onClick={onSignout}>
          Log Out
        </button>
      </ViewLayout>

      {showModal &&
        (selectedPreferences == 'theme' ? (
          <>
            <div className={styles['freeze-page-popup']}></div>
            <motion.div
              className={styles['popup-container']}
              ref={modalRef}
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{
                duration: 0.5,
                type: 'spring',
                stiffness: 300,
                damping: 25
              }}
            >
              <div className={styles['popup-container-header']}>
                <p className="text-18 font-500 color-black">Themes</p>
              </div>
              <div
                className={styles['popup-container-body']}
                style={{ height: '300px' }}
              >
                <div>
                  <p className="text-16 color-black-70 mb-20">Select Theme :</p>
                  <div className="dF align-center gap-20">
                    {themes.map((item) => (
                      <div
                        key={item.name}
                        className={classNames(
                          styles['theme-round'],
                          styles[item.name],
                          item.name === theme && styles['selected']
                        )}
                        data-name={item.name}
                        onClick={selectTheme}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        ) : (
          <>
            <div className={styles['freeze-page-popup']}></div>
            <motion.div
              className={styles['popup-container']}
              ref={modalRef}
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{
                duration: 0.5,
                type: 'spring',
                stiffness: 300,
                damping: 25
              }}
            >
              <div className={styles['popup-container-header']}>
                <p className="text-18 font-500 color-black">Languages</p>
              </div>
              <div
                className={styles['popup-container-body']}
                style={{ height: '300px' }}
              >
                <div>
                  <p className="text-16 color-black-70 mb-20">
                    Select Language :
                  </p>
                  <div className="dF align-center gap-20">
                    {languages.map((item) => (
                      <div
                        key={item.label}
                        className={classNames(
                          styles['language-option'],
                          selectedLanguage.label === item.label &&
                            styles['selected']
                        )}
                        data-label={item.label}
                        data-value={item.value}
                        onClick={selectLanguage}
                      >
                        <div className={styles['language-select-box']}></div>
                        <div>{item.label}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="primary-btn ml-auto mr-auto"
                  onClick={applyLanguage}
                >
                  Save
                </div>
              </div>
            </motion.div>
          </>
        ))}
    </>
  );
};

export default Settings;
