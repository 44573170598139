import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { IOptions } from '../../../types/global';
import BlackCrossSvg from '../../assets/icons/BlackCrossSvg';
import FilterSvg from '../../assets/icons/FilterSvg';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import styles from './style.module.css';

export type FilterProps = {
  defaultValue?: IOptions | null;
  values: string[];
  onChange?: (data: { label: string }) => void;
};

const Filter: React.FC<FilterProps> = ({ defaultValue, values, onChange }) => {
  const filterWrapper = useRef<HTMLDivElement>(null);
  const Options = useRef<(HTMLInputElement | null)[]>([]);
  const [option, setOption] = useState<IOptions | null>(defaultValue ? defaultValue : null);
  const [showFilter, setShowFilter] = useState(false);

  const options: IOptions[] = values.map((val) => ({
    label: val,
    value: val
  }));

  const removeOption = useCallback(() => {
    setOption(null);
    if (onChange) {
      onChange({ label: '' });
    }
  }, [onChange]);

  const onClickOption = useCallback(
    (selectedOption: IOptions) => {
      setOption(selectedOption);
      setShowFilter(false);

      if (onChange) {
        onChange({ label: selectedOption.label });
      }
    },
    [onChange]
  );

  const closeAllPopup = useCallback(() => {
    setShowFilter(false);
  }, []);

  const filterPopupRef = useOnOutsideClick<HTMLDivElement>(closeAllPopup);

  return (
    <>
      <div
        ref={filterWrapper}
        className="dF align-center justify-between cP pR"
      >
        <div className="w-full">
          {option ? (
            <div className={styles['selectedBadge']}>
              <span className={styles['selectedLabel']}>{option.label}</span>
              <span className={styles['close-icon']} onClick={removeOption}>
                <BlackCrossSvg />
              </span>
            </div>
          ) : (
            <div className={styles['selectedBadge']}>
              <span className={styles['selectedLabel']} style={{ margin: 0 }}>
                All
              </span>
            </div>
          )}
        </div>
        <div
          className={classNames(styles['pR'])}
          onClick={() => setShowFilter(!showFilter)}
        >
          <FilterSvg />
          <div
            className={classNames({
              [styles['dot']]: !!option?.label
            })}
          ></div>
        </div>
        {showFilter && (
          <div
            ref={filterPopupRef}
            className={classNames(styles['filterContainer'], 'pA', 'bg-white', {
              dB: showFilter
            })}
          >
            {options.map((item, index) => (
              <div
                key={item.value}
                className={`${styles.filterOption} p-12-8 cP dF bb-black-10`}
                onClick={() => onClickOption(item)}
              >
                <input
                  className="cP"
                  type="radio"
                  name="filterOption"
                  value={item.value}
                  ref={(element: HTMLInputElement | null) =>
                    (Options.current[index] = element)
                  }
                  checked={option?.value === item.value}
                  readOnly
                />
                <label className="w140 text-14 font-400 cP mt-5 hFitContent text-overflow-wrap">
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Filter;
