import React, { HTMLAttributes } from 'react'
import style from './index.module.css';
const Header: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({children}) => {
    return (
        <>
            <div className={`dF p-20 align-center justify-between text-18 color-white font-600 ${style['header']}`}>
                {children}
            </div>
        </>
        
    )
}

export default Header