import React from "react";

export type ListItemTitleProps = {
  title: string;
  color_code?: string
};

const ListItemTitle: React.FC<ListItemTitleProps> = ({ title, color_code }) => {
  return <div className="list-title">
    {color_code && (<span className={`edd-type ${color_code}-dot`}></span>)}
    <h6 className="text-14 font-600 color-black">{title}</h6>
  </div>;
};


export default ListItemTitle;