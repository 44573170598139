import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavigateArrowSvg from '../../../../shared/assets/icons/NavigateArrowSvg';
import DateFilter from '../../../../shared/components/date-filter';
import Loader from '../../../../shared/components/loader';
import NavCard from '../../../../shared/components/nav-card';
import ProspectEnquiryClassification from '../../../../shared/enums/ProspectEnquiryClassification';
import ProspectStage from '../../../../shared/enums/ProspectStage';
import useEnquiryStat from '../../../../shared/hooks/useEnquiryStat';
import UserContext from '../../../context/UserContext';
import styles from './styles.module.css';

const Enquiry: React.FC = () => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const { data, isLoading } = useEnquiryStat({
    startDateTime: selectedDateRange?.start,
    endDateTime: selectedDateRange?.end
  });

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');
      const stages = element.getAttribute('data-stages');
      const enquiryClassifications = element.getAttribute(
        'data-enquiry-classifications'
      );
      const edd = element.getAttribute('data-edd-end-value');
      const sogs = element.getAttribute('data-sogs');
      const searchParams = new URLSearchParams({
        ...(stages ? { stages } : {}),
        ...(sogs ? { sogs } : {}),
        ...(enquiryClassifications
          ? { enquiry_classifications: enquiryClassifications }
          : {})
      });
      if (title) {
        searchParams.set('title', title);
      }

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          searchParams.set(
            'created_time_start_value',
            selectedDateRange.start.toString()
          );
        }
        if (selectedDateRange.end) {
          searchParams.set(
            'created_time_end_value',
            selectedDateRange.end.toString()
          );
        }
      }

      if (edd) {
        searchParams.set('edd_end_value', edd);
      }

      navigate(
        {
          pathname: `/${user.user_role}/prospects`,
          search: searchParams.toString()
        },
        {}
      );
    },
    [selectedDateRange, navigate]
  );

  const openEnquiry = useCallback(() => {
    navigate(`/${user.user_role}/prospect/create`);
  }, []);

  return (
    <>
      <div className="p-20">
        <DateFilter
          values={[
            'All Time',
            'Today',
            'Yesterday',
            'This week',
            'Last week',
            'This month',
            'Last month',
            'Last 3 months',
            'This year',
            'Custom range'
          ]}
          onChange={handleDateChange}
        />
        {isLoading ? (
          <div style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 270px)' }}>
            <Loader />
          </div>
        ) : (
          <>
            <div className="tafe-card p-14 mt-20 mb-20">
              <NavCard
                title={t('enquiries.enquiry_conversion')}
                customAttributes={{
                  'data-title': 'Enquiry Conversion',
                  'data-stages': 'Delivery,Sales Lost,Sales Dropped'
                }}
                showArrow={true}
                onClick={navigateToDetails}
              >
                <div className="dF w100p">
                  <div className="w100p pt-10 pb-10 br-primary-10">
                    <p className="text-12 color-black-55 font-600 ls-048">
                      {t('common.deliveries')}
                    </p>
                    <p
                      className="text-18 color-link font-500 mt-10  dF align-center"
                      data-stages="Delivery"
                      data-title="Delivery"
                      onClick={navigateToDetails}
                    >
                      {data?.total_deliveries}
                    </p>
                  </div>
                  <div className="w100p pt-10 pb-10 br-primary-10">
                    <div className="w100p pl-10 br-primary-10">
                      <p className="text-12 color-black-55 font-600 ls-048">
                        {t('common.sales_lost')}
                      </p>
                      <p
                        className="text-18 color-link font-500 mt-10  dF align-center"
                        data-stages="Sales Lost"
                        data-title="Sales Lost"
                        onClick={navigateToDetails}
                      >
                        {data?.total_sales_lost}
                      </p>
                    </div>
                  </div>
                  <div className="w100p pt-10 pb-10">
                    <div className="w100p pl-10">
                      <p className="text-12 color-black-55 font-600 ls-048">
                        {t('common.sales_dropped')}
                      </p>
                      <p
                        className="text-18 color-link font-500 mt-10  dF align-center"
                        data-stages="Sales Dropped"
                        data-title="Sales Dropped"
                        onClick={navigateToDetails}
                      >
                        {data?.total_sales_dropped}
                      </p>
                    </div>
                  </div>
                </div>
              </NavCard>
            </div>
            <div className="dF gap-12 mb-12">
              <div className="two-column-width">
                <NavCard
                  title={t('common.leads')}
                  customAttributes={{
                    'data-title': ProspectEnquiryClassification.LEAD,
                    'data-enquiry-classifications':
                      ProspectEnquiryClassification.LEAD
                  }}
                  showArrow={true}
                  onClick={navigateToDetails}
                >
                  <p className="text-24 h44 font-500 color-link  dF align-center">
                    {data?.total_leads}
                  </p>
                </NavCard>
              </div>
              <div className="two-column-width">
                <NavCard
                  title={t('common.prospects')}
                  showArrow={true}
                  customAttributes={{
                    'data-title': ProspectEnquiryClassification.PROSPECT,
                    'data-enquiry-classifications':
                      ProspectEnquiryClassification.PROSPECT
                  }}
                  onClick={navigateToDetails}
                >
                  <p className="text-24 font-500 h44 color-link  dF align-center">
                    {data?.total_prospects}
                  </p>
                </NavCard>
              </div>
            </div>
            <div className="dF gap-12 mb-20">
              <div className="two-column-width">
                <NavCard
                  title={t('common.hot_prospects')}
                  showArrow={true}
                  customAttributes={{
                    'data-title': ProspectEnquiryClassification.HOT_PROSPECT,
                    'data-enquiry-classifications':
                      ProspectEnquiryClassification.HOT_PROSPECT
                  }}
                  onClick={navigateToDetails}
                >
                  <p className="text-24 h44 font-500 color-link dF align-center">
                    {data?.total_hot_prospects}
                  </p>
                </NavCard>
              </div>
              <div className="two-column-width">
                <NavCard
                  title={t('common.overdue_hps')}
                  showArrow={true}
                  customAttributes={{
                    'data-title': ProspectEnquiryClassification.HOT_PROSPECT,
                    'data-edd-end-value': Date.now().toString(),
                    'data-stages': [
                      ProspectStage.F1,
                      ProspectStage.F2,
                      ProspectStage.F3,
                      ProspectStage.F4,
                      ProspectStage.F5
                    ].join(','),
                    'data-enquiry-classifications':
                      ProspectEnquiryClassification.HOT_PROSPECT
                  }}
                  onClick={navigateToDetails}
                >
                  <p className="text-24 h44 color-error font-500  dF align-center">
                    {data?.total_overdue_hot_prospects}
                  </p>
                </NavCard>
              </div>
            </div>
            <div className="tafe-card mb-20">
              <div className="text-14 color-secondary font-600 ls-028 p-12 bb-black-10">
                Hot Prospects - Stages
              </div>
              <table className={styles['enquiry-table']}>
                <thead>
                  <tr>
                    <th>Stage</th>
                    <th>HPs</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.hot_prospect_stages.map((item, index) => (
                    <tr
                      key={index}
                      data-title="Hot Prospect - Stages"
                      data-stages={[item.key].join(',').toString()}
                      onClick={navigateToDetails}
                    >
                      <td>{item.key.split('-')[0]}</td>
                      <td>{item.value}</td>
                      <td>
                        <span className={styles['rotate-arrow']}>
                          <NavigateArrowSvg />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="tafe-card">
              <div className="text-14 color-secondary font-600 ls-028 p-12 bb-black-10">
                Hot Prospects SoG
              </div>
              <table className={styles['enquiry-table']}>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>HPs</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.hot_prospect_sogs.map((item, index) => (
                    <tr
                      key={index}
                      data-title="Hot Prospect - SoG"
                      data-sogs={[item.key].join(',').toString()}
                      onClick={navigateToDetails}
                    >
                      <td>{item.key}</td>
                      <td>{item.value}</td>
                      <td>
                        <span className={styles['rotate-arrow']}>
                          <NavigateArrowSvg />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      {user.user_role !== 'tetm' && (
        <button
          className="primary-btn"
          style={{ position: 'fixed', bottom: '100px', right: '15px' }}
          onClick={openEnquiry}
        >
          <div className="plus-icon"></div>
          Add Enquiry
        </button>
      )}
    </>
  );
};

export default Enquiry;
