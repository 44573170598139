import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilterSvg from '../../assets/icons/FilterSvg';
import styles from './style.module.css';
import classNames from 'classnames';
import { IOptions } from '../../../types/global';
import BlackCrossSvg from '../../assets/icons/BlackCrossSvg';
import DateProcessor from '../../processor/DateProcessor';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PopupLayout from '../../layout/popup-layout/PopupLayout';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import useModal from '../../hooks/useModal';

export type FilterProps = {
  defaultValue?: IOptions;
  values: string[];
  onChange?: (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => void;
};

const DateFilter: React.FC<FilterProps> = ({
  defaultValue = { label: 'All Time', value: 'All Time' },
  values,
  onChange
}) => {
  const filterWrapper = useRef<HTMLDivElement>(null);
  const Options = useRef<(HTMLInputElement | null)[]>([]);
  const [option, setOption] = useState<IOptions | null>(defaultValue);
  const [showFilter, setShowFilter] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [label, setLabel] = useState<string>(defaultValue.label);
  const [selectedCustomRange, setSelectedCustomRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({
    start: undefined,
    end: undefined
  });

  const [customRange, setCustomRange] = useState<{
    start: Date | undefined;
    end: Date | undefined;
  }>({
    start: undefined,
    end: undefined
  });
  const { open: showModal, openModal, closeModal } = useModal({});

  const dateOptions: IOptions[] = values.map((val) => ({
    label: val,
    value: val
  }));

  const applyDateRange = () => {
    if (selectedCustomRange.start && selectedCustomRange.end) {
      const formattedLabel =
        DateProcessor.format(selectedCustomRange.start.getTime()) +
        ' to ' +
        DateProcessor.format(selectedCustomRange.end.getTime());

      setCustomRange({
        start: selectedCustomRange.start || undefined,
        end: selectedCustomRange.end || undefined
      });

      setLabel(formattedLabel);
      setOption({
        label: 'Custom range',
        value: 'Custom range'
      });

      if (onChange) {
        onChange({
          dateRange: {
            start: selectedCustomRange.start.getTime(),
            end: selectedCustomRange.end.getTime()
          },
          label: formattedLabel
        });
      }

      closeModal();
    }
  };

  const getDateRange = (
    value: string
  ): { start: number; end: number } | undefined => {
    const currentTimestamp = Date.now();
    switch (value) {
      case 'Today':
        return DateProcessor.getToday(currentTimestamp);
      case 'Yesterday':
        return DateProcessor.getDayBefore(currentTimestamp);
      case 'This week':
        return DateProcessor.getWeekStartOf(currentTimestamp);
      case 'Last week':
        return DateProcessor.getLastWeek(currentTimestamp);
      case 'This month':
        return DateProcessor.getThisMonth(currentTimestamp);
      case 'Last month':
        return DateProcessor.getLastMonth(currentTimestamp);
      case 'Last 3 months':
        return DateProcessor.getLastThreeMonths(currentTimestamp);
      case 'This year':
        return DateProcessor.getThisYear(currentTimestamp);
      case 'All Time':
        return undefined;
      case 'Custom range':
        if (customRange.start && customRange.end) {
          return {
            start: customRange.start.getTime(),
            end: customRange.end.getTime()
          };
        }
        return undefined;
      case 'January':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 0);
      case 'February':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 1);
      case 'March':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 2);
      case 'April':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 3);
      case 'May':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 4);
      case 'June':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 5);
      case 'July':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 6);
      case 'August':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 7);
      case 'September':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 8);
      case 'October':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 9);
      case 'November':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 10);
      case 'December':
        return DateProcessor.getSpecificMonthRange(currentTimestamp, 11);
      default:
        return undefined;
    }
  };

  const onClickOption = useCallback(
    (selectedOption: IOptions | null) => {
      if (selectedOption?.value === 'Custom range') {
        openModal();
        return;
      }
      const dateRange = selectedOption
        ? getDateRange(selectedOption.value)
        : undefined;

      setLabel(selectedOption?.label || '');
      setOption(selectedOption);
      setShowFilter(false);

      if (onChange) {
        onChange({ dateRange, label: selectedOption?.label || '' });
      }
    },
    [onChange, customRange]
  );

  const removeOption = useCallback(() => {
    if (label === defaultValue.label) return;
    setLabel(defaultValue.label);
    setOption({ label: defaultValue.label, value: defaultValue.label });
    if (onChange) {
      onChange({ dateRange: undefined, label: '' });
    }
  }, [label, onChange]);

  const handleCustomRangeChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;
    setSelectedCustomRange({
      start: start || undefined,
      end: end || undefined
    });
  };

  const closeAllPopup = useCallback(() => {
    setShowFilter(false);
  }, []);

  const filterPopupRef = useOnOutsideClick<HTMLDivElement>(closeAllPopup);

  return (
    <>
      <div
        ref={filterWrapper}
        className="dF align-center justify-between cP pR"
      >
        <div className="w-full">
          {label ? (
            <div className={styles['selectedBadge']}>
              <span className={styles['selectedLabel']}>{label}</span>
              <span
                className={classNames(styles['close-icon'], {
                  [styles['disabled']]: label === defaultValue.label
                })}
                onClick={removeOption}
              >
                <BlackCrossSvg />
              </span>
            </div>
          ) : (
            <div className={styles['selectedBadge']}>
              <span className={styles['selectedLabel']} style={{ margin: 0 }}>
                All Time
              </span>
            </div>
          )}
        </div>
        <div
          className={classNames(styles['pR'])}
          onClick={() => setShowFilter(!showFilter)}
        >
          <FilterSvg />
          <div
            className={classNames({
              [styles['dot']]: label?.length
            })}
          ></div>
        </div>
        <div
          ref={filterPopupRef}
          className={classNames(styles['filterContainer'], 'pA', 'bg-white', {
            dB: showFilter
          })}
        >
          {dateOptions.map((item, index) => (
            <div
              key={item.value}
              className={`${styles.filterOption} p-12-8 cP dF bb-black-10`}
              onClick={() => onClickOption(item)}
            >
              <input
                className="cP"
                type="radio"
                name="filterOption"
                value={item.value}
                ref={(element: HTMLInputElement | null) =>
                  (Options.current[index] = element)
                }
                checked={option?.value === item.value}
                onChange={() => {}}
              />
              <label className="w140 text-14 font-400 cP mt-5 hFitContent text-overflow-wrap">
                {item.label}
              </label>
            </div>
          ))}
        </div>
        {/* Show DatePicker for Custom Range */}
        <PopupLayout show={showModal} onClose={closeModal}>
          <div className={styles['datepicker-block']}>
            <DatePicker
              selected={selectedCustomRange.start}
              onChange={handleCustomRangeChange}
              startDate={selectedCustomRange.start}
              endDate={selectedCustomRange.end}
              selectsRange
              inline
            />
          </div>
          <div className={styles['datepicker-footer']}>
            <button className="secondary-btn small" onClick={closeModal}>
              Cancel
            </button>
            <button className="primary-btn small" onClick={applyDateRange}>
              Apply
            </button>
          </div>
        </PopupLayout>
      </div>
      <div className="freeze"></div>
    </>
  );
};

export default DateFilter;
