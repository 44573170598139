import { useCallback, useState } from "react";
import { UseCurrentUserStatHook } from "../../types/hooks";
import { STAT } from "../../types/model";
import UserService from "../services/UserService";
import useEffectAfterMount from "./useEffectAfterMount";

export default function useCurrentUserStat({
    onFailure,
    filters
  }: UseCurrentUserStatHook.Parameters): UseCurrentUserStatHook.Result {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<STAT | undefined>();
    const [error, setError] = useState<any>();
    const [__filters, setFilters] = useState<UseCurrentUserStatHook.Filters | undefined>(filters);
  
    useEffectAfterMount(() => {
      setIsLoading(true);
      UserService.getUserMyStats(
        {
          startDateTime: __filters?.startDateTime,
          endDateTime: __filters?.endDateTime
        }
      )
        .then((result) => {
            setData(result.data);
            setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          if (onFailure) {
            onFailure(err);
          }
        });
    }, [onFailure, __filters]);
    const updateFilters = useCallback((filters: UseCurrentUserStatHook.Filters) => {
      setFilters(filters);
    }, [])
    return {
      data,
      error,
      isLoading,
      updateFilters
    };
  }