import { useEffect, useState } from 'react';
import { IEnquiriesStat } from '../../types/global';
import APIRequest from '../constants/APIRequest';
import RequestMethod from '../enums/RequestMethod';
import ProspectService from '../services/ProspectServices';
import ServerConnectorUtil from '../utils/ServerConnectorUtil';
import moment from 'moment';

export default function useEnquiryStat({
  startDateTime,
  endDateTime
}: {
  startDateTime?: number;
  endDateTime?: number;
}) {
  const [data, setData] = useState<IEnquiriesStat | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const statAbort = new AbortController();
    setIsLoading(true);
    ProspectService.getEnquiryStat({
      signal: statAbort.signal,
      params: {
        startDateTime,
        endDateTime,
      }
    })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        ServerConnectorUtil.handleServerError(error);
      });

    return () => {
      statAbort.abort();
    };
  }, [startDateTime, endDateTime]);

  return {
    data,
    isLoading
  };
}
