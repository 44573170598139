import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import NavArrowSvg from '../../shared/assets/icons/NavArrowSvg';
import dummyUser from '../../shared/assets/images/dummy-user.png';
import Accordion from '../../shared/components/accordion';
import CallTemp from '../../shared/components/call-contact';
import DateFilter from '../../shared/components/date-filter';
import EmptyData from '../../shared/components/empty-data';
import Loader from '../../shared/components/loader';
import NavCard from '../../shared/components/nav-card';
import WhatsappTemp from '../../shared/components/whatsapp-contact';
import UserContext from '../../shared/context/UserContext';
import ProspectEnquiryClassification from '../../shared/enums/ProspectEnquiryClassification';
import ProspectStage from '../../shared/enums/ProspectStage';
import SuccessLayout from '../../shared/layout/success-layout';
import { convertDateFormat } from '../../shared/utils/ConvertDateFormat';
import { convertToRelativeDays } from '../../shared/utils/ConvertRelativeDays';
import { getFullName } from '../../shared/utils/FullNameUtil';
import ToastUtil from '../../shared/utils/ToastUtil';
import { HomeOverview, IProspectValidation } from '../../types/global';
import styles from './styles.module.css';

interface MenuProps {
  to: string;
  data: IProspectValidation;
}
const ValidationList: React.FC<MenuProps> = ({ to, data }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [submittingGenuine, setSubmittingGenuine] = useState(false);
  const [submittingNotGenuine, setSubmittingNotGenuine] = useState(false);

  const [showSuccessLayout, setShowSuccessLayout] = useState(false);

  const validateProspect = useCallback((event: any) => {
    event.stopPropagation();
    const status = event.currentTarget.getAttribute('data-name');
    const id = event.currentTarget.getAttribute('data-id');
    if (status === 'Genuine') {
      setSubmittingGenuine(true);
    } else {
      setSubmittingNotGenuine(true);
    }
    axios
      .put(`/api/v1/prospect-validation-tasks/${id}`, {
        prospect_enquiry_status: status
      })
      .then(() => {
        setShowSuccessLayout(true);
        if (status === 'Genuine') {
          setSubmittingGenuine(false);
        } else {
          setSubmittingNotGenuine(false);
        }
      })
      .catch((err) => {
        const { message } = err.response;
        ToastUtil.makeFailureToast(message);
      });
  }, []);

  const goToHome = useCallback(() => {
    navigate(`/tetm/main/home`);
  }, [navigate]);

  return (
    <>
      {showSuccessLayout ? (
        <SuccessLayout
          title={t('common.congratulations')}
          desc={t('common.prospect_assigned')}
          button_name={t('common.back_to_home')}
          onClick={goToHome}
        />
      ) : (
        <Link to={`${to}/${data.id}`}>
          <div className="accordion-list no-border">
            <div className="dF direction-column gap-8">
              <div className="dF align-center gap-4">
                {data.prospect.contact.color_code && (
                  <div
                    className={classNames(
                      `${data.prospect.contact.color_code}-dot`
                    )}
                    style={{ marginLeft: '-14px' }}
                  ></div>
                )}
                <p className="text-14 color-black-70 ls-028">
                  {t('common.prospects')} :
                </p>
                <p className="text-14 font-600 color-black-70 ls-028">
                  {data.prospect.name}
                </p>
              </div>
              <div className="dF align-center gap-4">
                <p className="text-14 color-black-70 ls-028">
                  {t('common.dsp')} :
                </p>
                <p className="text-14 font-600 color-black-70 ls-028">
                  {getFullName(
                    data.prospect.owner.first_name,
                    data.prospect.owner.last_name
                  )}
                </p>
              </div>
              <div className="dF align-center gap-4">
                <p className="text-14 color-black-70 ls-028">
                  {t('common.edd')} :
                </p>
                <p className="text-14 font-600 color-black-70 ls-028">
                  {convertDateFormat(data.prospect.edd)}
                </p>
              </div>
              <div className="dF align-center gap-4">
                <p className="text-14 color-black-70 ls-028">
                  {data.prospect.contact.village}
                </p>
                {data.prospect.contact.tiv && (
                  <>
                    <div className="separate-dot"></div>
                    <p className="text-14 color-black-70 ls-028">
                      {data.prospect.contact.tiv}
                    </p>
                  </>
                )}
                {data.prospect.contact.color_code && (
                  <>
                    <div className="separate-dot"></div>
                    <p className="text-14 color-black-70 ls-028">
                      {data.prospect.contact.color_code}
                    </p>
                  </>
                )}
              </div>
              <p className="text-14 color-error ls-028">
                {convertToRelativeDays(data.due_date)} - {data.status}
              </p>
            </div>
            <div className="dF direction-column align-end gap-20">
              <div className="mr-6">
                <NavArrowSvg />
              </div>
              <div className="dF align-center gap-16">
                <CallTemp phoneNumber={data.prospect.contact.mobile_number} />
                <WhatsappTemp
                  phoneNumber={data.prospect.contact.mobile_number}
                />
              </div>
            </div>
          </div>
          <div className="accordion-list-bottom">
            <button
              data-id={data.id}
              data-name="Genuine"
              className={classNames('tertiary-btn blue pR', validateProspect)}
              disabled={submittingGenuine}
              onClick={validateProspect}
            >
              <div className={classNames(submittingGenuine && 'vH')}>
                <div className="dF align-center gap-4">
                  <div className="btn-tick"></div>
                  {t('enquiries.genuine')}
                </div>
              </div>
              <div className={classNames('pA', !submittingGenuine && 'vH')}>
                {t('common.loading')} ...
              </div>
            </button>
            <button
              data-id={data.id}
              data-name="Not Genuine"
              className={classNames('tertiary-btn black pR', validateProspect)}
              disabled={submittingGenuine}
              onClick={validateProspect}
            >
              <div className={classNames(submittingGenuine && 'vH')}>
                <div className="dF align-center gap-4">
                  <div className="btn-close"></div>
                  {t('enquiries.not_genuine')}
                </div>
              </div>
              <div className={classNames('pA', !submittingGenuine && 'vH')}>
                {t('common.loading')} ...
              </div>
            </button>
          </div>
        </Link>
      )}
    </>
  );
};
const AssignmentList: React.FC<MenuProps> = ({ to, data }) => {
  const { t } = useTranslation();

  return (
    <Link to={`${to}/${data.id}`}>
      <div className="accordion-list">
        <div className="dF direction-column gap-8">
          <div className="dF align-center gap-4">
            <p className="text-14 color-black-70 ls-028">
              {t('enquiries.prospect')} :
            </p>
            <p className="text-14 font-600 color-black-70 ls-028">
              {data.prospect.name}
            </p>
          </div>
          <div className="dF align-center gap-4">
            <p className="text-14 color-black-70 ls-028">
              {t('common.type')} :
            </p>
            <p className="text-14 font-600 color-black-70 ls-028">
              {data.prospect.enquiry_classification}
            </p>
          </div>
          <div className="dF align-center gap-4">
            <p className="text-14 color-black-70 ls-028">
              {t('enquiries.sog')} :
            </p>
            <p className="text-14 font-600 color-black-70 ls-028">
              {data.prospect.sog}
            </p>
          </div>
          <div className="dF align-center gap-4">
            <p className="text-14 color-black-70 ls-028">
              {data.prospect.contact.village}
            </p>
            {data.prospect.contact.tiv && (
              <>
                <div className="separate-dot"></div>
                <p className="text-14 color-black-70 ls-028">
                  {data.prospect.contact.tiv}
                </p>
              </>
            )}
            {data.prospect.contact.color_code && (
              <>
                <div className="separate-dot"></div>
                <p className="text-14 color-black-70 ls-028">
                  {data.prospect.contact.color_code}
                </p>
              </>
            )}
          </div>
          <div className="dF align-center gap-4">
            <p className="text-14 color-black-70 ls-028">{t('common.edd')} :</p>
            <p className="text-14 font-600 color-black-70 ls-028">
              {convertDateFormat(data.prospect.edd)}
            </p>
          </div>
        </div>
        <div className="dF direction-column align-end gap-20">
          <div className="mr-6">
            <NavArrowSvg />
          </div>
          <div className="dF align-center gap-16">
            <CallTemp phoneNumber={data.prospect.contact.mobile_number} />
            <WhatsappTemp phoneNumber={data.prospect.contact.mobile_number} />
          </div>
        </div>
      </div>
    </Link>
  );
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);
  const [isFetching, setIsFetching] = useState({
    overview: true,
    validation: false,
    assignment: false
  });
  const [overviewData, setOverviewData] = useState<HomeOverview | null>(null);
  const [prospectAssignmentData, setProspectAssignmentData] = useState<
    IProspectValidation[]
  >([]);
  const [prospectValidationData, setProspectValidationData] = useState<
    IProspectValidation[]
  >([]);
  const [totalAssignment, setTotalAssignment] = useState(0);
  const [totalValidation, setTotalValidation] = useState(0);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const getFormattedDate = (): string => {
    const today = new Date();

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-GB', options).format(today);
  };

  const goToVisits = useCallback(() => {
    navigate(`/tetm/myvisits`);
  }, [navigate]);

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      overview: true
    }));
    const params: Record<string, string> = {};

    if (selectedDateRange?.start) {
      params.start_date_time = selectedDateRange?.start
        ? moment(new Date(selectedDateRange?.start)).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
        : '';
    }

    if (selectedDateRange?.end) {
      params.end_date_time = selectedDateRange?.end
        ? moment(new Date(selectedDateRange?.end)).format('YYYY-MM-DDTHH:mm:ss')
        : '';
    }

    axios.get('/api/v1/prospects/overview', { params }).then((response) => {
      setOverviewData(response.data.data);
      setIsFetching((prevState) => ({
        ...prevState,
        overview: false
      }));
    });
  }, [selectedDateRange?.start, selectedDateRange?.end]);

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      validation: true
    }));
    axios
      .get('/api/v1/tasks/pending-prospect-validation-tasks?page=1&perPage=2')
      .then((response) => {
        setProspectValidationData(response.data.data);
        setTotalValidation(response.data.page_info.total_records);
        setIsFetching((prevState) => ({
          ...prevState,
          validation: false
        }));
      });
  }, []);

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      assignment: true
    }));
    axios
      .get('/api/v1/tasks/pending-prospect-assignment-tasks?page=1&perPage=2')
      .then((response) => {
        setProspectAssignmentData(response.data.data);
        setTotalAssignment(response.data.page_info.total_records);
        setIsFetching((prevState) => ({
          ...prevState,
          assignment: false
        }));
      });
  }, []);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');
      const stages = element.getAttribute('data-stages');
      const enquiryClassifications = element.getAttribute(
        'data-enquiry-classifications'
      );
      const edd = element.getAttribute('data-edd-end-value');
      const sogs = element.getAttribute('data-sogs');
      const searchParams = new URLSearchParams({
        ...(stages ? { stages } : {}),
        ...(sogs ? { sogs } : {}),
        ...(enquiryClassifications
          ? { enquiry_classifications: enquiryClassifications }
          : {})
      });
      if (title) {
        searchParams.set('title', title);
      }

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          searchParams.set(
            'created_time_start_value',
            selectedDateRange.start.toString()
          );
        }
        if (selectedDateRange.end) {
          searchParams.set(
            'created_time_end_value',
            selectedDateRange.end.toString()
          );
        }
      }

      if (edd) {
        searchParams.set('edd_end_value', edd);
      }

      navigate(
        {
          pathname: '/tetm/prospects',
          search: searchParams.toString()
        },
        {}
      );
    },
    [selectedDateRange]
  );

  const openProspectValidation = useCallback(() => {
    navigate(
      {
        pathname: `/tetm/prospects-validations`
      },
      {}
    );
  }, []);

  const openProspectAssignment = useCallback(() => {
    navigate(
      {
        pathname: `/tetm/prospects-assignments`
      },
      {}
    );
  }, []);

  return (
    <>
      <div className={styles['profile-box']}>
        <img
          className={styles['profile-img']}
          src={dummyUser}
          alt="User Image"
        />
        <div>
          <p className="text-12 color-black ls-048">{t('common.welcome')}</p>
          <p className="text-18 color-primary mt-2">
            {user && getFullName(user.data.first_name, user.data.last_name)}
          </p>
          <p className="text-14 color-black-40 ls-028 mt-2">
            {getFormattedDate()}
          </p>
        </div>
      </div>
      <div className="m-20">
        {isFetching.validation || isFetching.assignment ? (
          <div
            className="w100p"
            style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 275px)' }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="tafe-card p-20 mb-20">
              <DateFilter
                values={[
                  'All Time',
                  'Today',
                  'Yesterday',
                  'This week',
                  'Last week',
                  'This month',
                  'Last month',
                  'Last 3 months',
                  'This year',
                  'Custom range'
                ]}
                onChange={handleDateChange}
              />

              {isFetching.overview ? (
                <div style={{ height: '200px' }}>
                  <Loader size="medium" />
                </div>
              ) : (
                <>
                  <div className="dF gap-12 mt-15 mb-12">
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.deliveries')}
                        customAttributes={{
                          'data-stages': 'Delivery',
                          'data-title': 'Delivery'
                        }}
                        showArrow={true}
                        onClick={navigateToDetails}
                      >
                        <p className="text-14 pt-10 color-black-70 ls-028">
                          {t('common.actual_target')}
                        </p>
                        <p className="text-14 color-black h44 dF align-center">
                          <span className="text-24 font-500 color-link">
                            {overviewData?.total_deliveries}
                          </span>
                          / {overviewData?.total_deliveries_target}
                        </p>
                      </NavCard>
                    </div>
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.hot_prospects')}
                        showArrow={true}
                        customAttributes={{
                          'data-title':
                            ProspectEnquiryClassification.HOT_PROSPECT,
                          'data-enquiry-classifications':
                            ProspectEnquiryClassification.HOT_PROSPECT
                        }}
                        onClick={navigateToDetails}
                      >
                        <p className="text-14 pt-10 color-black-70 ls-028">
                          {t('common.actual_target')}
                        </p>
                        <p className="text-14 color-black h44 dF align-center">
                          <span className="text-24 font-500 color-link">
                            {overviewData?.total_hot_prospects}
                          </span>
                          /{overviewData?.total_hot_prospects_target}
                        </p>
                      </NavCard>
                    </div>
                  </div>
                  <div className="dF gap-12">
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.enquiries')}
                        customAttributes={{
                          'data-title': ProspectEnquiryClassification.ENQUIRIES,
                          'data-enquiry-classifications': [
                            ProspectEnquiryClassification.LEAD,
                            ProspectEnquiryClassification.PROSPECT
                          ].join(',')
                        }}
                        showArrow={true}
                        onClick={navigateToDetails}
                      >
                        <p className="h44 pt-10 text-24 font-500 color-link">
                          {overviewData
                            ? overviewData.total_leads +
                              overviewData.total_prospects
                            : 0}
                        </p>
                      </NavCard>
                    </div>
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.overdue_hps')}
                        showArrow={true}
                        customAttributes={{
                          'data-title':
                            ProspectEnquiryClassification.HOT_PROSPECT,
                          'data-edd-end-value': Date.now().toString(),
                          'data-stages': [
                            ProspectStage.F1,
                            ProspectStage.F2,
                            ProspectStage.F3,
                            ProspectStage.F4,
                            ProspectStage.F5
                          ].join(','),
                          'data-enquiry-classifications':
                            ProspectEnquiryClassification.HOT_PROSPECT
                        }}
                        onClick={navigateToDetails}
                      >
                        <p className="h44 pt-10 text-24 font-500 color-error">
                          {overviewData?.total_overdue_hot_prospects}
                        </p>
                      </NavCard>
                    </div>
                  </div>
                </>
              )}
            </div>

            <Accordion setInitialOpen={true}>
              <Accordion.Header>
                <p className="dF align-center gap-4 text-16 font-600 color-black">
                  {t('enquiries.prospect_validation')}
                  <span className="sm-dot bg-info"></span>
                  <span className="color-info" onClick={openProspectValidation}>
                    {totalValidation}
                  </span>
                </p>
              </Accordion.Header>

              <Accordion.Body>
                <div className="dF direction-column">
                  {prospectValidationData.length === 0 ? (
                    <EmptyData />
                  ) : (
                    <>
                      {prospectValidationData.map(
                        (item: IProspectValidation) => (
                          <ValidationList
                            key={item.id}
                            to="/tetm/prospects-validation"
                            data={item}
                          />
                        )
                      )}
                      {totalValidation > 2 && (
                        <button
                          className="tertiary-btn w100p ml-auto mr-auto pt-10"
                          onClick={openProspectValidation}
                        >
                          {t('common.show_more')}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </Accordion.Body>
            </Accordion>

            <Accordion setInitialOpen={true}>
              <Accordion.Header>
                <p className="dF align-center gap-4 text-16 font-600 color-black">
                  {t('enquiries.advance_validation')}
                  <span className="sm-dot bg-info"></span>
                  <span className="color-info">1</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="dF direction-column">
                  <div className="accordion-list">
                    <div className="dF direction-column gap-8">
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          {t('common.name')} :
                        </p>
                        <p className="text-14 font-600 color-black-70 ls-028">
                          Gokul
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          {t('common.dealer')} :
                        </p>
                        <p className="text-14 font-600 color-black-70 ls-028">
                          Parthiban
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          {t('common.type')} :
                        </p>
                        <p className="text-14 font-600 color-black-70 ls-028">
                          Bank
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          Paduppadi
                        </p>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">Low</p>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">Yellow</p>
                      </div>
                      <p className="text-14 color-error ls-028">
                        Yesterday - Pending
                      </p>
                    </div>
                    <div className="dF direction-column align-end gap-20">
                      <div className="mr-6">
                        <NavArrowSvg />
                      </div>
                      <div className="dF align-center gap-16">
                        <CallTemp phoneNumber="1234567890" />
                        <WhatsappTemp phoneNumber="1234567890" />
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion>

            <Accordion setInitialOpen={true}>
              <Accordion.Header>
                <p className="dF align-center gap-4 text-16 font-600 color-black">
                  {t('enquiries.prospect_assignment')}
                  <span className="sm-dot bg-info"></span>
                  <span className="color-info" onClick={openProspectAssignment}>
                    {totalAssignment}
                  </span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="dF direction-column">
                  {prospectAssignmentData.length === 0 ? (
                    <EmptyData />
                  ) : (
                    <>
                      {prospectAssignmentData.map(
                        (item: IProspectValidation) => (
                          <AssignmentList
                            key={item.id}
                            to="/tetm/prospects-assignment"
                            data={item}
                          />
                        )
                      )}
                      {totalAssignment > 2 && (
                        <button
                          className="tertiary-btn w100p ml-auto mr-auto pt-10"
                          onClick={openProspectAssignment}
                        >
                          {t('common.show_more')}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </Accordion.Body>
            </Accordion>

            <div className="pA bottom-90 right-15">
              <button className="primary-btn" onClick={goToVisits}>
                <div className={styles['visit-icon']}></div>
                {t('home.my_visits')}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
