import { useEffect, useRef } from 'react';
import EnvironmentUtil from '../utils/EnvironmentUtil';
export default function useEffectAfterMount(
  callback: Function,
  dependencies: Array<unknown>
) {
  const hasMounted = useRef(false);
  useEffect(() => {
    if (EnvironmentUtil.isDevelopment()) {
      if (hasMounted.current) {
        return callback();
      } else {
        hasMounted.current = true;
      }
    } else {
      callback();
    }
  }, [...dependencies]);
}
