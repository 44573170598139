import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import {
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath
} from 'react-router-dom';
import ViewLayout from '../../../layout/view-layout';
import styles from './styles.module.css';
import UserContext from '../../../context/UserContext';

interface MenuProps {
  to: string;
  title: string;
}
interface MenuItems {
  to: string;
  title: string;
}

const Menu: React.FC<MenuProps> = ({ to, title }) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({
    path: resolved.pathname,
    end: false
  });

  const goToMenu = useCallback(() => {
    navigate(`/${user.user_role}/jcp/${to}`, {
      replace: true
    });
  }, [navigate, to]);

  return (
    <div
      className={classNames(styles['menulist'], { [styles['active']]: match })}
      onClick={goToMenu}
    >
      <p className={styles['menu-title']}>{title}</p>
    </div>
  );
};

const Jcp: React.FC = () => {
  const [menuList] = useState<MenuItems[]>([
    { to: 'jcp-plan', title: 'JCP Plan' },
    { to: 'jcp-review', title: 'JCP Review' }
  ]);

  return (
    <>
      <ViewLayout title="JCP">
        <div className={styles['tab-header']}>
          {menuList.map((item) => (
            <Menu key={item.to} to={item.to} title={item.title} />
          ))}
        </div>
        <Outlet />
      </ViewLayout>
    </>
  );
};

export default Jcp;
