import React, { PropsWithChildren } from 'react'
import style from './index.module.css';
const TableBody: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>> = ({children, ...props}) => {
    return (
        <tbody className={`${style['tbody']}`} {...props}>
            {children}
        </tbody>
    )
}

export default TableBody