import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateFilter from '../../../../components/date-filter';
import Loader from '../../../../components/loader';
import NavCard from '../../../../components/nav-card';
import UserContext from '../../../../context/UserContext';
import useEffectAfterMount from '../../../../hooks/useEffectAfterMount';
import DateProcessor from '../../../../processor/DateProcessor';
import ServerConnectorUtil from '../../../../utils/ServerConnectorUtil';

export interface PlanningSummary {
  total_users: number;
  total_planned_villages: number;
  total_planned_no_of_days: number;
  total_planned_unique_villages: number;
  total_users_with_no_jc_plannings: number;
  total_users_with_approved_jc_plannings: number;
  total_users_with_submitted_jc_plannings: number;
  total_planned_unique_villages_visited_last_month: number;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const JcpPlan: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PlanningSummary>();
  const [dateFilterOptions] = useState(() =>
    monthNames.slice(0, new Date().getMonth() + 1)
  );
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const index = new Date().getMonth();
    return monthNames[index];
  });
  const [currentYear, setCurrentYear] = useState<number>(() =>
    new Date().getFullYear()
  );
  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(() =>
    DateProcessor.getSpecificMonthRange(Date.now(), new Date().getMonth())
  );

  const handleDateChange = useCallback(
    (data: {
      dateRange: { start: number; end: number } | undefined;
      label: string;
    }) => {
      setSelectedDateRange(data.dateRange);
      setSelectedMonth(data.label);
    },
    []
  );

  useEffectAfterMount(() => {
    setIsLoading(true);
    if (selectedMonth && currentYear) {
      axios
        .get(
          `/api/v1/dsp-users/jc-planning-user-statistics?month=${selectedMonth}&year=${currentYear}`
        )
        .then((response) => {
          setData(response.data.data);
        })
        .catch(ServerConnectorUtil.handleServerError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedMonth, currentYear]);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');

      const searchParams = new URLSearchParams();
      if (title) {
        searchParams.set('title', title);
        searchParams.set('name', title.replaceAll(' ', '_').toLowerCase());
      }
      searchParams.set('month', selectedMonth);
      searchParams.set('year', currentYear.toString());

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          searchParams.set(
            'start_date_time',
            selectedDateRange.start.toString()
          );
        }
        if (selectedDateRange.end) {
          searchParams.set('end_date_time', selectedDateRange.end.toString());
        }

        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 1).getTime();
        searchParams.set('till_date_start_value', startOfYear.toString());
      }

      navigate(
        {
          pathname: `/${user.user_role}/jcp/jcp-plan/list`,
          search: searchParams.toString()
        },
        {}
      );
    },
    [selectedDateRange, currentYear, selectedMonth]
  );

  return (
    <div className="p-20">
      <DateFilter
        defaultValue={{
          label: selectedMonth || '',
          value: selectedMonth || ''
        }}
        values={dateFilterOptions}
        onChange={handleDateChange}
      />
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: 'calc(calc(var(--vh, 1vh) * 100) - 184px)'
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <table className="tafe-table mt-15">
            <tr>
              <td>
                <p className="text-12 font-500 color-black-55">
                  Planned villages
                </p>
                <p className="text-18 color-black mt-5">
                  {data?.total_planned_villages}
                </p>
              </td>
              <td>
                <p className="text-12 font-500 color-black-55">
                  Planned no. of days
                </p>
                <p className="text-18 color-black mt-5">
                  {data?.total_planned_no_of_days}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p className="text-12 font-500 color-black-55">
                  Planned unique villages
                </p>
                <p className="text-18 color-black mt-5">
                  {data?.total_planned_unique_villages}
                </p>
              </td>
              <td>
                <p className="text-12 font-500 color-black-55">
                  Planned unique villages - Visited LM
                </p>
                <p className="text-18 color-black mt-5">
                  {data?.total_planned_unique_villages_visited_last_month}
                </p>
              </td>
            </tr>
          </table>
          <div className="dF gap-12 mt-20">
            <div className="two-column-width">
              <NavCard
                title="Total DSP"
                showArrow={true}
                customAttributes={{
                  'data-title': 'Total DSP'
                }}
                onClick={navigateToDetails}
              >
                <p className="text-24 h44 font-500 color-link  dF align-center">
                  {data?.total_users}
                </p>
              </NavCard>
            </div>
            <div className="two-column-width">
              <NavCard
                title="DSPs with No JCP"
                showArrow={true}
                customAttributes={{
                  'data-title': 'DSPs with No JCP'
                }}
                onClick={navigateToDetails}
              >
                <p className="text-24 h44 font-500 color-link dF align-center">
                  {data?.total_users_with_no_jc_plannings}
                </p>
              </NavCard>
            </div>
          </div>
          <div className="dF gap-12 mt-20">
            <div className="two-column-width">
              <NavCard
                title="DSPs with JCP (pending approval)"
                showArrow={true}
                customAttributes={{
                  'data-title': 'DSPs with JCP (pending approval)'
                }}
                onClick={navigateToDetails}
              >
                <p className="text-24 h44 font-500 color-link  dF align-center">
                  {data?.total_users_with_submitted_jc_plannings}
                </p>
              </NavCard>
            </div>
            <div className="two-column-width">
              <NavCard
                title="DSPs with JCP (approved)"
                showArrow={true}
                customAttributes={{
                  'data-title': 'DSPs with JCP (approved)'
                }}
                onClick={navigateToDetails}
              >
                <p className="text-24 h44 font-500 color-link  dF align-center">
                  {data?.total_users_with_approved_jc_plannings}
                </p>
              </NavCard>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JcpPlan;
