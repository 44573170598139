import { ArrowSvg } from '../../assets/icons/ArrowSvg';

const ListItemLink: React.FC = () => {
  return (
    <div className="link-arrow-position">
      <ArrowSvg />
    </div>
  );
};

export default ListItemLink;
