import React, { useContext } from 'react';

type AccordianContextProps = {
  open: boolean;
  toggleOpen: () => void;
};

export const AccordianContext = React.createContext<
  AccordianContextProps | undefined
>(undefined);

export function useAccordianContext(): AccordianContextProps {
  const context = useContext(AccordianContext);

  if (context === undefined) {
    throw new Error('Unable to read accordian context.');
  }

  return context;
}
