import { useState } from "react";

import useEffectAfterMount from "./useEffectAfterMount";
import GeographyService from "../services/GeographyServices";
import distanceCalc from "../utils/distanceCalc";
import { VILLAGE } from "../../types/model";
import { UseNonAnchorVillagesHook } from "../../types/hooks";

export default function useNonAnchorVillages({
  latitude,
  longitude,
  onFailure,
  additionalFields = []
}: UseNonAnchorVillagesHook.Parameters): UseNonAnchorVillagesHook.Result {
  const [data, setData] = useState<VILLAGE[]>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    setIsLoading(true);
    GeographyService.getPagedNonAnchorVillages({
      page: 1,
      latitude,
      longitude,
      perPage: 2000,
      additionalFields
    })
      .then((result) => {
        setData(
          result.data.map((item) => {
            item.distance = distanceCalc.calculateDistance(
              parseFloat(latitude),
              parseFloat(longitude),
              parseFloat(item.latitude as string),
              parseFloat(item.longitude as string)
            );
            return item;
          })
        );
        console.log(result);
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [latitude, longitude, onFailure, additionalFields]);
  return {
    data,
    error,
    isLoading
  };
}
