import styles from "./styles.module.css";

import classNames from "classnames";
import { useCallback, useState } from "react";

import View from "../../enums/View";
import { CreateJcPlanningForm } from "../../types";
import JcPlanningAdditionalFields from "../../../../shared/enums/JcPlanningAdditionalFields";
import { useFormContext } from "../../../../shared/hooks/useFormContext";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";
import { VILLAGE } from "../../../../types/model";
import useNonAnchorVillages from "../../../../shared/hooks/useNonAnchorVillages";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import ViewLayout from "../view-layout";
import Loader from "../../../../shared/components/loader";
import EmptyRecords from "../../../../shared/components/empty-records";
import DistanceFilter from "./components/distance-filter";
import NonAnchorVillage from "./components/non-anchor-village";
import Button from "../../../../shared/components/button";
import ZohoMaps from "../../../../shared/components/ZohoMaps";
import { useTranslation } from "react-i18next";

const additionalFields = [
  JcPlanningAdditionalFields.STATS_TOTAL_JCP_VISITS_YEAR_TILL_DATE
];

const SelectNonAnchorVillagesView: React.FC = () => {
  const { values, setValue } = useFormContext<CreateJcPlanningForm>();
  const { goToView, cancelOperation } = useCreateJcPlanningContext();
  const {t} = useTranslation();
  const [filteredVillages, setFilteredVillages] = useState<
    Array<VILLAGE>
  >([]);

  const [selectedVillages, setSelectedVillages] = useState<
    Array<VILLAGE>
  >(() => values.nonAnchorVillages || []);

  const [anchorVillage] = useState(
    () => values.anchorVillage as unknown as VILLAGE
  );

  const [shapeOptions] = useState(() => {
    return {
      radius:20000,
      latitude: anchorVillage.latitude,
      longitude: anchorVillage.longitude
    };
  });

  const { data: originalVillages, isLoading } = useNonAnchorVillages({
    latitude: anchorVillage.latitude,
    longitude: anchorVillage.longitude,
    additionalFields,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const addVillage = useCallback((village: VILLAGE) => {
    setSelectedVillages((prev) => prev.concat(village));
  }, []);

  const removeVillage = useCallback((village: VILLAGE) => {
    setSelectedVillages((prev) => prev.filter((obj) => obj.id !== village.id));
  }, []);

  const nextOperation = useCallback(() => {
    setValue("nonAnchorVillages", selectedVillages);
    goToView(View.FORM_VIEW);
  }, [goToView, selectedVillages, setValue]);

  const filterVillages = useCallback(
    (distance: number, skipSelectedVillagesReset: boolean) => {
      if (!skipSelectedVillagesReset) {
        setSelectedVillages([]);
      }

      setFilteredVillages(
        (originalVillages as Array<VILLAGE>).filter(
          (obj) => obj.distance && obj.distance <= distance
        )
      );
    },
    [originalVillages]
  );

  return (
    <ViewLayout>
      <ViewLayout.Body>
        {isLoading ? (
            <Loader />
        ) : originalVillages.length === 0 ? (
          <EmptyRecords message={t('dsp.create_jcp.non_anchor_village.empty_non_anchor_village')} />
        ) : (
          <>
            <div className={styles["slider-container"]}>
              <div className={styles["form-info-container"]}>
                <p className={styles["text"]}>{t('dsp.create_jcp.non_anchor_village.select_upto_no_options')}</p>
                <p
                  className={classNames(
                    styles["text"],
                    styles["selected-text"]
                  )}
                >
                  {selectedVillages.length} {t('dsp.create_jcp.non_anchor_village.selected')}
                </p>
              </div>
              <DistanceFilter filterVillages={filterVillages} />
            </div>
            <div className={styles["list-container"]}>
              {filteredVillages.length ? (
                filteredVillages
                  .map((item) => (
                    <NonAnchorVillage
                      key={item.id}
                      village={item as VILLAGE}
                      disabled={selectedVillages.length === 3}
                      checked={selectedVillages.some(
                        (obj) => obj.id === item.id
                      )}
                      addVillage={addVillage}
                      removeVillage={removeVillage}
                    />
                  ))
              ) : (
                <EmptyRecords
                  size="sm"
                  message={t('dsp.create_jcp.non_anchor_village.empty_non_anchor_village')}
                />
              )}
            </div>
            <div className={styles["maps-container"]}>
              <ZohoMaps
                className={styles["maps"]}
                shapeOptions={shapeOptions}
                markers={originalVillages.map((item) => ({
                  id: item.id,
                  isRemoved: !filteredVillages.some(
                    (obj) => obj.id === item.id
                  ),
                  isActive: selectedVillages.some((obj) => obj.id === item.id),
                  latitude: item.latitude,
                  longitude: item.longitude
                }))}
              />
            </div>
          </>
        )}
      </ViewLayout.Body>
      <ViewLayout.Footer>
        <Button label={t('common.cancel')} variant="secondary" onClick={cancelOperation} />
        <Button
          label={t('common.next')}
          variant="primary"
          onClick={nextOperation}
          disabled={isLoading || selectedVillages.length === 0}
        />
      </ViewLayout.Footer>
    </ViewLayout>
  );
};

export default SelectNonAnchorVillagesView;
