import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../shared/components/button";
import JcPlanningAdditionalFields from "../../shared/enums/JcPlanningAdditionalFields";
import distanceCalc from "../../shared/utils/distanceCalc";
import ServerConnectorUtil from "../../shared/utils/ServerConnectorUtil";
import EmptyData from "../../shared/components/empty-data";
import Loader from "../../shared/components/loader";
import useCurrentLocation from "../../shared/hooks/useCurrentLocation";
import useJcPlanningById from "../../shared/hooks/useJcPlanningById";
import ViewLayout from "../../shared/layout/view-layout";
import { useTranslation } from "react-i18next";
import NavCard from "../../shared/components/nav-card";
import style from './index.module.css';
import AppUrlConstants from "../../shared/constants/AppUrlContanst";
import SuccessLayout from "../../shared/layout/success-layout";
import JcPlanningService from "../../shared/services/JcPlanningService";

const jcPlanningAdditionalFields = [
  JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_PROSPECTS,
  JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_MF_SATISFIED,
  JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_MF_UNSATISFIED,
  JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_COMPETITION_SATISFIED,
  JcPlanningAdditionalFields.VILLAGE_STATS_TOTAL_TUDB_COMPETITION_UNSATISFIED
]
const CheckInJcp: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { jcpId } = useParams() as Record<string, string>;
  const [additional_fields] = useState<Array<string>>(jcPlanningAdditionalFields);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [successLayout, setSuccessLayout] = useState<boolean>(false);
  const { data, isLoading } = useJcPlanningById({
    id: jcpId,
    additional_fields
  });

  const { latitude, longitude } = useCurrentLocation();

  const checkInJcp = useCallback(() => {
    if (latitude === undefined || longitude === undefined) {
      console.log("Unable to access the current location.");
      let error = {
        message: "Unable to access the current location."
      };
      ServerConnectorUtil.handleServerError(error);
      return;
    }
    const distance = distanceCalc.calculateDistance(
      latitude,
      longitude,
      parseFloat(data!.village.latitude),
      parseFloat(data!.village.longitude)
    );

    if (!distance || distance > 6) {
      let error = {
        message: 'Not allowed to check-in'
      };
      ServerConnectorUtil.handleServerError(error);
      return;
    }

    setIsProcessing(true);
    JcPlanningService.checkInJcp({ id: jcpId, latitude, longitude })
      .then(() => {
        setIsProcessing(false);
        setSuccessLayout(true);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsProcessing(false);
      });
  }, [data, jcpId, latitude, longitude, navigate]);
  const goToHome = useCallback(() => {
    navigate(`/${AppUrlConstants.DSP.BASE_NAME}/main/${AppUrlConstants.DSP.HOME}`);
  }, [navigate]);
  return (
    successLayout ?
      <SuccessLayout
        title={t('dsp.check_in_jcp.check_in_successfull')}
        desc={t('dsp.check_in_jcp.check_in_success_desc')}
        button_name={t('common.back_to_home')}
        onClick={goToHome}
      />
    :

      <ViewLayout title={t('dsp.check_in_jcp.village_details')}>
        {isLoading ? (
            <Loader size="medium"/>
        ) : data === undefined ? (
            <EmptyData/>
        ) : (
          <div>
            <div className="bg-white p-14 dF align-center gap-16">
              <div className={`${data.village.color_code}-dot`}></div>
              <div>
                <h1 className="text-32 font-600">{data.village.name}</h1>
                <span className="dF align-center">
                  <span className="text-14 font-400 color-black-55">{data.village.taluka}</span>
                  <span className="grey-dot"></span>
                  <span className="text-14 font-400 color-black-55">{data.village.tiv}</span>
                </span>
              </div>
            </div>
            <div className={`m-20 dF direction-column gap-12 ${style['check-in-cards']}`}>
              <div className="dF gap-12">
                <div className="two-column-width">
                  <NavCard 
                    title={t('dsp.check_in_jcp.total_prospects')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{data.village.stats.total_prospects}</div>
                  </NavCard>
                </div>
                <div className="two-column-width">
                  <NavCard 
                    title={t('common.mfsatisfied_label')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{data.village.stats.total_tudb_mf_satisfied}</div>
                  </NavCard>
                </div>
              </div>
              <div className="dF gap-12">
                <div className="two-column-width">
                  <NavCard 
                    title={t('dsp.check_in_jcp.mf_dissatisfied')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{data.village.stats.total_tudb_mf_unsatisfied}</div>
                  </NavCard>
                </div>
                <div className="two-column-width">
                  <NavCard 
                    title={t('dsp.check_in_jcp.mpc_coordinators')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{0}</div>
                  </NavCard>
                </div>
              </div>
              <div className="dF gap-12">
                <div className="two-column-width">
                  <NavCard 
                    title={t('dsp.check_in_jcp.comp_satisfied')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{data.village.stats.total_tudb_competition_satisfied}</div>
                  </NavCard>
                </div>
                <div className="two-column-width">
                  <NavCard 
                    title={t('dsp.check_in_jcp.comp_dissatisfied')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{data.village.stats.total_tudb_competition_unatisfied}</div>
                  </NavCard>
                </div>
              </div>
              <div className="dF gap-12">
                <div className="two-column-width">
                  <NavCard 
                    title={t('dsp.check_in_jcp.recommendation')}
                    to={'#'}
                  >
                    <div className="p-12 text-24 color-link">{0}</div>
                  </NavCard>
                </div>
              </div>
            </div>
            <div className={`m-20`}>
              <Button
                size="large"
                variant="primary"
                label={`${t('dsp.check_in_jcp.proceed_to')} ${t('dsp.check_in_jcp.check_in')}`}
                onClick={checkInJcp}
                isLoading={isProcessing}
                appearance="box"
              />
            </div>
          </div>
        )}
      </ViewLayout>
  );
};

export default CheckInJcp;
