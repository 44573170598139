import { Navigate, Route } from 'react-router-dom';
import DspPeerReportees from '../shared/views/dsp-peer-reportees';
import DspDetails from '../shared/views/dsp/dspDetails';
import Enquiries from '../shared/views/enquiries';
import Enquiry from '../shared/views/enquiries/enquiry';
import TUDB from '../shared/views/enquiries/tudb';
import CreateEnquiry from '../shared/views/forms/create-enquiry';
import AdvanceMaster from '../shared/views/menu/advance-master';
import Advances from '../shared/views/menu/advances';
import EnquiryMaster from '../shared/views/menu/enquiry-master';
import EnquiryView from '../shared/views/menu/enquiry-master/view';
import FieldActivitiesMaster from '../shared/views/menu/field-activities-master';
import FieldActivity from '../shared/views/menu/field-activity';
import Jcp from '../shared/views/menu/jcp';
import JcpPlan from '../shared/views/menu/jcp/jcp-plan';
import JcpPlanDspDetails from '../shared/views/menu/jcp/jcp-plan/dsp-details';
import JcpPlanList from '../shared/views/menu/jcp/jcp-plan/jcp-plan-list';
import JcpReview from '../shared/views/menu/jcp/jcp-review';
import JcpReviewDspDetails from '../shared/views/menu/jcp/jcp-review/dsp-details';
import JcpReviewDetails from '../shared/views/menu/jcp/jcp-review/jcp-review-details';
import Settings from '../shared/views/menu/settings';
import Stock from '../shared/views/menu/stock';
import StockMaster from '../shared/views/menu/stock-master';
import TargetSetting from '../shared/views/menu/target-setting';
import TudbMaster from '../shared/views/menu/tudb-master';
import TudbView from '../shared/views/menu/tudb-master/view';
import VillageMaster from '../shared/views/menu/village-master';
import Dashboard from './dashboard';
import Home from './home';
import Main from './main';
import Menu from './menu';
import EditEnquiry from '../shared/views/forms/edit-enquiry';

const DealerRoutes = (): JSX.Element => {
  return (
    <Route path="dealer">
      <Route path="*" element={<Navigate to="main" replace={true} />} />
      <Route path="" element={<Navigate to="main" replace={true} />} />
      <Route path="main" element={<Main />}>
        <Route path="*" element={<Navigate to="home" replace={true} />} />
        <Route path="" element={<Navigate to="home" replace={true} />} />
        <Route path="home" element={<Home />} />
        <Route path="dsp" element={<DspPeerReportees />} />
        <Route path="enquiries" element={<Enquiries />}>
          <Route path="*" element={<Navigate to="enquiry" replace={true} />} />
          <Route path="" element={<Navigate to="enquiry" replace={true} />} />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="tudb" element={<TUDB />} />
        </Route>
        <Route path="dashboard" element={<Dashboard />} />
      </Route>
      <Route path="dsp/:id/prospects" element={<DspDetails />} />
      <Route path="prospect/:id" element={<EnquiryView />} />
      <Route path="menu" element={<Menu />} />
      <Route path="jcp" element={<Jcp />}>
        <Route path="*" element={<Navigate to="jcp-plan" replace={true} />} />
        <Route path="" element={<Navigate to="jcp-plan" replace={true} />} />
        <Route path="jcp-plan" element={<JcpPlan />} />
        <Route path="jcp-review" element={<JcpReview />} />
      </Route>
      <Route path="jcp/jcp-plan/pending-approvals" element={<JcpPlanList />} />
      <Route path="jcp/jcp-plan/dsp-details" element={<JcpPlanDspDetails />} />
      <Route path="jcp/jcp-review/dsp" element={<JcpReviewDetails />} />
      <Route
        path="jcp/jcp-review/dsp-details/:id"
        element={<JcpReviewDspDetails />}
      />
      <Route path="target-setting" element={<TargetSetting />} />
      <Route path="field-activity" element={<FieldActivity />} />
      <Route path="field-activities" element={<FieldActivitiesMaster />} />
      <Route path="stock" element={<Stock />} />
      <Route path="stocks" element={<StockMaster />} />
      <Route path="advance" element={<Advances />} />
      <Route path="advances" element={<AdvanceMaster />} />
      <Route path="prospects" element={<EnquiryMaster />} />
      <Route path="prospect/:id" element={<EnquiryView />} />
      <Route path="prospect/create" element={<CreateEnquiry />} />
      <Route path="prospect/:id/edit" element={<EditEnquiry />} />
      <Route path="tudb" element={<TudbMaster />} />
      <Route path="tudb/:id" element={<TudbView />} />
      <Route path="villages" element={<VillageMaster />} />
      <Route path="settings" element={<Settings />} />
    </Route>
  );
};
export default DealerRoutes;
