import React from 'react';

export const GreenArrow: React.FC = () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.43926 6.95204V5.90203H10.1455L7.05384 2.82495L4.83717 5.04162L0.739258 0.943703L1.48301 0.199951L4.83717 3.55412L7.05384 1.33745L10.8893 5.15829V3.45204H11.9393V6.95204H8.43926Z"
        fill="#007851"
      />
    </svg>
  );
};
