import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const EmptyData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="dN">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 139"
          fill="none"
          id="no-records"
        >
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.592 116.937H126.85C127.327 116.937 127.792 116.87 128.239 116.744C128.686 116.87 129.151 116.937 129.628 116.937H177.775C181.355 116.937 184.257 113.176 184.257 108.537C184.257 103.898 181.355 100.137 177.775 100.137H172.22C168.64 100.137 165.738 96.3766 165.738 91.7376C165.738 87.0986 168.64 83.3379 172.22 83.3379H189.812C193.392 83.3379 196.294 79.5772 196.294 74.9381C196.294 70.2991 193.392 66.5384 189.812 66.5384H169.442C173.022 66.5384 175.923 62.7777 175.923 58.1387C175.923 53.4996 173.022 49.7389 169.442 49.7389H110.184C113.763 49.7389 116.665 45.9782 116.665 41.3392C116.665 36.7001 113.763 32.9395 110.184 32.9395H57.4066C53.827 32.9395 50.9252 36.7001 50.9252 41.3392C50.9252 45.9782 53.827 49.7389 57.4066 49.7389H20.3701C16.7905 49.7389 13.8887 53.4996 13.8887 58.1387C13.8887 62.7777 16.7905 66.5384 20.3701 66.5384H43.5179C47.0975 66.5384 49.9993 70.2991 49.9993 74.9381C49.9993 79.5772 47.0975 83.3379 43.5179 83.3379H6.48139C2.90182 83.3379 0 87.0986 0 91.7376C0 96.3766 2.90182 100.137 6.48139 100.137H42.592C39.0124 100.137 36.1106 103.898 36.1106 108.537C36.1106 113.176 39.0124 116.937 42.592 116.937ZM193.518 116.937C197.098 116.937 200 113.176 200 108.537C200 103.898 197.098 100.137 193.518 100.137C189.939 100.137 187.037 103.898 187.037 108.537C187.037 113.176 189.939 116.937 193.518 116.937Z"
            fill="#FFEBEB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M128.984 28.3133L140.798 114.475L141.859 123.119C142.201 125.904 140.22 128.439 137.434 128.781L63.0233 137.918C60.2372 138.26 57.7013 136.279 57.3594 133.495L45.9092 40.2399C45.7382 38.8475 46.7289 37.5801 48.122 37.409C48.1308 37.408 48.1396 37.4069 48.1484 37.4059L54.3218 36.7134"
            fill="white"
          />
          <path
            d="M128.984 28.3133L129.479 28.2454C129.442 27.9765 129.198 27.7861 128.928 27.8164L128.984 28.3133ZM140.798 114.475L141.294 114.414L141.293 114.407L140.798 114.475ZM141.859 123.119L142.356 123.059L141.859 123.119ZM137.434 128.781L137.495 129.277L137.434 128.781ZM63.0233 137.918L63.0842 138.414L63.0233 137.918ZM57.3594 133.495L57.8557 133.434L57.3594 133.495ZM48.1484 37.4059L48.0927 36.9091L48.1484 37.4059ZM54.3218 36.7134L54.3775 37.2103L54.3777 37.2103L54.3218 36.7134ZM128.488 28.3812L140.303 114.543L141.293 114.407L129.479 28.2454L128.488 28.3812ZM140.302 114.536L141.363 123.18L142.356 123.059L141.294 114.414L140.302 114.536ZM141.363 123.18C141.671 125.691 139.885 127.976 137.373 128.285L137.495 129.277C140.555 128.902 142.731 126.118 142.356 123.059L141.363 123.18ZM137.373 128.285L62.9624 137.421L63.0842 138.414L137.495 129.277L137.373 128.285ZM62.9624 137.421C60.4501 137.73 58.1639 135.944 57.8557 133.434L56.8631 133.556C57.2388 136.615 60.0242 138.79 63.0842 138.414L62.9624 137.421ZM57.8557 133.434L46.4054 40.1789L45.4129 40.3008L56.8631 133.556L57.8557 133.434ZM46.4054 40.1789C46.2682 39.0609 47.0637 38.0427 48.1829 37.9053L48.0611 36.9128C46.3941 37.1174 45.2082 38.6341 45.4129 40.3008L46.4054 40.1789ZM48.1829 37.9053C48.19 37.9044 48.1971 37.9036 48.2042 37.9028L48.0927 36.9091C48.0821 36.9102 48.0716 36.9115 48.0611 36.9128L48.1829 37.9053ZM48.2042 37.9028L54.3775 37.2103L54.266 36.2165L48.0927 36.9091L48.2042 37.9028ZM54.3777 37.2103L129.04 28.8102L128.928 27.8164L54.2659 36.2165L54.3777 37.2103Z"
            fill="#101F3E"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.773 33.7407L136.471 111.826L137.434 119.66C137.744 122.183 135.972 124.478 133.477 124.784L66.8405 132.961C64.3454 133.268 62.0715 131.47 61.7614 128.946L51.3396 44.1111C51.2049 43.0147 51.9845 42.0169 53.0808 41.8824L61.8617 40.8048"
            fill="#FFEBEB"
          />
          <path
            d="M73.2695 11.0391H134.344H150.405C152.338 11.0391 153.905 12.6061 153.905 14.5391V30.5765V113.22C153.905 115.153 152.338 116.72 150.405 116.72H73.2695C71.3365 116.72 69.7695 115.153 69.7695 113.22V14.5391C69.7695 12.6061 71.3365 11.0391 73.2695 11.0391Z"
            fill="white"
            stroke="#101F3E"
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M117.206 62.9375L147.98 102.418L147.981 110.311C147.982 112.854 145.944 114.915 143.43 114.915H76.2936C73.7798 114.915 71.7418 112.854 71.7414 110.311L71.7307 24.8379C71.7306 23.7333 72.6259 22.8379 73.7305 22.8379H82.5773"
            fill="#FFEBEB"
          />
          <path
            d="M28.473 114.915H21.8332M33.6091 108.627H19.6602H33.6091ZM39.2939 108.627H37.4901H39.2939Z"
            stroke="#101F3E"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M177.857 33.6329H184.497M172.721 27.3447H186.67H172.721ZM167.036 27.3447H168.84H167.036Z"
            stroke="#101F3E"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105.487 6.6707C105.84 3.48088 108.544 1 111.828 1C115.112 1 117.817 3.48088 118.17 6.6707H133.007C133.559 6.6707 134.007 7.11841 134.007 7.6707V19.0766C134.007 19.6289 133.559 20.0766 133.007 20.0766H90.6641C90.1118 20.0766 89.6641 19.6289 89.6641 19.0766V7.6707C89.6641 7.11841 90.1118 6.6707 90.6641 6.6707H105.487Z"
            fill="white"
          />
          <path
            d="M105.487 6.6707V7.1707H105.934L105.984 6.72572L105.487 6.6707ZM118.17 6.6707L117.673 6.72572L117.722 7.1707H118.17V6.6707ZM105.984 6.72572C106.309 3.78639 108.802 1.5 111.828 1.5V0.5C108.287 0.5 105.371 3.17537 104.99 6.61568L105.984 6.72572ZM111.828 1.5C114.855 1.5 117.348 3.78639 117.673 6.72572L118.667 6.61568C118.286 3.17537 115.37 0.5 111.828 0.5V1.5ZM118.17 7.1707H133.007V6.1707H118.17V7.1707ZM133.007 7.1707C133.283 7.1707 133.507 7.39456 133.507 7.6707H134.507C134.507 6.84227 133.835 6.1707 133.007 6.1707V7.1707ZM133.507 7.6707V19.0766H134.507V7.6707H133.507ZM133.507 19.0766C133.507 19.3528 133.283 19.5766 133.007 19.5766V20.5766C133.835 20.5766 134.507 19.9051 134.507 19.0766H133.507ZM133.007 19.5766H90.6641V20.5766H133.007V19.5766ZM90.6641 19.5766C90.3879 19.5766 90.1641 19.3528 90.1641 19.0766H89.1641C89.1641 19.9051 89.8356 20.5766 90.6641 20.5766V19.5766ZM90.1641 19.0766V7.6707H89.1641V19.0766H90.1641ZM90.1641 7.6707C90.1641 7.39456 90.3879 7.1707 90.6641 7.1707V6.1707C89.8356 6.1707 89.1641 6.84227 89.1641 7.6707H90.1641ZM90.6641 7.1707H105.487V6.1707H90.6641V7.1707Z"
            fill="black"
          />
          <path
            d="M79.3281 41.5366L82.151 44.225L90.2161 36.4287"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M79.3281 64.3325L82.151 67.0209L90.2161 59.2246"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M79.3281 87.1294L82.151 89.8178L90.2161 82.0215"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M79.3281 109.925L82.151 112.614L90.2161 104.817"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 36.7793H137.698"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 42.666H116.666"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 53.75H137.698"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 59.6357H116.666"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 70.7197H137.698"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 76.6055H116.666"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 87.6895H137.698"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M94.0391 93.5762H116.666"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M146.674 131.663C157.912 131.663 167.022 122.591 167.022 111.401C167.022 100.21 157.912 91.1387 146.674 91.1387C135.436 91.1387 126.326 100.21 126.326 111.401C126.326 122.591 135.436 131.663 146.674 131.663Z"
            fill="#AD000E"
          />
          <path
            d="M148 105C148 104.45 147.55 104 147 104C146.45 104 146 104.45 146 105V114C146 114.55 146.45 115 147 115C147.55 115 148 114.55 148 114V105Z"
            fill="white"
          />
          <path
            d="M148 118C148 117.448 147.552 117 147 117C146.448 117 146 117.448 146 118C146 118.552 146.448 119 147 119C147.552 119 148 118.552 148 118Z"
            fill="white"
          />
          <ellipse
            cx="146.682"
            cy="111.4"
            rx="15.7"
            ry="15.7"
            stroke="white"
            stroke-width="0.8"
          />
        </svg>
      </div>

      <div className={styles['empty-data-container']}>
        <svg className={styles['empty-svg']}>
          <use href={`#no-records`}></use>
        </svg>
        <p className={styles['no-data-found']}>{t('common.no_data_found')}</p>
      </div>
    </>
  );
};

export default EmptyData;
