import React from 'react'
import style from './style.module.css';
import { ProfileContext } from '../../context/ProfileContext';
import Avatar from './avatar';
import DealerName from './dealer-name';
import SkillLevel from './skill-level';
import DealerLocation from './dealer-location';
import Name from './user-name';

type ProfileProps = {
    imageSrc?: string,
    name: string,
    dealerName?: string,
    dealerLocation?: string,
    skillLevel?: string
}
type ProfileCollection = {
    Name: React.FC,
    Avatar: React.FC,
    DealerName: React.FC,
    DealerLocation: React.FC,
    SkillLevel: React.FC
}
const Profile: React.FC<React.PropsWithChildren<ProfileProps>> & ProfileCollection = ({name, imageSrc, dealerName, dealerLocation, skillLevel, children}) => {
    return (
        <div className={`${style['profile-container']} bg-white bb-black-10 dF`}>
            <ProfileContext.Provider value={{imageSrc, name, dealerName, dealerLocation, skillLevel}}>
                {children}
            </ProfileContext.Provider>
        </div>
    )
}
Profile.Name = Name;
Profile.Avatar = Avatar;
Profile.DealerName = DealerName;
Profile.SkillLevel = SkillLevel;
Profile.DealerLocation = DealerLocation;
export default Profile;