import axios from 'axios';
import { useQueryStates } from 'nuqs';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavArrowSvg from '../../../../../assets/icons/NavArrowSvg';
import EmptyData from '../../../../../components/empty-data';
import Loader from '../../../../../components/loader';
import UserContext from '../../../../../context/UserContext';
import DateFormat from '../../../../../enums/DateFormat';
import useEffectAfterMount from '../../../../../hooks/useEffectAfterMount';
import ViewLayout from '../../../../../layout/view-layout';
import DateProcessor from '../../../../../processor/DateProcessor';
import { getFullName } from '../../../../../utils/FullNameUtil';
import ServerConnectorUtil from '../../../../../utils/ServerConnectorUtil';
import './index.css';

const additionalFields = [
  'stats.total_jc_plannings_approved',
  'stats.total_jc_plannings_submitted',
  'stats.total_jc_plannings_planned_unique_villages'
];

interface Role {
  id: string;
  name: string;
}

interface JcpStats {
  total_jc_plannings_approved: number;
  total_jc_plannings_submitted: number;
  total_jc_plannings_planned_unique_villages: number;
}

interface User {
  id: string;
  role: Role;
  zuid: string;
  stats: JcpStats;
  email: string;
  last_name: string;
  first_name: string;
  dealer_code: string;
  company_code: string;
}

const JcpPlanList: React.FC = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  // Url Params
  const [
    {
      start_date_time,
      end_date_time,
      till_date_start_value,
      title,
      name,
      month,
      year
    }
  ] = useQueryStates(
    {
      start_date_time: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      end_date_time: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      till_date_start_value: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      title: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      name: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      month: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      year: {
        defaultValue: '',
        parse: (value) => value || ''
      }
    },
    {
      history: 'replace'
    }
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);

  useEffectAfterMount(() => {
    setIsLoading(true);

    const params = new URLSearchParams();
    if (additionalFields.length > 0) {
      params.append('fields', additionalFields.join(','));
    }
    params.append(
      'start_date_time',
      DateProcessor.format(parseInt(start_date_time), DateFormat.ISO_DATE_TIME)
    );
    params.append(
      'end_date_time',
      DateProcessor.format(parseInt(end_date_time), DateFormat.ISO_DATE_TIME)
    );
    params.append(
      'till_date_start_value',
      DateProcessor.format(
        parseInt(till_date_start_value),
        DateFormat.ISO_DATE_TIME
      )
    );

    axios
      .get(`/api/v1/dsp-users-with-statistics?${params.toString()}`)
      .then((response) => {
        const { data } = response.data;
        if (name === 'total_dsp') {
          setData(data);
        } else if (name === 'dsps_with_no_jcp') {
          const filteredData = data.filter(
            (item: User) =>
              item.stats.total_jc_plannings_approved +
                item.stats.total_jc_plannings_submitted ===
              0
          );
          setData(filteredData);
        } else if (name === 'dsps_with_jcp_(pending_approval)') {
          const filteredData = data.filter(
            (item: User) => item.stats.total_jc_plannings_submitted !== 0
          );
          setData(filteredData);
        } else if (name === 'dsps_with_jcp_(approved)') {
          const filteredData = data.filter(
            (item: User) => item.stats.total_jc_plannings_approved !== 0
          );
          setData(filteredData);
        }
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const searchParams = new URLSearchParams();
      const id = event.currentTarget.getAttribute('data-id');
      const name = event.currentTarget.getAttribute('data-name');
      searchParams.set('month', month);
      searchParams.set('year', year);
      searchParams.set('dsp_name', name || '');

      navigate(
        {
          pathname: `/${user.user_role}/jcp/jcp-plan/dsp-details/${id}`,
          search: searchParams.toString()
        },
        {}
      );
    },
    []
  );

  return (
    <ViewLayout title={title}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-20">
          <div className="dF direction-column gap-10">
            {data?.map((item: User) => (
              <div
                data-id={item.id}
                data-name={item.first_name + ' ' + item.last_name}
                className="tafe-card p-20 dF align-center justify-between"
                onClick={navigateToDetails}
              >
                <div className="dF direction-column gap-8">
                  <div className="dF align-center gap-4">
                    <p className="text-16 color-black-70 ls-028">DSP Name : </p>
                    <p className="text-16 font-600 color-black ls-028">
                      {getFullName(item.first_name, item.last_name)}
                    </p>
                  </div>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">
                      Planned JCP :
                    </p>
                    <p className="text-14 font-600 color-black ls-028">
                      {item.stats.total_jc_plannings_submitted +
                        item.stats.total_jc_plannings_approved}
                    </p>
                  </div>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">
                      Unique Villages :
                    </p>
                    <p className="text-14 font-600 color-black ls-028">
                      {item.stats.total_jc_plannings_planned_unique_villages}
                    </p>
                  </div>
                  {name === 'dsps_with_jcp_(pending_approval)' && (
                    <div className="dF align-center gap-10">
                      <div className="dF align-center gap-2">
                        <div className="approve-tick"></div>
                        <p className="text-16 color-link text-decoration-underline">
                          Approve
                        </p>
                      </div>
                      <div className="dF align-center gap-2">
                        <div className="reject-tick"></div>
                        <p className="text-16 color-black-40 text-decoration-underline">
                          Reject
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="dF direction-column align-end gap-20">
                  <div className="mr-6">
                    <NavArrowSvg />
                  </div>
                </div>
              </div>
            ))}
            {data.length == 0 && (
              <div style={{ width: '100%', height: 'calc(100vh - 150px)' }}>
                <EmptyData />
              </div>
            )}
          </div>
        </div>
      )}
    </ViewLayout>
  );
};

export default JcpPlanList;
