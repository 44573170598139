import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowSvg } from '../../assets/icons/ArrowSvg';
import styles from './styles.module.css';
interface ViewLayoutProps {
  title?: string;
  children: React.ReactNode;
}

const ViewLayout: React.FC<ViewLayoutProps> = ({ title, children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goToPreviousRoute = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={styles['viewlayout-container']}>
      <div className={styles['viewlayout-header']}>
        <div className="dF align-center">
          <div
            className="dF justify-center w48 cP rotate-180"
            onClick={goToPreviousRoute}
          >
            <ArrowSvg fill="white" />
          </div>

          <h2 className="text-18 font-600 color-white mt-2">
            {searchParams.get('title') || title}
          </h2>
        </div>
      </div>
      <div className={styles['viewlayout-body']}>{children}</div>
    </div>
  );
};

export default ViewLayout;
