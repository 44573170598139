import './modules/shared/css/global.css';
import './modules/shared/css/helper.css';

import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import { NuqsAdapter } from 'nuqs/adapters/react-router';
import DealerRoutes from './modules/dealer';
import DsmRoutes from './modules/dsm';
import Login from './modules/login';
import AppUrlConstants from './modules/shared/constants/AppUrlContanst';
import Translations from './modules/shared/translations';
import ToastUtil from './modules/shared/utils/ToastUtil';
import Validator from './modules/validator';
import TetmRoutes from './modules/tetm';
import DspRoutes from './modules/dsp';
import usePreferences from './modules/shared/hooks/usePreferences';

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const setGlobalCssProperties = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const savedTheme = localStorage.getItem('data-theme') || 'default';
    document.getElementById('root')?.setAttribute('data-theme', savedTheme);

    const savedLanguage = localStorage.getItem('data-language') || 'en';
    i18next
      .use(initReactI18next)
      .init({
        lng: savedLanguage,
        fallbackLng: 'en',
        debug: true,
        resources: {
          en: {
            translation: Translations.EN
          },
          ta: {
            translation: Translations.TA
          }
        },
        interpolation: {
          escapeValue: false
        }
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        ToastUtil.makeFailureToast('Failed to load translations.');
      });

    setGlobalCssProperties();
    window.addEventListener('resize', setGlobalCssProperties);
    window.addEventListener('orientationchange', setGlobalCssProperties);

    return () => {
      window.removeEventListener('resize', setGlobalCssProperties);
      window.removeEventListener('orientationchange', setGlobalCssProperties);
    };
  }, []);

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 4000,
          success: {
            duration: 3000
          },
          error: {
            duration: 3000
          }
        }}
      ></Toaster>
      {!isLoading && (
        <I18nextProvider i18n={i18next}>
          <HashRouter>
            <NuqsAdapter>
              <Routes>
                <Route path="" element={<Validator />}>
                  {TetmRoutes()}
                  {DsmRoutes()}
                  {DealerRoutes()}
                  {DspRoutes()}
                  <Route path={AppUrlConstants.LOGIN} element={<Login />} />
                </Route>
              </Routes>
            </NuqsAdapter>
          </HashRouter>
        </I18nextProvider>
      )}
    </>
  );
}

export default App;
