import { useState } from "react";

import useEffectAfterMount from "./useEffectAfterMount";
import { UseJcPlanningMetaHook } from "../../types/hooks";
import JcPlanningService from "../services/JcPlanningService";
import { CommonTypes } from "../../types/common";

export default function useJcPlanningMeta({
  onSuccess,
  onFailure
}: UseJcPlanningMetaHook.Parameters): UseJcPlanningMetaHook.ReturnType {
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [planTypeOptions, setPlanTypeOptions] = useState<
    Array<CommonTypes.FieldOption>
  >([]);

  useEffectAfterMount(() => {
    setIsLoading(true);
    JcPlanningService.getMeta()
      .then((result) => {
        setPlanTypeOptions(
          result.find((obj) => obj.name === "plan_type")?.options || []
        );
        if (onSuccess) {
          onSuccess.apply(null, [result]);
        }
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onSuccess, onFailure]);

  return {
    error,
    isLoading,
    planTypeOptions
  };
}
