import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export type DateProps = {
  label?: string;
  name: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  type?: 'date';
  mandatory?: boolean;
  dateFormat?: string;
  onChange?: (name: string, value: string) => void;
};

const InputDate: React.FC<DateProps> = ({
  label,
  name,
  value,
  error,
  disabled = false,
  mandatory = false,
  type = 'date',
  dateFormat = 'dd-MM-yyyy',
  onChange
}) => {
  const [selectedDate, setSelectedDate] = useState(
    value ? new Date(value) : null
  );
  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        setSelectedDate(date);
        onChange?.(name, date.toISOString());
      } else {
        onChange?.(name, '');
      }
    },
    [onChange, name]
  );

  return (
    <div className="w100p">
      {label && <p className="text-14 font-500 mb-4">{label}{mandatory && <span className="color-error">*</span>}</p>}
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        disabled={disabled}
        dateFormat={dateFormat}
      />
      {error && <p className="text-12 color-error">{error}</p>}
    </div>
  );
};

export default InputDate;
