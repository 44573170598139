import { useState } from 'react';
import DateFilter from '../../../components/date-filter';
import ViewLayout from '../../../layout/view-layout';

const TargetSetting: React.FC = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  return (
    <>
      <ViewLayout title="Target Setting">
        <div className="text-14 font-500 p-20 color-black bb-black-10">
          Select a Dealer to add Target:
        </div>
        <div className="p-20">
          <DateFilter
            values={[
              'All Time',
              'Today',
              'Yesterday',
              'This week',
              'Last week',
              'This month',
              'Last month',
              'Last 3 months',
              'This year',
              'Custom range'
            ]}
            onChange={handleDateChange}
          />
          <div className="dF direction-column gap-14 mt-20">
            <div className="tafe-card p-14 dF align-center justify-between">
              <div className="dF direction-column gap-8">
                <h2 className="text-16 font-600 color-black">
                  Rao Tractors Pvt. Ltd
                </h2>
                <div>
                  <p className="text-12 color-black-55 font-400">
                    Hot Prospect
                  </p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
                <div>
                  <p className="text-12 color-black-55 font-400">Delivery</p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
              </div>
              {/* <div className="dF direction-column align-end gap-20">
                <div className="mr-6">
                  <NavArrowSvg />
                </div>
              </div> */}
            </div>

            <div className="tafe-card p-14 dF align-center justify-between">
              <div className="dF direction-column gap-8">
                <h2 className="text-16 font-600 color-black">
                  Rao Tractors Pvt. Ltd
                </h2>
                <div>
                  <p className="text-12 color-black-55 font-400">
                    Hot Prospect
                  </p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
                <div>
                  <p className="text-12 color-black-55 font-400">Delivery</p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
              </div>
              {/* <div className="dF direction-column align-end gap-20">
                <div className="mr-6">
                  <NavArrowSvg />
                </div>
              </div> */}
            </div>

            <div className="tafe-card p-14 dF align-center justify-between">
              <div className="dF direction-column gap-8">
                <h2 className="text-16 font-600 color-black">
                  Rao Tractors Pvt. Ltd
                </h2>
                <div>
                  <p className="text-12 color-black-55 font-400">
                    Hot Prospect
                  </p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
                <div>
                  <p className="text-12 color-black-55 font-400">Delivery</p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
              </div>
              {/* <div className="dF direction-column align-end gap-20">
                <div className="mr-6">
                  <NavArrowSvg />
                </div>
              </div> */}
            </div>

            <div className="tafe-card p-14 dF align-center justify-between">
              <div className="dF direction-column gap-8">
                <h2 className="text-16 font-600 color-black">
                  Rao Tractors Pvt. Ltd
                </h2>
                <div>
                  <p className="text-12 color-black-55 font-400">
                    Hot Prospect
                  </p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
                <div>
                  <p className="text-12 color-black-55 font-400">Delivery</p>
                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">Target : </p>
                    <p className="text-14 font-600 color-black-70 ls-028">55</p>
                  </div>
                </div>
              </div>
              {/* <div className="dF direction-column align-end gap-20">
                <div className="mr-6">
                  <NavArrowSvg />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </ViewLayout>
    </>
  );
};

export default TargetSetting;
