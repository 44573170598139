import React from 'react';

export const TafeLogoSvg: React.FC = () => {
  return (
    <svg
      width="80"
      height="28"
      viewBox="0 0 600 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.14 80.96H180.77C181.2 81.01 181.42 81.23 181.42 81.61V113.46C181.42 113.9 181.2 114.11 180.77 114.11H68.81C56.89 114.11 49.07 120.07 45.33 131.98C44.95 133.71 44.76 135.99 44.76 138.81V163.27C44.76 174.97 49.96 182.77 60.36 186.67C62.47 187.54 65.5 187.97 69.46 187.97H180.77C181.2 188.02 181.42 188.21 181.42 188.54V220.14C181.42 220.58 181.2 220.79 180.77 220.79H43.79C27.59 220.79 14.68 213.91 5.03998 200.15C1.67998 194.57 0 188.83 0 182.93V117.53C0 106.81 5.92998 97.11 17.79 88.44C26.02 83.46 34.47 80.97 43.14 80.97V80.96Z"
        fill="black"
      />
      <path
        d="M196.58 80.9599H326.68C340.65 80.9599 352.43 86.48 362.02 97.53C367.22 104.3 369.82 111.4 369.82 118.82V144.49C369.82 157.54 362.53 168.49 347.97 177.31C345.31 178.34 343.85 178.85 343.58 178.85C345.75 182.48 354.49 196.24 369.82 220.12V220.77H313.84C312.65 219.85 306.72 208.29 296.05 186.08L293.53 182.26H240.69V220.12C240.69 220.56 240.47 220.77 240.04 220.77H196.57C196.13 220.77 195.92 220.55 195.92 220.12V81.5899C195.97 81.1599 196.19 80.9399 196.57 80.9399L196.58 80.9599ZM240.69 114.11V149.13H307.26C316.36 149.13 322.1 145.18 324.48 137.27C324.91 135.37 325.13 133.61 325.13 131.99C325.13 123.49 321.58 117.85 314.49 115.09C312.27 114.44 310.08 114.12 307.91 114.12H240.69V114.11Z"
        fill="black"
      />
      <path
        d="M415.81 68.2501C434.422 68.2501 449.51 53.1621 449.51 34.5501C449.51 15.9381 434.422 0.850098 415.81 0.850098C397.198 0.850098 382.11 15.9381 382.11 34.5501C382.11 53.1621 397.198 68.2501 415.81 68.2501Z"
        fill="#00A551"
      />
      <path
        d="M566.3 68.2501C584.912 68.2501 600 53.1621 600 34.5501C600 15.9381 584.912 0.850098 566.3 0.850098C547.688 0.850098 532.6 15.9381 532.6 34.5501C532.6 53.1621 547.688 68.2501 566.3 68.2501Z"
        fill="#ED1C24"
      />
      <path
        d="M118.97 7.4502H96.87L63.14 61.6602H82.01L89.76 49.0202L126.37 48.9502L134.3 61.6602H153.11L119.74 7.4502H118.97ZM97.69 36.1602L106.56 22.0202H109.95L118.72 36.1402L97.69 36.1602ZM81.64 7.4502H0V8.82019L0.0100098 22.0202H31.1V61.6502H51.01V22.0102H81.63V7.4502H81.64ZM301.54 21.9802V7.4502H232.22V61.6602H301.54V47.1402H249.73V40.8402H295.14V28.0602H249.73V21.9902H301.54V21.9802ZM158.13 61.6602H175.64V40.8302H221.05V28.0102H175.64V21.9802H227.45V7.4502H158.13V61.6602Z"
        fill="#EC1C24"
      />
      <path
        d="M600 81.2898C600 80.8798 599.67 80.5498 599.26 80.5498H580.94C580.94 80.5498 580.91 80.5498 580.9 80.5498C562.87 80.7398 545.21 79.4298 542.97 102.05C542.58 117.82 530.61 140.33 495.58 145.9C494.48 146.1 492.88 146.24 491.06 146.24C489.24 146.24 487.64 146.1 486.55 145.89C451.52 140.32 439.55 117.81 439.16 102.05C436.91 79.4298 424.45 80.7298 406.42 80.5498C406.4 80.5498 406.39 80.5498 406.38 80.5498H382.86C382.45 80.5498 382.12 80.8798 382.12 81.2898V118.26C382.12 118.37 382.15 118.48 382.2 118.58L382.12 220.99L439.08 221.04L439.12 167.14C449.68 173.72 469.12 182.44 491.06 182.44C513 182.44 532.44 173.72 543 167.14L543.04 221.04L600 220.99L599.92 118.58C599.97 118.48 600 118.38 600 118.26V81.2898Z"
        fill="black"
      />
    </svg>
  );
};
