import React, { HTMLAttributes } from 'react'
import style from './index.module.css';

const Content: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({children}) => {
    return (
        <div className={`p-16 bg-white ${style['content']}`}>
            {children}
        </div>
    )
}

export default Content