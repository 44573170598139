import { IOptions } from '../../../types/global';
import SearchDropdown from '../search-dropdown';

export type RecordDropdownSearchBoxProps = {
  value: string;
  placeholder: string;
  dropDownOptions: IOptions[];
  initialDropDownValue?: IOptions;
  onDropDownChange: (value: IOptions) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RecordDropdownSearchBox: React.FC<RecordDropdownSearchBoxProps> = ({
  value,
  onChange,
  placeholder,
  dropDownOptions,
  onDropDownChange,
  initialDropDownValue
}) => {
  return (
    <div className="search-details-header">
      <SearchDropdown
        options={dropDownOptions}
        onSelect={onDropDownChange}
        placeholder="Select"
        defaultValue={initialDropDownValue}
      />
      <div className="pR w100p">
        {/* <div className="input-search-icon"></div> */}
        <input
          type="text"
          placeholder={placeholder}
          className="input-box search-input"
          onChange={onChange}
          value={value}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default RecordDropdownSearchBox;
