import { useCallback, useContext, useEffect, useState } from 'react';
import { DspReportee, DspReportees } from '../../../types/model';
import NavCard from '../../components/nav-card';
import styles from './styles.module.css';
import dummyUser from '../../assets/images/dummy-user.png';
import { useTranslation } from 'react-i18next';
import { TrophySvg } from '../../assets/icons/TrophySvg';
import { ArrowPrimarySvg } from '../../assets/icons/ArrowPrimarySvg';
import { RedArrow } from '../../assets/icons/RedArrow';
import { GreenArrow } from '../../assets/icons/GreenArrow';
import { getFullName } from '../../utils/FullNameUtil';
import EmptyData from '../../components/empty-data';
import { ArrowSvg } from '../../assets/icons/ArrowSvg';
import ProspectEnquiryClassification from '../../enums/ProspectEnquiryClassification';
import ProspectStage from '../../enums/ProspectStage';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../context/UserContext';

type DspViewProps = {
  data: DspReportees;
};

const DspView: React.FC<DspViewProps> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [showZeroDeliveries, setShowZeroDeliveries] = useState(false);
  const [zeroDeliveriesData, setZeroDeliveriesData] = useState<DspReportee[]>(
    []
  );
  const [showZeroHotProspects, setShowZeroHotProspects] = useState(false);
  const [zeroHotProspectsData, setZeroHotProspectsData] = useState<
    DspReportee[]
  >([]);
  const [showLessEnquiries, setShowLessEnquiries] = useState(false);
  const [zeroLessEnquiriesData, setZeroLessEnquiriesData] = useState<
    DspReportee[]
  >([]);
  const [showZeroCheckIn, setShowZeroCheckIn] = useState(false);
  const [zeroCheckInData, setZeroCheckInData] = useState<DspReportee[]>([]);
  const [showDspDetails, setShowDspDetails] = useState(false);
  const [dspDetail, setDspDetail] = useState<DspReportee | null>(null);

  useEffect(() => {
    if (data.users.length > 0) {
      let zeroDeliveriesData =
        data.users.filter((item) => item.stats.total_deliveries === 0) || [];
      setZeroDeliveriesData(zeroDeliveriesData);

      let zeroHotProspectData =
        data.users.filter((item) => item.stats.total_hot_prospects === 0) || [];
      setZeroHotProspectsData(zeroHotProspectData);

      let zeroLessEnquiriesData =
        data.users.filter(
          (item) =>
            item.stats.total_leads +
              item.stats.total_hot_prospects +
              item.stats.total_prospects <
            8
        ) || [];
      setZeroLessEnquiriesData(zeroLessEnquiriesData);

      let zeroCheckInData =
        data.users.filter(
          (item) =>
            item.stats.total_jc_planning_check_in_in_last_five_days === 0
        ) || [];
      setZeroCheckInData(zeroCheckInData);
    }
  }, [data]);

  const showReporteeDetails = useCallback((data: DspReportee) => {
    setShowDspDetails(true);
    setDspDetail(data);
  }, []);
  const hideReporteeDetails = useCallback(() => {
    setShowDspDetails(false);
    setDspDetail(null);
  }, []);

  const getFormattedDate = (): string => {
    const today = new Date();

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-GB', options).format(today);
  };

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');
      const stages = element.getAttribute('data-stages');
      const enquiryClassifications = element.getAttribute(
        'data-enquiry-classifications'
      );
      const edd = element.getAttribute('data-edd-end-value');
      const sogs = element.getAttribute('data-sogs');
      const searchParams = new URLSearchParams({
        ...(stages ? { stages } : {}),
        ...(sogs ? { sogs } : {}),
        ...(enquiryClassifications
          ? { enquiry_classifications: enquiryClassifications }
          : {})
      });

      if (dspDetail && dspDetail.id) {
        searchParams.set('my_dsp_reportee_id', dspDetail.id);
      }
      if (title) {
        searchParams.set('title', title);
      }

      if (edd) {
        searchParams.set('edd_end_value', edd);
      }

      navigate(
        {
          pathname: `/${user.user_role}/prospects`,
          search: searchParams.toString()
        },
        {}
      );
    },
    [dspDetail]
  );

  return (
    <>
      <div className="mt-20">
        <NavCard title="Total number of DSP/ Target">
          <p className="text-14 color-black h44 dF align-center">
            <span className="text-24 font-300">{data.total_no_of_users}</span>/
            {data.total_no_of_users_target}
          </p>
        </NavCard>
        <h2 className="text-14 font-600 color-black ls-028 mb-16 mt-20">
          DSP Productivity :
        </h2>
        <div className="dF gap-12 mb-12">
          <div className="two-column-width">
            <NavCard
              title="Zero deliveries"
              showArrow={true}
              onClick={() => setShowZeroDeliveries(true)}
            >
              <div className="text-24 font-500 color-link h44 dF align-center gap-4">
                {data.zero_delivery_analytics.current_value}
                {data.zero_delivery_analytics.variation !== 0 &&
                  (data.zero_delivery_analytics.variation > 0 ? (
                    <>
                      <span className="text-14" style={{ color: '#b51213' }}>
                        (+{data.zero_delivery_analytics.variation})
                      </span>
                      <RedArrow />
                    </>
                  ) : (
                    <>
                      <span className="text-14" style={{ color: '#007851' }}>
                        ({data.zero_delivery_analytics.variation})
                      </span>
                      <GreenArrow />
                    </>
                  ))}
              </div>
            </NavCard>
          </div>
          <div className="two-column-width">
            <NavCard
              title="Zero Hot Prospects"
              showArrow={true}
              onClick={() => setShowZeroHotProspects(true)}
            >
              <p className="text-24 font-500 color-link h44 dF align-center gap-4">
                {data.zero_hot_prospect_analytics.current_value}
                {data.zero_hot_prospect_analytics.variation !== 0 &&
                  (data.zero_hot_prospect_analytics.variation > 0 ? (
                    <>
                      <span className="text-14" style={{ color: '#b51213' }}>
                        (+{data.zero_hot_prospect_analytics.variation})
                      </span>
                      <RedArrow />
                    </>
                  ) : (
                    <>
                      <span className="text-14" style={{ color: '#007851' }}>
                        ({data.zero_hot_prospect_analytics.variation})
                      </span>
                      <GreenArrow />
                    </>
                  ))}
              </p>
            </NavCard>
          </div>
        </div>
        <div className="dF gap-12 mb-20">
          <div className="two-column-width">
            <NavCard
              title="Less than 8 enquiries"
              showArrow={true}
              onClick={() => setShowLessEnquiries(true)}
            >
              <p className="text-24 font-500 color-link h44 dF align-center gap-4">
                {data.less_than_eight_enquires_analytics.current_value}
                {data.less_than_eight_enquires_analytics.variation !== 0 &&
                  (data.less_than_eight_enquires_analytics.variation > 0 ? (
                    <>
                      <span className="text-14" style={{ color: '#b51213' }}>
                        (+{data.less_than_eight_enquires_analytics.variation})
                      </span>
                      <RedArrow />
                    </>
                  ) : (
                    <>
                      <span className="text-14" style={{ color: '#007851' }}>
                        ({data.less_than_eight_enquires_analytics.variation})
                      </span>
                      <GreenArrow />
                    </>
                  ))}
              </p>
            </NavCard>
          </div>
          <div className="two-column-width">
            <NavCard
              title="Zero check-in in last 5 days"
              showArrow={true}
              onClick={() => setShowZeroCheckIn(true)}
            >
              <p className="text-24 font-500 color-link h44 dF align-center gap-4">
                {data.total_zero_jc_planning_check_in_in_last_five_days}
              </p>
            </NavCard>
          </div>
        </div>
        <h2 className="text-14 font-600 color-black ls-028 mb-10">
          DSP Leader board
        </h2>
        <table className={styles['table']}>
          <thead className={styles['thead']}>
            <tr>
              <th className="text-12 font-500 color-black-55 p-12-8 text-left bb-black-10">
                {t('dsp.rank')}
              </th>
              <th className="text-12 font-500 color-black-55 p-12-8 text-left bb-black-10">
                {t('common.name')}
              </th>
              {user.user_role === 'dealer' || user.user_role === 'dsm' ? (
                <>
                  <th className="text-12 font-500 color-black-55 p-12-8 text-left bb-black-10">
                    Del
                  </th>
                  <th className="text-12 font-500 color-black-55 p-12-8 text-left bb-black-10">
                    HPs
                  </th>
                </>
              ) : (
                <th className="text-12 font-500 color-black-55 p-12-8 text-left bb-black-10">
                  {t('dsp.deliveries')}
                </th>
              )}
            </tr>
            <tr></tr>
            <tr></tr>
            {/* This both empty tr for space between header and body */}
          </thead>
          <tbody className={`bg-white`}>
            {data.users.map((item, index) => (
              <tr
                key={item.id}
                className={styles['tr']}
                onClick={() => showReporteeDetails(item)}
              >
                <td className={styles['td']}>
                  <div className="pR dF justify-center">
                    <TrophySvg />
                    <span className={styles['trophyIndex']}>{index + 1}</span>
                  </div>
                </td>
                <td className={styles['td']}>
                  <div className="dF align-center">
                    <img
                      className={styles['dsp-img']}
                      src={dummyUser}
                      alt="User Image"
                    />
                    <p className="ml-8 text-14 font-500 w150 text-overflow-wrap">
                      {getFullName(item.first_name, item.last_name)}
                    </p>
                  </div>
                </td>
                {user.user_role === 'dealer' || user.user_role === 'dsm' ? (
                  <>
                    <td className={styles['td']}>
                      <span className="dF justify-center gap-4">
                        <span className="mt-2">
                          {item.stats.total_deliveries}
                        </span>
                        {item.stats.total_deliveries !== 0 && (
                          <ArrowPrimarySvg
                            fill={
                              item.stats.total_deliveries > 0
                                ? '#007851'
                                : '#b51213'
                            }
                            style={
                              item.stats.total_deliveries > 0
                                ? {
                                    transform: 'scale(.7) rotate(270deg)',
                                    marginTop: '2.5px'
                                  }
                                : {
                                    transform: 'scale(.7) rotate(90deg)',
                                    marginTop: '2.5px'
                                  }
                            }
                          />
                        )}
                      </span>
                    </td>
                    <td className={styles['td']}>
                      <span className="dF justify-center gap-4">
                        <span className="mt-2">
                          {item.stats.total_hot_prospects}
                        </span>
                        {item.stats.total_hot_prospects !== 0 && (
                          <ArrowPrimarySvg
                            fill={
                              item.stats.total_hot_prospects > 0
                                ? '#007851'
                                : '#b51213'
                            }
                            style={
                              item.stats.total_hot_prospects > 0
                                ? {
                                    transform: 'scale(.7) rotate(270deg)',
                                    marginTop: '2.5px'
                                  }
                                : {
                                    transform: 'scale(.7) rotate(90deg)',
                                    marginTop: '2.5px'
                                  }
                            }
                          />
                        )}
                      </span>
                    </td>
                  </>
                ) : (
                  <td className={styles['td']}>
                    <span className="dF justify-center gap-4">
                      <span className="mt-2">
                        {item.stats.total_deliveries}
                      </span>
                      {item.stats.total_deliveries !== 0 && (
                        <ArrowPrimarySvg
                          fill={
                            item.stats.total_deliveries > 0
                              ? '#007851'
                              : '#b51213'
                          }
                          style={
                            item.stats.total_deliveries > 0
                              ? {
                                  transform: 'scale(.7) rotate(270deg)',
                                  marginTop: '2.5px'
                                }
                              : {
                                  transform: 'scale(.7) rotate(90deg)',
                                  marginTop: '2.5px'
                                }
                          }
                        />
                      )}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showZeroDeliveries && (
        <div className={styles['dsp_details']}>
          <div className={styles['dsp_details_header']}>
            <div className="dF align-center">
              <div
                className="dF justify-center w48 cP rotate-180"
                onClick={() => setShowZeroDeliveries(false)}
              >
                <ArrowSvg fill="white" />
              </div>
              <h2 className="text-18 font-600 color-white mt-2">
                Zero deliveries
              </h2>
            </div>
          </div>
          <div className={styles['dsp_details_body']}>
            <div className="m-20">
              <div className="tafe-card">
                <h3
                  className="text-14 p-14 font-600 color-secondary ls-028"
                  style={{ borderBottom: '1px solid var(--primary-10)' }}
                >
                  Zero deliveries dashboard
                </h3>

                {zeroDeliveriesData.length ? (
                  <table className="tafe-table">
                    <tr>
                      <td className="color-black-70">Name</td>
                      <td className="color-black-70">Target</td>
                    </tr>
                    {zeroDeliveriesData.map((item) => (
                      <tr>
                        <td>
                          <p className="text-14 color-black-70">
                            {getFullName(item.first_name, item.last_name)}
                          </p>
                          <p className="text-10 color-black-30 mt-5">
                            {item.company_code}
                          </p>
                        </td>
                        <td>
                          <p className="text-18 color-black">
                            {item.stats?.total_deliveries_target || 0}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <EmptyData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showZeroHotProspects && (
        <div className={styles['dsp_details']}>
          <div className={styles['dsp_details_header']}>
            <div className="dF align-center">
              <div
                className="dF justify-center w48 cP rotate-180"
                onClick={() => setShowZeroHotProspects(false)}
              >
                <ArrowSvg fill="white" />
              </div>
              <h2 className="text-18 font-600 color-white mt-2">
                Zero Hot Prospects
              </h2>
            </div>
          </div>
          <div className={styles['dsp_details_body']}>
            <div className="m-20">
              <div className="tafe-card">
                <h3
                  className="text-14 p-14 font-600 color-secondary ls-028"
                  style={{ borderBottom: '1px solid var(--primary-10)' }}
                >
                  Zero Hot Prospects Dashboard
                </h3>

                {zeroHotProspectsData.length ? (
                  <table className="tafe-table">
                    <tr>
                      <td className="color-black-70">Name</td>
                      <td className="color-black-70">Target</td>
                    </tr>
                    {zeroHotProspectsData.map((item) => (
                      <tr>
                        <td>
                          <p className="text-14 color-black-70">
                            {getFullName(item.first_name, item.last_name)}
                          </p>
                          <p className="text-10 color-black-30 mt-5">
                            {item.company_code}
                          </p>
                        </td>
                        <td>
                          <p className="text-18 color-black">
                            {(item.stats?.total_leads_target || 0) +
                              (item.stats?.total_prospects_target || 0)}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <EmptyData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showLessEnquiries && (
        <div className={styles['dsp_details']}>
          <div className={styles['dsp_details_header']}>
            <div className="dF align-center">
              <div
                className="dF justify-center w48 cP rotate-180"
                onClick={() => setShowLessEnquiries(false)}
              >
                <ArrowSvg fill="white" />
              </div>
              <h2 className="text-18 font-600 color-white mt-2">
                Less than 8 enquiries
              </h2>
            </div>
          </div>
          <div className={styles['dsp_details_body']}>
            <div className="m-20">
              <div className="tafe-card">
                <h3
                  className="text-14 p-14 font-600 color-secondary ls-028"
                  style={{ borderBottom: '1px solid var(--primary-10)' }}
                >
                  Less than 8 enquiries Dashboard
                </h3>

                {zeroLessEnquiriesData.length ? (
                  <table className="tafe-table">
                    <tr>
                      <td className="color-black-70">Name</td>
                      <td className="color-black-70">Value</td>
                    </tr>
                    {zeroLessEnquiriesData.map((item) => (
                      <tr>
                        <td>
                          <p className="text-14 color-black-70">
                            {getFullName(item.first_name, item.last_name)}
                          </p>
                          <p className="text-10 color-black-30 mt-5">
                            {item.company_code}
                          </p>
                        </td>
                        <td>
                          <p className="text-18 color-black">
                            {item.stats.total_leads +
                              item.stats.total_prospects}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <EmptyData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showZeroCheckIn && (
        <div className={styles['dsp_details']}>
          <div className={styles['dsp_details_header']}>
            <div className="dF align-center">
              <div
                className="dF justify-center w48 cP rotate-180"
                onClick={() => setShowZeroCheckIn(false)}
              >
                <ArrowSvg fill="white" />
              </div>
              <h2 className="text-18 font-600 color-white mt-2">
                Zero check-in in last 5 days
              </h2>
            </div>
          </div>
          <div className={styles['dsp_details_body']}>
            <div className="m-20">
              <div className="tafe-card">
                <h3
                  className="text-14 p-14 font-600 color-secondary ls-028"
                  style={{ borderBottom: '1px solid var(--primary-10)' }}
                >
                  Zero check-in in last 5 days Dashboard
                </h3>

                {zeroCheckInData.length ? (
                  <table className="tafe-table">
                    <tr>
                      <td className="color-black-70">Name</td>
                    </tr>
                    {zeroCheckInData.map((item) => (
                      <tr>
                        <td>
                          <p className="text-14 color-black-70">
                            {getFullName(item.first_name, item.last_name)}
                          </p>
                          <p className="text-10 color-black-30 mt-5">
                            {item.company_code}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <EmptyData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showDspDetails && (
        <div className={styles['dsp_details']}>
          <div className={styles['dsp_details_header']}>
            <div className="dF align-center">
              <div
                className="dF justify-center w48 cP rotate-180"
                onClick={hideReporteeDetails}
              >
                <ArrowSvg fill="white" />
              </div>
              <h2 className="text-18 font-600 color-white mt-2">Dsp Details</h2>
            </div>
          </div>
          <div className={styles['dsp_details_body']}>
            <div className={styles['profile-box']}>
              <img
                className={styles['profile-img']}
                src={dummyUser}
                alt="User Image"
              />
              <div>
                <p className="text-18 color-primary">
                  {dspDetail &&
                    getFullName(dspDetail?.first_name, dspDetail.last_name)}
                </p>
                <p className="text-14 color-black-40 ls-028 mt-2">
                  {getFormattedDate()}
                </p>
              </div>
            </div>
            <div className="m-20">
              <div className="mb-20">
                <NavCard title={t('dsp.dsp_details.total_deliveries')}>
                  <div className="text-14 h64 ptb-14 color-black">
                    <span className="text-24">
                      {dspDetail?.stats.total_deliveries}
                    </span>
                    <span className="text-14">
                      /{dspDetail?.stats.total_deliveries_target || 0}
                    </span>
                  </div>
                </NavCard>
              </div>
              <div className="mb-20">
                <h3 className="text-14 font-600 color-secondary ls-028 mb-16">
                  {t('dsp.dsp_details.active_pipeline')} :
                </h3>
                <div className="dF gap-16 mb-16">
                  <div className="two-column-width">
                    <NavCard
                      title={t('common.leads')}
                      customAttributes={{
                        'data-title': ProspectEnquiryClassification.LEAD,
                        'data-enquiry-classifications':
                          ProspectEnquiryClassification.LEAD
                      }}
                      showArrow={true}
                      onClick={navigateToDetails}
                    >
                      <div className="text-24 font-500 h64 ptb-14 color-link">
                        {dspDetail?.stats.total_leads}
                      </div>
                    </NavCard>
                  </div>
                  <div className="two-column-width">
                    <NavCard
                      title={t('common.prospects')}
                      showArrow={true}
                      customAttributes={{
                        'data-title': ProspectEnquiryClassification.PROSPECT,
                        'data-enquiry-classifications':
                          ProspectEnquiryClassification.PROSPECT
                      }}
                      onClick={navigateToDetails}
                    >
                      <div className="text-24 font-500 h64 ptb-14 color-link">
                        {dspDetail?.stats.total_prospects}
                      </div>
                    </NavCard>
                  </div>
                </div>
                <div className="dF gap-16">
                  <div className="two-column-width">
                    <NavCard
                      title={t('common.hot_prospects')}
                      showArrow={true}
                      customAttributes={{
                        'data-title':
                          ProspectEnquiryClassification.HOT_PROSPECT,
                        'data-enquiry-classifications':
                          ProspectEnquiryClassification.HOT_PROSPECT
                      }}
                      onClick={navigateToDetails}
                    >
                      <div className="text-24 font-500 h64 ptb-14 color-link">
                        {dspDetail?.stats.total_hot_prospects}
                      </div>
                    </NavCard>
                  </div>
                  <div className="two-column-width">
                    <NavCard
                      title={t('common.overdue_hps')}
                      showArrow={true}
                      customAttributes={{
                        'data-title':
                          ProspectEnquiryClassification.HOT_PROSPECT,
                        'data-edd-end-value': Date.now().toString(),
                        'data-stages': [
                          ProspectStage.F1,
                          ProspectStage.F2,
                          ProspectStage.F3,
                          ProspectStage.F4,
                          ProspectStage.F5
                        ].join(','),
                        'data-enquiry-classifications':
                          ProspectEnquiryClassification.HOT_PROSPECT
                      }}
                      onClick={navigateToDetails}
                    >
                      <div className="text-24 h64 ptb-14 color-error">
                        {dspDetail?.stats.total_overdue_hot_prospects}
                      </div>
                    </NavCard>
                  </div>
                </div>
              </div>
              <div className="mb-20">
                <h3 className="text-14 font-600 color-secondary ls-028 mb-16">
                  {t('dsp.dsp_details.jcp_overview')} :
                </h3>

                <div className="tafe-card">
                  <table className="tafe-table">
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail?.stats.total_jc_planning_planned}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned TD
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail?.stats.total_jc_planning_planned_till_date}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Visited TD
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail?.stats.total_jc_planning_visited_till_date}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Tractor Users Added
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail?.stats.total_tractor_user_database_added}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Enquiries Generated
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail &&
                            dspDetail?.stats.total_prospects +
                              dspDetail?.stats.total_leads}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          HPs Generated
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail?.stats.total_hot_prospects}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <p className="text-12 font-500 color-black-55">
                          Deliveries
                        </p>
                        <p className="text-18 color-black mt-5">
                          {dspDetail?.stats.total_deliveries}
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DspView;
