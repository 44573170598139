import axios from 'axios';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dummyUser from '../../shared/assets/images/dummy-user.png';
import DateFilter from '../../shared/components/date-filter';
import Loader from '../../shared/components/loader';
import NavCard from '../../shared/components/nav-card';
import UserContext from '../../shared/context/UserContext';
import ProspectEnquiryClassification from '../../shared/enums/ProspectEnquiryClassification';
import ProspectStage from '../../shared/enums/ProspectStage';
import { getFullName } from '../../shared/utils/FullNameUtil';
import { HomeOverview } from '../../types/global';
import styles from './styles.module.css';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);
  const [isFetching, setIsFetching] = useState({
    overview: true
  });
  const [overviewData, setOverviewData] = useState<HomeOverview | null>(null);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const getFormattedDate = (): string => {
    const today = new Date();

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-GB', options).format(today);
  };

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      overview: true
    }));
    const params: Record<string, string> = {};

    if (selectedDateRange?.start) {
      params.start_date_time = selectedDateRange?.start
        ? moment(new Date(selectedDateRange?.start)).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
        : '';
    }

    if (selectedDateRange?.end) {
      params.end_date_time = selectedDateRange?.end
        ? moment(new Date(selectedDateRange?.end)).format('YYYY-MM-DDTHH:mm:ss')
        : '';
    }

    axios.get('/api/v1/prospects/overview', { params }).then((response) => {
      setOverviewData(response.data.data);
      setIsFetching((prevState) => ({
        ...prevState,
        overview: false
      }));
    });
  }, [selectedDateRange?.start, selectedDateRange?.end]);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');
      const stages = element.getAttribute('data-stages');
      const enquiryClassifications = element.getAttribute(
        'data-enquiry-classifications'
      );
      const edd = element.getAttribute('data-edd-end-value');
      const sogs = element.getAttribute('data-sogs');
      const searchParams = new URLSearchParams({
        ...(stages ? { stages } : {}),
        ...(sogs ? { sogs } : {}),
        ...(enquiryClassifications
          ? { enquiry_classifications: enquiryClassifications }
          : {})
      });
      if (title) {
        searchParams.set('title', title);
      }

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          searchParams.set(
            'created_time_start_value',
            selectedDateRange.start.toString()
          );
        }
        if (selectedDateRange.end) {
          searchParams.set(
            'created_time_end_value',
            selectedDateRange.end.toString()
          );
        }
      }

      if (edd) {
        searchParams.set('edd_end_value', edd);
      }

      navigate(
        {
          pathname: '/dealer/prospects',
          search: searchParams.toString()
        },
        {}
      );
    },
    [selectedDateRange]
  );

  return (
    <>
      <div className={styles['profile-box']}>
        <img
          className={styles['profile-img']}
          src={dummyUser}
          alt="User Image"
        />
        <div>
          <p className="text-12 color-black ls-048">{t('common.welcome')}</p>
          <p className="text-18 color-primary mt-2">
            {user && getFullName(user.data.first_name, user.data.last_name)}
          </p>
          <p className="text-14 color-black-40 ls-028 mt-2">
            {getFormattedDate()}
          </p>
        </div>
      </div>
      <div className="m-20">
        {isFetching.overview ? (
          <div
            className="w100p"
            style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 275px)' }}
          >
            <Loader />
          </div>
        ) : (
          <div className="dF direction-column gap-14">
            <div className="tafe-card p-20">
              <DateFilter
                values={[
                  'All Time',
                  'Today',
                  'Yesterday',
                  'This week',
                  'Last week',
                  'This month',
                  'Last month',
                  'Last 3 months',
                  'This year',
                  'Custom range'
                ]}
                onChange={handleDateChange}
              />

              {isFetching.overview ? (
                <div style={{ height: '200px' }}>
                  <Loader size="medium" />
                </div>
              ) : (
                <>
                  <div className="dF gap-12 mt-15 mb-12">
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.deliveries')}
                        customAttributes={{
                          'data-stages': 'Delivery',
                          'data-title': 'Delivery'
                        }}
                        showArrow={true}
                        onClick={navigateToDetails}
                      >
                        <p className="text-14 pt-10 color-black-70 ls-028">
                          {t('common.actual_target')}
                        </p>
                        <p className="text-14 color-black h44 dF align-center">
                          <span className="text-24 font-500 color-link">
                            {overviewData?.total_deliveries}
                          </span>
                          / {overviewData?.total_deliveries_target}
                        </p>
                      </NavCard>
                    </div>
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.hot_prospects')}
                        showArrow={true}
                        customAttributes={{
                          'data-title':
                            ProspectEnquiryClassification.HOT_PROSPECT,
                          'data-enquiry-classifications':
                            ProspectEnquiryClassification.HOT_PROSPECT
                        }}
                        onClick={navigateToDetails}
                      >
                        <p className="text-14 pt-10 color-black-70 ls-028">
                          {t('common.actual_target')}
                        </p>
                        <p className="text-14 color-black h44 dF align-center">
                          <span className="text-24 font-500 color-link">
                            {overviewData?.total_hot_prospects}
                          </span>
                          /{overviewData?.total_hot_prospects_target}
                        </p>
                      </NavCard>
                    </div>
                  </div>
                  <div className="dF gap-12">
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.enquiries')}
                        customAttributes={{
                          'data-title': 'Enquiries',
                          'data-enquiry-classifications': [
                            ProspectEnquiryClassification.LEAD,
                            ProspectEnquiryClassification.PROSPECT
                          ].join(',')
                        }}
                        showArrow={true}
                        onClick={navigateToDetails}
                      >
                        <p className="h44 pt-10 text-24 font-500 color-link">
                          {overviewData
                            ? overviewData.total_leads +
                              overviewData.total_prospects
                            : 0}
                        </p>
                      </NavCard>
                    </div>
                    <div className="two-column-width">
                      <NavCard
                        title={t('common.overdue_hps')}
                        showArrow={true}
                        customAttributes={{
                          'data-title':
                            ProspectEnquiryClassification.HOT_PROSPECT,
                          'data-edd-end-value': Date.now().toString(),
                          'data-stages': [
                            ProspectStage.F1,
                            ProspectStage.F2,
                            ProspectStage.F3,
                            ProspectStage.F4,
                            ProspectStage.F5
                          ].join(','),
                          'data-enquiry-classifications':
                            ProspectEnquiryClassification.HOT_PROSPECT
                        }}
                        onClick={navigateToDetails}
                      >
                        <p className="h44 pt-10 text-24 font-500 color-error">
                          {overviewData?.total_overdue_hot_prospects}
                        </p>
                      </NavCard>
                    </div>
                  </div>
                </>
              )}
            </div>
            <NavCard
              title="Current Stock · 14"
              showArrow={true}
              className="bp0"
            >
              <table className="tafe-table">
                <tr>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      &lt;30 Days
                    </p>
                    <p className="text-18 font-500 color-link mt-5">04</p>
                  </td>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      30 - 60 Days
                    </p>
                    <p className="text-18 font-500 color-link mt-5">03</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      61 - 90 Days
                    </p>
                    <p className="text-18 font-500 color-link mt-5">12</p>
                  </td>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      &gt;90 Days
                    </p>
                    <p className="text-18 font-500 color-link mt-5">08</p>
                  </td>
                </tr>
              </table>
            </NavCard>
            <NavCard title="Advances" showArrow={true} className="bp0">
              <table className="tafe-table">
                <tr>
                  <td>
                    <p className="text-12 font-500 color-black-55">Units</p>
                    <p className="text-18 font-500 color-link mt-5">12</p>
                  </td>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      INR (Lakh)
                    </p>
                    <p className="text-18 font-500 color-link mt-5">08</p>
                  </td>
                </tr>
              </table>
            </NavCard>
            <NavCard
              title="DSP - Today's Tasks"
              showArrow={true}
              className="bp0"
            >
              <table className="tafe-table">
                <tr>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      Today's follow ups
                    </p>
                    <p className="text-14 color-black mt-5">
                      <span className="text-18 font-500 color-link">05</span>/07
                    </p>
                  </td>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      Today's JCP visits
                    </p>
                    <p className="text-14 color-black mt-5">
                      <span className="text-18 font-500 color-link">06</span>/09
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      Overdue follow ups
                    </p>
                    <p className="text-14 color-black mt-5">
                      <span className="text-18 font-500 color-link">06</span>/09
                    </p>
                  </td>
                  <td>
                    <p className="text-12 font-500 color-black-55">
                      Overdue JCP visits
                    </p>
                    <p className="text-14 color-black mt-5">
                      <span className="text-18 font-500 color-link">05</span>/07
                    </p>
                  </td>
                </tr>
              </table>
            </NavCard>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
