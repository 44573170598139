import React from 'react';
import styles from './index.module.css';
import TableHead from './table-head';
import TableBody from './table-body';
import TableRow from './table-row';
import TableHeader from './table-header';
import TableCellData from './table-datacell';
type TableDataProp = {
    TableHead: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>>;
    TableRow: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLTableRowElement>>>;
    TableHeader: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLTableCellElement>>>;
    TableBody: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>>;
    TableDataCell: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLTableCellElement>>>
}
const TableData: React.FC<React.PropsWithChildren> & TableDataProp = ({children}) => {
    return (
        <table className={`${styles['table']}`}>
            {children}
        </table>
    )
}
TableData.TableHead = TableHead;
TableData.TableBody = TableBody;
TableData.TableRow = TableRow;
TableData.TableHeader = TableHeader;
TableData.TableDataCell = TableCellData;
export default TableData