import React, { useCallback, useState } from 'react'
import DateFilter from '../../shared/components/date-filter'
import NavCard from '../../shared/components/nav-card'
import { useTranslation } from 'react-i18next';
import ProspectStage from '../../shared/enums/ProspectStage';
import useCurrentUserStat from '../../shared/hooks/useCurrentUserStat';
import ServerConnectorUtil from '../../shared/utils/ServerConnectorUtil';
import UserAdditionalFields from '../../shared/enums/UserAdditionalFields';
import Loader from '../../shared/components/loader';
import style from './index.module.css';
import TableData from '../../shared/components/table-data';
import { getFullName } from '../../shared/utils/FullNameUtil';
import dummyUser from '../../shared/assets/images/dummy-user.png'
import classNames from 'classnames';
import { TrophySvg } from '../../shared/assets/icons/TrophySvg';
import usePagedMyDspPeers from '../../shared/hooks/usePagedMyDspPeers';

const additionalFields = [
    UserAdditionalFields.STATS_TOTAL_DELIVERIES
]
const Dashboard: React.FC = () => {
    const {t} = useTranslation();
    const {data, isLoading, updateFilters} = useCurrentUserStat({
        onFailure: ServerConnectorUtil.handleServerError
    })
    const {data: dspData, isLoading: isDspLoading} = usePagedMyDspPeers({
        additionalFields: additionalFields,
        onFailure: ServerConnectorUtil.handleServerError
    })
    const [totalMonths] = useState<number>(() => new Date().getMonth() + 1);
    const handleDateChange = useCallback((data: {
        dateRange: { start: number; end: number } | undefined;
    }) => {
        updateFilters({
            startDateTime: data.dateRange?.start,
            endDateTime: data.dateRange?.end
        })
    }, [])
    return (
        <>
            <div className={classNames('bg-white m-20 p-14 br-12', style['dashboard_stats'])}>
                <DateFilter
                    values={[
                        'All Time',
                        'Today',
                        'Yesterday',
                        'This week',
                        'Last week',
                        'This month',
                        'Last month',
                        'Last 3 months',
                        'This year',
                        'Custom range'
                      ]}
                      onChange={handleDateChange}
                />
                {
                    (isLoading) ?
                        <div className={style['loader-height']}>
                            <Loader size='medium'/>
                        </div>
                    :
                        <>
                            <div className='mt-15 dF direction-column gap-12'>
                                <div className='dF gap-12'>
                                    <div className='two-column-width'>
                                        <NavCard 
                                            title={t('common.deliveries')}
                                            customAttributes={{
                                                'data-stages': ProspectStage.DELIVERY,
                                                'data-start_date': new Date(2023, 0, 1).getTime().toString()
                                            }}
                                            showArrow={(data && data.total_deliveries > 0) ? false : true}
                                        >
                                            <p className='pt-10 pb-10'>
                                                <div className='color-black-70 text-14'>{t('common.actual_target')}</div>
                                                <div className='text-14 color-black mt-10'>
                                                    <span className='text-24 color-link'>{data?.total_deliveries}</span>/8
                                                </div>
                                            </p>
                                        </NavCard>
                                        
                                    </div>
                                    <div className='two-column-width'>
                                        <NavCard 
                                            title={t('dsp.dashboard.avg_deliveries')}
                                            customAttributes={{
                                                'data-start_date': new Date(2023, 0, 1).getTime().toString()
                                            }}
                                            showArrow = {(data && (Math.ceil(data.total_deliveries / totalMonths)) > 0) ? false : true}
                                        >
                                            <p className='pt-10 pb-10'>
                                                <div className='color-black-70 text-14'>{t('dsp.dashboard.actual')}</div>
                                                <div className='text-24 color-link mt-10'>
                                                    {
                                                        data?.total_deliveries
                                                        ? Math.ceil(
                                                            data.total_deliveries /
                                                            totalMonths
                                                        )
                                                        : 0
                                                    }
                                                </div>
                                            </p>
                                        </NavCard>
                                    </div>
                                </div>
                                <div className='dF gap-12'>
                                    <div className='two-column-width'>
                                        <NavCard 
                                            title={t('dsp.dashboard.my_incentive')}
                                        >
                                            <p className='text-24 color-black ptb-14'>Rs. 12,500</p>
                                        </NavCard>
                                    </div>
                                    <div className='two-column-width'>
                                        <NavCard 
                                            title={t('dsp.dashboard.my_rewards')}
                                        >
                                            <p className='text-24 color-black ptb-14'>500</p>
                                        </NavCard>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
            <div className='m-10-20'>
                <div className='dF align-center justify-between mb-10'>
                    <p className='text-14 font-600 color-secondary'>{t('dsp.dashboard.leader_board')}</p>
                </div>
                {
                    isDspLoading ?
                        <div className={style['loader-height']}>
                            <Loader size='medium'/>
                        </div>
                    :
                    <TableData>
                        <TableData.TableHead>
                            <TableData.TableRow>
                                <TableData.TableHeader>
                                    {`${t('dsp.rank')}(${dspData?.length})`}
                                </TableData.TableHeader>
                                <TableData.TableHeader>
                                    {t('common.name')}
                                </TableData.TableHeader>
                                <TableData.TableHeader>
                                    {t('dsp.deliveries')}
                                </TableData.TableHeader>
                            </TableData.TableRow>
                            <TableData.TableRow/>
                            <TableData.TableRow/>
                        </TableData.TableHead>
                        <TableData.TableBody>
                            {
                                dspData?.map((dsp, index) => (
                                    <TableData.TableRow key={dsp.id}>
                                        <TableData.TableDataCell className={classNames('text-12 pR dF justify-center', style['dsp-trophy'])}>
                                            <TrophySvg/>
                                            <p className={classNames(style['trophyIndex'], 'pA text-12 font-500')}>{index + 1}</p>
                                        </TableData.TableDataCell>
                                        <TableData.TableDataCell>
                                            <div className='dF align-center gap-12'>
                                                <img className={classNames('br-circle', style['profile-img'])} src={dummyUser}/>
                                                <p className='text-14 font-600'>
                                                    {getFullName(dsp.first_name, dsp.last_name)}    
                                                </p>
                                            </div>
                                        </TableData.TableDataCell>
                                        <TableData.TableDataCell className='text-14'>
                                            {dsp.stats.total_deliveries}
                                        </TableData.TableDataCell>
                                    </TableData.TableRow>
                                ))
                            }
                        </TableData.TableBody>
                    </TableData>
                }
            </div>
        </>
    )
}

export default Dashboard