import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import {
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath
} from 'react-router-dom';
import UserContext from '../../context/UserContext';
import styles from './styles.module.css';

// Fix MenuProps to include title instead of name
interface MenuProps {
  to: string;
  title: string;
}

// Correct MenuItems structure
interface MenuItems {
  to: string;
  title: string;
}

const Menu: React.FC<MenuProps> = ({ to, title }) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({
    path: resolved.pathname,
    end: false
  });

  const goToMenu = useCallback(() => {
    navigate(`/${user.user_role}/main/enquiries/${to}`, {
      replace: true
    });
  }, [navigate, to]);

  return (
    <div
      className={classNames(styles['menulist'], { [styles['active']]: match })}
      onClick={goToMenu}
    >
      <p className={styles['menu-title']}>{title}</p>
    </div>
  );
};

const Enquiries: React.FC = () => {
  const [menuList] = useState<MenuItems[]>([
    { to: 'enquiry', title: 'Enquiry' },
    { to: 'tudb', title: 'TUDB' }
  ]);

  return (
    <>
      <div className={styles['tab-header']}>
        {menuList.map((item) => (
          <Menu key={item.to} to={item.to} title={item.title} />
        ))}
      </div>
      <div className={styles['tab-body']}>
        <Outlet />
      </div>
    </>
  );
};

export default Enquiries;
