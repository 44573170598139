import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import classNames from 'classnames';
import NavArrowSvg from '../../assets/icons/NavArrowSvg';

interface NavCardProps {
  title: string;
  children?: React.ReactNode;
  to?: string;
  showArrow?: boolean;
  className?: string;
  customAttributes?: Record<string, string>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const NavCard: React.FC<NavCardProps> = ({
  title,
  children,
  to,
  showArrow,
  className,
  customAttributes,
  onClick
}) => {
  const cardContent = (
    <div
      {...customAttributes}
      className={classNames(
        styles['tafe-card'],
        className && styles[className],
        className
      )}
      onClick={onClick}
    >
      <div className={classNames(styles['tafe-card-header'])}>
        <h3 className="text-14 font-600 color-secondary ls-028 w90p">{title}</h3>
        {(to || showArrow) && <NavArrowSvg />}
      </div>
      <div className={styles['tafe-card-body']}>{children}</div>
    </div>
  );

  if (to) {
    return <Link to={to}>{cardContent}</Link>;
  }

  return cardContent;
};

export default NavCard;
