import axios from 'axios';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateFilter from '../../../components/date-filter';
import Loader from '../../../components/loader';
import NavCard from '../../../components/nav-card';
import Filter from '../../../components/static-filter';
import ViewLayout from '../../../layout/view-layout';
import UserContext from '../../../context/UserContext';

interface StockAvailability {
  segment: string;
  total_stocks: number;
  total_hot_prospects: number;
}

interface StockAgeing {
  age: string;
  total_stocks: number;
  hp_segment: string;
}

interface StockData {
  total_billed: number;
  total_received: number;
  total_in_transit: number;
  stock_availabilities: StockAvailability[];
  stock_ageings: StockAgeing[];
}

const Stock: React.FC = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<StockData | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);

  useEffect(() => {
    setIsLoading(true);
    const params: Record<string, string> = {};

    if (selectedDateRange?.start) {
      params.start_date_time = moment(new Date(selectedDateRange.start)).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    if (selectedDateRange?.end) {
      params.end_date_time = moment(new Date(selectedDateRange.end)).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    axios.get('/api/v1/stocks/summary', { params }).then((response) => {
      setIsLoading(false);
      setData(response.data.data);
    });
  }, [selectedDateRange?.start, selectedDateRange?.end]);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const handleFilterChange = (data: { label: string }) => {
    setSelectedFilter(data.label);
  };

  const filteredData = useMemo(() => {
    if (!selectedFilter) {
      return data?.stock_ageings || [];
    }

    return (data?.stock_ageings || []).filter(
      (item) => item.hp_segment === selectedFilter
    );
  }, [data?.stock_ageings, selectedFilter]);

  const stockAgeings = useMemo(() => {
    const grouped: Record<string, number> = {};

    filteredData.forEach((item) => {
      if (!grouped[item.age]) {
        grouped[item.age] = 0;
      }
      grouped[item.age] += item.total_stocks;
    });

    return Object.entries(grouped).map(([age, totalStocks]) => ({
      age,
      totalStocks
    }));
  }, [filteredData]);

  const totalStocks = useMemo(
    () => stockAgeings.reduce((acc, curr) => acc + curr.totalStocks, 0),
    [stockAgeings]
  );

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      navigate({
        pathname: `/${user.user_role}/stocks`
      });
    },
    [navigate]
  );

  return (
    <>
      <ViewLayout title="Current Stock">
        <div className="p-20">
          <DateFilter
            values={[
              'All Time',
              'Today',
              'Yesterday',
              'This week',
              'Last week',
              'This month',
              'Last month',
              'Last 3 months',
              'This year',
              'Custom range'
            ]}
            onChange={handleDateChange}
          />
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: 'calc(calc(var(--vh, 1vh) * 100) - 140px)'
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                Billing
              </h3>
              <table className="tafe-table mb-16">
                <thead>
                  <tr>
                    <th>Total billed</th>
                    <th>In transit</th>
                    <th>Received</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="text-14 font-500 color-link text-decoration-underline"
                      onClick={navigateToDetails}
                    >
                      {data?.total_billed}
                    </td>
                    <td
                      className="text-14 font-500 color-link text-decoration-underline"
                      onClick={navigateToDetails}
                    >
                      {data?.total_in_transit}
                    </td>
                    <td
                      className="text-14 font-500 color-link text-decoration-underline"
                      onClick={navigateToDetails}
                    >
                      {data?.total_received}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                Stock Availability
              </h3>
              <table className="tafe-table mb-16">
                <thead>
                  <tr>
                    <th>HP Segment</th>
                    <th>Stock Available</th>
                    <th>Hot Prospect Available</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.stock_availabilities.map(
                    (item: StockAvailability, index: number) => (
                      <tr key={index}>
                        <td className="text-14 font-400 color-black-70">
                          {item.segment}
                        </td>
                        <td
                          className="text-14 font-500 color-link"
                          onClick={navigateToDetails}
                        >
                          {item.total_stocks}
                        </td>
                        <td
                          className="text-14 font-500 color-link"
                          onClick={navigateToDetails}
                        >
                          {item.total_hot_prospects}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                Stock Ageing
              </h3>
              <Filter
                values={[
                  '0-20 HP',
                  '21-30 HP',
                  '31-40 HP',
                  '41-50 HP',
                  '50+ HP'
                ]}
                onChange={handleFilterChange}
              />
              <div className="mt-20"></div>
              <NavCard
                title="Total"
                showArrow={true}
                onClick={navigateToDetails}
              >
                <p className="text-14 color-link font-500 h44 dF align-center">
                  {totalStocks}
                </p>
              </NavCard>
              <table className="tafe-table mt-20">
                <thead>
                  <tr>
                    <th>Age</th>
                    <th>Stock Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {stockAgeings.map((item, index) => (
                    <tr key={index}>
                      <td className="text-14 font-400 color-black-70">
                        {item.age}
                      </td>
                      <td
                        className="text-14 font-500 color-link"
                        onClick={navigateToDetails}
                      >
                        {item.totalStocks}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </ViewLayout>
    </>
  );
};

export default Stock;
