import { useCallback, useEffect, useRef, useState } from 'react';
import { IJcpReviewDsp } from '../../types/global';
import { UseJcpPlanDspHook } from '../../types/hooks';
import JcpPlanDspServices from '../services/JcpPlanDspServices';
import useDebounce from './useDebounce';

export default function useJcpPlanDsp({
  id,
  month,
  year,
  page,
  perPage
}: UseJcpPlanDspHook.Parameters): UseJcpPlanDspHook.Result {
  const controller = useRef<AbortController | undefined>();

  const [data, setData] = useState<IJcpReviewDsp[]>([]);
  const [error, setError] = useState<any>(null);
  const [__page, setPage] = useState<number>(page);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [initiateRequest, setInitiateRequest] = useState<boolean>(false);

  const __triggerRequest = useCallback(() => {
    setInitiateRequest(true);
  }, []);

  const debouncedTriggerRequest = useDebounce({
    fn: __triggerRequest,
    delay: 800
  });

  useEffect(() => {
    setInitiateRequest(true);
  }, []);

  useEffect(() => {
    if (initiateRequest) {
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      JcpPlanDspServices.getPagedDsp({
        id,
        month,
        year,
        page: __page,
        perPage,
        signal: controller.current.signal
      })
        .then((result) => {
          const { data, page_info } = result;

          setData((prev) => (__page === 1 ? data : prev.concat(data)));
          setHasMore(page_info.hasMore);
          setError(null);
          setIsLoading(false);
          setIsLoadingMore(false);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setInitiateRequest(false);
        });

      return () => {
        if (controller.current) controller.current.abort();
      };
    }
  }, [__page, perPage, initiateRequest]);

  const loadMoreRecords = useCallback(() => {
    if (hasMore && !isLoadingMore) {
      setPage((c) => c + 1);
      setIsLoadingMore(true);
      setInitiateRequest(true);
    }
  }, [hasMore, isLoadingMore]);

  return {
    data,
    error,
    hasMore,
    isLoading,
    isLoadingMore,
    loadMoreRecords
  };
}
