import React, { useState } from 'react'
import useEffectAfterMount from './useEffectAfterMount'
import { DSP } from '../../types/model'
import UserService from '../services/UserService';
import { UsePagedMyDspPeersHook } from '../../types/hooks';

export default function usePagedMyDspPeers({
    additionalFields,
    onFailure
}: UsePagedMyDspPeersHook.Parameters): UsePagedMyDspPeersHook.Result {
    const [data, setData] = useState<Array<DSP>| null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffectAfterMount(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        UserService.getMyDspPeers({
            signal: abortController.signal,
            additionalFields: additionalFields
        })
        .then((result) =>{
            setData(result.data);
            setIsLoading(false);
        })
        .catch((error) => onFailure && onFailure(error))

        return () => {
            abortController.abort();
        }
    }, [])
    return {
        data,
        isLoading
    }
}