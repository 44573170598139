import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAccordianContext } from '../../context/AccordianContext';

export interface AccordionBodyProps {
  children: React.ReactNode;
}

const AccordionBody: React.FC<AccordionBodyProps> = ({ children }) => {
  const { open } = useAccordianContext();
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={'accordion-body'}
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ duration: 0.4 }}
          style={{ overflow: 'hidden' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AccordionBody;
