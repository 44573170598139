import classNames from 'classnames';
import React, { useState, ReactNode, ReactElement, useCallback } from 'react';

import './index.css';

import AccordionBody, { AccordionBodyProps } from './accordion-body';
import AccordionHeader, { AccordionHeaderProps } from './accordion-header';
import AccordionArrow from './accordion-arrow';
import { AccordianContext } from '../../context/AccordianContext';
import AccordionLoader from './accordion-loader';

type AccordionComposition = {
  Header: React.FC<AccordionHeaderProps>;
  Body: React.FC<AccordionBodyProps>;
  Arrow: React.FC;
  Loader: React.FC
};

interface AccordionProps {
  className?: string;
  setInitialOpen?: boolean;
}

const Accordion: React.FunctionComponent<
  React.PropsWithChildren<AccordionProps>
> &
  AccordionComposition = ({ children, className, setInitialOpen = false }) => {
  const [open, setOpen] = useState<boolean>(setInitialOpen);

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <div className={classNames('accordion-container', className)}>
      <AccordianContext.Provider value={{ open, toggleOpen }}>
        {children}
      </AccordianContext.Provider>
    </div>
  );
};

Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
Accordion.Arrow = AccordionArrow;
Accordion.Loader = AccordionLoader

export default Accordion;
