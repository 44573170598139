import { Framework, IStock } from '../../types/global';
import {
  StockServiceParameters
} from '../../types/parameters';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

export default class StockService {
  public static async getPagedStocks({
    page,
    signal,
    perPage,
    filters: { serial_no, model_codes },
    additionalFields = []
  }: StockServiceParameters.GetPagedStocks): Promise<
    Framework.PagedResult<IStock>
  > {
    const handler = new RequestHandler('/api/v1/stocks', RequestMethod.GET);
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);
    if (signal) {
      handler.setAbortSignal(signal);
    }
    if (serial_no) {
      handler.addParam('serial_no', serial_no);
    }
    if (model_codes) {
      handler.addParam('model_codes', model_codes);
    }

    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<IStock>);
  }
}
