import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import DateFormat from "../enums/DateFormat";
import DateProcessor from "../processor/DateProcessor";
import DateOperationResultType from "../enums/DateOperationResultType";
import { UseJcPlanApprovalHook, UseJcPlanningsHook } from "../../types/hooks";

export default function useJcPlanningCalender() {
  const [searchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date>(() => {
    const year = searchParams.get("year");
    const month = searchParams.get("month");

    if (month !== null && year !== null) {
      return DateProcessor.getStartOfByMonthAndYear(
        month,
        year,
        DateOperationResultType.DATE
      ) as Date;
    } else {
      return DateProcessor.getStartOFMonthByDate(
        Date.now(),
        DateOperationResultType.DATE
      ) as Date;
    }
  });
  const [endDate, setEndDate] = useState<Date>(() => {
    const year = searchParams.get("year");
    const month = searchParams.get("month");

    if (month !== null && year !== null) {
      return DateProcessor.getEndOfByMonthAndYear(
        month,
        year,
        DateOperationResultType.DATE
      ) as Date;
    } else {
      return DateProcessor.getEndOFMonthByDate(
        Date.now(),
        DateOperationResultType.DATE
      ) as Date;
    }
  });

  const [excludedDates, setExcludedDates] = useState<Array<Date>>([]);
  const [dateColorCodeMap, setDateColorCodeMap] = useState<
    Record<string, Array<string>>
  >({});
  const [jcPlanApprovalFilters, setJcPlanApprovalFilters] =
    useState<UseJcPlanApprovalHook.Filters>(() => ({
      year: DateProcessor.format(startDate.getTime(), DateFormat.YEAR),
      month: DateProcessor.format(startDate.getTime(), DateFormat.MONTH)
    }));
  const [jcPlanningFilters, setJcPlanningFilters] =
    useState<UseJcPlanningsHook.Filters>(() => ({
      dateStartValue: startDate.getTime(),
      dateEndValue: endDate.getTime()
    }));

  const onMonthChange = useCallback((month: string, year: string) => {
    const startDate = DateProcessor.getStartOfByMonthAndYear(
      month,
      year,
      DateOperationResultType.DATE
    ) as Date;
    const endDate = DateProcessor.getEndOfByMonthAndYear(
      month,
      year,
      DateOperationResultType.DATE
    ) as Date;

    setEndDate(endDate);
    setStartDate(startDate);
    setExcludedDates([]);
    setJcPlanningFilters({
      dateStartValue: startDate.getTime(),
      dateEndValue: endDate.getTime()
    });
    setJcPlanApprovalFilters({
      year: DateProcessor.format(startDate.getTime(), DateFormat.YEAR),
      month: DateProcessor.format(endDate.getTime(), DateFormat.MONTH)
    });
  }, []);

  return {
    endDate,
    startDate,
    excludedDates,
    onMonthChange,
    dateColorCodeMap,
    setExcludedDates,
    jcPlanningFilters,
    setDateColorCodeMap,
    jcPlanApprovalFilters
  };
}
