import { EventEmitter } from 'fbemitter';

type EventSubscription = {
  remove: () => void;
};

export default class CustomEventEmiterUtil {
  private static emitter: EventEmitter;

  private static initialize(): void {
    if (this.emitter === undefined) {
      this.emitter = new EventEmitter();
    }
  }

  public static emit(eventName: string, ...params: Array<any>): void {
    this.initialize();
    this.emitter.emit(eventName, ...params);
  }

  public static addListener(
    eventName: string,
    listener: (...params: Array<any>) => void
  ): EventSubscription {
    this.initialize();
    const subscription = this.emitter.addListener(eventName, listener);
    return {
      remove: subscription.remove
    };
  }
}
