import React, { ReactNode } from 'react';
import styles from './styles.module.css';
import BlackCrossSvg from '../../assets/icons/BlackCrossSvg';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';

type PopupLayoutProps = {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
};

const PopupLayout: React.FC<PopupLayoutProps> = ({
  show,
  onClose,
  children
}) => {
  const modalRef = useOnOutsideClick<HTMLDivElement>(onClose)

  return (
    <>
      {show && (
        <>
          <div className={styles['freeze-page-popup']}></div>
          <div className={styles['popup-container']} ref={modalRef}>{children}</div>
        </>
      )}
    </>
  );
};

export default PopupLayout;
