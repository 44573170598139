import { Framework } from '../../types/global';
import { VILLAGE } from '../../types/model';
import { GeographyServiceParameters } from '../../types/parameters';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

export default class GeographyService {
  public static async getPagedNonAnchorVillages({
    page,
    perPage,
    latitude,
    longitude,
    additionalFields = []
  }: GeographyServiceParameters.GetPagedNonAnchorVillages): Promise<
    Framework.PagedResult<VILLAGE>
  > {
    const handler = new RequestHandler(
      '/api/v1/geography/non-anchor-villages',
      RequestMethod.GET
    );
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);
    handler.addParam('latitude', latitude);
    handler.addParam('longitude', longitude);

    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<VILLAGE>);
  }

  public static async getPagedAnchorVillages({
    page,
    perPage,
    additionalFields = []
  }: GeographyServiceParameters.GetPagedAnchorVillages): Promise<
    Framework.PagedResult<VILLAGE>
  > {
    const handler = new RequestHandler(
      '/api/v1/geography/anchor-villages',
      RequestMethod.GET
    );
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);

    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<VILLAGE>);
  }

  public static async getPagedVillages({
    page,
    signal,
    perPage,
    filters: { name, state, district, taluk },
    additionalFields = []
  }: GeographyServiceParameters.GetPagedVillages): Promise<
    Framework.PagedResult<VILLAGE>
  > {
    const handler = new RequestHandler(
      '/api/v1/geography/villages',
      RequestMethod.GET
    );
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);
    if (signal) {
      handler.setAbortSignal(signal);
    }
    if (name) {
      handler.addParam('name', name);
    }
    if (state) {
      handler.addParam('state', state);
    }
    if (district) {
      handler.addParam('district', district);
    }
    if (taluk) {
      handler.addParam('taluk', taluk);
    }

    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<VILLAGE>);
  }
}
