import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JcpCheckinSvg } from "../../shared/assets/icons/JcpCheckinSvg";
import ViewLayout from "../../shared/layout/view-layout";
import NavArrowSvg from "../../shared/assets/icons/NavArrowSvg";
import AppUrlConstants from "../../shared/constants/AppUrlContanst";

type CheckInCategory = {
  name: string;
  path: string;
  describe: string;
  icon: JSX.Element;
};
const checkInCategories: Array<CheckInCategory> = [
  {
    name: "JCP",
    path:
      `/${AppUrlConstants.DSP.BASE_NAME}/${AppUrlConstants.DSP.CHECKIN.BASE_NAME}/${AppUrlConstants.DSP.CHECKIN.JCPS}`,
    describe: "Check-in for your journey centre planning",
    icon: <JcpCheckinSvg />
  },
  // {
  //   name: t("dsp.check_in_category.jcp"),
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=check-in-jcps",
  //   describe: t("dsp.check_in_category.check_in_jcp_describe"),
  //   icon: <JcpCheckinSvg />
  // },
  // {
  //   name: t("dsp.check_in_category.check_in_enq_gen"),
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: t("dsp.check_in_category.check_in_enq_gen_describe"),
  //   icon: <EnqGenSvg />
  // },
  // {
  //   name: t("dsp.check_in_category.check_in_field_activity"),
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: t("dsp.check_in_category.check_in_field_activity_describe"),
  //   icon: <FieldActivitySvg />
  // },
  // {
  //   name: t("dsp.check_in_category.check_in_ficollection"),
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: t("dsp.check_in_category.check_in_ficollection_describe"),
  //   icon: <FICollectionSvg />
  // },
  // {
  //   name: t("dsp.check_in_category.check_in_other_tasks"),
  //   path:
  //     AppUrlConstants.INNER_BASE +
  //     AppUrlConstants.CHECK_IN +
  //     AppUrlConstants.JCPS +
  //     "?title=visit_details",
  //   describe: t("dsp.check_in_category.check_in_other_tasks_describe"),
  //   icon: <OtherTaskSvg />
  // }
];

const CheckInCategories: React.FC = () => {
  const {t} = useTranslation();
  return (
    <ViewLayout title={t('dsp.check_in_category.title')}>
      <div className="text-16 font-400 p-16-20 bg-white bb-black-10">
        {t("dsp.check_in_category.category_header")}
      </div>
      <div className="m-20">
        {checkInCategories.map((item) => (
          <Link className="p-20 bg-white br-12 dF align-center justify-between" key={item.name} to={item.path}>
            {item.icon}
            <div className="color-black ml-14">
              <div className="text-16 font-600">{item.name}</div>
              <div className="text-14 font-400">{item.describe}</div>
            </div>
            <NavArrowSvg/>
          </Link>
        ))}
      </div>
    </ViewLayout>
  );
};
export default CheckInCategories;
