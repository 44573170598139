import styles from "./styles.module.css";

import classNames from "classnames";
import React, { useCallback } from "react";

import View from "../../enums/View";
import JcPlanningAdditionalFields from "../../../../shared/enums/JcPlanningAdditionalFields";
import { useFormContext } from "../../../../shared/hooks/useFormContext";
import { CreateJcPlanningForm } from "../../types";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";
import useAnchorVillages from "../../../../shared/hooks/useAnchorVillages";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import useCurrentLocation from "../../../../shared/hooks/useCurrentLocation";
import ViewLayout from "../view-layout";
import Loader from "../../../../shared/components/loader";
import EmptyRecords from "../../../../shared/components/empty-records";
import AnchorVillage from "./components/anchor-village";
import { VILLAGE } from "../../../../types/model";
import MostVisitedVillage from "./components/most-visited-village";
import Button from "../../../../shared/components/button";
import { useTranslation } from "react-i18next";

const additionalFields = [
  JcPlanningAdditionalFields.STATS_TOTAL_JCP_VISITS_YEAR_TILL_DATE
];

const SelectAnchorVillageView: React.FC = () => {
  const {t} = useTranslation();
  const { values } = useFormContext<CreateJcPlanningForm>();
  const { goToView, cancelOperation } = useCreateJcPlanningContext();

  const { data, isLoading } = useAnchorVillages({
    page: 1,
    perPage: 1000,
    additionalFields,
    onFailure: ServerConnectorUtil.handleServerError
  });

  const {
    latitude: currentLocationLatitude,
    longitude: currentLocationLongitude
  } = useCurrentLocation();

  const nextOperation = useCallback(() => {
    goToView(View.FORM_VIEW);
  }, [goToView]);

  return (
    <ViewLayout>
      <ViewLayout.Body>
        {isLoading ? (
          <Loader/>
        ) : data.length === 0 ? (
          <EmptyRecords message={t('dsp.create_jcp.anchor_village.empty_anchor_village')} />
        ) : (
          <>
            <div
              className={classNames(
                styles["list"],
                styles["anchor-village-list"]
              )}
            >
              {data.map((item) => (
                <AnchorVillage
                  key={item.id}
                  village={item as VILLAGE}
                  currentLocationLatitude={currentLocationLatitude}
                  currentLocationLongitude={currentLocationLongitude}
                />
              ))}
            </div>
            <div
              className={classNames(
                styles["list"],
                styles["most-visited-village-list"]
              )}
            >
              <h6 className={styles["title"]}>{t('dsp.create_jcp.anchor_village.most_visited_village')}</h6>
              {data
                .map((item) => (
                  <MostVisitedVillage
                    key={item.id}
                    village={item as VILLAGE}
                  />
                ))}
            </div>
          </>
        )}
      </ViewLayout.Body>
      <ViewLayout.Footer>
        <Button label={t('common.cancel')} variant="secondary" onClick={cancelOperation} />
        <Button
          label={t('common.next')}
          variant="primary"
          onClick={nextOperation}
          disabled={isLoading || values.anchorVillage === null}
        />
      </ViewLayout.Footer>
    </ViewLayout>
  );
};

export default SelectAnchorVillageView;
