import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DSP } from '../../../../types/model';
import dummyUser from '../../../assets/images/dummy-user.png';
import Loader from '../../../components/loader';
import NavCard from '../../../components/nav-card';
import UserContext from '../../../context/UserContext';
import ProspectEnquiryClassification from '../../../enums/ProspectEnquiryClassification';
import ProspectStage from '../../../enums/ProspectStage';
import UserAdditionalFields from '../../../enums/UserAdditionalFields';
import ViewLayout from '../../../layout/view-layout';
import { getFullName } from '../../../utils/FullNameUtil';
import styles from './styles.module.css';
import useEffectAfterMount from '../../../hooks/useEffectAfterMount';

const additionalFields = [
  UserAdditionalFields.STATS_TOTAL_DELIVERIES,
  UserAdditionalFields.STATS_TOTAL_CHECK_IN_IN_LAST_FIVE_DAYS,
  UserAdditionalFields.STATS_TOTAL_LEADS,
  UserAdditionalFields.STATS_TOTAL_PROSPECTS,
  UserAdditionalFields.STATS_TOTAL_HOT_PROSPECTS,
  UserAdditionalFields.STATS_TOTAL_JCP_APPROVED,
  UserAdditionalFields.STATS_TOTAL_JCP_VISITED,
  UserAdditionalFields.STATS_TOTAL_TRACTOR_USER_DATABASE_ADDED,
  UserAdditionalFields.STATS_TOTAL_OVERDUE_HOT_PROSPECTS
];

const DspDetails: React.FC = () => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dspDetails, setDspDetails] = useState<DSP | undefined>();

  useEffectAfterMount(() => {
    setIsLoading(true);
    axios
      .get(`/api/v1/user/my-dsp-reportees/${id}`, {
        params: {
          additional_fields: additionalFields.join(',')
        }
      })
      .then((response) => {
        setDspDetails(response.data.data);
        setIsLoading(false);
      });
  }, []);

  const getFormattedDate = (): string => {
    const today = new Date();

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-GB', options).format(today);
  };

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');
      const stages = element.getAttribute('data-stages');
      const enquiryClassifications = element.getAttribute(
        'data-enquiry-classifications'
      );
      const edd = element.getAttribute('data-edd-end-value');
      const sogs = element.getAttribute('data-sogs');
      const searchParams = new URLSearchParams({
        ...(stages ? { stages } : {}),
        ...(sogs ? { sogs } : {}),
        ...(enquiryClassifications
          ? { enquiry_classifications: enquiryClassifications }
          : {})
      });

      if (id) {
        searchParams.set('my_dsp_reportee_id', id);
      }
      if (title) {
        searchParams.set('title', title);
      }

      if (edd) {
        searchParams.set('edd_end_value', edd);
      }

      navigate(
        {
          pathname: `/${user.user_role}/prospects`,
          search: searchParams.toString()
        },
        {}
      );
    },
    []
  );

  return (
    <ViewLayout title={t('dsp.dsp_details.title')}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles['profile-box']}>
            <img
              className={styles['profile-img']}
              src={dummyUser}
              alt="User Image"
            />
            <div>
              <p className="text-18 color-primary">
                {dspDetails &&
                  getFullName(dspDetails.first_name, dspDetails.last_name)}
              </p>
              <p className="text-14 color-black-40 ls-028 mt-2">
                {getFormattedDate()}
              </p>
            </div>
          </div>
          <div className="m-20">
            <div className="mb-20">
              <NavCard title={t('dsp.dsp_details.total_deliveries')}>
                <div className="text-14 h64 ptb-14 color-black">
                  <span className="text-24">
                    {dspDetails?.stats.total_deliveries}
                  </span>
                  <span className="text-14">/25</span>
                </div>
              </NavCard>
            </div>
            <div className="mb-20">
              <h3 className="text-14 font-600 color-secondary ls-028 mb-16">
                {t('dsp.dsp_details.active_pipeline')} :
              </h3>
              <div className="dF gap-16 mb-16">
                <div className="two-column-width">
                  <NavCard
                    title={t('common.leads')}
                    customAttributes={{
                      'data-title': ProspectEnquiryClassification.LEAD,
                      'data-enquiry-classifications':
                        ProspectEnquiryClassification.LEAD
                    }}
                    showArrow={true}
                    onClick={navigateToDetails}
                  >
                    <div className="text-24 font-500 h64 ptb-14 color-link">
                      {dspDetails?.stats.total_leads}
                    </div>
                  </NavCard>
                </div>
                <div className="two-column-width">
                  <NavCard
                    title={t('common.prospects')}
                    showArrow={true}
                    customAttributes={{
                      'data-title': ProspectEnquiryClassification.PROSPECT,
                      'data-enquiry-classifications':
                        ProspectEnquiryClassification.PROSPECT
                    }}
                    onClick={navigateToDetails}
                  >
                    <div className="text-24 font-500 h64 ptb-14 color-link">
                      {dspDetails?.stats.total_prospects}
                    </div>
                  </NavCard>
                </div>
              </div>
              <div className="dF gap-16">
                <div className="two-column-width">
                  <NavCard
                    title={t('common.hot_prospects')}
                    showArrow={true}
                    customAttributes={{
                      'data-title': ProspectEnquiryClassification.HOT_PROSPECT,
                      'data-enquiry-classifications':
                        ProspectEnquiryClassification.HOT_PROSPECT
                    }}
                    onClick={navigateToDetails}
                  >
                    <div className="text-24 font-500 h64 ptb-14 color-link">
                      {dspDetails?.stats.total_hot_prospects}
                    </div>
                  </NavCard>
                </div>
                <div className="two-column-width">
                  <NavCard
                    title={t('common.overdue_hps')}
                    showArrow={true}
                    customAttributes={{
                      'data-title': ProspectEnquiryClassification.HOT_PROSPECT,
                      'data-edd-end-value': Date.now().toString(),
                      'data-stages': [
                        ProspectStage.F1,
                        ProspectStage.F2,
                        ProspectStage.F3,
                        ProspectStage.F4,
                        ProspectStage.F5
                      ].join(','),
                      'data-enquiry-classifications':
                        ProspectEnquiryClassification.HOT_PROSPECT
                    }}
                    onClick={navigateToDetails}
                  >
                    <div className="text-24 h64 ptb-14 color-error">
                      {dspDetails?.stats.total_overdue_hot_prospects}
                    </div>
                  </NavCard>
                </div>
              </div>
            </div>
            <div className="mb-20">
              <h3 className="text-14 font-600 color-secondary ls-028 mb-16">
                {t('dsp.dsp_details.jcp_overview')} :
              </h3>

              <div className="tafe-card">
                <table className="tafe-table">
                  <tr>
                    <td>
                      <p className="text-12 font-500 color-black-55">Planned</p>
                      <p className="text-18 color-black mt-5">
                        {dspDetails?.stats.total_jcp_approved}
                      </p>
                    </td>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Visited TD
                      </p>
                      <p className="text-18 color-black mt-5">
                        {dspDetails?.stats.total_jcp_visited}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Tractor Users Added
                      </p>
                      <p className="text-18 color-black mt-5">
                        {dspDetails?.stats.total_tractor_user_database_added}
                      </p>
                    </td>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Enquiries Generated
                      </p>
                      <p className="text-18 color-black mt-5">
                        {(dspDetails as DSP)?.stats.total_prospects +
                          (dspDetails as DSP)?.stats.total_leads +
                          (dspDetails as DSP)?.stats
                            .total_overdue_hot_prospects}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        HPs Generated
                      </p>
                      <p className="text-18 color-black mt-5">
                        {dspDetails?.stats.total_hot_prospects}
                      </p>
                    </td>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Deliveries
                      </p>
                      <p className="text-18 color-black mt-5">
                        {dspDetails?.stats.total_deliveries}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </ViewLayout>
  );
};

export default DspDetails;
