import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IProspect } from '../../../../../types/global';
import Loader from '../../../../components/loader';
import ViewLayout from '../../../../layout/view-layout';
import { convertDateFormat } from '../../../../utils/ConvertDateFormat';
import ServerConnectorUtil from '../../../../utils/ServerConnectorUtil';
import UserContext from '../../../../context/UserContext';

const EnquiryView: React.FC = () => {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IProspect | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/v1/prospects/${id}`)
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch(ServerConnectorUtil.handleServerError);
  }, [id]);

  return (
    <ViewLayout title="Prospect Details">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {user.user_role !== 'tetm' && (
            <Link to={`/${user.user_role}/prospect/${id}/edit`} replace={true}>
              <div className="viewlayout-edit-btn"></div>
            </Link>
          )}
          <div className="tafe-full-form-body p-20">
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Phone number</p>
                <input
                  value={data?.contact.mobile_number}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Name</p>
                <input
                  value={data?.contact.name}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Taluka</p>
                <input
                  value={data?.contact.taluka || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Village</p>
                <input
                  value={data?.contact.village || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Enquiry Category
                </p>
                <input
                  value={data?.enquiry_category || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Source of Generation
                </p>
                <input
                  value={data?.sog}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Enquiry stage</p>
                <input
                  value={data?.stage}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Model Family</p>
                <input
                  value={data?.interested_model_family}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Follow up date</p>
                <input
                  value={data?.next_follow_up_date}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">EDD</p>
                <input
                  value={data?.edd ? convertDateFormat(data?.edd) : ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Interested in Exchange?
                </p>
                <div className="dF align-center gap-10">
                  <div className="dF align-center">
                    <input
                      id="yes"
                      name="option"
                      value="yes"
                      type="radio"
                      checked={data?.has_exchange === 'Yes' ? true : false}
                      disabled={true}
                    />
                    <label htmlFor="yes" className="text-14 font-400 cP mt-5">
                      Yes
                    </label>
                  </div>
                  <div className="dF align-center">
                    <input
                      id="no"
                      name="option"
                      value="no"
                      type="radio"
                      checked={data?.has_exchange === 'No' ? true : false}
                    />
                    <label htmlFor="no" className="text-14 font-400 cP mt-5">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Mode of payment</p>
                <input
                  value={data?.payment_mode || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            {user.user_role !== 'tetm' && (
              <div className="form-row">
                <div className="form-column">
                  <p className="text-14 ls-024 color-black-70">Dealer Name</p>
                  <input
                    value={data?.dealer.name || ''}
                    type="text"
                    className="input-box"
                    disabled={true}
                  />
                </div>
                <div className="form-column">
                  <p className="text-14 ls-024 color-black-70">Dealer Code</p>
                  <input
                    value={data?.dealer.dealer_code || ''}
                    type="text"
                    className="input-box"
                    disabled={true}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </ViewLayout>
  );
};

export default EnquiryView;
