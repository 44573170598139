import React, { useContext } from "react";
import { UseFormHook } from "../../types/hooks";

export const FormContext = React.createContext<
  UseFormHook.ReturnType<any> | undefined
>(undefined);

export function useFormContext<T = any>(): UseFormHook.ReturnType<T> {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error("Unknown form context.");
  }

  return context;
}
