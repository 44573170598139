export type SearchBoxProps = {
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  onChange,
  placeholder
}) => {
  return (
    <div className="search-details-header">
      <div className="pR w100p">
        <div className="input-search-icon"></div>
        <input
          type="text"
          placeholder={placeholder}
          className="input-box search-input"
          style={{ border: 'none' }}
          onChange={onChange}
          value={value}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default SearchBox;
