import React from 'react';
import { IIconProps } from '../../../types/global';

export const ArrowSvg: React.FC<IIconProps> = ({ fill }) => {
  fill = fill !== undefined ? fill : 'var(--primary)';
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.175 9.5H1.19209e-07V7.5H12.175L6.575 1.9L8 0.499999L16 8.5L8 16.5L6.575 15.1L12.175 9.5Z"
        fill={fill}
      />
    </svg>
  );
};
