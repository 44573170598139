import axios from 'axios';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import DateFilter from '../../../../shared/components/date-filter';
import Loader from '../../../../shared/components/loader';
import NavCard from '../../../../shared/components/nav-card';
import Filter from '../../../../shared/components/static-filter';
import ViewLayout from '../../../../shared/layout/view-layout';
import UserContext from '../../../context/UserContext';

interface Stage {
  age: string;
  stage: string;
  total_advances_with_bank_case: number;
  total_advances_with_cash_case: number;
}

interface AdvancesData {
  total_advances: number;
  total_advances_with_bank_case: number;
  total_advances_with_cash_case: number;
  stages: Stage[];
}

const Advances: React.FC = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<AdvancesData | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);
  const [selectedStageFilter, setSelectedStageFilter] = useState<string>();

  useEffect(() => {
    setIsLoading(true);
    const params: Record<string, string> = {};

    if (selectedDateRange?.start) {
      params.start_date_time = moment(new Date(selectedDateRange.start)).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    if (selectedDateRange?.end) {
      params.end_date_time = moment(new Date(selectedDateRange.end)).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    axios.get('/api/v1/advances/summary', { params }).then((response) => {
      setIsLoading(false);
      const responseData = response.data.data;
      const processedStages = responseData.stages.map((stage: Stage) => ({
        ...stage,
        stage: stage.stage.split(' - ')[0]
      }));
      setData({
        ...responseData,
        stages: processedStages
      });
    });
  }, [selectedDateRange?.start, selectedDateRange?.end]);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const handleStageFilterChange = (data: { label: string }) => {
    setSelectedStageFilter(data.label);
  };

  const groupedStages = useMemo(() => {
    return (data?.stages || []).reduce((groups, stage) => {
      if (!groups[stage.age]) {
        groups[stage.age] = [];
      }
      groups[stage.age].push(stage);
      return groups;
    }, {} as Record<string, Stage[]>);
  }, [data?.stages]);

  const groupedByStage = useMemo(() => {
    return (data?.stages || []).reduce((groups, stage) => {
      if (!groups[stage.stage]) {
        groups[stage.stage] = 0;
      }
      groups[stage.stage] += stage.total_advances_with_bank_case;
      return groups;
    }, {} as Record<string, number>);
  }, [data?.stages]);

  const filteredGroupedByStage = useMemo(() => {
    if (!selectedStageFilter) return groupedByStage;
    return (data?.stages || []).reduce((groups, stage) => {
      if (!groups[stage.stage]) {
        groups[stage.stage] = 0;
      }
      if (stage.age === selectedStageFilter) {
        groups[stage.stage] += stage.total_advances_with_bank_case;
      }
      return groups;
    }, {} as Record<string, number>);
  }, [groupedByStage, selectedStageFilter, data?.stages]);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      navigate({
        pathname: `/${user.user_role}/advances`
      });
    },
    [navigate]
  );

  return (
    <>
      <ViewLayout title="Advances">
        <div className="p-20">
          <DateFilter
            values={[
              'All Time',
              'Today',
              'Yesterday',
              'This week',
              'Last week',
              'This month',
              'Last month',
              'Last 3 months',
              'This year',
              'Custom range'
            ]}
            onChange={handleDateChange}
          />
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: 'calc(calc(var(--vh, 1vh) * 100) - 140px)'
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                Advances Summary
              </h3>
              <NavCard
                title={`Total Advances (Units): ${data?.total_advances || 0}`}
                showArrow={true}
                onClick={navigateToDetails}
              >
                <div className="dF w100p">
                  <div className="w100p pt-10 pb-10 br-primary-10">
                    <p className="text-12 color-black-55 font-600 ls-048">
                      Bank Cases
                    </p>
                    <p className="text-18 color-link font-400 mt-10 dF align-center">
                      {data?.total_advances_with_bank_case || 0}{' '}
                      <span className="text-12 color-secondary">
                        (
                        {data?.total_advances
                          ? (
                              (data.total_advances_with_bank_case /
                                data.total_advances) *
                              100
                            ).toFixed(2)
                          : '0.00'}
                        %)
                      </span>
                    </p>
                  </div>
                  <div className="w100p pt-10 pb-10">
                    <div className="w100p pl-10">
                      <p className="text-12 color-black-55 font-600 ls-048">
                        Cash Cases
                      </p>
                      <p className="text-18 color-error font-400 mt-10 dF align-center">
                        {data?.total_advances_with_cash_case || 0}{' '}
                        <span className="text-12 color-secondary">
                          (
                          {data?.total_advances
                            ? (
                                (data.total_advances_with_cash_case /
                                  data.total_advances) *
                                100
                              ).toFixed(2)
                            : '0.00'}
                          %)
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </NavCard>

              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                Advances by Age Group
              </h3>
              <table className="tafe-table mt-20 mb-16">
                <thead>
                  <tr>
                    <th>Age Group</th>
                    <th>Bank Cases</th>
                    <th>Cash Cases</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedStages).map((age) => {
                    const stagesForAge = groupedStages[age];
                    const totalBankCases = stagesForAge.reduce(
                      (sum, stage) => sum + stage.total_advances_with_bank_case,
                      0
                    );
                    const totalCashCases = stagesForAge.reduce(
                      (sum, stage) => sum + stage.total_advances_with_cash_case,
                      0
                    );

                    return (
                      <tr key={age}>
                        <td className="text-14 font-400 color-black-70">
                          {age}
                        </td>
                        <td
                          className="text-14 font-500 color-link"
                          onClick={navigateToDetails}
                        >
                          {totalBankCases}
                        </td>
                        <td
                          className="text-14 font-500 color-link"
                          onClick={navigateToDetails}
                        >
                          {totalCashCases}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                Bank Cases Stages
              </h3>
              <Filter
                values={[...Object.keys(groupedStages)]}
                onChange={handleStageFilterChange}
              />

              <table className="tafe-table mt-20">
                <thead>
                  <tr>
                    <th>Stage</th>
                    <th>Units</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(filteredGroupedByStage).map((stageKey) => (
                    <tr key={stageKey}>
                      <td className="text-14 font-400 color-black-70">
                        {stageKey}
                      </td>
                      <td
                        className="text-14 font-500 color-link"
                        onClick={navigateToDetails}
                      >
                        {filteredGroupedByStage[stageKey]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </ViewLayout>
    </>
  );
};

export default Advances;
