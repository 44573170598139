import React, { HTMLAttributes } from 'react'
import style from './index.module.css';

const Footer: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({children}) => {
    return (
        <div className={`bg-white p-10 ${style['footer']}`}>
            {children}
        </div>
    )
}

export default Footer