import React, { HTMLAttributes } from 'react'
import style from './index.module.css';

const Close: React.FC<HTMLAttributes<SVGSVGElement>> = ({...props}) => {
    return (
        <svg
            className={style['close-svg']}
            viewBox="0 0 14 14" 
            fill="none" xmlns="http://www.w3.org/2000/svg"
            {...props}
            >
            <path 
                d="M10.5 3.5L3.5 10.5" 
                stroke="white" 
                stroke-width="1.5" 
                stroke-linecap="round" 
                stroke-linejoin="round"/>
            <path 
                d="M3.5 3.5L10.5 10.5" 
                stroke="white" 
                stroke-width="1.5" 
                stroke-linecap="round" 
                stroke-linejoin="round"/>
        </svg>
    )
}

export default Close