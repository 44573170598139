import { useEffect, useState } from 'react';
import { DspReportees } from '../../types/model';
import UserService from '../services/UserService';
import ServerConnectorUtil from '../utils/ServerConnectorUtil';
import useEffectAfterMount from './useEffectAfterMount';

export default function usePagedDspPeerReportees({
  current_start_date_time,
  current_end_date_time,
  previous_start_date_time,
  previous_end_date_time,
  till_date_start_value
}: {
  current_start_date_time?: number;
  current_end_date_time?: number;
  previous_start_date_time?: number;
  previous_end_date_time?: number;
  till_date_start_value?: number;
}) {
  const [data, setData] = useState<DspReportees>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    if (
      current_start_date_time &&
      current_end_date_time &&
      previous_start_date_time &&
      previous_end_date_time &&
      till_date_start_value
    ) {
      const abortController = new AbortController();
      setIsLoading(true);

      UserService.getDspReportees({
        current_start_date_time,
        current_end_date_time,
        previous_start_date_time,
        previous_end_date_time,
        till_date_start_value
      })
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          console.error(error);
          ServerConnectorUtil.handleServerError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      return () => {
        abortController.abort();
      };
    }
  }, [
    current_start_date_time,
    current_end_date_time,
    current_end_date_time,
    previous_end_date_time,
    till_date_start_value
  ]);

  return {
    data,
    isLoading
  };
}
