import React from 'react';

export const AccordionArrowSvg: React.FC = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8.375L0 2.375L1.4 0.974998L6 5.575L10.6 0.974998L12 2.375L6 8.375Z"
        fill="var(--primary)"
      />
    </svg>
  );
};
