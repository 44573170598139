import { useTranslation } from "react-i18next";
import RangeSlider from "../../../../../../shared/components/RangeSlider";
import useEffectAfterMount from "../../../../../../shared/hooks/useEffectAfterMount";
import styles from "./styles.module.css";

import { useRef, useState } from "react";

const DistanceFilter: React.FC<{
  filterVillages: (
    distance: number,
    skipSelectedVillagesReset: boolean
  ) => void;
}> = ({ filterVillages }) => {
  const [min] = useState(0);
  const [max] = useState(20);
  const skipSelectedVillagesReset = useRef<boolean>(true);
  const [values, setValues] = useState<Array<number>>([20]);
  const {t} = useTranslation();


  useEffectAfterMount(() => {
    if (values && values.length) {
      filterVillages(values[0], skipSelectedVillagesReset.current);
      if (skipSelectedVillagesReset.current) {
        skipSelectedVillagesReset.current = false;
      }
    }
  }, [values]);
  return (
    <>
      <p className={styles["title"]}>{t('dsp.jcp.distance')}</p>
      <RangeSlider
        min={min}
        max={max}
        step={0.5}
        values={values}
        onChange={setValues}
        className={styles["slider"]}
      />
      <div className={styles["value-container"]}>
        <div className={styles["box"]}>{min} {t('common.kilo_meter')}</div>
        <div className={styles["hr"]} />
        <div className={styles["box"]}>{values[0]} {t('common.kilo_meter')}</div>
      </div>
    </>
  );
};

export default DistanceFilter;
