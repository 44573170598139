import { useEffect, useState } from "react";

import UserService from "../services/UserService";

import { User } from "../../types/model";
import { UseCurrentUserParameters } from "../../types/hooks";

export default function useCurrentUser({
  onSuccess,
  onFailure,
  additionalFields
}: UseCurrentUserParameters.Parameters): UseCurrentUserParameters.ReturnType {
  const [data, setData] = useState<User | null>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    UserService.getCurrentUser({ additionalFields })
      .then((data) => {
        setData(data);
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onSuccess, onFailure, additionalFields]);

  return {
    data,
    error,
    isLoading
  };
}
