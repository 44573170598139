import React from 'react'
import { useProfileContext } from '../../context/ProfileContext'

const DealerLocation: React.FC = () => {
    const {dealerLocation} = useProfileContext();
    return (
        <p className='text-14 color-black dF align-center'>{dealerLocation}</p>
    )
}

export default DealerLocation