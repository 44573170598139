import { useCallback, useState } from "react";
import View from "../enums/View";

export namespace UseViewNavigation {
  export type ReturnType = {
    view: View;
    goToView: (view: View) => void;
  };
}

export default function useViewNavigation(): UseViewNavigation.ReturnType {
  const [view, setView] = useState<View>(View.SUMMARY);

  const goToView = useCallback((view: View) => {
    setView(view);
  }, []);

  return {
    view,
    goToView
  }
}
