import axios from 'axios';
import classNames from 'classnames';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../shared/components/loader';
import useModal from '../../shared/hooks/useModal';
import SuccessLayout from '../../shared/layout/success-layout';
import ViewLayout from '../../shared/layout/view-layout';
import { convertDateFormat } from '../../shared/utils/ConvertDateFormat';
import { IProspectValidation } from '../../types/global';
import styles from './styles.module.css';
import ToastUtil from '../../shared/utils/ToastUtil';

const ProspectValidation: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open: showModal, openModal, closeModal } = useModal({});

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [submittingGenuine, setSubmittingGenuine] = useState(false);
  const [submittingNotGenuine, setSubmittingNotGenuine] = useState(false);

  const [showSuccessLayout, setShowSuccessLayout] = useState(false);
  const [data, setData] = useState<IProspectValidation | undefined>(undefined);

  const goToHome = useCallback(() => {
    navigate(`/tetm/main/home`, {
      replace: true
    });
  }, [navigate]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/v1/tasks/pending-prospect-validation-tasks/${id}`)
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      });
  }, []);

  const validateProspect = useCallback((event: any) => {
    const status = event.currentTarget.getAttribute('data-name');
    if (status === 'Genuine') {
      setSubmittingGenuine(true);
    } else {
      setSubmittingNotGenuine(true);
    }
    axios
      .put(`/api/v1/prospect-validation-tasks/${id}`, {
        prospect_enquiry_status: status
      })
      .then(() => {
        setShowSuccessLayout(true);
        if (status === 'Genuine') {
          setSubmittingGenuine(false);
        } else {
          setSubmittingNotGenuine(false);
        }
      })
      .catch((err) => {
        const { message } = err.response;
        ToastUtil.makeFailureToast(message);
      });
  }, []);

  return (
    <>
      {showSuccessLayout ? (
        <SuccessLayout
          title="Congratulations!"
          desc="The prospect has been validated successfully."
          button_name="Back to Home"
          onClick={goToHome}
        />
      ) : (
        <ViewLayout title="Prospect Details">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="tafe-form-body">
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Phone number
                    </p>
                    <input
                      value={data?.prospect.contact.mobile_number}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">Name</p>
                    <input
                      value={data?.prospect.name}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">Taluka</p>
                    <input
                      value={data?.prospect.contact.taluka}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">Village</p>
                    <input
                      value={data?.prospect.contact.village}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Enquiry Category
                    </p>
                    <input
                      value={data?.prospect.enquiry_category}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Source of Generation
                    </p>
                    <input
                      value={data?.prospect.sog}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Enquiry stage
                    </p>
                    <input
                      value={data?.prospect.stage}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Model Family
                    </p>
                    <input
                      value={data?.prospect.interested_model_family}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Follow up date
                    </p>
                    <input
                      value={data?.prospect.next_follow_up_date}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">EDD</p>
                    <input
                      value={
                        data?.prospect.edd
                          ? convertDateFormat(data?.prospect.edd)
                          : ''
                      }
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Interested in Exchange?
                    </p>
                    <div className="dF align-center gap-10">
                      <div className="dF align-center">
                        <input
                          id="yes"
                          name="option"
                          value="yes"
                          type="radio"
                          checked={
                            data?.prospect.has_exchange === 'Yes'
                              ? true
                              : false
                          }
                          disabled={true}
                        />
                        <label
                          htmlFor="yes"
                          className="text-14 font-400 cP mt-5"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="dF align-center">
                        <input
                          id="no"
                          name="option"
                          value="no"
                          type="radio"
                          checked={
                            data?.prospect.has_exchange === 'No'
                              ? true
                              : false
                          }
                        />
                        <label
                          htmlFor="no"
                          className="text-14 font-400 cP mt-5"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">
                      Mode of payment
                    </p>
                    <input
                      value={data?.prospect.payment_mode}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">Dealer Name</p>
                    <input
                      value={data?.prospect.dealer.name}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                  <div className="form-column">
                    <p className="text-14 ls-024 color-black-70">Dealer Code</p>
                    <input
                      value={data?.prospect.dealer.dealer_code}
                      type="text"
                      className="input-box"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="tafe-form-footer">
                <button
                  data-name="Not Genuine"
                  className={classNames('secondary-btn pR')}
                  disabled={submittingNotGenuine}
                  onClick={validateProspect}
                >
                  <div className={classNames(submittingNotGenuine && 'vH')}>
                    Not Genuine
                  </div>
                  <div
                    className={classNames('pA', !submittingNotGenuine && 'vH')}
                  >
                    <div className="dot-flashing"></div>
                  </div>
                </button>
                <button
                  data-name="Genuine"
                  className={classNames('primary-btn pR')}
                  disabled={submittingGenuine}
                  onClick={validateProspect}
                >
                  <div className={classNames(submittingGenuine && 'vH')}>
                    <div className="dF align-center gap-4">
                      <div className={styles['plus-icon']}></div>
                      Genuine
                    </div>
                  </div>
                  <div className={classNames('pA', !submittingGenuine && 'vH')}>
                    <div className="dot-flashing"></div>
                  </div>
                </button>
              </div>
              {/* <PopupLayout show={showModal} onClose={closeModal}>
                <div className={styles['popup-block']}>
                  <InputTemplate
                    label="Please add comments to proceed:"
                    name="comments"
                    value=""
                    error=""
                    onChange={handleInputChange}
                  />
                </div>
                <div className={styles['popup-footer']}>
                  <button className="secondary-btn" onClick={closeModal}>
                    Cancel
                  </button>
                  <button className="primary-btn" onClick={AddComment}>
                    Submit
                  </button>
                </div>
              </PopupLayout> */}
            </>
          )}
        </ViewLayout>
      )}
    </>
  );
};

export default ProspectValidation;
