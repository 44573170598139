import React, { useState, useEffect } from 'react';
import Select, { SingleValue, MultiValue } from 'react-select';
import classNames from 'classnames';
import './index.css';
import { IOptions } from '../../../types/global';

export interface multiSelectProps {
  label?: string;
  name?: string;
  options: IOptions[];
  isMulti?: boolean;
  defaultValue?: IOptions[] | undefined;
  error?: string;
  placeholder?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  mandatory?: boolean;
  classes?: string;
  onSelect?: (option: IOptions[] | undefined) => void;
}

const InputMultiSelect: React.FC<multiSelectProps> = ({
  label,
  name,
  options,
  defaultValue,
  error,
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isClearable = false,
  isLoading = false,
  isMulti = false,
  mandatory = false,
  classes,
  onSelect
}) => {
  const [selectedOption, setSelectedOption] =
    useState<MultiValue<IOptions> | null>(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setSelectedOption(defaultValue as MultiValue<IOptions>);
    }
  }, [defaultValue]);

  const handleChange = (
    option: SingleValue<IOptions> | MultiValue<IOptions>
  ) => {
    setSelectedOption(option as MultiValue<IOptions>);
    if (onSelect) onSelect(option as IOptions[]);
  };

  return (
    <div className="w100p">
      <p className="text-14 font-500 mb-4">
        {label}
        {mandatory && <span className="color-error">*</span>}
      </p>
      <Select
        name={name}
        options={options}
        menuPosition="fixed"
        className={classNames('defaultSelectBtn', classes && classes)}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isLoading={isLoading}
        classNamePrefix="default-select"
        value={selectedOption}
        onChange={handleChange}
        isMulti={isMulti}
      />
      {error && <p className="text-12 color-error">{error}</p>}
    </div>
  );
};

export default InputMultiSelect;
