export const getFullName = (
  firstName: string | null,
  lastName: string | null
): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  }
  return '';
};
