import { useCallback, useState } from 'react';
import { IOptions } from '../../../../types/global';
import { UsePagedStocksHook } from '../../../../types/hooks';
import RecordCollection from '../../../components/record-collection';
import usePagedStocks from '../../../hooks/usePagedStocks';
import useScroll from '../../../hooks/useScroll';
import ViewLayout from '../../../layout/view-layout';

const allDropDownOptions = [
  { value: 'serial_no', label: 'Serial Number' },
  { value: 'model_codes', label: 'Model Codes' }
];

const StockMaster = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('serial_no');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedStocks({
    page: 1,
    perPage: 200,
    filters: {}
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedStocksHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue('');
      setSearchLabel(value.label.toLowerCase());
      setFilters({});
    },
    [setFilters]
  );

  return (
    <ViewLayout title="Stocks">
      <RecordCollection>
        <RecordCollection.DropdownSearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={allDropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper key={item.id}>
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle title={item.model.family} />
                  <RecordCollection.ListItemKeyValue
                    label="Model code"
                    value={item.model.code || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="HP Segment"
                    value={item.model.hp_segment || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Received date"
                    value={item.received_date || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Status"
                    value={item.status}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Age"
                    value={item.age || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Serial number"
                    value={item.serial_no || '--'}
                  />
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default StockMaster;
