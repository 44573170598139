import React, { useCallback } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath
} from 'react-router-dom';
import { MenuSvg } from '../../assets/icons/MenuSvg';
import { NotificationSvg } from '../../assets/icons/NotificationSvg';
import tafeLogo from '../../assets/images/tafelogo.png';
import styles from './styles.module.css';
import { TafeLogoSvg } from '../../assets/icons/TafeLogoSvg';

interface MenuProps {
  to: string;
  title: string;
  icon: string;
}
interface PageLayoutProps {
  menuLink: string;
  menuItems: MenuProps[];
}

const Menu: React.FC<MenuProps> = ({ to, title, icon }) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({
    path: resolved.pathname,
    end: false
  });
  const goToMenu = useCallback(() => {
    navigate(`${to}`, {
      replace: true
    });
  }, [navigate, to]);

  return (
    <div
      className={styles['menulist'] + (match ? ` ${styles['active']}` : '')}
      onClick={goToMenu}
    >
      <div className={styles['icon-box']}>
        <div className={styles['icon']}>
          <svg
            className={match ? styles['menu-svg-active'] : styles['menu-svg']}
          >
            <use href={`#${icon}`}></use>
          </svg>
        </div>
      </div>
      <p className={styles['menu-title']}>{title}</p>
    </div>
  );
};

const PageLayout: React.FC<PageLayoutProps> = ({ menuLink, menuItems }) => {
  return (
    <div className={styles['pagelayout-container']}>
      <div className={styles['pagelayout-header']}>
        <Link to={menuLink}>
          <div className="dF justify-center w48 cP">
            <MenuSvg />
          </div>
        </Link>
        <div className={styles['tafe-logo-box']}>
          <TafeLogoSvg />
        </div>
        <div className="dF justify-center w48 cP">
          <NotificationSvg />
        </div>
      </div>
      <div className={styles['pagelayout-body']}>
        <Outlet />
      </div>
      <div className={styles['pagelayout-footer']}>
        {menuItems.map((item) => (
          <Menu
            key={item.to}
            to={item.to}
            title={item.title}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default PageLayout;
