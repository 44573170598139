import './index.css';

import React from 'react';

import ListEmpty from './list-empty';
import ListItem from './list-item';
import ListItemAction from './list-item-action';
import ListItemKeyValue, { ListItemKeyValueProps } from './list-item-key-value';
import ListItemKeyValuePairs, {
  ListItemKeyValuePairsProps
} from './list-item-key-value-pairs';
import ListItemLink from './list-item-link';
import ListItemTitle, { ListItemTitleProps } from './list-item-title';
import ListItemWrapper, { ListItemWrapperProps } from './list-item-wrapper';
import RecordListLoader from './record-list-loader';
import RecordDropdownSearchBox, {
  RecordDropdownSearchBoxProps
} from './record-dropdown-search-box';
import LoadMoreLoader from './load-more-loader';
import List, { ListProp } from './list';
import SearchBox, { SearchBoxProps } from './record-search-box';

type RecordCollectionComposition = {
  List: React.FC<ListProp>;
  LoadMoreLoader: React.FC;
  ListItem: React.FC<React.PropsWithChildren>;
  ListEmpty: React.FC;
  DropdownSearchBox: React.FC<RecordDropdownSearchBoxProps>;
  SearchBox: React.FC<SearchBoxProps>;
  ListLoader: React.FC;
  ListAction: React.FC<React.PropsWithChildren>;
  ListItemLink: React.FC;
  ListItemTitle: React.FC<ListItemTitleProps>;
  ListItemWrapper: React.FC<ListItemWrapperProps>;
  ListItemKeyValue: React.FC<ListItemKeyValueProps>;
  ListItemKeyValuePairs: React.FC<ListItemKeyValuePairsProps>;
};

const RecordCollection: React.FunctionComponent<React.PropsWithChildren> &
  RecordCollectionComposition = ({ children }) => {
  return <div className="List-of-details-wrap">{children}</div>;
};

RecordCollection.List = List;
RecordCollection.LoadMoreLoader = LoadMoreLoader;
RecordCollection.ListItem = ListItem;
RecordCollection.ListEmpty = ListEmpty;
RecordCollection.DropdownSearchBox = RecordDropdownSearchBox;
RecordCollection.SearchBox = SearchBox;
RecordCollection.ListAction = ListItemAction;
RecordCollection.ListLoader = RecordListLoader;
RecordCollection.ListItemLink = ListItemLink;
RecordCollection.ListItemTitle = ListItemTitle;
RecordCollection.ListItemWrapper = ListItemWrapper;
RecordCollection.ListItemKeyValue = ListItemKeyValue;
RecordCollection.ListItemKeyValuePairs = ListItemKeyValuePairs;

export default RecordCollection;
