import React, { useContext } from "react";
type ProfilePropContext = {
    imageSrc?: string,
    name: string,
    skillLevel?: string,
    dealerName?: string,
    dealerLocation?: string
}
export const ProfileContext = React.createContext<ProfilePropContext | undefined>(undefined);

export function useProfileContext() {
    const context = useContext(ProfileContext);

    if(context === undefined){
        throw new Error('Unable to read Profile Context.')
    }

    return context;
}