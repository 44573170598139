import { useCallback, useEffect, useRef, useState } from 'react';
import { UseProspectValidationTasksHook } from '../../types/hooks';
import { Task } from '../../types/model';
import ProspectValidationTaskService from '../services/ProspectValidationTaskService';
import useDebounce from './useDebounce';

export default function useProspectValidationTasks({
  page,
  filters,
  perPage,
  onFailure
}: UseProspectValidationTasksHook.Parameters): UseProspectValidationTasksHook.Result {
  const controller = useRef<AbortController | undefined>();

  const [data, setData] = useState<Task[]>([]);
  const [error, setError] = useState<any>(null);
  const [__page, setPage] = useState<number>(page);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [initiateRequest, setInitiateRequest] = useState<boolean>(false);

  const [__filters, __setFilters] = useState<{}>(filters);

  const __triggerRequest = useCallback(() => {
    setInitiateRequest(true);
  }, []);

  const debouncedTriggerRequest = useDebounce({
    fn: __triggerRequest,
    delay: 800
  });

  useEffect(() => {
    setInitiateRequest(true);
  }, []);

  useEffect(() => {
    if (initiateRequest) {
      if (controller.current) controller.current.abort();
      controller.current = new AbortController();
      ProspectValidationTaskService.getPagedProspectValidations({
        page: __page,
        perPage,
        filters: __filters,
        signal: controller.current.signal
      })
        .then((result) => {
          const { data, page_info } = result;

          setData((prev) => (__page === 1 ? data : prev.concat(data)));
          setHasMore(page_info.hasMore);
          setError(null);
          setIsLoading(false);
          setIsLoadingMore(false);
        })
        .catch((err) => {
          setError(err);
          if (onFailure) onFailure(err);
        })
        .finally(() => {
          setInitiateRequest(false);
        });

      return () => {
        if (controller.current) controller.current.abort();
      };
    }
  }, [__page, perPage, __filters, onFailure, initiateRequest]);

  const updateFilterKey = useCallback(
    (key: keyof UseProspectValidationTasksHook.Filters, value: string) => {
      __setFilters((prev) => ({
        ...prev,
        [key]: value
      }));
      setPage(1);
      setData([]);
      setIsLoading(true);
      debouncedTriggerRequest();
    },
    [debouncedTriggerRequest]
  );

  const setFilters = useCallback(
    (filters: UseProspectValidationTasksHook.Filters) => {
      __setFilters(filters);
      setPage(1);
      setData([]);
      setIsLoading(true);
      debouncedTriggerRequest();
    },
    [debouncedTriggerRequest]
  );

  const loadMoreRecords = useCallback(() => {
    if (hasMore && !isLoadingMore) {
      setPage((c) => c + 1);
      setIsLoadingMore(true);
      setInitiateRequest(true);
    }
  }, [hasMore, isLoadingMore]);

  return {
    data,
    error,
    hasMore,
    isLoading,
    isLoadingMore,
    setFilters,
    loadMoreRecords,
    updateFilterKey
  };
}
