import React from 'react'
import { useProfileContext } from '../../context/ProfileContext'
import style from './style.module.css';

const Avatar: React.FC = () => {
    const {imageSrc} = useProfileContext()
    return (
        <img src={imageSrc} className={`br-circle ${style['profile-img']}`}/>
    )
}

export default Avatar