import { useCallback, useEffect, useState } from "react";

import JcPlanningService from "../services/JcPlanningService";
import { UseJcPlanningsHook } from "../../types/hooks";
import { JCP } from "../../types/model";

export default function useJcPlannings({
  page,
  perPage,
  filters = {},
  onFailure,
  onSuccess,
  additionalFields
}: UseJcPlanningsHook.Parameters): UseJcPlanningsHook.Result {
  const [data, setData] = useState<Array<JCP>>([]);
  const [error, setError] = useState<any>(null);
  const [__page, setPage] = useState<number>(1);
  const [__perPage, setPerPage] = useState<number>(200);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [initiateRequest, setInitiateRequest] = useState<boolean>(false);
  const [__filters, setFilters] = useState<UseJcPlanningsHook.Filters>({});

  useEffect(() => {
    // Run on initial mount
    setInitiateRequest(true);
  }, []);

  useEffect(() => {
    setPage(page);
    setPerPage(perPage);
    setFilters({
      ...filters
    });
    setInitiateRequest(true);
    setIsLoading(true);
  }, [page, perPage, filters]);

  useEffect(() => {
    if (initiateRequest) {
      JcPlanningService.getPagedJcPlannings({
        page: __page,
        perPage: __perPage,
        filters: __filters,
        additionalFields,
      })
        .then((result) => {
          const { data, page_info } = result;

          if (__page === 1) {
            setData(data);
          } else {
            setData((prev) => prev.concat(data));
          }

          if (onSuccess) {
            onSuccess.apply(null, [
              {
                data,
                page: __page,
                perPage: __perPage
              }
            ]);
          }
          setIsLoading(false);
          setIsLoadingMore(false);
          setHasMore(page_info.hasMore);
        })
        .catch((err) => {
          setError(err);
          if (onFailure) {
            onFailure.apply(null, [err]);
          }
        })
        .finally(() => {
          setInitiateRequest(false);
        });
    }
  }, [
    __page,
    __perPage,
    __filters,
    onSuccess,
    onFailure,
    isLoading,
    isLoadingMore,
    initiateRequest,
    additionalFields
  ]);

  const deleteJcPlanning = useCallback((id: string) => {
    setData((prev) => prev.filter((obj) => obj.id !== id));
  }, []);

  return {
    data,
    error,
    hasMore,
    isLoading,
    isLoadingMore,
    deleteJcPlanning
  };
}
