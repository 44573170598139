import React, { useCallback, useContext } from 'react'
import UserContext from '../../shared/context/UserContext';
import { useTranslation } from 'react-i18next';
import style from './index.module.css';
import Profile from '../../shared/components/profile';
import dummySrc from '../../shared/assets/images/dummy-user.png'
import { getFullName } from '../../shared/utils/FullNameUtil';
import { Link } from 'react-router-dom';
import Accordion from '../../shared/components/accordion';
import { ArrowPrimarySvg } from '../../shared/assets/icons/ArrowPrimarySvg';
import CallTemp from '../../shared/components/call-contact';
import WhatsappTemp from '../../shared/components/whatsapp-contact';
import NavArrowSvg from '../../shared/assets/icons/NavArrowSvg';
import { JCP, Task } from '../../types/model';
import EmptyData from '../../shared/components/empty-data';
import Loader from '../../shared/components/loader';
import useFollowupTask from '../../shared/hooks/useFollowupTask';
import ServerConnectorUtil from '../../shared/utils/ServerConnectorUtil';
import FollowupStatuses from '../../shared/enums/FollowupStatuses';
import UserAdditionalFields from '../../shared/enums/UserAdditionalFields';
import JcpStatuses from '../../shared/enums/JcpStatuses';
import usePagedProspects from '../../shared/hooks/usePagedProspects';
import ProspectEnquiryClassification from '../../shared/enums/ProspectEnquiryClassification';
import { IProspect } from '../../types/global';
import DateProcessor from '../../shared/processor/DateProcessor';
import DateFormat from '../../shared/enums/DateFormat';
import usePagedJcPlannings from '../../shared/hooks/usePagedJcPlannings';
import Button from '../../shared/components/button';
import AppUrlConstants from '../../shared/constants/AppUrlContanst';
interface MenuProps<T> {
    to: string;
    data: T | null;
}
const Followups: React.FC<MenuProps<Task>> = ({to, data}) => {
    const {t} = useTranslation();
    const renderDueDays = useCallback(
        (date: string): JSX.Element => {
            const inputDate = new Date(date);
            const today = new Date();
            let timeDifference = today.getTime() - inputDate.getTime();
            if(timeDifference < 0) {
                timeDifference = parseInt(timeDifference.toString().split('-')[1])
                return <div className='color-info text-14'>
                    {Math.floor(timeDifference / (1000 * 60 * 60 * 24))} {t('common.days')} - {t('common.upcoming')}
                </div>
            }else if(timeDifference > 0){
                return <div className='color-error text-14'>
                    {Math.floor(timeDifference / (1000 * 60 * 60 * 24))} {t('common.days')} - {t('common.pending')}
                </div>
            }else{
                return <div className='color-black text-14'>
                    {t('common.today')}
                </div>
            }
        },
        []
    )
    return (
        <div className='dF align-center justify-between pt-10 pb-10 bb-black-10 font-400'>
            <div className='dF align-center gap-10'>
                <div className={data?.prospect.contact?.color_code ? `${data.prospect.contact.color_code}-dot`: ``}></div>
                <div className='text-14 dF direction-column gap-2'>
                    <div className='dF align-center gap-4'>
                        <span className='font-500'>{data?.prospect.contact?.name ? data.prospect.contact.name : '--'}</span>
                        <Link to={to} className={`${style['nav-arrow']}`}>
                            <ArrowPrimarySvg/>
                        </Link>
                    </div>
                    <div className='dF align-center'>
                        {t('common.edd')} - {data?.prospect.expected_date_of_delivery_in_days ? data.prospect.expected_date_of_delivery_in_days : '--'} {t('common.days')} <span className='grey-dot'></span><span className='color-secondary-65'>{
                            data?.prospect.contact?.village ? data.prospect.contact.village : '--'
                        }</span>
                    </div>
                    { data && renderDueDays(data?.due_date) }
                </div>
            </div>
            <div className='dF gap-10 align-center'>
                <CallTemp phoneNumber="9994761341"/>
                <WhatsappTemp phoneNumber="9994761341"/>
            </div>
        </div>
    )
}
const PlannedVisit: React.FC<MenuProps<JCP>> = ({to, data}) => {
    const {t} = useTranslation();
    return (
        <div className='mt-10'>
            <div className='dF align-center gap-10 justify-between pt-10 pb-10 bb-black-10 font-400'>
                <div className='text-14 dF align-center gap-10'>
                    <div className={data?.village.color_code ? `${data.village.color_code}-dot` : ``}></div>
                    <div className='dF direction-column gap-2'>
                        <span className='font-600'>{data?.village.name}</span>
                        <div className='dF align-center color-secondary-65'>
                            {data?.village.tiv ? data.village.tiv : `NA`}<span className='grey-dot'></span>{data?.plan_type ? data.plan_type : `NA`}
                        </div>
                        <div className=''>{t('common.today')}</div>
                    </div>
                </div>
                <Link to={to} className={``}>
                    <NavArrowSvg/>
                </Link>
            </div>
        </div>
    )
}
const OverdueHp: React.FC<MenuProps<IProspect>> = ({to, data}) => {
    const {t} = useTranslation();
    return (
        <div className='mt-10'>
            <div className='dF align-center gap-10 justify-between pt-10 pb-10 bb-black-10 font-400'>
                <div className='text-14 dF align-center gap-10'>
                    <div className='Red-dot'></div>
                    <div className='text-14 dF direction-column gap-2'>
                        <div className='dF align-center gap-4'>
                            <span className='font-600'>{data?.contact.name}</span>
                            <Link to={'#'} className={`${style['nav-arrow']}`}>
                                <ArrowPrimarySvg/>
                            </Link>
                        </div>
                        <div className=''>EDD - {data?.edd ? DateProcessor.format(new Date(data.edd).getTime(), DateFormat.APP_DATE) : `NA`}</div>
                        <div className='color-secondary-65'>{data?.contact.village ? data.contact.village : `NA`}</div>
                        <div className=''>{t('common.modelInterest')} - {data?.interested_model_family ? data.interested_model_family : `NA`}</div>
                    </div>
                </div>
                <div className='dF gap-10 align-center'>
                    <CallTemp phoneNumber={data?.contact.mobile_number ? data.contact.mobile_number : ``}/>
                    <WhatsappTemp phoneNumber={data?.contact.mobile_number ? data.contact.mobile_number : ``}/>
                </div>
            </div>
        </div>
    )
}
const Home: React.FC = () => {
    const userData = useContext(UserContext);
    const {t} = useTranslation();
    const onCommonFailure = useCallback((error: any) => {
        ServerConnectorUtil.handleServerError(error);
    }, [])
    const {data: followups, totalRecord: totalFollowups, isLoading: isTaskLoading} = useFollowupTask({
        page: 1,
        perPage: 2,
        filters: {
            statuses: [FollowupStatuses.NOT_STARTED]
        },
        onFailure: ServerConnectorUtil.handleServerError
    })
    const {data: plannedVisit, totalRecord: totalPlannedVisit, isLoading: isPlannedVisitLoading} = usePagedJcPlannings({
        page: 1,
        perPage: 200,
        additionalFields: [UserAdditionalFields.VILLAGE_STATS_TOTAL_VISITS_YEAR_TILL_DATE],
        filters: {
            statuses: [JcpStatuses.APPROVED],
            dateStartValue: Date.now(),
            dateEndValue: Date.now()
        },
        onFailure: ServerConnectorUtil.handleServerError
    })
    const {data: overdueProspects, isLoading: isOverdueLoading, totalRecords: totalOverdue} = usePagedProspects({
        page: 1,
        perPage: 2,
        filters: {
            enquiryClassifications: [ProspectEnquiryClassification.HOT_PROSPECT],
            edd_end_value: Date.now()
        }
    })
    return (
        <>
            <Profile name={getFullName(userData.user.data.first_name, userData.user.data.last_name)} imageSrc={dummySrc} skillLevel={userData.user.data.skill_level}>
                <Profile.Avatar/>
                <Profile.Name/>
                <Profile.SkillLevel/>
            </Profile>
            <div className='p-16 dF justify-center mt-88'>
                <Link to={'#'}>
                    <p className={`color-primary font-600 ${style['add-enquiry']}`}>{t('dsp.add_enquiry')}</p>
                </Link>
            </div>
            <div className={`ml-20 mr-20 ${style['mb-80']}`}>
                <Accordion setInitialOpen={true}>
                    <Accordion.Header>
                        <div className='dF align-center text-16 font-600 gap-4'>
                            <p className='color-black '>{t('dsp.home.followup')}</p>
                            <div className='sm-dot bg-info'></div>
                            <p className='color-info'>{totalFollowups}</p>
                        </div>
                    </Accordion.Header>
                    {
                        isTaskLoading ?
                            <Accordion.Loader/>
                        :
                            <Accordion.Body>
                                <div className='mt-10'>
                                    {
                                        (followups.length === 0) ?
                                            <EmptyData/>
                                        :
                                        <>
                                            {
                                                followups.map((followup) => (
                                                    <Followups data={followup} to='#'/>
                                                ))
                                            }
                                            <button
                                                className="tertiary-btn ml-auto mr-auto mt-10"
                                            >
                                                Show More
                                            </button>
                                        </>
                                    }
                                </div>
                            </Accordion.Body>
                    }
                </Accordion>
                <Accordion setInitialOpen={true}>
                    <Accordion.Header>
                        <div className='dF align-center text-16 font-600 gap-4'>
                            <p className='color-black '>{t('dsp.home.planned_visit_activation')}</p>
                            <div className='sm-dot bg-info'></div>
                            <p className='color-info'>{totalPlannedVisit}</p>
                        </div>
                    </Accordion.Header>
                    {
                        isPlannedVisitLoading ?
                            <Accordion.Loader/>
                        :
                            <Accordion.Body>
                                {
                                    plannedVisit.length === 0 ?
                                        <EmptyData/>
                                    :
                                        plannedVisit.map((jcPlannedVisit) => (
                                            <PlannedVisit data={jcPlannedVisit} to='#'/>
                                        ))
                                }
                            </Accordion.Body>
                    }
                </Accordion>
                <Accordion setInitialOpen={true}>
                    <Accordion.Header>
                        <div className='dF align-center text-16 font-600 gap-4'>
                            <p className='color-black '>{t('dsp.home.overdue_hp')}</p>
                            <div className='sm-dot bg-info'></div>
                            <p className='color-info'>{totalOverdue}</p>
                        </div>
                    </Accordion.Header>
                    {
                        isOverdueLoading ?
                            <Accordion.Loader/>
                        :
                            <Accordion.Body>
                                {
                                    overdueProspects.length === 0 ?
                                        <EmptyData/>
                                    :
                                        <>
                                            {
                                                overdueProspects.map((overdue) => (
                                                    <OverdueHp to='#' data={overdue}/>
                                                ))
                                            }
                                                <button
                                                    className="tertiary-btn ml-auto mr-auto mt-10"
                                                >
                                                    {t('common.showMore')}
                                                </button>
                                        </>
                                }
                            </Accordion.Body>
                    }
                </Accordion>
            </div>
            <div className='pA right-15 bottom-90'>
                <Link to={`/${AppUrlConstants.DSP.BASE_NAME}/${AppUrlConstants.DSP.CHECKIN.BASE_NAME}/${AppUrlConstants.DSP.CHECKIN.CATEGORIES}`}>
                    <Button variant='primary' label='Check-in'/>
                </Link>
            </div>
        </>
    )
}

export default Home