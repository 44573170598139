import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseProspectValidationTasksHook } from '../../../types/hooks';
import CallTemp from '../../components/call-contact';
import RecordCollection from '../../components/record-collection';
import WhatsappTemp from '../../components/whatsapp-contact';
import useProspectValidationTasks from '../../hooks/useProspectValidationTasks';
import useScroll from '../../hooks/useScroll';
import ViewLayout from '../../layout/view-layout';
import { convertDateFormat } from '../../utils/ConvertDateFormat';
import { convertToRelativeDays } from '../../utils/ConvertRelativeDays';
import { getFullName } from '../../utils/FullNameUtil';
import useProspectAssignmentTasks from '../../hooks/useProspectAssignmentTasks';
import UserContext from '../../context/UserContext';

const allDropDownOptions = [{ value: 'prospectName', label: 'Prospect Name' }];

const ProspectAssignmentMaster = () => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('prospectName');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const [initialFilters] = useState<UseProspectValidationTasksHook.Filters>(
    () => {
      const initialFilters: UseProspectValidationTasksHook.Filters = {};
      return initialFilters;
    }
  );

  const { data, isLoading, updateFilterKey, isLoadingMore, loadMoreRecords } =
    useProspectAssignmentTasks({
      page: 1,
      perPage: 50,
      filters: initialFilters
    });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(
        searchKey as keyof UseProspectValidationTasksHook.Filters,
        value
      );
    },
    [searchKey, updateFilterKey]
  );

  return (
    <ViewLayout title="Prospects Assignment">
      <RecordCollection>
        <RecordCollection.SearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper
                key={item.id}
                to={`/${user.user_role}/prospects-assignment/${item.id}?title=Prospect Details`}
              >
                <RecordCollection.ListItemLink />
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle
                    title={item.prospect.name}
                    color_code={
                      item.prospect.contact.color_code
                        ? item.prospect.contact.color_code
                        : undefined
                    }
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Type"
                    value={item.prospect.enquiry_classification}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="SoG"
                    value={item.prospect.sog}
                  />

                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">
                      {item.prospect.contact.village}
                    </p>
                    {item.prospect.contact.tiv && (
                      <>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          {item.prospect.contact.tiv}
                        </p>
                      </>
                    )}
                    {item.prospect.contact.color_code && (
                      <>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          {item.prospect.contact.color_code}
                        </p>
                      </>
                    )}
                  </div>

                  <RecordCollection.ListItemKeyValue
                    label={t('common.edd')}
                    value={convertDateFormat(item.prospect.edd)}
                  />

                  <RecordCollection.ListItemKeyValue
                    label={t('common.contact')}
                    value={`+91 ${item.prospect.contact.mobile_number}`}
                  />
                  <RecordCollection.ListAction>
                    <CallTemp
                      className="prospect-contact"
                      phoneNumber={item.prospect.contact.mobile_number}
                    />
                    <WhatsappTemp
                      phoneNumber={item.prospect.contact.mobile_number}
                    />
                  </RecordCollection.ListAction>
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default ProspectAssignmentMaster;
