import React from 'react';
import { IContactProps } from '../../../types/global';
import CallblackSvg from '../../assets/icons/CallblackSvg';

const CallTemp: React.FC<IContactProps> = ({ phoneNumber }) => {
  const telLink = `tel:${phoneNumber}`;
  const handleInnerClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <a href={telLink} target="_blank" onClick={handleInnerClick}>
      <CallblackSvg className="prospect-contact" />
    </a>
  );
};

export default CallTemp;
