import classNames from 'classnames';
import React, { ReactNode } from 'react';
import InputMultiSelect, { multiSelectProps } from './input-multiselect';
import InputRadio, { RadioProps as RadioTemplateProps } from './input-radio';
import InputSelect, { SelectProps } from './input-select';
import InputText, { InputTextProps } from './input-text';
import InputDate, { DateProps } from './input-date';
import FormFlexWrapper, { FormFlexWrapperProps } from './form-flex-wrapper';

type FormComponentProps = {
  className?: string;
  children: ReactNode;
};

const FormComponents: React.FC<FormComponentProps> & {
  InputText: React.FC<InputTextProps>;
  TextArea: React.FC<InputTextProps>;
  InputSelect: React.FC<SelectProps>;
  InputMultiSelect: React.FC<multiSelectProps>;
  InputRadio: React.FC<RadioTemplateProps>;
  InputDate: React.FC<DateProps>;
  FormFlexWrapper: React.FC<FormFlexWrapperProps>;
} = ({ children, className }) => {
  return (
    <form className={classNames('form-component-container', className)}>
      {children}
    </form>
  );
};

FormComponents.InputText = InputText;
FormComponents.TextArea = (props: InputTextProps) => (
  <InputText {...props} type="textarea" />
);
FormComponents.InputSelect = InputSelect;
FormComponents.InputMultiSelect = InputMultiSelect;
FormComponents.InputRadio = InputRadio;
FormComponents.InputDate = InputDate;
FormComponents.FormFlexWrapper = FormFlexWrapper;

export default FormComponents;
