import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ITUDB } from '../../../../../types/global';
import Loader from '../../../../components/loader';
import UserContext from '../../../../context/UserContext';
import ViewLayout from '../../../../layout/view-layout';

const TudbView: React.FC = () => {
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ITUDB | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/api/v1/tractor-user-database/${id}`).then((response) => {
      setData(response.data.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <ViewLayout title="Prospect Details">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="tafe-full-form-body p-20">
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Phone number</p>
                <input
                  value={data?.mobile_number}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Name</p>
                <input
                  value={data?.name}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Taluka</p>
                <input
                  value={data?.taluka || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Village</p>
                <input
                  value={data?.village || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Customer Type</p>
                <input
                  value={data?.type || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Source of User</p>
                <input
                  value={data?.source}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Satisfied with tractor
                </p>
                <div className="dF align-center gap-10">
                  <div className="dF align-center">
                    <input
                      id="yes"
                      name="option"
                      value="yes"
                      type="radio"
                      checked={
                        data?.is_satisfied_with_his_tractor === 'Yes'
                          ? true
                          : false
                      }
                      disabled={true}
                    />
                    <label htmlFor="yes" className="text-14 font-400 cP mt-5">
                      Yes
                    </label>
                  </div>
                  <div className="dF align-center">
                    <input
                      id="no"
                      name="option"
                      value="no"
                      type="radio"
                      checked={
                        data?.is_satisfied_with_his_tractor === 'No'
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="no" className="text-14 font-400 cP mt-5">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Reason for dissatisfaction
                </p>
                <input
                  value={data?.reason_of_dissatisfaction || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  No of Tractors Owned
                </p>
                <input
                  value={data?.no_of_tractors_owned || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Current brand</p>
                <input
                  value={data?.tractor.brand || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">Current model</p>
                <input
                  value={data?.tractor.model || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Year of Manufacture
                </p>
                <input
                  value={data?.tractor.purchased_year || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">HP Segment</p>
                <input
                  value={data?.tractor.hp_segment || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
              <div className="form-column">
                <p className="text-14 ls-024 color-black-70">
                  Total number of tractors in your village
                </p>
                <input
                  value={data?.total_no_of_tractors_in_the_village || ''}
                  type="text"
                  className="input-box"
                  disabled={true}
                />
              </div>
            </div>
            {user.user_role !== 'tetm' && (
              <div className="form-row">
                <div className="form-column">
                  <p className="text-14 ls-024 color-black-70">Dealer Name</p>
                  <input
                    value={user?.dealer_name || ''}
                    type="text"
                    className="input-box"
                    disabled={true}
                  />
                </div>
                <div className="form-column">
                  <p className="text-14 ls-024 color-black-70">Dealer Code</p>
                  <input
                    value={user?.dealer_code || ''}
                    type="text"
                    className="input-box"
                    disabled={true}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </ViewLayout>
  );
};

export default TudbView;
