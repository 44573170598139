import axios from 'axios';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOptions, IVillage } from '../../../../types/global';
import FormComponents from '../../../components/form-components';
import Loader from '../../../components/loader';
import UserContext from '../../../context/UserContext';
import SuccessLayout from '../../../layout/success-layout';
import ViewLayout from '../../../layout/view-layout';
import ContactService from '../../../services/ContactService';
import ServerConnectorUtil from '../../../utils/ServerConnectorUtil';

interface enquiryFields {
  id: string;
  name: string;
  options: IOptions[];
  type: string;
}
interface IEnquiryData {
  contact_mobile_number: string | null;
  contact_name: string | null;
  contact_district: string | null;
  contact_state: string | null;
  contact_taluka: string | null;
  contact_village: string | null;
  enquiry_category: string | null;
  sog: string | null;
  interested_model_family: string | null;
  next_follow_up_in_days: string | null;
  expected_date_of_delivery_in_days: string | null;
  has_exchange: string | null;
  payment_mode: string | null;
}

const regex = {
  phoneRegex: /^\d{10}$/
};

const CreateEnquiry: React.FC = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [showSuccessLayout, setShowSuccessLayout] = useState(false);
  const [isFetching, setIsFetching] = useState({
    meta: false,
    taluk: false,
    village: false
  });
  const [submitting, setSubmitting] = useState(false);

  const [enquiryData, setEnquiryData] = useState<IEnquiryData>({
    contact_mobile_number: null,
    contact_name: null,
    contact_taluka: null,
    contact_village: null,
    contact_district: null,
    contact_state: null,
    enquiry_category: null,
    sog: null,
    interested_model_family: null,
    next_follow_up_in_days: null,
    expected_date_of_delivery_in_days: null,
    has_exchange: 'No',
    payment_mode: null
  });
  const [errors, setErrors] = useState({
    contact_mobile_number: '',
    contact_name: '',
    contact_taluka: '',
    contact_village: '',
    enquiry_category: '',
    sog: '',
    interested_model_family: '',
    next_follow_up_in_days: '',
    expected_date_of_delivery_in_days: '',
    has_exchange: '',
    payment_mode: ''
  });

  const [sogArray, setsogArray] = useState<IOptions[]>([]);
  const [IMFArray, setIMFArray] = useState<IOptions[]>([]);
  const [ECArray, setECArray] = useState<IOptions[]>([]);
  const [paymentArray, setpaymentArray] = useState<IOptions[]>([]);
  const [contactName, setContactName] = useState<string>('');
  const [talukasArray, setTalukasArray] = useState<IOptions[]>([]);
  const [villagesArray, setVillagesArray] = useState<IOptions[]>([]);
  const [villageData, setVillageData] = useState<IVillage[]>([]);
  const [selectedVillageData, setSelectedVillageData] =
    useState<IVillage | null>(null);

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      meta: true
    }));
    axios
      .get('/api/v1/prospects/meta/fields')
      .then((response) => {
        const { data } = response.data;
        data.forEach((item: enquiryFields) => {
          switch (item.name) {
            case 'sog':
              setsogArray(item.options);
              break;
            case 'interested_model_family':
              setIMFArray(item.options);
              break;
            case 'enquiry_category':
              setECArray(item.options);
              setEnquiryData((prev) => ({
                ...prev,
                enquiry_category: item.options[0].value
              }));
              break;
            case 'payment_mode':
              setpaymentArray(item.options);
              setEnquiryData((prev) => ({
                ...prev,
                payment_mode: item.options[1].value
              }));
              break;
          }
        });
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsFetching((prevState) => ({
          ...prevState,
          meta: false
        }));
      });
  }, []);

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      taluk: true
    }));
    axios
      .get('/api/v1/geography/taluks')
      .then((response) => {
        const { data } = response.data;
        const formattedTalukasArray = data.map((taluka: string) => ({
          label: taluka,
          value: taluka
        }));
        setTalukasArray(formattedTalukasArray);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsFetching((prevState) => ({
          ...prevState,
          taluk: false
        }));
      });
  }, []);

  useEffect(() => {
    if (enquiryData.contact_taluka) {
      setIsFetching((prevState) => ({
        ...prevState,
        village: true
      }));
      axios
        .get(
          `/api/v1/geography/villages?page=1&perPage=2000&taluk=${enquiryData.contact_taluka}`
        )
        .then((response) => {
          const { data } = response.data;
          const formattedVillageArray = data.map((village: IVillage) => ({
            label: village.name,
            value: village.id
          }));
          setVillageData(data);
          setVillagesArray(formattedVillageArray);
        })
        .catch(ServerConnectorUtil.handleServerError)
        .finally(() => {
          setIsFetching((prevState) => ({
            ...prevState,
            village: false
          }));
        });
    }
  }, [enquiryData.contact_taluka]);

  const handleValueChange = useCallback((name: string, value: string) => {
    setEnquiryData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: ''
    }));
  }, []);

  const handleOptionChange = useCallback(
    (option: IOptions | undefined, name: string | undefined) => {
      if (option) {
        if (name && name === 'contact_village') {
          const village = villageData.filter(
            (item) => item.id === option.value
          );
          setEnquiryData((prevState) => ({
            ...prevState,
            [name]: option.label,
            contact_district: village[0].district,
            contact_state: village[0].state
          }));
          setErrors((prev) => ({
            ...prev,
            [name]: ''
          }));
          setSelectedVillageData(village[0]);
        } else if (name) {
          setEnquiryData((prevState) => ({
            ...prevState,
            [name]: option.value
          }));
          setErrors((prev) => ({
            ...prev,
            [name]: ''
          }));
        }
      }
    },
    [villageData]
  );

  const handleRadioOptionChange = useCallback(
    (name: string, option: { label: string; value: string }) => {
      if (option) {
        setEnquiryData((prevState) => ({
          ...prevState,
          [name]: option.value
        }));
      }
    },
    []
  );

  const onChangeMobileNumber = useCallback((name: string, value: string) => {
    let mobileNumber = value;

    setEnquiryData((prev) => ({
      ...prev,
      contact_mobile_number: mobileNumber
    }));
    setErrors((prev) => ({
      ...prev,
      contact_mobile_number: ''
    }));
    if (mobileNumber.length == 10) {
      ContactService.getPagedContacts({
        page: 1,
        perPage: 1,
        filters: {
          mobileNumber
        }
      })
        .then((pagedResut) => {
          if (pagedResut?.data?.length) {
            setContactName(pagedResut.data[0].name);
            setEnquiryData((prev) => ({
              ...prev,
              contact_name: pagedResut.data[0].name
            }));
          } else {
            setContactName('');
          }
        })
        .catch(ServerConnectorUtil.handleServerError);
    }
  }, []);

  const validateForm = useCallback(() => {
    if (
      !enquiryData.contact_mobile_number ||
      enquiryData.contact_mobile_number.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        contact_mobile_number: 'Mobile is required'
      }));
      return false;
    }

    if (
      enquiryData.contact_mobile_number !== null &&
      !regex.phoneRegex.test(enquiryData.contact_mobile_number)
    ) {
      setErrors((prev) => ({
        ...prev,
        contact_mobile_number: 'Invalid Mobile'
      }));
      return false;
    }

    if (
      !enquiryData.contact_name ||
      enquiryData.contact_name.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        contact_name: 'Name is required'
      }));
      return false;
    }

    if (
      !enquiryData.contact_taluka ||
      enquiryData.contact_taluka.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        contact_taluka: 'Taluka is required'
      }));
      return false;
    }

    if (
      !enquiryData.contact_village ||
      enquiryData.contact_village.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        contact_village: 'Village is required'
      }));
      return false;
    }

    if (
      !enquiryData.enquiry_category ||
      enquiryData.enquiry_category.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        enquiry_category: 'Enquiry category is required'
      }));
      return false;
    }

    if (!enquiryData.sog || enquiryData.sog.trim().length === 0) {
      setErrors((prev) => ({
        ...prev,
        sog: 'SOG is required'
      }));
      return false;
    }

    if (
      !enquiryData.interested_model_family ||
      enquiryData.interested_model_family.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        interested_model_family: 'Interested Model Family is required'
      }));
      return false;
    }

    if (
      !enquiryData.next_follow_up_in_days ||
      isNaN(Number(enquiryData.next_follow_up_in_days))
    ) {
      setErrors((prev) => ({
        ...prev,
        next_follow_up_in_days: 'Next Followup is required'
      }));
      return false;
    }

    if (
      !enquiryData.expected_date_of_delivery_in_days ||
      isNaN(Number(enquiryData.expected_date_of_delivery_in_days))
    ) {
      setErrors((prev) => ({
        ...prev,
        expected_date_of_delivery_in_days:
          'Expected Date of Delivery is required'
      }));
      return false;
    }

    if (
      parseInt(enquiryData.next_follow_up_in_days) >
      parseInt(enquiryData.expected_date_of_delivery_in_days)
    ) {
      setErrors((prev) => ({
        ...prev,
        next_follow_up_in_days: 'Invalid Next Followup'
      }));
      return false;
    }

    if (
      !enquiryData.payment_mode ||
      enquiryData.payment_mode.trim().length === 0
    ) {
      setErrors((prev) => ({
        ...prev,
        payment_mode: 'Payment Mode is required'
      }));
      return false;
    }
    return true;
  }, [enquiryData]);

  const createEnquiry = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    setSubmitting(true);
    axios
      .post('/api/v1/prospect', enquiryData)
      .then(() => {
        setShowSuccessLayout(true);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setSubmitting(false);
      });
  }, [enquiryData]);

  const goToEnquiry = useCallback(() => {
    navigate(`/${user.user_role}/main/enquiries/enquiry`, {
      replace: true
    });
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      {showSuccessLayout ? (
        <SuccessLayout
          title="Congratulations!"
          desc="Enquiry created successfully."
          button_icon="add"
          button_name="Back to Enquiry"
          onClick={goToEnquiry}
        />
      ) : (
        <ViewLayout title="Add Enquiry">
          {isFetching.meta || isFetching.taluk ? (
            <Loader />
          ) : (
            <>
              <div className="tafe-form-body">
                <FormComponents>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputText
                      label="Mobile"
                      type="number"
                      name="contact_mobile_number"
                      placeholder="Mobile"
                      mandatory={true}
                      value={enquiryData.contact_mobile_number || ''}
                      onChange={onChangeMobileNumber}
                      error={errors.contact_mobile_number}
                    />
                    <FormComponents.InputText
                      label="Name"
                      placeholder="Name"
                      name="contact_name"
                      mandatory={true}
                      value={enquiryData.contact_name || ''}
                      onChange={handleValueChange}
                      error={errors.contact_name}
                    />
                  </FormComponents.FormFlexWrapper>
                  {contactName.length > 0 && (
                    <div className="mb-20">
                      <FormComponents.InputText
                        label="Contact Name"
                        value={contactName || ''}
                        disabled={true}
                      />
                    </div>
                  )}
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputSelect
                      label="Taluka"
                      name="contact_taluka"
                      placeholder="Select Taluka"
                      mandatory={true}
                      defaultValue={
                        enquiryData.contact_taluka
                          ? {
                              label: enquiryData.contact_taluka,
                              value: enquiryData.contact_taluka
                            }
                          : null
                      }
                      options={talukasArray}
                      onSelect={handleOptionChange}
                      error={errors.contact_taluka}
                    />
                    <FormComponents.InputSelect
                      label="Village"
                      name="contact_village"
                      placeholder="Select Village"
                      mandatory={true}
                      isLoading={isFetching.village}
                      defaultValue={
                        enquiryData.contact_village
                          ? {
                              label: enquiryData.contact_village,
                              value: enquiryData.contact_village
                            }
                          : null
                      }
                      options={villagesArray}
                      onSelect={handleOptionChange}
                      error={errors.contact_village}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputText
                      label={`${user.data.company_code?.toUpperCase()} BRYG`}
                      value={selectedVillageData?.color_code || ''}
                      disabled={true}
                    />
                    <FormComponents.InputText
                      label={`${user.data.company_code?.toUpperCase()} TIV`}
                      value={selectedVillageData?.tiv || ''}
                      disabled={true}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputSelect
                      label="Enquiry Category"
                      name="enquiry_category"
                      placeholder="Select Enquiry Category"
                      mandatory={true}
                      defaultValue={
                        enquiryData.enquiry_category
                          ? {
                              label: enquiryData.enquiry_category,
                              value: enquiryData.enquiry_category
                            }
                          : null
                      }
                      options={ECArray}
                      onSelect={handleOptionChange}
                      error={errors.enquiry_category}
                    />
                    <FormComponents.InputSelect
                      label="SOG"
                      name="sog"
                      placeholder="Select SOG"
                      mandatory={true}
                      defaultValue={
                        enquiryData.sog
                          ? {
                              label: enquiryData.sog,
                              value: enquiryData.sog
                            }
                          : null
                      }
                      options={sogArray}
                      onSelect={handleOptionChange}
                      error={errors.sog}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputSelect
                      label="Interested Model Family"
                      name="interested_model_family"
                      placeholder="Interested Model Family"
                      mandatory={true}
                      defaultValue={
                        enquiryData.interested_model_family
                          ? {
                              label: enquiryData.interested_model_family,
                              value: enquiryData.interested_model_family
                            }
                          : null
                      }
                      options={IMFArray}
                      onSelect={handleOptionChange}
                      error={errors.interested_model_family}
                    />
                    <FormComponents.InputText
                      type="number"
                      label="Next Followup (In Days)"
                      name="next_follow_up_in_days"
                      placeholder="Next Followup (In Days)"
                      mandatory={true}
                      value={enquiryData.next_follow_up_in_days || ''}
                      onChange={handleValueChange}
                      error={errors.next_follow_up_in_days}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputText
                      type="number"
                      label="Expected Date of Delivery (Days)"
                      name="expected_date_of_delivery_in_days"
                      placeholder="Expected Date of Delivery (Days)"
                      mandatory={true}
                      value={
                        enquiryData.expected_date_of_delivery_in_days || ''
                      }
                      onChange={handleValueChange}
                      error={errors.expected_date_of_delivery_in_days}
                    />
                    <FormComponents.InputRadio
                      label="Exchange"
                      name="has_exchange"
                      selectedValue={{
                        label: enquiryData.has_exchange || '',
                        value: enquiryData.has_exchange || ''
                      }}
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' }
                      ]}
                      onChange={handleRadioOptionChange}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputSelect
                      label="Payment Mode"
                      name="payment_mode"
                      placeholder="Payment Mode"
                      mandatory={true}
                      defaultValue={
                        enquiryData.payment_mode
                          ? {
                              label: enquiryData.payment_mode,
                              value: enquiryData.payment_mode
                            }
                          : null
                      }
                      options={paymentArray}
                      onSelect={handleOptionChange}
                      error={errors.payment_mode}
                    />
                    {user.user_role !== 'tetm' && (
                      <FormComponents.InputText
                        label="Dealer Code"
                        value={user.data.dealer_code}
                        disabled={true}
                      />
                    )}
                  </FormComponents.FormFlexWrapper>
                  {user.user_role !== 'tetm' && (
                    <div className="w100p">
                      <p className="text-14 font-500 mb-4">Dealer Name</p>
                      <div className="dealer-name-box">
                        {user.data.dealer_name}
                      </div>
                    </div>
                  )}
                </FormComponents>
              </div>
              <div className="tafe-form-footer">
                <button className="secondary-btn" onClick={goBack}>
                  Cancel
                </button>
                <button
                  className={classNames('primary-btn pR')}
                  disabled={submitting}
                  onClick={createEnquiry}
                >
                  <div className={classNames(submitting && 'vH')}>Create</div>
                  <div className={classNames('pA', !submitting && 'vH')}>
                    <div className="dot-flashing"></div>
                  </div>
                </button>
              </div>
            </>
          )}
        </ViewLayout>
      )}
    </>
  );
};

export default CreateEnquiry;
