import { Navigate, Route } from 'react-router-dom'
import AppUrlConstants from '../shared/constants/AppUrlContanst'
import DspHome from './home';
import JCP from './jcp';
import Menu from './menu';
import Main from './main';
import DspDashboard from './dashboard';
import Enquiries from '../shared/views/enquiries';
import Enquiry from '../shared/views/enquiries/enquiry';
import TUDB from '../shared/views/enquiries/tudb';
import CheckInCategories from './check-in-categories';
import CheckInJcps from './check-in-jcps';
import CheckInJcp from './check-in-jcp';
import CreateJcPlanning from './create-jc-planning';
import SelectDateView from './create-jc-planning/components/select-date-view';
import FormView from './create-jc-planning/components/form-view';
import SelectAnchorVillageView from './create-jc-planning/components/select-anchor-village-view';
import SelectNonAnchorVillagesView from './create-jc-planning/components/select-non-anchor-villages-view';
// import CreateJcPlanning from './CreateJcPlanning';
// import CreateJcPlanningSelectDate from './CreateJcPlanningSelectDate';
// import CreateJcPlanningForm from './CreateJcPlanningForm';
// import CreateJcPlanningSelectAnchorVillage from './CreateJcPlanningSelectAnchorVillage';
// import CreateJcPlanningSelectNonAnchorVillagesWrapper from './CreateJcPlanningSelectNonAnchorVillages';

const DspRoutes = (): JSX.Element => {
    return (
        <Route path={AppUrlConstants.DSP.BASE_NAME}>
            <Route
                path="*"
                element={<Navigate to="main" replace={true} />}
            />
            <Route
                path=""
                element={<Navigate to="main" replace={true} />}
            />
            <Route path="main" element={<Main />}>
                <Route
                path="*"
                element={
                    <Navigate
                    to={AppUrlConstants.DSP.HOME}
                    replace={true}
                    />
                }
                />
                <Route
                path=""
                element={
                    <Navigate
                    to={AppUrlConstants.DSP.HOME}
                    replace={true}
                    />
                }
                />
                <Route
                path={AppUrlConstants.DSP.HOME}
                element={<DspHome />}
                />
                <Route path={AppUrlConstants.DSP.JCP} element={<JCP />} />
                <Route
                path={AppUrlConstants.DSP.ENQUIRIES.BASE_NAME}
                element={<Enquiries />}
                >
                <Route
                    path="*"
                    element={<Navigate to="enquiry" replace={true} />}
                />
                <Route
                    path=""
                    element={<Navigate to="enquiry" replace={true} />}
                />
                <Route path="enquiry" element={<Enquiry />} />
                <Route path="tudb" element={<TUDB />} />
                </Route>
                <Route
                path={AppUrlConstants.DSP.DASHBOARD}
                element={<DspDashboard />}
                />
            </Route>
            <Route path={AppUrlConstants.DSP.MENU} element={<Menu />} />
            <Route path={AppUrlConstants.DSP.CHECKIN.BASE_NAME}>
                <Route
                    path='*'
                    element={<Navigate to={AppUrlConstants.DSP.CHECKIN.CATEGORIES} replace={true}/>}
                />
                <Route
                    path=''
                    element={<Navigate to={AppUrlConstants.DSP.CHECKIN.CATEGORIES} replace={true}/>}
                />
                <Route path={AppUrlConstants.DSP.CHECKIN.CATEGORIES} element={<CheckInCategories/>}/>
                <Route path={AppUrlConstants.DSP.CHECKIN.JCPS} element={<CheckInJcps/>}/>
                <Route path={`${AppUrlConstants.DSP.CHECKIN.JCP}${AppUrlConstants.DSP.CHECKIN.JCP_ID}`} element={<CheckInJcp/>}/>
            </Route>
            <Route path={AppUrlConstants.DSP.CREATE_JCP.BASE_NAME} element={<CreateJcPlanning/>}/>
        </Route>
    )
}

export default DspRoutes;