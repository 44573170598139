import React from 'react'
import { useAccordianContext } from '../../context/AccordianContext'
import Loader from '../loader';
import { AnimatePresence } from 'framer-motion';

const AccordionLoader: React.FC = () => {
    const {open} = useAccordianContext();
    return (
        <AnimatePresence>
            {
                open &&
                <div style={{height: '150px'}}>
                    <Loader size='small'/>
                </div>
            }
        </AnimatePresence>
    )
}

export default AccordionLoader