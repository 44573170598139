import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DateFilter from '../../../../../components/date-filter';
import Loader from '../../../../../components/loader';
import useCurrentLocation from '../../../../../hooks/useCurrentLocation';
import useJcpReviewDsp from '../../../../../hooks/useJcpReviewDsp';
import useScroll from '../../../../../hooks/useScroll';
import ViewLayout from '../../../../../layout/view-layout';
import distanceCalc from '../../../../../utils/distanceCalc';
import ServerConnectorUtil from '../../../../../utils/ServerConnectorUtil';
import useJcpPlanDsp from '../../../../../hooks/useJcpPlanDsp';
import useEffectAfterMount from '../../../../../hooks/useEffectAfterMount';
import { useQueryStates } from 'nuqs';

interface Breakdown {
  category: string;
  total_planned: number;
  total_visited: number;
  total_unique_villages: number;
}

interface DspSummary {
  village_tiv_breakdowns: Breakdown[];
  village_color_code_breakdowns: Breakdown[];
}

const JcpReviewDspDetails: React.FC = () => {
  const { id } = useParams();

  const {
    latitude: currentLocationLatitude,
    longitude: currentLocationLongitude
  } = useCurrentLocation();

  const calculateDistance = useCallback(
    (
      latitude: string,
      longitude: string,
      currentLocationLatitude?: number,
      currentLocationLongitude?: number
    ) => {
      if (!currentLocationLatitude || !currentLocationLongitude) {
        return 'NA';
      } else {
        const distance = distanceCalc.calculateDistance(
          currentLocationLatitude,
          currentLocationLongitude,
          parseFloat(latitude),
          parseFloat(longitude)
        );

        if (distance > 50) {
          return '50+ KM';
        } else {
          return distance.toFixed(2) + ' KM';
        }
      }
    },
    []
  );

  const [{ month, year, dsp_name, dealer_codes }] = useQueryStates(
    {
      month: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      year: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      dsp_name: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      dealer_codes: {
        defaultValue: '',
        parse: (value) => value || ''
      }
    },
    {
      history: 'replace'
    }
  );
  const [isFetching, setIsFetching] = useState(false);
  const [stats, setStats] = useState<DspSummary>();

  useEffectAfterMount(() => {
    setIsFetching(true);

    const params = new URLSearchParams();
    params.append('month', month);
    params.append('year', year);
    params.append('dealer_codes', dealer_codes);

    axios
      .get(
        `/api/v1/dsp-users/${id}/jc-planning-statistics?${params.toString()}`
      )
      .then((response) => {
        const { data } = response.data;
        setStats(data);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsFetching(false);
      });
  }, [id]);

  const { data, isLoading, loadMoreRecords } = useJcpPlanDsp({
    id: id ? id : '',
    month,
    year,
    page: 1,
    perPage: 200
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  return (
    <ViewLayout title="DSP Details">
      <div className="p-20" style={{ height: '100%', overflow: 'auto' }}>
        {isLoading || isFetching ? (
          <div style={{ width: '100%', height: 'calc(100vh - 140px' }}>
            <Loader />
          </div>
        ) : (
          <>
            {stats && stats.village_color_code_breakdowns.length > 0 && (
              <div className="tafe-card">
                <h2 className="text-14 color-secondary p-16 bb-primary-10">
                  BRYG
                </h2>
                <table className="dsp-table">
                  <tr>
                    <th>Village category</th>
                    <th>Planned</th>
                    <th>Visited</th>
                  </tr>
                  {stats.village_color_code_breakdowns.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className={classNames(`${item.category}-dot m-auto`)}
                        ></div>
                      </td>
                      <td>{item.total_planned}</td>
                      <td>{item.total_visited}</td>
                    </tr>
                  ))}
                </table>
              </div>
            )}

            <div className="tafe-card mb-20">
              <h2 className="text-14 color-secondary mt-15 p-16 bb-primary-10">
                HTIV/LTIV
              </h2>
              <table className="dsp-table">
                <tr>
                  <th>Category</th>
                  <th>Planned</th>
                  <th>Visited</th>
                </tr>
                {stats?.village_tiv_breakdowns.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p className="text-14">{item.category}</p>
                    </td>
                    <td>{item.total_planned}</td>
                    <td>{item.total_visited}</td>
                  </tr>
                ))}
              </table>
            </div>

            {data.length > 0 && (
              <div
                style={{ height: '300px', overflow: 'auto' }}
                onScroll={onScrollEventListener}
              >
                {data.map((item, index) => (
                  <div className="tafe-card p-14 mb-10">
                    <div className="dF direction-column gap-8">
                      <div className="dF align-center gap-8">
                        <h3 className="text-14 font-500 color-black">
                          {item.village.name}
                        </h3>
                        <p className="text-14 font-400 color-black-70 ls-028">
                          Type :
                          <span className="font-600 color-primary">
                            {item.village.color_code || 'NA'}
                          </span>
                        </p>
                        <p className="text-14 color-primary">
                          {item.checked_in ? 'Visited' : ''}
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          {item.created_time.split('T')[0]}
                        </p>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          {calculateDistance(
                            item.village.latitude?.toString(),
                            item.village.longitude?.toString(),
                            currentLocationLatitude,
                            currentLocationLongitude
                          )}
                        </p>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          TIV: {item.village.tiv || 'NA'}
                        </p>
                      </div>
                      <div className="dF align-center gap-8">
                        <p className="text-14 color-black-70 ls-028">
                          {item.date}
                        </p>
                        <p className="text-14 color-black-70 ls-028">
                          Visits YTD: NA
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          Activity :
                        </p>
                        <p className="text-14 color-black ls-028">
                          {item.plan_type}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </ViewLayout>
  );
};

export default JcpReviewDspDetails;
