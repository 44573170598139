import { Framework, ITUDBData } from '../../types/global';
import APIRequest from '../constants/APIRequest';
import DateFormat from '../enums/DateFormat';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';
import DateProcessor from '../processor/DateProcessor';

export default class TudbService {
  public static getTudbStat = ({
    params: { endDateTime, startDateTime },
    signal
  }: {
    params: {
      startDateTime?: number;
      endDateTime?: number;
    };
    signal: AbortSignal;
  }): Promise<Framework.IResult<ITUDBData>> => {
    const reqHandler = new RequestHandler(
      '/api/v1/tractor-user-databases/summary',
      RequestMethod.GET
    );

    if (startDateTime !== undefined) {
      reqHandler.addParam(
        'start_date_time',
        DateProcessor.format(startDateTime, DateFormat.ISO_DATE_TIME)
      );
    }
    if (endDateTime !== undefined) {
      reqHandler.addParam(
        'end_date_time',
        DateProcessor.format(endDateTime, DateFormat.ISO_DATE_TIME)
      );
    }
    reqHandler.setAbortSignal(signal);

    return reqHandler
      .execute()
      .then((response) => response.data as Framework.IResult<ITUDBData>);
  };
}
