import { useTranslation } from "react-i18next";
import { VILLAGE } from "../../../../../../types/model";
import styles from "./styles.module.css";
import classNames from "classnames";

const MostVisitedVillage: React.FC<{ village: VILLAGE }> = ({ village }) => {
  const {t} = useTranslation();
  return (
    <div className={styles["list-item"]}>
      <div className={styles["count"]}>
        {village.stats?.total_jcp_visits_year_till_date}
      </div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["title"]}>{village.name}</div>
        <div className={styles["info-container"]}>
          <p className={styles["color-code"]}>
            {t('dsp.create_jcp.anchor_village.village')}:{" "}
            <span
              className={classNames(
                styles["color"],
                styles[village.color_code?.toLowerCase() || "na"]
              )}
            >
              {village.color_code || "NA"}
            </span>
          </p>
          <p className={styles["tiv"]}>
            {village.tiv?.toLowerCase() === "high" ? t('dsp.create_jcp.summary.high_tiv') : t('dsp.create_jcp.summary.low_tiv')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MostVisitedVillage;
