export interface Criteria {
  value: any;
  field: string;
  comparator: 'equal' | 'not_equal';
  group?: Criteria[];
  group_operator?: 'and' | 'or';
}

export interface Action {
  type: 'Show_Fields' | 'Mandatory_Fields' | 'Show_Sections';
  fields?: string[];
  sections?: string[];
}

export interface Rule {
  criteria: Criteria;
  actions: Action[];
}

export type InputData = Record<string, any>;

export interface EngineOutput {
  showFields: string[];
  showSections: string[];
  mandatoryFields: string[];
}

export class FieldEngine {
  private rules: Rule[];

  constructor(rules: Rule[]) {
    this.rules = rules;
  }

  private evaluateCondition(
    value: any,
    comparator: string,
    target: any
  ): boolean {
    if (comparator === 'equal') {
      return Array.isArray(target) ? target.includes(value) : value === target;
    } else if (comparator === 'not_equal') {
      return Array.isArray(target) ? !target.includes(value) : value !== target;
    }
    return false;
  }

  private evaluateCriteria(criteria: Criteria, input: InputData): boolean {
    if (criteria.group) {
      const results = criteria.group.map((c) =>
        this.evaluateCriteria(c, input)
      );
      return criteria.group_operator === 'and'
        ? results.every((res) => res)
        : results.some((res) => res);
    }
    const fieldValue = input[criteria.field];
    return this.evaluateCondition(
      fieldValue,
      criteria.comparator,
      criteria.value
    );
  }

  public process(input: InputData): EngineOutput {
    const showFields = new Set<string>();
    const showSections = new Set<string>();
    const mandatoryFields = new Set<string>();

    for (const rule of this.rules) {
      if (this.evaluateCriteria(rule.criteria, input)) {
        for (const action of rule.actions) {
          if (action.type === 'Show_Fields' && action.fields) {
            action.fields.forEach((field) => showFields.add(field));
          }
          if (action.type === 'Mandatory_Fields' && action.fields) {
            action.fields.forEach((field) => mandatoryFields.add(field));
          }
          if(action.type === 'Show_Sections' && action.sections) {
            action.sections.forEach((section)=>showSections.add(section))
          }
        }
      }
    }

    return {
      showFields: Array.from(showFields),
      showSections:Array.from(showSections),
      mandatoryFields: Array.from(mandatoryFields)
    };
  }
}
