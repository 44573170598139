import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseProspectValidationTasksHook } from '../../types/hooks';
import CallTemp from '../../shared/components/call-contact';
import RecordCollection from '../../shared/components/record-collection';
import WhatsappTemp from '../../shared/components/whatsapp-contact';
import useProspectValidationTasks from '../../shared/hooks/useProspectValidationTasks';
import useScroll from '../../shared/hooks/useScroll';
import ViewLayout from '../../shared/layout/view-layout';
import { convertDateFormat } from '../../shared/utils/ConvertDateFormat';
import { convertToRelativeDays } from '../../shared/utils/ConvertRelativeDays';
import { getFullName } from '../../shared/utils/FullNameUtil';

const allDropDownOptions = [{ value: 'prospectName', label: 'Prospect Name' }];

const ProspectValidationMaster = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [searchKey] = useState<string>('prospectName');
  const [searchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const [initialFilters] = useState<UseProspectValidationTasksHook.Filters>(
    () => {
      const initialFilters: UseProspectValidationTasksHook.Filters = {};
      return initialFilters;
    }
  );

  const { data, isLoading, updateFilterKey, isLoadingMore, loadMoreRecords } =
    useProspectValidationTasks({
      page: 1,
      perPage: 50,
      filters: initialFilters
    });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(
        searchKey as keyof UseProspectValidationTasksHook.Filters,
        value
      );
    },
    [searchKey, updateFilterKey]
  );

  return (
    <ViewLayout title="Prospects Validation">
      <RecordCollection>
        <RecordCollection.SearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper
                key={item.id}
                to={`/tetm/prospects-validation/${item.id}?title=Prospect Details`}
              >
                <RecordCollection.ListItemLink />
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle
                    title={item.prospect.name}
                    color_code={
                      item.prospect.contact.color_code
                        ? item.prospect.contact.color_code
                        : undefined
                    }
                  />
                  <RecordCollection.ListItemKeyValue
                    label="DSP"
                    value={getFullName(
                      item.prospect.owner.first_name,
                      item.prospect.owner.last_name
                    )}
                  />
                  <RecordCollection.ListItemKeyValue
                    label={t('common.edd')}
                    value={convertDateFormat(item.prospect.edd)}
                  />

                  <div className="dF align-center gap-4">
                    <p className="text-14 color-black-70 ls-028">
                      {item.prospect.contact.village}
                    </p>
                    {item.prospect.contact.tiv && (
                      <>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          {item.prospect.contact.tiv}
                        </p>
                      </>
                    )}
                    {item.prospect.contact.color_code && (
                      <>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          {item.prospect.contact.color_code}
                        </p>
                      </>
                    )}
                  </div>

                  <p className="text-14 color-error ls-028">
                    {convertToRelativeDays(item.due_date)} - {item.status}
                  </p>

                  <RecordCollection.ListItemKeyValue
                    label={t('common.contact')}
                    value={`+91 ${item.prospect.contact.mobile_number}`}
                  />
                  <RecordCollection.ListAction>
                    <CallTemp
                      className="prospect-contact"
                      phoneNumber={item.prospect.contact.mobile_number}
                    />
                    <WhatsappTemp
                      phoneNumber={item.prospect.contact.mobile_number}
                    />
                  </RecordCollection.ListAction>
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default ProspectValidationMaster;
