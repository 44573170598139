export type FormFlexWrapperProps = {
  children: React.ReactNode;
};

const FormFlexWrapper: React.FC<FormFlexWrapperProps> = ({ children }) => {
  return (
    <div className="form-row">
      {children}
    </div>
  );
};

export default FormFlexWrapper;
