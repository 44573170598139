import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import classNames from 'classnames';
import './index.css';
import { IOptions } from '../../../types/global';

interface ISearchDropdownProps {
  options: IOptions[];
  defaultValue?: IOptions | null;
  placeholder?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  classes?: string;
  onSelect?: {
    (option: IOptions): void;
  };
}

const SearchDropdown: React.FC<ISearchDropdownProps> = ({
  options,
  defaultValue,
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isClearable = false,
  isLoading = false,
  classes,
  onSelect
}) => {
  const handleChange = (option: SingleValue<IOptions>) => {
    if (option) {
      if (onSelect) onSelect(option);
    }
  };
  return (
    <Select
      options={options}
      menuPosition="fixed"
      className={classNames('defaultSelectButton', classes && classes)}
      isClearable={isClearable}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isLoading={isLoading}
      classNamePrefix="default-select"
      value={defaultValue}
      onChange={handleChange}
    />
  );
};

export default SearchDropdown;
