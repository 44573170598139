import { useCallback, useState } from "react";
import { CreateJcPlanningForm } from "./types";
import { useNavigate } from "react-router-dom";
import useViewNavigation from "./hooks/useViewNavigation";
import useForm from "../../shared/hooks/useForm";
import Loader from "../../shared/components/loader";
import { CreateJcPlanningContext } from "./hooks/useCreateJcPlanningContext";
import { FormContext } from "../../shared/hooks/useFormContext";
import useJcPlanningMeta from "../../shared/hooks/useJcPlanningMeta";
import View from "./enums/View";
import FormView from "./components/form-view";
import SelectAnchorVillageView from "./components/select-anchor-village-view";
import SelectNonAnchorVillagesView from "./components/select-non-anchor-villages-view";
import ViewLayout from "../../shared/layout/view-layout";
import { useTranslation } from "react-i18next";
import AppUrlConstants from "../../shared/constants/AppUrlContanst";
import JcPlanningSummary from "./components/jc-planning-summary";
import SuccessLayout from "../../shared/layout/success-layout";

const initialValues: CreateJcPlanningForm = {
  date: null,
  planType: null,
  anchorVillage: null,
  nonAnchorVillages: null
};
const CreateJcPlanning: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [showSuccessLayout, setShowSuccessLayout] = useState(false);
  const { view, goToView } = useViewNavigation();
  const { isLoading, planTypeOptions } = useJcPlanningMeta({});
  const formOperations = useForm<CreateJcPlanningForm>({
    initialValues
  });

  const cancelOperation = useCallback(() => {
    navigate(`/${AppUrlConstants.DSP.BASE_NAME}/main/${AppUrlConstants.DSP.JCP}`);
  }, [navigate]);
  const goToHome = useCallback(() => {
    navigate(`/${AppUrlConstants.DSP.BASE_NAME}/main/${AppUrlConstants.DSP.JCP}`);
  }, [navigate])
  return (
    showSuccessLayout ?
        <SuccessLayout
          title={t('success_layout.success')}
          desc={t('success_layout.jcp_create_desc')}
          button_name={t('common.back_to_home')}
          onClick={goToHome}
        />
    :
    
      <ViewLayout title={t('dsp.jcp.create_jcp')}>
        {isLoading ? (
          <Loader/>
        ) : (
          <CreateJcPlanningContext.Provider
            value={{ goToView, cancelOperation, planTypeOptions, setShowSuccessLayout }}
          >
            <FormContext.Provider value={formOperations}>
              {view === View.SUMMARY ? (
                <JcPlanningSummary />
              ) : view === View.FORM_VIEW ? (
                <FormView />
              ) : view === View.SELECT_ANCHOR_VILLAGE_VIEW ? (
                <SelectAnchorVillageView />
              ) : (
                <SelectNonAnchorVillagesView />
              )}
            </FormContext.Provider>
          </CreateJcPlanningContext.Provider>
        )}
      </ViewLayout>
  );
};

export default CreateJcPlanning;
