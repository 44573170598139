import React from 'react';
import { IIconProps } from '../../../types/global';

const NavArrowSvg: React.FC<IIconProps> = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4043 6.675H0.191797V5.325H8.4043L4.63555 1.55625L5.5918 0.599999L10.9918 6L5.5918 11.4L4.63555 10.4437L8.4043 6.675Z"
        fill="var(--primary)"
      />
    </svg>
  );
};

export default NavArrowSvg;
