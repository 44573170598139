import TaskService from './TaskService';

import { TaskServiceParameters } from '../../types/parameters';

export default class ProspectValidationTaskService extends TaskService {
  public static getPagedProspectValidations(
    params: Omit<TaskServiceParameters.GetPagedTasks.Parameters, 'url'>
  ): Promise<TaskServiceParameters.GetPagedTasks.ReturnType> {
    return this.getPagedTasks({
      url: '/api/v1/tasks/pending-prospect-validation-tasks',
      ...params
    });
  }
  public static getPagedProspectAssignments(
    params: Omit<TaskServiceParameters.GetPagedTasks.Parameters, 'url'>
  ): Promise<TaskServiceParameters.GetPagedTasks.ReturnType> {
    return this.getPagedTasks({
      url: '/api/v1/tasks/pending-prospect-assignment-tasks',
      ...params
    });
  }
}
