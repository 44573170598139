import React, { useCallback, useState } from 'react'
import useEffectAfterMount from './useEffectAfterMount';
import { UsePagedTask } from '../../types/hooks';
import { Task } from '../../types/model';
import FollowupServices from '../services/FollowupServices';

function useFollowupTask({
    page,
    perPage,
    filters,
    onFailure
}: UsePagedTask.Parameters) {
    const [__page, setPage] = useState<number>(page);
    const [__perPage] = useState<number>(perPage);
    const [__filters] = useState(filters);
    const [data, setData] = useState<(Task | null)[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffectAfterMount(() => {
        setIsLoading(true);
        const abortSignal = new AbortController();
        FollowupServices.getPagedFollowup(
            {
                page: __page,
                perPage: __perPage,
                filters: __filters,
                signal: abortSignal.signal
            }
        ).then((result) => {
            const {data, page_info} = result;
            setData((prev) => (page_info.page === 1 ? data : prev.concat(data)));
            setHasMore(page_info.hasMore);
            if(page_info.total_records)
                setTotalRecord(page_info.total_records);
            setIsLoading(false);
        })
        .catch((error) => {
            if(onFailure) {
                onFailure(error);
            }
        })
        return (() => {
            abortSignal.abort();
        })
    }, [__page, __perPage, __filters, onFailure])
    return {
        data,
        totalRecord,
        isLoading
    };
}

export default useFollowupTask