import axios from 'axios';
import classNames from 'classnames';
import { useQueryStates } from 'nuqs';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import Accordion from '../../../../../components/accordion';
import Loader from '../../../../../components/loader';
import useCurrentLocation from '../../../../../hooks/useCurrentLocation';
import useEffectAfterMount from '../../../../../hooks/useEffectAfterMount';
import useJcpPlanDsp from '../../../../../hooks/useJcpPlanDsp';
import useScroll from '../../../../../hooks/useScroll';
import ViewLayout from '../../../../../layout/view-layout';
import distanceCalc from '../../../../../utils/distanceCalc';
import ServerConnectorUtil from '../../../../../utils/ServerConnectorUtil';
import styles from './styles.module.css';

interface Breakdown {
  category: string;
  total_planned: number;
  total_visited: number;
}

interface Stats {
  tiv_breakdowns: Breakdown[];
  color_code_break_downs: Breakdown[];
  total_planned_villages: number;
  total_planned_no_of_days: number;
  total_planned_unique_villages: number;
  total_planned_unique_villages_visited_last_month: number;
}

const JcpPlanDspDetails: React.FC = () => {
  const { id } = useParams();

  const {
    latitude: currentLocationLatitude,
    longitude: currentLocationLongitude
  } = useCurrentLocation();

  const calculateDistance = useCallback(
    (
      latitude: string,
      longitude: string,
      currentLocationLatitude?: number,
      currentLocationLongitude?: number
    ) => {
      if (!currentLocationLatitude || !currentLocationLongitude) {
        return 'NA';
      } else {
        const distance = distanceCalc.calculateDistance(
          currentLocationLatitude,
          currentLocationLongitude,
          parseFloat(latitude),
          parseFloat(longitude)
        );

        if (distance > 50) {
          return '50+ KM';
        } else {
          return distance.toFixed(2) + ' KM';
        }
      }
    },
    []
  );

  const [{ month, year, dsp_name }] = useQueryStates(
    {
      month: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      year: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      dsp_name: {
        defaultValue: '',
        parse: (value) => value || ''
      }
    },
    {
      history: 'replace'
    }
  );
  const [isFetching, setIsFetching] = useState(false);
  const [stats, setStats] = useState<Stats>();

  useEffectAfterMount(() => {
    setIsFetching(true);

    const params = new URLSearchParams();
    params.append('month', month);
    params.append('year', year);

    axios
      .get(`/api/v1/dsp-users/${id}/jc-planning-report?${params.toString()}`)
      .then((response) => {
        const { data } = response.data;
        setStats(data);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsFetching(false);
      });
  }, [id]);

  const { data, isLoading, loadMoreRecords } = useJcpPlanDsp({
    id: id ? id : '',
    month,
    year,
    page: 1,
    perPage: 200
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  return (
    <ViewLayout title="DSP Details">
      <div className={styles['dsp-body']}>
        {isLoading || isFetching ? (
          <div style={{ width: '100%', height: 'calc(100vh - 140px' }}>
            <Loader />
          </div>
        ) : (
          <>
            <Accordion className="jcp-accordion mt-20" setInitialOpen={true}>
              <Accordion.Header>
                <p className="dF align-center gap-4 text-16 font-600 color-black">
                  {dsp_name}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <table className="tafe-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned villages
                        </p>
                        <p className="text-18 color-black mt-5">
                          {stats?.total_planned_villages}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned no. of days
                        </p>
                        <p className="text-18 color-black mt-5">
                          {stats?.total_planned_no_of_days}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned unique villages
                        </p>
                        <p className="text-18 color-black mt-5">
                          {stats?.total_planned_unique_villages}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned unique villages - Visited LM
                        </p>
                        <p className="text-18 color-black mt-5">
                          {
                            stats?.total_planned_unique_villages_visited_last_month
                          }
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion>
            {stats && stats.color_code_break_downs.length > 0 && (
              <div className="tafe-card mb-20">
                <h2 className="text-14 color-secondary mt-15 p-16 bb-primary-10">
                  BRYG
                </h2>
                <table className="dsp-table">
                  <tr>
                    <th>Village category</th>
                    <th>Planned</th>
                    <th>Visited</th>
                  </tr>
                  {stats.color_code_break_downs.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className={classNames(`${item.category}-dot m-auto`)}
                        ></div>
                      </td>
                      <td>{item.total_planned}</td>
                      <td>{item.total_visited}</td>
                    </tr>
                  ))}
                </table>
              </div>
            )}

            <div className="tafe-card mb-20">
              <h2 className="text-14 color-secondary mt-15 p-16 bb-primary-10">
                HTIV/LTIV
              </h2>
              <table className="dsp-table">
                <tr>
                  <th>Category</th>
                  <th>Planned</th>
                  <th>Visited</th>
                </tr>
                {stats?.tiv_breakdowns.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p className="text-14">{item.category}</p>
                    </td>
                    <td>{item.total_planned}</td>
                    <td>{item.total_visited}</td>
                  </tr>
                ))}
              </table>
            </div>
            {data.length > 0 && (
              <div
                style={{ height: '300px', overflow: 'auto' }}
                onScroll={onScrollEventListener}
              >
                {data.map((item, index) => (
                  <div className="tafe-card p-14 mb-10" key={index}>
                    <div className="dF direction-column gap-8">
                      <div className="dF align-center gap-8">
                        <h3 className="text-14 font-500 color-black">
                          {item.village.name}
                        </h3>
                        <p className="text-14 font-400 color-black-70 ls-028">
                          Type :
                          <span className="font-600 color-primary">
                            {item.village.color_code || 'NA'}
                          </span>
                        </p>
                        <p className="text-14 color-primary">
                          {item.checked_in ? 'Visited' : ''}
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          {item.created_time.split('T')[0]}
                        </p>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          {calculateDistance(
                            item.village.latitude?.toString(),
                            item.village.longitude?.toString(),
                            currentLocationLatitude,
                            currentLocationLongitude
                          )}
                        </p>
                        <div className="separate-dot"></div>
                        <p className="text-14 color-black-70 ls-028">
                          TIV: {item.village.tiv || 'NA'}
                        </p>
                      </div>
                      <div className="dF align-center gap-8">
                        <p className="text-14 color-black-70 ls-028">
                          {item.date}
                        </p>
                        <p className="text-14 color-black-70 ls-028">
                          Visits YTD: NA
                        </p>
                      </div>
                      <div className="dF align-center gap-4">
                        <p className="text-14 color-black-70 ls-028">
                          Activity :
                        </p>
                        <p className="text-14 color-black ls-028">
                          {item.plan_type}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles['dsp-footer']}>
        <button className="secondary-btn">Reject</button>
        <button className="primary-btn">
          <div className={styles['plus-icon']}></div>
          Approve
        </button>
      </div>
    </ViewLayout>
  );
};

export default JcpPlanDspDetails;
