import EmptyData from '../empty-data';

const ListEmpty: React.FC = () => {
  return (
    <div className="record-list-empty-container">
      <EmptyData />
    </div>
  );
};

export default ListEmpty;
