import { useCallback, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Loader from '../shared/components/loader';
import AppUrlConstants from '../shared/constants/AppUrlContanst';
import UserContext from '../shared/context/UserContext';
import UserAdditionalFields from '../shared/enums/UserAdditionalFields';
import UserRole from '../shared/enums/UserRole';
import useCurrentUser from '../shared/hooks/useCurrentUser';
import { User } from '../types/model';

const Validator: React.FC = () => {
  const navigate = useRef(useNavigate());
  const [additionalFields] = useState([
    UserAdditionalFields.DEALER_NAME,
    UserAdditionalFields.SKILL_LEVEL
  ]);
  const [userRole, setUserRole] = useState<string>();

  const onFailure = useCallback(() => {
    navigate.current(AppUrlConstants.LOGIN, {
      replace: true
    });
  }, []);

  const onSuccess = useCallback((user: User) => {
    if (
      user.role.name === UserRole.MF_TE_TM ||
      user.role.name === UserRole.EIC_AREA_INCHARGE
    ) {
      setUserRole('tetm');
      if (!window.location.hash.includes('tetm')) {
        navigate.current('tetm', {
          replace: true
        });
      }
    } else if (
      user.role.name === UserRole.MF_DEALER ||
      user.role.name === UserRole.EIC_DEALER
    ) {
      setUserRole('dealer');
      if (!window.location.hash.includes('dealer')) {
        navigate.current('dealer', {
          replace: true
        });
      }
    } else if (
      user.role.name === UserRole.MF_DEALER_SALES_MANAGER ||
      user.role.name === UserRole.EIC_DEALER_SALES_MANAGER
    ) {
      setUserRole('dsm');
      if (!window.location.hash.includes('dsm')) {
        navigate.current('dsm', {
          replace: true
        });
      }
    } else if (
      user.role.name === UserRole.MF_DSP ||
      user.role.name === UserRole.EIC_DSP
    ) {
      setUserRole('dsp');
      if (!window.location.hash.includes('dsp')) {
        navigate.current('dsp', {
          replace: true
        });
      }
    }
  }, []);

  const { data, isLoading } = useCurrentUser({
    onFailure,
    onSuccess,
    additionalFields
  });
  return (
    <>
      <UserContext.Provider value={{ user: { data, user_role: userRole } }}>
        {isLoading ? (
          <div style={{ height: 'calc(calc(var(--vh, 1vh) * 100))' }}>
            <Loader />
          </div>
        ) : (
          <Outlet />
        )}
      </UserContext.Provider>
    </>
  );
};

export default Validator;
