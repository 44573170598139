import { useCallback, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IOptions } from '../../../../types/global';
import { UsePagedTractorUserDatabasesHook } from '../../../../types/hooks';
import CallTemp from '../../../components/call-contact';
import RecordCollection from '../../../components/record-collection';
import WhatsappTemp from '../../../components/whatsapp-contact';
import usePagedTractorUserDatabases from '../../../hooks/usePagedTractorUserDatabases';
import useScroll from '../../../hooks/useScroll';
import ViewLayout from '../../../layout/view-layout';
import UserContext from '../../../context/UserContext';

const allDropDownOptions = [
  { value: 'name', label: 'Name' },
  { value: 'mobile_number', label: 'Mobile Number' }
];

type Params = {
  type?: string;
  name?: string;
  village_name?: string;
  tractor_model?: string;
  mobile_number?: string;
};

const TudbMaster = () => {
  const { user } = useContext(UserContext);

  const [searchParams] = useSearchParams();

  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const [initialFilters] = useState<UsePagedTractorUserDatabasesHook.Filters>(
    () => {
      const initialFilters: UsePagedTractorUserDatabasesHook.Filters = {
        type: searchParams.get('type') || undefined,
        name: searchParams.get('name') || undefined,
        village_name: searchParams.get('village_name') || undefined,
        tractor_model: searchParams.get('tractor_model') || undefined,
        mobile_number: searchParams.get('mobile_number') || undefined,
        village_color_code: searchParams.get('village_color_code') || undefined,
        is_satisfied_with_his_tractor:
          searchParams.get('is_satisfied_with_his_tractor') || undefined
      };
      const createdTimeStartValue = searchParams.get(
        'created_time_start_value'
      );
      if (createdTimeStartValue) {
        initialFilters.created_time_start_value = parseInt(
          createdTimeStartValue
        );
      }
      const createdTimeEndValue = searchParams.get('created_time_end_value');
      if (createdTimeEndValue) {
        initialFilters.created_time_end_value = parseInt(createdTimeEndValue);
      }

      return initialFilters;
    }
  );

  const {
    data,
    isLoading,
    isLoadingMore,
    setFilters,
    updateFilterKey,
    loadMoreRecords
  } = usePagedTractorUserDatabases({
    page: 1,
    perPage: 200,
    filters: initialFilters
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(
        searchKey as keyof UsePagedTractorUserDatabasesHook.Filters,
        value
      );
    },
    [searchKey, updateFilterKey]
  );

  const [dropDownOptions] = useState<IOptions[]>(() => {
    const keysToExclude = Object.keys(initialFilters).filter(
      (key): key is keyof Params => Boolean(initialFilters[key as keyof Params])
    ) as string[];
    const filteredOptions = allDropDownOptions.filter(
      (option) => !keysToExclude.includes(option.value)
    );
    return filteredOptions;
  });

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue('');
      setSearchLabel(value.label.toLowerCase());
      setFilters(initialFilters);
    },
    [initialFilters, setFilters]
  );

  return (
    <ViewLayout title="Tudb Master">
      <RecordCollection>
        <RecordCollection.DropdownSearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={dropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            <>
              {data.map((item) => (
                <RecordCollection.ListItemWrapper
                  key={item.id}
                  to={`/${user.user_role}/tudb/${item.id}?title=Tudb Details`}
                >
                  <RecordCollection.ListItemLink />
                  <RecordCollection.ListItem>
                    <RecordCollection.ListItemTitle title={item.name} />
                    <RecordCollection.ListItemKeyValue
                      label="type"
                      value={item.type}
                    />
                    <RecordCollection.ListItemKeyValue
                      label="status"
                      value={item.status}
                    />
                    <RecordCollection.ListItemKeyValue
                      label="Source"
                      value={item.source}
                    />
                    <RecordCollection.ListItemKeyValue
                      label="Village"
                      value={item.village}
                    />
                    <RecordCollection.ListItemKeyValue
                      label="Model"
                      value={item.tractor.model}
                    />
                    <RecordCollection.ListItemKeyValue
                      label="Contact"
                      value={`+91 ${item.mobile_number}`}
                    />
                    <RecordCollection.ListAction>
                      <CallTemp
                        className="prospect-contact"
                        phoneNumber={item.mobile_number}
                      />
                      <WhatsappTemp phoneNumber={item.mobile_number} />
                    </RecordCollection.ListAction>
                  </RecordCollection.ListItem>
                </RecordCollection.ListItemWrapper>
              ))}
              {isLoadingMore && <RecordCollection.LoadMoreLoader />}
            </>
          )}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default TudbMaster;
