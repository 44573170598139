import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import View from "../../enums/View";
import { useCreateJcPlanningContext } from "../../hooks/useCreateJcPlanningContext";
import { CreateJcPlanningForm } from "../../types";
import { IOptions } from "../../../../types/global";
import ServerConnectorUtil from "../../../../shared/utils/ServerConnectorUtil";
import { useFormContext } from "../../../../shared/hooks/useFormContext";
import Button from "../../../../shared/components/button";
import ViewLayout from "../view-layout";
import JcPlanningService from "../../../../shared/services/JcPlanningService";
import FormComponents from "../../../../shared/components/form-components";
import { useTranslation } from "react-i18next";


const FormView: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { values, errors, setValue, setError, clearErrors } =
    useFormContext<CreateJcPlanningForm>();
  const { planTypeOptions, goToView, cancelOperation } =
    useCreateJcPlanningContext();

  const onPlanTypeChange = useCallback(
    (option: IOptions | undefined) => {
      setValue("planType", option);
    },
    [setValue]
  );

  const onAnchorVillageClick = useCallback(() => {
    goToView(View.SELECT_ANCHOR_VILLAGE_VIEW);
  }, [goToView]);

  const onNonAnchorVillageClick = useCallback(() => {
    if (!values.anchorVillage) {
      setError("anchorVillage", `${t('dsp.create_jcp.errors.select_anchor_village')}`);
      return;
    }
    goToView(View.SELECT_NON_ANCHOR_VILLAGES_VIEW);
  }, [setError, goToView, values.anchorVillage]);

  const createJcPlanning = useCallback(() => {
    clearErrors();
    if (!values.planType) {
      setError("planType", t('dsp.create_jcp.errors.plan_type_empty'));
      return;
    }
    if (!values.anchorVillage) {
      setError("anchorVillage", t('dsp.create_jcp.errors.anchor_village_empty'));
      return;
    }
    if (!values.nonAnchorVillages || values.nonAnchorVillages.length === 0) {
      setError("nonAnchorVillages", t('dsp.create_jcp.errors.non_anchor_village_empty'));
    }

    setIsProcessing(true);
    JcPlanningService.createJcPlannings({
      data: values
        .nonAnchorVillages!.map((item) => ({
          date: values.date as number,
          planType: values.planType!.value,
          villageId: item.id
        }))
        .concat([
          {
            date: values.date as number,
            planType: values.planType!.value,
            villageId: values.anchorVillage!.id
          }
        ])
    })
      .then(() =>
        goToView(View.SUMMARY)
      )
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => setIsProcessing(false));
  }, [values, navigate, setError, clearErrors, goToView]);

  return (
    <ViewLayout>
      <ViewLayout.Body className={styles["container"]}>
        <FormComponents>
          <FormComponents.InputSelect
            label={t('dsp.create_jcp.form.plan_type')}
            options={planTypeOptions}
            defaultValue={values.planType}
            placeholder={t('dsp.create_jcp.form.plan_type_placeholder')}
            onSelect={onPlanTypeChange}
            error={errors.planType}
          />
          <div className="w100p mt-15">
            <p className="text-14 font-500 mb-4">{t('dsp.create_jcp.form.anchor')}</p>
            <div className={`p-10 b-black-10 br-8 text-16 ${values.anchorVillage === null ? 'color-black-40' : 'color-black'}`} onClick={onAnchorVillageClick}>
              {values.anchorVillage?.name || t('dsp.create_jcp.errors.select_anchor_village')}
            </div>
            {
              errors.anchorVillage && <p className="text-12 color-primary">{errors.anchorVillage}</p>
            }
          </div>
          <div className="w100p mt-15">
            <p className="text-14 font-500 mb-4">{t('dsp.create_jcp.form.villages')}</p>
            <div className={`p-10 b-black-10 br-8 text-16 ${values.nonAnchorVillages === null ? 'color-black-40' : 'color-black'}`} onClick={onNonAnchorVillageClick}>
              {values.nonAnchorVillages?.map((item) => item.name).join(" , ") ||
                t('dsp.create_jcp.form.non_anchor_placeholder')}
            </div>
            {
              errors.nonAnchorVillages && <p className="text-12 color-primary">{errors.nonAnchorVillages}</p>
            }
          </div>
        </FormComponents>
      </ViewLayout.Body>
      <ViewLayout.Footer>
        <Button label={t('common.cancel')} variant="secondary" onClick={cancelOperation} />
        <Button
          label={t('common.submit')}
          variant="primary"
          isLoading={isProcessing}
          onClick={createJcPlanning}
          disabled={
            !values.planType ||
            !values.anchorVillage ||
            !values.nonAnchorVillages ||
            !values.nonAnchorVillages.length
          }
        />
      </ViewLayout.Footer>
    </ViewLayout>
  );
};

export default FormView;
