import React from 'react';
import styles from './styles.module.css';

interface LoaderProps {
  size?: 'small' | 'medium' | 'large';
}

const Loader: React.FC<LoaderProps> = ({ size = 'large' }) => {
  return (
    <div className={styles['loader-container']}>
      <div className={`${styles['loader']} ${styles[size]}`}></div>
    </div>
  );
};

export default Loader;
