import axios from 'axios';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldEngine, Rule } from '../../../../shared/utils/FieldEngine';
import { IOptions } from '../../../../types/global';
import { Prospect } from '../../../../types/model';
import FormComponents from '../../../components/form-components';
import Loader from '../../../components/loader';
import UserContext from '../../../context/UserContext';
import SuccessLayout from '../../../layout/success-layout';
import ViewLayout from '../../../layout/view-layout';
import ServerConnectorUtil from '../../../utils/ServerConnectorUtil';

interface Fields {
  label: string;
  name: string;
  type: string;
  options: IOptions[];
  section_name: string;
}
interface IEnquiryData {
  contact_mobile_number: string | null;
  contact_name: string | null;
  contact_taluka: string | null;
  contact_village: string | null;
  contact_district: string | null;
  contact_state: string | null;
  enquiry_category: string | null;
  sog: string | null;
  stage: string | null;
  interested_model_family: string | null;
  next_follow_up_in_days: string | null;
  expected_date_of_delivery_in_days: string | null;
  has_exchange: string | null;
  payment_mode: string | null;
}

const regex = {
  phoneRegex: /^\d{10}$/
};

const EditEnquiry: React.FC = () => {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [showSuccessLayout, setShowSuccessLayout] = useState(false);
  const [isFetching, setIsFetching] = useState({
    meta: false,
    taluk: false,
    village: false,
    prospect: false,
    layoutRule: false
  });
  const [submitting, setSubmitting] = useState(false);

  const [enquiryData, setEnquiryData] = useState<IEnquiryData>({
    contact_mobile_number: null,
    contact_name: null,
    contact_taluka: null,
    contact_village: null,
    contact_district: null,
    contact_state: null,
    enquiry_category: null,
    sog: null,
    stage: null,
    interested_model_family: null,
    next_follow_up_in_days: null,
    expected_date_of_delivery_in_days: null,
    has_exchange: 'No',
    payment_mode: null
  });
  const [fields, setFields] = useState<Fields[]>([]);
  const [prospect, setProspect] = useState<Prospect>();
  const [layoutRule, setLayoutRule] = useState<Rule[]>([]);
  const [extraFields, setExtraFields] = useState<any[]>([]);

  const [errors, setErrors] = useState({
    contact_mobile_number: '',
    contact_name: '',
    contact_taluka: '',
    contact_village: '',
    enquiry_category: '',
    sog: '',
    interested_model_family: '',
    next_follow_up_in_days: '',
    expected_date_of_delivery_in_days: '',
    has_exchange: '',
    payment_mode: ''
  });

  // Initial Fetch API
  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      meta: true
    }));
    axios
      .get('/api/v1/prospects/meta/fields')
      .then((response) => {
        const { data } = response.data;
        setFields(data);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsFetching((prevState) => ({
          ...prevState,
          meta: false
        }));
      });
  }, []);

  useEffect(() => {
    if (id) {
      setIsFetching((prevState) => ({
        ...prevState,
        prospect: true
      }));
      axios
        .get(`/api/v1/prospects/${id}`)
        .then((response) => {
          const { data } = response.data;
          setProspect(data);
          setEnquiryData({
            contact_mobile_number: data.contact.mobile_number,
            contact_name: data.contact.name,
            contact_taluka: data.contact.taluka,
            contact_village: data.contact.village,
            contact_district: data.contact.district,
            contact_state: data.contact.state,
            enquiry_category: data.enquiry_category,
            sog: data.sog,
            stage: data.stage,
            interested_model_family: data.interested_model_family,
            next_follow_up_in_days: data.next_follow_up_in_days,
            expected_date_of_delivery_in_days:
              data.expected_date_of_delivery_in_days,
            has_exchange: data.has_exchange,
            payment_mode: data.payment_mode
          });
        })
        .catch(ServerConnectorUtil.handleServerError)
        .finally(() => {
          setIsFetching((prevState) => ({
            ...prevState,
            prospect: false
          }));
        });
    }
  }, [id]);

  useEffect(() => {
    setIsFetching((prevState) => ({
      ...prevState,
      layoutRule: true
    }));
    axios
      .get('/api/v1/prospects/meta/layout-rule-queries')
      .then((response) => {
        const { data } = response.data;
        setLayoutRule(data);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsFetching((prevState) => ({
          ...prevState,
          layoutRule: false
        }));
      });
  }, []);

  //Field Engine
  useEffect(() => {
    if (layoutRule.length && prospect) {
      const generatedFields: Array<{
        name: string;
        type: string;
        options: IOptions[];
        section_name: string;
        mandatory: boolean;
      }> = [];
      const engine = new FieldEngine(layoutRule);
      const result = engine.process(prospect);

      result.showFields.forEach((fieldName) => {
        const isMandatory = result.mandatoryFields.includes(fieldName);
        const fieldDetails = fields.find((f) => f.name === fieldName);
        if (fieldDetails) {
          generatedFields.push({
            ...fieldDetails,
            mandatory: isMandatory
          });
        }
      });

      result.showSections.forEach((sectionName) => {
        const sectionFields = fields.filter(
          (obj) => obj.section_name === sectionName
        );

        sectionFields.forEach((sectionField) => {
          const tempField = generatedFields.find(
            (obj) => obj.name === sectionField.name
          );

          if (tempField === undefined) {
            const isMandatory = result.mandatoryFields.includes(
              sectionField.name
            );
            const fieldDetails = fields.find(
              (f) => f.name === sectionField.name
            );
            if (fieldDetails) {
              generatedFields.push({
                ...fieldDetails,
                mandatory: isMandatory
              });
            }
          }
        });
      });

      setExtraFields(generatedFields);
    }
  }, [layoutRule, prospect, fields]);
  useEffect(() => {
    const generatedFields: Array<{
      name: string;
      type: string;
      options: IOptions[];
      section_name: string;
      mandatory: boolean;
    }> = [];
    const engine = new FieldEngine(layoutRule);
    const result = engine.process(enquiryData);

    result.showFields.forEach((fieldName) => {
      const isMandatory = result.mandatoryFields.includes(fieldName);
      const fieldDetails = fields.find((f) => f.name === fieldName);
      if (fieldDetails) {
        generatedFields.push({
          ...fieldDetails,
          mandatory: isMandatory
        });
      }
    });

    result.showSections.forEach((sectionName) => {
      const sectionFields = fields.filter(
        (obj) => obj.section_name === sectionName
      );

      sectionFields.forEach((sectionField) => {
        const tempField = generatedFields.find(
          (obj) => obj.name === sectionField.name
        );

        if (tempField === undefined) {
          const isMandatory = result.mandatoryFields.includes(
            sectionField.name
          );
          const fieldDetails = fields.find((f) => f.name === sectionField.name);
          if (fieldDetails) {
            generatedFields.push({
              ...fieldDetails,
              mandatory: isMandatory
            });
          }
        }
      });
    });

    setExtraFields(generatedFields);
  }, [enquiryData]);
  //Field Engine end

  const handleValueChange = useCallback((name: string, value: string) => {
    setEnquiryData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: ''
    }));
  }, []);

  const handleOptionChange = useCallback(
    (option: IOptions | undefined, name: string | undefined) => {
      if (option) {
        if (name) {
          setEnquiryData((prevState) => ({
            ...prevState,
            [name]: option.value
          }));
          setErrors((prev) => ({
            ...prev,
            [name]: ''
          }));
        }
      }
    },
    []
  );

  const handleRadioOptionChange = useCallback(
    (name: string, option: { label: string; value: string }) => {
      if (option) {
        setEnquiryData((prevState) => ({
          ...prevState,
          [name]: option.value
        }));
      }
    },
    []
  );

  const validateForm = useCallback(() => {
    const newErrors = {} as typeof errors;

    if (
      !enquiryData.contact_mobile_number ||
      !regex.phoneRegex.test(enquiryData.contact_mobile_number)
    ) {
      newErrors.contact_mobile_number = 'Invalid Mobile';
    }
    if (!enquiryData.contact_name) newErrors.contact_name = 'Name is required';
    if (!enquiryData.contact_taluka)
      newErrors.contact_taluka = 'Taluka is required';
    if (!enquiryData.contact_village)
      newErrors.contact_village = 'Village is required';
    if (!enquiryData.enquiry_category)
      newErrors.enquiry_category = 'Enquiry category is required';
    if (!enquiryData.sog) newErrors.sog = 'SOG is required';
    if (!enquiryData.interested_model_family)
      newErrors.interested_model_family = 'Interested Model Family is required';
    if (!enquiryData.payment_mode)
      newErrors.payment_mode = 'Payment Mode is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    return true;
  }, [enquiryData]);

  const updateEnquiry = useCallback(() => {
    if (!validateForm()) {
      return;
    }

    setSubmitting(true);
    axios
      .put(`/api/v1/prospect/${id}`, enquiryData)
      .then(() => {
        setShowSuccessLayout(true);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setSubmitting(false);
      });
  }, [enquiryData]);

  const goToEnquiry = useCallback(() => {
    navigate(`/${user.user_role}/main/enquiries/enquiry`, {
      replace: true
    });
  }, [navigate]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const renderFilterInput = (field: Fields) => {
    switch (field.type) {
      case 'textarea':
        return (
          <FormComponents.InputText
            type="text"
            key={field.name}
            label={field.label}
            name={field.name}
            placeholder={`Enter ${field.label}`}
            value={enquiryData[field.name as keyof IEnquiryData] || ''}
            onChange={handleValueChange}
          />
        );
      case 'integer':
        return (
          <FormComponents.InputText
            type="number"
            key={field.name}
            label={field.label}
            placeholder={`Enter ${field.label}`}
            name={field.name}
            value={enquiryData[field.name as keyof IEnquiryData] || ''}
            onChange={handleValueChange}
          />
        );
      case 'picklist':
        return (
          <FormComponents.InputSelect
            key={field.name}
            label={field.label}
            name={field.name}
            placeholder={`Select ${field.label}`}
            options={field.options || []}
            defaultValue={
              enquiryData[field.name as keyof IEnquiryData]
                ? {
                    label: enquiryData[field.name as keyof IEnquiryData] || '',
                    value: enquiryData[field.name as keyof IEnquiryData] || ''
                  }
                : null
            }
            onSelect={(option) => handleOptionChange(option, field.name)}
          />
        );
      case 'date':
        return (
          <FormComponents.InputDate
            key={field.name}
            label={field.label}
            name={field.name}
            value={enquiryData[field.name as keyof IEnquiryData] as string}
            onChange={handleValueChange}
          />
        );
      default:
        return null;
    }
  };

  console.log(enquiryData);

  return (
    <>
      {showSuccessLayout ? (
        <SuccessLayout
          title="Congratulations!"
          desc="Enquiry updated successfully."
          button_icon="add"
          button_name="Back to Enquiry"
          onClick={goToEnquiry}
        />
      ) : (
        <ViewLayout title="Edit Enquiry">
          {isFetching.meta || isFetching.prospect ? (
            <Loader />
          ) : (
            <>
              <div className="tafe-form-body">
                <FormComponents>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputText
                      label="Mobile"
                      type="number"
                      name="contact_mobile_number"
                      placeholder="Mobile"
                      disabled
                      value={enquiryData.contact_mobile_number || ''}
                    />
                    <FormComponents.InputText
                      label="Name"
                      placeholder="Name"
                      name="contact_name"
                      disabled
                      value={enquiryData.contact_name || ''}
                    />
                  </FormComponents.FormFlexWrapper>
                  {prospect?.contact.name && (
                    <div className="mb-20">
                      <FormComponents.InputText
                        label="Contact Name"
                        value={prospect?.contact.name}
                        disabled={true}
                      />
                    </div>
                  )}
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputSelect
                      label="Taluka"
                      name="contact_taluka"
                      placeholder="Select Taluka"
                      defaultValue={
                        enquiryData.contact_taluka
                          ? {
                              label: enquiryData.contact_taluka,
                              value: enquiryData.contact_taluka
                            }
                          : null
                      }
                      isDisabled={true}
                      options={[]}
                    />
                    <FormComponents.InputSelect
                      label="Village"
                      name="contact_village"
                      placeholder="Select Village"
                      defaultValue={
                        enquiryData.contact_village
                          ? {
                              label: enquiryData.contact_village,
                              value: enquiryData.contact_village
                            }
                          : null
                      }
                      isDisabled={true}
                      options={[]}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputText
                      label={`${user.data.company_code?.toUpperCase()} BRYG`}
                      value={prospect?.contact.color_code || ''}
                      disabled={true}
                    />
                    <FormComponents.InputText
                      label={`${user.data.company_code?.toUpperCase()} TIV`}
                      value={prospect?.contact.tiv || ''}
                      disabled={true}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    {user.user_role !== 'tetm' && (
                      <FormComponents.InputText
                        label="Dealer Code"
                        value={user.data.dealer_code}
                        disabled={true}
                      />
                    )}
                    <FormComponents.InputSelect
                      label="SOG"
                      name="sog"
                      placeholder="Select SOG"
                      defaultValue={
                        enquiryData.sog
                          ? {
                              label: enquiryData.sog,
                              value: enquiryData.sog
                            }
                          : null
                      }
                      options={[]}
                      isDisabled={true}
                      onSelect={handleOptionChange}
                      error={errors.sog}
                    />
                  </FormComponents.FormFlexWrapper>
                  {user.user_role !== 'tetm' && (
                    <div className="w100p mb-20">
                      <p className="text-14 font-500 mb-4">Dealer Name</p>
                      <div className="dealer-name-box">
                        {user.data.dealer_name}
                      </div>
                    </div>
                  )}
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputSelect
                      label="Enquiry Category"
                      name="enquiry_category"
                      placeholder="Select Enquiry Category"
                      defaultValue={
                        enquiryData.enquiry_category
                          ? {
                              label: enquiryData.enquiry_category,
                              value: enquiryData.enquiry_category
                            }
                          : null
                      }
                      options={
                        fields.find((obj) => obj.name === 'enquiry_category')
                          ?.options || []
                      }
                      onSelect={handleOptionChange}
                      error={errors.enquiry_category}
                    />
                    <FormComponents.InputSelect
                      label="Interested Model Family"
                      name="interested_model_family"
                      placeholder="Interested Model Family"
                      defaultValue={
                        enquiryData.interested_model_family
                          ? {
                              label: enquiryData.interested_model_family,
                              value: enquiryData.interested_model_family
                            }
                          : null
                      }
                      options={
                        fields.find(
                          (obj) => obj.name === 'interested_model_family'
                        )?.options || []
                      }
                      onSelect={handleOptionChange}
                      error={errors.interested_model_family}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputText
                      type="number"
                      label="Next Followup (In Days)"
                      name="next_follow_up_in_days"
                      placeholder="Next Followup (In Days)"
                      value={enquiryData.next_follow_up_in_days || ''}
                      onChange={handleValueChange}
                      error={errors.next_follow_up_in_days}
                    />
                    <FormComponents.InputText
                      type="number"
                      label="Expected Date of Delivery (Days)"
                      name="expected_date_of_delivery_in_days"
                      placeholder="Expected Date of Delivery (Days)"
                      value={
                        enquiryData.expected_date_of_delivery_in_days || ''
                      }
                      onChange={handleValueChange}
                      error={errors.expected_date_of_delivery_in_days}
                    />
                  </FormComponents.FormFlexWrapper>
                  <FormComponents.FormFlexWrapper>
                    <FormComponents.InputRadio
                      label="Exchange"
                      name="has_exchange"
                      selectedValue={{
                        label: enquiryData.has_exchange || '',
                        value: enquiryData.has_exchange || ''
                      }}
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' }
                      ]}
                      onChange={handleRadioOptionChange}
                    />
                    <FormComponents.InputSelect
                      label="Payment Mode"
                      name="payment_mode"
                      placeholder="Payment Mode"
                      defaultValue={
                        enquiryData.payment_mode
                          ? {
                              label: enquiryData.payment_mode,
                              value: enquiryData.payment_mode
                            }
                          : null
                      }
                      options={
                        fields.find((obj) => obj.name === 'payment_mode')
                          ?.options || []
                      }
                      onSelect={handleOptionChange}
                      error={errors.payment_mode}
                    />
                  </FormComponents.FormFlexWrapper>
                </FormComponents>
                <div className="form-row" style={{ flexWrap: 'wrap' }}>
                  <FormComponents.InputSelect
                    label="stage"
                    name="stage"
                    placeholder="Select Stage"
                    defaultValue={
                      enquiryData.stage
                        ? {
                            label: enquiryData.stage,
                            value: enquiryData.stage
                          }
                        : null
                    }
                    options={
                      fields.find((obj) => obj.name === 'stage')?.options || []
                    }
                    onSelect={handleOptionChange}
                    error={errors.interested_model_family}
                  />
                  {extraFields.map((field) => (
                    <>{renderFilterInput(field)}</>
                  ))}
                </div>
              </div>
              <div className="tafe-form-footer">
                <button className="secondary-btn" onClick={goBack}>
                  Cancel
                </button>
                <button
                  className={classNames('primary-btn pR')}
                  disabled={submitting}
                  // onClick={updateEnquiry}
                >
                  <div className={classNames(submitting && 'vH')}>Save</div>
                  <div className={classNames('pA', !submitting && 'vH')}>
                    <div className="dot-flashing"></div>
                  </div>
                </button>
              </div>
            </>
          )}
        </ViewLayout>
      )}
    </>
  );
};

export default EditEnquiry;
