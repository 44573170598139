export default class AppUrlConstants {
  public static readonly LOGIN = 'login';
  public static readonly TETM = class {
    public static readonly BASE_NAME = 'tetm';
    public static readonly HOME = 'home';
    public static readonly ENQUIRIES = class {
      public static readonly ENQUIRY = 'enquiry';
      public static readonly TUDB = 'tudb';
      public static readonly PROSPECTS = 'prospects';
    };
    public static readonly PROSPECTS = 'prospects';
    public static readonly DSP = 'dsp';
    public static readonly DASHBOARD = 'dashboard';
    public static readonly MENU = 'menu';
  };
  public static readonly DSP = class {
    public static readonly BASE_NAME = 'dsp';
    public static readonly MENU = 'menu';
    public static readonly DASHBOARD = 'dashboard';
    public static readonly HOME = 'home';
    public static readonly JCP = 'jcp';
    public static readonly ENQUIRIES = class {
      public static readonly BASE_NAME = 'enquiries';
      public static readonly ENQUIRY = 'enquiry';
      public static readonly TUDB = 'tudb';
      public static readonly PROSPECTS = 'prospects';
    };
    public static readonly CHECKIN = class {
      public static readonly BASE_NAME = 'check-in'
      public static readonly CATEGORIES = 'categories';
      public static readonly JCPS = 'jcps';
      public static readonly JCP = 'jcp';
      public static readonly JCP_ID = '/:jcpId'
    }
    public static readonly CREATE_JCP = class {
      public static readonly BASE_NAME = 'create-jcp';
      public static readonly SELECT_DATE = 'select-date';
      public static readonly FORM = 'form';
      public static readonly SELECT_ANCHOR_VILLAGE = "select-anchor-village";
      public static readonly SELECT_NON_ANCHOR_VILLAGES = "select-non-anchor-villages";
    }
  }
}
