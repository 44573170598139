enum UserAdditionalFields {
  DEALER_NAME = "dealer_name",
  SKILL_LEVEL = 'skill_level',
  STATS_TOTAL_LEADS = 'stats.total_leads',
  STATS_TOTAL_PROSPECTS = 'stats.total_prospects',
  STATS_TOTAL_DELIVERIES = 'stats.total_deliveries',
  STATS_TOTAL_JCP_VISITED = 'stats.total_jcp_visited',
  STATS_TOTAL_JCP_APPROVED = 'stats.total_jcp_approved',
  STATS_TOTAL_HOT_PROSPECTS = 'stats.total_hot_prospects',
  STATS_TOTAL_OVERDUE_HOT_PROSPECTS = 'stats.total_overdue_hot_prospects',
  STATS_TOTAL_CHECK_IN_IN_LAST_FIVE_DAYS = 'stats.total_check_in_in_last_five_days',
  STATS_TOTAL_TRACTOR_USER_DATABASE_ADDED = 'stats.total_tractor_user_database_added',
  VILLAGE_STATS_TOTAL_VISITS_YEAR_TILL_DATE = 'village.stats.total_visits_year_till_date'
}

export default UserAdditionalFields;
