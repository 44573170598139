import Loader from '../loader';

const LoadMoreLoader: React.FC = () => {
  return (
    <div style={{ width: '50px', height: '50px', margin: '14px auto' }}>
      <Loader size="small" />
    </div>
  );
};

export default LoadMoreLoader;
