import ToastTypeEnum from '../enums/ToastTypeEnum';
import ToastService from '../services/ToastService';

export default class ToastUtil {
  public static makeInfoToast(message: string): void {
    ToastService.makeToast(ToastTypeEnum.INFO, message);
  }
  public static makeSuccessToast(message: string): void {
    ToastService.makeToast(ToastTypeEnum.SUCCESS, message);
  }

  public static makeFailureToast(message: string): void {
    ToastService.makeToast(ToastTypeEnum.FAILURE, message);
  }
}
