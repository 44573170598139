import React, { HTMLAttributes } from 'react'
import style from './index.module.css'
import Header from './header';
import Content from './content';
import Footer from './footer';
import Close from './close';
import Loader from './loader';
type ModalDataProps = {
    Header: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>>;
    Content: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>>;
    Footer: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>>;
    Close: React.FC<HTMLAttributes<SVGSVGElement>>;
    Loader: React.FC;
}
const ModalData: React.FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>> & ModalDataProps = ({children, className, ...props}) => {
    return (
        <>
            <div className={`bg-black-30 ${style['freeze']}`}/>
            <div className={`dF direction-column br-12 bg-white ${style['modal']} ${className}`} {...props}>
                {children}
            </div>
        </>
    )
}
ModalData.Header = Header;
ModalData.Content = Content;
ModalData.Footer = Footer;
ModalData.Close = Close;
ModalData.Loader = Loader;

export default ModalData