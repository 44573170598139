import { Link } from 'react-router-dom';

export type ListItemWrapperProps = {
  to?: string;
  children: React.ReactNode;
};

const ListItemWrapper: React.FC<ListItemWrapperProps> = ({ to, children }) => {
  return to ? (
    <>
      <Link to={to} className="list-item-wrapper">
        <div className="record-list-item-wrapper">{children}</div>
      </Link>
    </>
  ) : (
    <div className="record-list-item-wrapper">{children}</div>
  );
};

export default ListItemWrapper;
