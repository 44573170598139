import { Framework } from "../../types/global";
import { Task } from "../../types/model";
import { FollowupServicesParameters } from "../../types/parameters";
import APIRequest from "../constants/APIRequest";
import RequestMethod from "../enums/RequestMethod";
import RequestHandler from "../handlers/RequestHandler";

export default class FollowupServices {
    public static async getPagedFollowup({
        page,
        perPage,
        signal,
        filters
    }: FollowupServicesParameters.getPagedFollowup): Promise<Framework.PagedResult<Task>> {
        const handler = new RequestHandler(
            APIRequest.FOLLOWUP_DATA,
            RequestMethod.GET
        )
        handler.addParam('page', page);
        handler.addParam('per_page', perPage);
        if(signal){
            handler.setAbortSignal(signal);
        }
        if(filters.statuses){
            handler.addParam('statuses', filters.statuses.join(","))
        }
        if(filters.type) {
            handler.addParam('type', filters.type)
        }
        return handler
            .execute()
            .then((result) => result.data as Framework.PagedResult<Task>)
    }
}
