enum UserRole {
    MF_DSP = 'MF-DSP',
    EIC_DSP = 'EIC DSP',
    MF_TE_TM = 'MF TE/TM',
    MF_DEALER = 'MF Dealer',
    EIC_DEALER = 'EIC Dealer',
    EIC_AREA_INCHARGE = 'EIC Area Incharge',
    MF_DEALER_SALES_MANAGER = 'MF-Dealer Sales Manager',
    EIC_DEALER_SALES_MANAGER = 'EIC Dealer Sales Manager',
}

export default UserRole;
