import styles from "./styles.module.css";

import classNames from "classnames";
import React, { useCallback } from "react";
import Checkbox from "../../../../../../shared/components/Checkbox";
import { VILLAGE } from "../../../../../../types/model";
import { useTranslation } from "react-i18next";

const NonAnchorVillage: React.FC<{
  village: VILLAGE;
  checked: boolean;
  disabled: boolean;
  addVillage: (village: VILLAGE) => void;
  removeVillage: (village: VILLAGE) => void;
}> = ({ checked, disabled, village, addVillage, removeVillage }) => {
  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        addVillage(village);
      } else {
        removeVillage(village);
      }
    },
    [village, addVillage, removeVillage]
  );
  const {t} = useTranslation();
  return (
    <div className={styles["list-item"]}>
      <Checkbox
        checked={checked}
        onChange={onCheckboxChange}
        disabled={!checked && disabled}
      />
      <div className={styles["content-wrapper"]}>
        <h6 className={`${styles["title"]} font-500`}>{village.name}</h6>
        <div className={styles["info-container"]}>
          <p className={styles["text"]}>
            {village.distance ? village.distance.toFixed(2) + ` ${t('common.kilo_meter')}` : "NA"}
          </p>
          <div className={styles["color-code-stat-container"]}>
            <div
              className={classNames(
                styles["dot"],
                village.color_code
                  ? styles[village.color_code.toLowerCase()]
                  : styles["na"]
              )}
            />
            <p className={styles["text"]}>
              {t('dsp.create_jcp.visit_ytd')} : {village.stats?.total_jcp_visits_year_till_date}
            </p>
          </div>
          <p className={styles["text"]}>
            {village.stats?.total_jcp_visits_year_till_date}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NonAnchorVillage;
