import React, { useCallback, useState } from 'react'
import DateFilter from '../../shared/components/date-filter'
import { useTranslation } from 'react-i18next'
import Accordion from '../../shared/components/accordion';
import style from './style.module.css';
import usePagedJcPlannings from '../../shared/hooks/usePagedJcPlannings';
import JcpStatuses from '../../shared/enums/JcpStatuses';
import UserAdditionalFields from '../../shared/enums/UserAdditionalFields';
import distanceCalc from '../../shared/utils/distanceCalc';
import useCurrentLocation from '../../shared/hooks/useCurrentLocation';
import UserService from '../../shared/services/UserService';
import { STAT } from '../../types/model';
import useEffectAfterMount from '../../shared/hooks/useEffectAfterMount';
import Loader from '../../shared/components/loader';
import ServerConnectorUtil from '../../shared/utils/ServerConnectorUtil';
import EmptyData from '../../shared/components/empty-data';
import Button from '../../shared/components/button';
import { Link } from 'react-router-dom';
import AppUrlConstants from '../../shared/constants/AppUrlContanst';
const JCP: React.FC = () => {
    const {t} = useTranslation();
    const [initialRequest, setInitialRequest] = useState<boolean>(true);
    const [stats, setStats] = useState<STAT | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [jcpFilters, setJcpFilters] = useState<{
        start: number,
        end: number
    } | undefined>()
    const {data: jcPlanningData, isLoading: jcPlanningLoading, updateFilters} = usePagedJcPlannings({
        page: 1,
        perPage: 200,
        filters: {
            statuses: [JcpStatuses.APPROVED],
            dateStartValue: jcpFilters?.start,
            dateEndValue: jcpFilters?.end,
            checkIn: false
        },
        additionalFields: [UserAdditionalFields.VILLAGE_STATS_TOTAL_VISITS_YEAR_TILL_DATE],
        onFailure: ServerConnectorUtil.handleServerError
    })
    useEffectAfterMount(() => {
        const statsControllar = new AbortController();
        if(initialRequest){
            setIsLoading(true);
            UserService.getUserMyStats({
                startDateTime: jcpFilters?.start,
                endDateTime: jcpFilters?.end
            })
            .then((stats) => {
                setStats(stats.data)
                setIsLoading(false);
            })
            .catch((error) => {
                if(error){
                    ServerConnectorUtil.handleServerError(error)
                }
            })
            setInitialRequest(false);
        }
        return () => {
            statsControllar.abort()
        }
    }, [initialRequest])
    const {
        latitude,
        longitude
      } = useCurrentLocation();
    const handleDateChange = useCallback((data: {
        dateRange: { start: number; end: number } | undefined;
    }) => {
        setJcpFilters(data.dateRange);
        updateFilters({
            dateStartValue: data.dateRange?.start,
            dateEndValue: data.dateRange?.end
        })
        setInitialRequest(true)
    }, [jcpFilters])
    return (
        <div className='m-20'>
            <DateFilter
                values={[
                    'All Time',
                    'Today',
                    'Yesterday',
                    'This week',
                    'Last week',
                    'This month',
                    'Last month',
                    'Last 3 months',
                    'This year',
                    'Custom range'
                  ]}
                  onChange={handleDateChange}
            />
            {
                (isLoading || jcPlanningLoading) ?
                    <div style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 218px)' }}>
                        <Loader/>
                    </div>
                :
                    <>
                        <div className='mt-20 bg-white p-12 br-12'>
                            <h3 className='text-14'>{t('dsp.jcp.village_visit_summary')}</h3>
                            <div className='mt-10'>
                            <table className="tafe-table">
                                <tbody className='b-black-70'>
                                    <tr>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('dsp.jcp.planned')}</p>
                                            <p className='text-18 color-black mt-5'>{stats?.total_jcp_approved}</p>
                                        </td>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('dsp.jcp.visited_td')}</p>
                                            <p className='text-18 color-black mt-5'>{stats?.total_jcp_visited}</p>
                                        </td>
                                        {/* <td>
                                            <p className='text-12 color-black-55 font-500'>{t('dsp.jcp.planned_td')}</p>
                                            <p className='text-18 color-black mt-5'>00</p>
                                        </td> */}
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('dsp.jcp.tu_added')}</p>
                                            <p className='text-18 color-black mt-5'>{stats?.total_tractor_user_database_added}</p>
                                        </td>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('dsp.jcp.enquiries_generated')}</p>
                                            <p className='text-18 color-black mt-5'>
                                                {
                                                    stats
                                                    &&
                                                    (stats.total_leads + stats.total_overdue_hot_prospects + stats.total_prospects)
                                                }
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('dsp.jcp.hps_generated')}</p>
                                            <p className='text-18 color-black mt-5'>{stats?.total_hot_prospects}</p>
                                        </td>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('common.deliveries')}</p>
                                            <p className='text-18 color-black mt-5'>{stats?.total_deliveries}</p>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <p className='text-12 color-black-55 font-500'>{t('common.deliveries')}</p>
                                            <p className='text-18 color-black mt-5'>{stats?.total_deliveries}</p>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                            </div>
                        </div>
                        <div className='mt-20 mb-80'>
                            <h3 className='text-14 mb-16'>{t('dsp.jcp.scheduled_visits')}</h3>
                            <div className={`br-12 bg-white ${jcPlanningData.length === 0 ? 'pb-10' : ''}`}>
                                {
                                    jcPlanningData.length === 0 ?
                                        <EmptyData/>
                                    :
                                    jcPlanningData.map((jcp) => (
                                        <Accordion className={style['jcp-accordion']}>
                                            <Accordion.Header>
                                                <div>
                                                    <div className='dF gap-8 align-center'>
                                                        <span className='text-14 font-500'>{jcp?.village.name ? jcp.village.name : `NA`}</span>
                                                        <span className={`dF text-12 gap-4 color-black-55`}>{t('common.village')}:<p className={`color-${jcp?.village.color_code} font-600`}> {jcp?.village.color_code ? jcp.village.color_code : `NA`}</p></span>
                                                    </div>
                                                    <div className='dF align-center gap-2 mt-7'>
                                                        <span className='text-12 font-600 color-black-55'>{jcp?.month} {new Date(jcp?.date as string).getDate()}</span>
                                                        <span className='grey-dot'></span>
                                                        <span className='text-12 font-400 color-black-55 dF gap-6 align-center'>{t('common.tiv')}: <p className='font-600'>{jcp?.village.tiv ? jcp.village.tiv : `NA`}</p></span>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='dF align-center gap-4 mt-7 text-12 font-400 color-black-55'>
                                                    {t('dsp.jcp.visits_ytd')}: <p className='font-600'>{jcp?.village.stats.total_jcp_visits_year_till_date ? jcp.village.stats.total_jcp_visits_year_till_date: 0}</p>
                                                </div>
                                                <div className='dF align-center gap-4 mt-7 text-12 font-400 color-black-55'>
                                                    {t('dsp.jcp.distance')}: <p className='font-600'>{
                                                        distanceCalc.calculateDistance(
                                                            latitude as number,
                                                            longitude as number,
                                                            parseInt(jcp?.village.latitude as string),
                                                            parseInt(jcp?.village.longitude as string)
                                                        )
                                                    } Km</p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='pA right-15 bottom-90'>
                            <Link 
                                to={`/${AppUrlConstants.DSP.BASE_NAME}/${AppUrlConstants.DSP.CREATE_JCP.BASE_NAME}`}>
                                <Button variant='primary' label={t('dsp.jcp.create_jcp')}></Button>
                            </Link>
                        </div>
                    </>
            }
        </div>
    )
}

export default JCP