import axios from 'axios';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowSvg } from '../../../assets/icons/ArrowSvg';
import DateFilter from '../../../components/date-filter';
import EmptyData from '../../../components/empty-data';
import Loader from '../../../components/loader';
import ViewLayout from '../../../layout/view-layout';
import styles from './styles.module.css';
import UserContext from '../../../context/UserContext';

interface UniqueVillage {
  id: string;
  name: string;
}
interface ActivityBreakdown {
  name: string;
  total_planned: number;
  total_completed: number;
}
interface ReportData {
  total_planned: number;
  total_visitors: number;
  total_completed: number;
  unique_villages: UniqueVillage[];
  total_deliveries: number;
  activity_breakdowns: ActivityBreakdown[];
  total_enquires_generated: number;
  total_hot_prospect_generated: number;
  total_unique_villages_visited: number;
}
const FieldActivity: React.FC = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ReportData | null>(null);
  const [showUniqueVillages, setShowUniqueVillages] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);

  useEffect(() => {
    setIsLoading(true);
    const params: Record<string, string> = {};

    if (selectedDateRange?.start) {
      params.start_date_time = moment(new Date(selectedDateRange.start)).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    if (selectedDateRange?.end) {
      params.end_date_time = moment(new Date(selectedDateRange.end)).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }

    axios
      .get('/api/v1/field-activities/summary', { params })
      .then((response) => {
        setIsLoading(false);
        setData(response.data.data);
      });
  }, [selectedDateRange?.start, selectedDateRange?.end]);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      navigate({
        pathname: `/${user.user_role}/field-activities`
      });
    },
    [navigate]
  );

  return (
    <>
      <ViewLayout title="FA Review Dashboard">
        <div className="p-20">
          <DateFilter
            values={[
              'All Time',
              'Today',
              'Yesterday',
              'This week',
              'Last week',
              'This month',
              'Last month',
              'Last 3 months',
              'This year',
              'Custom range'
            ]}
            onChange={handleDateChange}
          />
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: 'calc(calc(var(--vh, 1vh) * 100) - 140px)'
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <h3 className="text-14 font-600 color-black mt-20 mb-10">
                FA review dashboard
              </h3>
              <table className="tafe-table mb-16">
                <tr>
                  <th>FA Planned</th>
                  <th>FA Completed</th>
                </tr>
                <tr>
                  <td
                    className="text-14 font-500 color-link"
                    onClick={navigateToDetails}
                  >
                    {data?.total_planned}
                  </td>
                  <td
                    className="text-14 font-500 color-link"
                    onClick={navigateToDetails}
                  >
                    {data?.total_completed}
                  </td>
                </tr>
              </table>
              <table className="tafe-table mb-16">
                <tr>
                  <th>Unique villages visited</th>
                  <th>Total visitors</th>
                </tr>
                <tr>
                  <td
                    className="text-14 font-500 color-link"
                    onClick={() => setShowUniqueVillages(true)}
                  >
                    {data?.total_unique_villages_visited}
                  </td>
                  <td className="text-14 font-500 color-black-70">
                    {data?.total_visitors}
                  </td>
                </tr>
              </table>
              <table className="tafe-table mb-16">
                <tr>
                  <th>HP Generated</th>
                  <th>Enquiry Generated</th>
                  <th>Deliveries</th>
                </tr>
                <tr>
                  <td className="text-14 font-500 color-link">
                    {data?.total_hot_prospect_generated}
                  </td>
                  <td className="text-14 font-500 color-link">
                    {data?.total_enquires_generated}
                  </td>
                  <td className="text-14 font-500 color-link">
                    {data?.total_deliveries}
                  </td>
                </tr>
              </table>
              <table className="tafe-table">
                <tr>
                  <th>Activity type</th>
                  <th>Planned</th>
                  <th>Completed</th>
                </tr>
                {data?.activity_breakdowns.map(
                  (item: ActivityBreakdown, index: number) => (
                    <tr key={index}>
                      <td className="text-14 font-400 color-black-70">
                        {item.name}
                      </td>
                      <td
                        className="text-14 font-500 color-link"
                        onClick={navigateToDetails}
                      >
                        {item.total_planned}
                      </td>
                      <td
                        className="text-14 font-500 color-link"
                        onClick={navigateToDetails}
                      >
                        {item.total_completed}
                      </td>
                    </tr>
                  )
                )}
              </table>
            </>
          )}
        </div>
      </ViewLayout>

      {showUniqueVillages && (
        <div className={styles['unique_village_list']}>
          <div className={styles['unique_village_list_header']}>
            <div className="dF align-center">
              <div
                className="dF justify-center w48 cP rotate-180"
                onClick={() => setShowUniqueVillages(false)}
              >
                <ArrowSvg fill="white" />
              </div>
              <h2 className="text-18 font-600 color-white mt-2">
                Unique villages visited
              </h2>
            </div>
          </div>
          <div className={styles['unique_village_list_body']}>
            <div className="m-20">
              <div className="tafe-card">
                <h3
                  className="text-14 p-14 font-600 color-secondary ls-028"
                  style={{ borderBottom: '1px solid var(--primary-10)' }}
                >
                  Zero deliveries dashboard
                </h3>

                {data?.unique_villages.length ? (
                  <table className="tafe-table">
                    <tr>
                      <td className="color-black-70">Id</td>
                      <td className="color-black-70">Name</td>
                    </tr>
                    {data?.unique_villages.map((item) => (
                      <tr>
                        <td>
                          <p className="text-14 color-black-70">{item.id}</p>
                        </td>
                        <td>
                          <p className="text-14 color-black">{item.name}</p>
                        </td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <EmptyData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FieldActivity;
