enum DateFormat {
    YEAR = 'yyyy',
    MONTH = 'MMMM',
    MONTH_YEAR = "MMMM, YYYY",
    ISO_DATE = 'YYYY-MM-DD',
    ISO_DATE_TIME = "YYYY-MM-DDTHH:mm:ss",
    ISO_DATE_TIME_WITH_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ssxxxxx",
    DAY_OF_MONTH = "DD",
    APP_DATE = 'DD-MM-YYYY'
}

export default DateFormat;
