import classNames from 'classnames';
import { on } from 'events';
import React, { useCallback } from 'react';

export interface InputTextProps {
  label: string;
  name?: string;
  value?: string | number;
  error?: string;
  disabled?: boolean;
  mandatory?: boolean;
  type?: 'text' | 'textarea' | 'number';
  placeholder?: string;
  onChange?: (name: string, value: string) => void;
  readonly?: boolean
}

const InputText: React.FC<InputTextProps> = ({
  label,
  name,
  value,
  error,
  disabled = false,
  mandatory = false,
  type = 'text',
  placeholder = '',
  onChange,
  readonly = false
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      if (onChange) onChange(name, value);
    },
    [onChange]
  );

  return (
    <div className="w100p">
      <p className="text-14 font-500 mb-4">
        {label}
        {mandatory && <span className="color-error">*</span>}
      </p>
      {type === 'textarea' && (
        <textarea
          name={name}
          value={value}
          className={classNames('input-box', error && 'invalid')}
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}

      {type === 'text' && (
        <input
          type="text"
          name={name}
          value={value}
          className={classNames('input-box', error && 'invalid')}
          disabled={disabled}
          autoComplete="off"
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={readonly}
        />
      )}

      {type === 'number' && (
        <input
          type="number"
          name={name}
          value={value}
          className={classNames('input-box', error && 'invalid')}
          disabled={disabled}
          autoComplete="off"
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
      {error && <p className="text-12 color-error">{error}</p>}
    </div>
  );
};

export default InputText;
