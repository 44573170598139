import classNames from 'classnames';
import React from 'react';
import { IContactProps } from '../../../types/global';
import WhatsappSvg from '../../assets/icons/WhatsappSvg';

const WhatsappTemp: React.FC<IContactProps> = ({ phoneNumber, className }) => {
  const whatsappLink = `https://wa.me/${phoneNumber}`;
  const handleInnerClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <a href={whatsappLink} target="_blank" onClick={handleInnerClick}>
      <WhatsappSvg className={classNames(className && className)} />
    </a>
  );
};

export default WhatsappTemp;
