import React, { PropsWithChildren } from 'react'
import styles from './index.module.css';
const TableHead: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLTableSectionElement>>> = ({children, ...props}) => {
    return (
        <thead className={styles['thead']} {...props}>
            {children}
        </thead>
    )
}

export default TableHead