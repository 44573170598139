import { useCallback, useState } from "react";
import { UseJcPlanning } from "../../types/hooks";
import { JCP } from "../../types/model";
import useEffectAfterMount from "./useEffectAfterMount";
import JcPlanningService from "../services/JcPlanningService";

export default function usePagedJcPlannings({
    page,
    perPage,
    filters,
    additionalFields,
    onFailure
}: UseJcPlanning.Parameters) {
    const [__page, setPage] = useState(page);
    const [__perPage, setPerPage] = useState(perPage);
    const [__filters, setFilters] = useState(filters);
    const [data, setData] = useState<(JCP | null)[]>([]);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffectAfterMount(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        JcPlanningService.getPagedJcPlannings({
            page: __page,
            perPage: __perPage,
            filters: __filters,
            additionalFields: additionalFields,
            signal: abortController.signal
        })
        .then((result) => {
            const { data, page_info } = result;

          if (__page === 1) {
            setData(data);
          } else {
            setData((prev) => prev.concat(data));
          }
            setTotalRecord(data.length);
            setIsLoading(false);
        })
        .catch((error) => {
            onFailure && onFailure(error)
        })
        return(() => {
            abortController.abort()
        })
    }, [
        __page,
        __perPage,
        __filters,
        onFailure
    ])
    const updateFilters = useCallback((
        filters: UseJcPlanning.Filters
    ) => {
        setFilters((prev) => ({...prev, ...filters}))
    }, [])
    return {
        data,
        totalRecord,
        isLoading,
        updateFilters
    }
}