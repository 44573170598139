import styles from "./styles.module.css";

import classNames from "classnames";
import { useCallback, useState } from "react";
import JcPlanningService from "../../services/JcPlanningService";
import ServerConnectorUtil from "../../utils/ServerConnectorUtil";
import CustomEventEmiterUtil from "../../utils/CustomEventEmitterUtil";
import useCustomEventListener from "../../hooks/useCustomEventListener";
import CustomEventNameConstants from "../../constants/CustomEventNameConstants";
import useModal from "../../hooks/useModal";
import { JCP } from "../../../types/model";
import Button from "../button";
import EmptyRecords from "../empty-records";
import ModalData from "../modal-data";
import { useTranslation } from "react-i18next";
import Loader from "../loader";

type JcPlanningScheduledVillageViewModalProps = {
  goToCreateView?: () => void;
};

const JcPlanningScheduledVillageViewModal: React.FC<
  JcPlanningScheduledVillageViewModalProps
> = ({ goToCreateView }) => {
  const { open, openModal, closeModal } = useModal({});
  const {t} = useTranslation();
  const [jcPlannings, setJcPlannings] = useState<Array<JCP>>([]);
  const [isCreationAllowed, setIsCreationAllowed] = useState<boolean>(false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onOpenModalEvent = useCallback(
    (
      jcPlannings: Array<JCP>,
      isCreationAllowed: boolean = false
    ) => {
      setIsCreationAllowed(isCreationAllowed);
      setJcPlannings(jcPlannings);
      openModal();
    },
    [openModal]
  );

  const deleteJcPlanning = useCallback(
    (event: React.MouseEvent<HTMLOrSVGElement>) => {
      const element = event.target as HTMLElement;

      const id = element.getAttribute("data-id") as string;

      setIsProcessing(true);
      JcPlanningService.deleteJcPlanningById({ id })
        .then(() => {
          setJcPlannings((prev) => prev.filter((item) => item.id !== id));

          CustomEventEmiterUtil.emit(
            CustomEventNameConstants.JC_PLANNING.DELETE_RECORD,
            id
          );
        })
        .catch(ServerConnectorUtil.handleServerError)
        .finally(() => setIsProcessing(false));
    },
    []
  );

  useCustomEventListener(
    CustomEventNameConstants.JC_PLANNING.OPEN_VIEW_MODAL,
    onOpenModalEvent
  );

  return (
    <>
      {open && (
        <>
          <div className="dN">
            <svg 
              id="delete"
              viewBox="0 0 14 14" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg">
                <path 
                  d="M1.75 3.5H2.91667H12.25" 
                  stroke="rgba(196, 18, 48, 1)" 
                  stroke-width="1.5" 
                  stroke-linecap="round" 
                  stroke-linejoin="round"/>
                <path 
                  d="M11.0832 3.49935V11.666C11.0832 11.9754 10.9603 12.2722 10.7415 12.491C10.5227 12.7098 10.2259 12.8327 9.9165 12.8327H4.08317C3.77375 12.8327 3.47701 12.7098 3.25821 12.491C3.03942 12.2722 2.9165 11.9754 2.9165 11.666V3.49935M4.6665 3.49935V2.33268C4.6665 2.02326 4.78942 1.72652 5.00821 1.50772C5.22701 1.28893 5.52375 1.16602 5.83317 1.16602H8.1665C8.47592 1.16602 8.77267 1.28893 8.99146 1.50772C9.21025 1.72652 9.33317 2.02326 9.33317 2.33268V3.49935" 
                  stroke="rgba(196, 18, 48, 1)" 
                  stroke-width="1.5" 
                  stroke-linecap="round" 
                  stroke-linejoin="round"/>
            </svg>
          </div>
          <ModalData>
            <ModalData.Header>
                {t('dsp.create_jcp.summary.scheduled_village')}
                <ModalData.Close onClick={closeModal}/>
            </ModalData.Header>
            <ModalData.Content>
              {
                isProcessing ? 
                  <Loader size="medium"/>
                :
                jcPlannings.length === 0 ? (
                  <EmptyRecords
                    size="sm"
                    message={t('dsp.create_jcp.summary.no_jcp_available_selected_date')}
                  />
                ) : (
                  jcPlannings.map((item) => (
                    <div
                      key={item.id}
                      className={`p-12-25 b-black-10 dF mb-12 align-center br-8 justify-between text-14`}
                    >
                      <div>
                        <div className="mb-4 font-500">{item.village.name}</div>
                        <div className="dF align-center gap-40">
                          <span className="mvv_tiv">
                            {t("dsp.create_jcp.anchor_village.village")}:{" "}
                            <b className={`color-${item.village.color_code}`}>
                              {item.village.color_code}
                            </b>
                          </span>
                          {item.village.tiv === "High" ? "HTIV" : "LTIV"}
                        </div>
                      </div>
                      {item.status === "In Draft" && (
                        <svg
                          width='14'
                          height='16'
                          data-id={item.id}
                          onClick={deleteJcPlanning}
                        >
                          <use href="#delete"/>
                        </svg>
                      )}
                    </div>
                  ))
                )
              }
            </ModalData.Content>
            {
                isCreationAllowed && (
                  <ModalData.Footer>
                      <Button
                      label={t("common.create")}
                      variant="primary"
                      size="large"
                      onClick={goToCreateView}
                      appearance="box"
                    />
                  </ModalData.Footer>
                )
            }
          </ModalData>
        </>
      )}
    </>
  );
};

export default JcPlanningScheduledVillageViewModal;
