import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from '../../../../components/accordion';
import DateFilter from '../../../../components/date-filter';
import Loader from '../../../../components/loader';
import UserContext from '../../../../context/UserContext';
import ServerConnectorUtil from '../../../../utils/ServerConnectorUtil';
import NavCard from '../../../../components/nav-card';
import DateProcessor from '../../../../processor/DateProcessor';
import DateFormat from '../../../../enums/DateFormat';
interface IUserStats {
  total_leads: number;
  total_prospects: number;
  total_deliveries: number;
  total_jcp_visited: number;
  total_jcp_approved: number;
  total_hot_prospects: number;
  total_overdue_hot_prospects: number;
  total_tractor_user_database_added: number;
}
interface IRole {
  id: string;
  name: string;
}
interface IDealer {
  id: string;
  role: IRole;
  zuid: string;
  stats: IUserStats;
  email: string;
  last_name: string;
  first_name: string | null;
  dealer_code: string;
  dealer_name: string;
  company_code: string;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const JcpReview: React.FC = () => {
  const { user } = useContext(UserContext);

  const [additionalFields] = useState<Array<string>>([
    'dealer_name',
    'stats.total_leads',
    'stats.total_prospects',
    'stats.total_deliveries',
    'stats.total_jcp_visited',
    'stats.total_jcp_approved',
    'stats.total_hot_prospects',
    'stats.total_overdue_hot_prospects',
    'stats.total_check_in_in_last_five_days',
    'stats.total_tractor_user_database_added'
  ]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IDealer[]>([]);
  const [dateFilterOptions] = useState(() =>
    monthNames.slice(0, new Date().getMonth() + 1)
  );
  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(() =>
    DateProcessor.getSpecificMonthRange(Date.now(), new Date().getMonth())
  );
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const index = new Date().getMonth();
    return monthNames[index];
  });
  const [currentYear, setCurrentYear] = useState<number>(() =>
    new Date().getFullYear()
  );

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  useEffect(() => {
    setIsLoading(true);

    const params = new URLSearchParams();
    if (additionalFields.length > 0) {
      params.append('additional_fields', additionalFields.join(','));
    }
    if (selectedDateRange) {
      if (selectedDateRange.start) {
        params.append(
          'start_date_time',
          DateProcessor.format(
            selectedDateRange.start,
            DateFormat.ISO_DATE_TIME
          )
        );
      }
      if (selectedDateRange.end) {
        params.append(
          'end_date_time',
          DateProcessor.format(selectedDateRange.end, DateFormat.ISO_DATE_TIME)
        );
      }

      const now = new Date();
      const startOfYear = new Date(now.getFullYear(), 0, 1).getTime();

      params.append(
        'till_date_start_value',
        DateProcessor.format(startOfYear, DateFormat.ISO_DATE_TIME)
      );
    }

    axios
      .get(`/api/v1/user/my-dealer-reportees?${params.toString()}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsLoading(false);
      });
  }, [additionalFields, selectedDateRange?.start]);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data_title');
      const dealer_name = element.getAttribute('dealer_name');
      const dealer_code = element.getAttribute('dealer_code');

      const searchParams = new URLSearchParams();
      searchParams.set('title', title || '');
      searchParams.set('dealer_name', dealer_name || '');
      searchParams.set('dealer_code', dealer_code || '');
      searchParams.set('month', selectedMonth);
      searchParams.set('year', currentYear.toString());

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          searchParams.set(
            'start_date_time',
            selectedDateRange.start.toString()
          );
        }
        if (selectedDateRange.end) {
          searchParams.set('end_date_time', selectedDateRange.end.toString());
        }

        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 1).getTime();
        searchParams.set('till_date_start_value', startOfYear.toString());
      }

      navigate(
        {
          pathname: `/${user.user_role}/jcp/jcp-review/dsp`,
          search: searchParams.toString()
        },
        {}
      );
    },
    [selectedDateRange, currentYear, selectedMonth]
  );

  return (
    <div className="p-20">
      <DateFilter
        defaultValue={{
          label: selectedMonth || '',
          value: selectedMonth || ''
        }}
        values={dateFilterOptions}
        onChange={handleDateChange}
      />
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: 'calc(calc(var(--vh, 1vh) * 100) - 184px)'
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="dF direction-column mt-20 gap-20">
          {data?.map((item: IDealer) => (
            <NavCard
              key={item.id}
              title={item.dealer_name}
              className="bp0"
              showArrow={true}
              customAttributes={{
                data_title: item.dealer_name,
                dealer_name: item.dealer_name,
                dealer_code: item.dealer_code
              }}
              onClick={navigateToDetails}
            >
              <table className="tafe-table">
                <tbody>
                  <tr>
                    <td>
                      <p className="text-12 font-500 color-black-55">Planned</p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_jcp_approved}
                      </p>
                    </td>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Planned TD
                      </p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_jcp_approved}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Visited TD
                      </p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_jcp_visited}
                      </p>
                    </td>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Tractor Users Added
                      </p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_tractor_user_database_added}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        Enquiries Generated
                      </p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_leads + item.stats.total_prospects}
                      </p>
                    </td>
                    <td>
                      <p className="text-12 font-500 color-black-55">
                        HPs Generated
                      </p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_hot_prospects}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <p className="text-12 font-500 color-black-55">
                        Deliveries
                      </p>
                      <p className="text-18 color-black mt-5">
                        {item.stats.total_deliveries}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </NavCard>
          ))}
        </div>
      )}
    </div>
  );
};

export default JcpReview;
