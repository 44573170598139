import React from 'react';
import styles from './styles.module.css';

interface SuccessLayoutProps {
  title: string;
  desc: string;
  button_name: string;
  button_icon?: string;
  onClick: () => void;
}

const SuccessLayout: React.FC<SuccessLayoutProps> = ({
  title,
  desc,
  button_name,
  button_icon,
  onClick
}) => {
  return (
    <>
      <div className="dN">
        <svg
          width="62"
          height="36"
          id="success-tick"
          viewBox="0 0 62 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.05 35.85L0.25 19.05L3.475 15.825L17.05 29.4L20.275 32.625L17.05 35.85ZM29.8 35.85L13 19.05L16.225 15.825L29.8 29.4L58.6 0.599995L61.825 3.825L29.8 35.85ZM29.8 23.1L26.575 19.875L45.85 0.599995L49.075 3.825L29.8 23.1Z"
            fill="white"
          />
        </svg>
        <svg
          width="14"
          height="12"
          id="add"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1639 6.4V5.06667H9.84727V3.71667H11.1639V2.4H12.5139V3.71667H13.8473V5.06667H12.5139V6.4H11.1639ZM5.6306 5.83333C4.86393 5.83333 4.21393 5.57222 3.6806 5.05C3.15838 4.52778 2.89727 3.88333 2.89727 3.11667C2.89727 2.36111 3.15838 1.72222 3.6806 1.2C4.21393 0.677777 4.86393 0.416666 5.6306 0.416666C6.38615 0.416666 7.02504 0.677777 7.54727 1.2C8.0806 1.72222 8.34727 2.36111 8.34727 3.11667C8.34727 3.88333 8.0806 4.52778 7.54727 5.05C7.02504 5.57222 6.38615 5.83333 5.6306 5.83333ZM0.497266 11.2833V9.4C0.497266 9.05556 0.580599 8.73889 0.747266 8.45C0.925043 8.16111 1.16393 7.93333 1.46393 7.76667C2.09727 7.41111 2.76393 7.13333 3.46393 6.93333C4.16393 6.73333 4.88615 6.63333 5.6306 6.63333C6.37504 6.63333 7.09727 6.73333 7.79727 6.93333C8.50838 7.12222 9.16949 7.4 9.7806 7.76667C10.0806 7.93333 10.3139 8.16111 10.4806 8.45C10.6584 8.72778 10.7473 9.04444 10.7473 9.4V11.2833H0.497266ZM2.1306 9.65H9.11393V9.45C9.11393 9.38333 9.09727 9.32778 9.06393 9.28333C9.0306 9.22778 8.98616 9.18333 8.9306 9.15C8.44171 8.87222 7.91949 8.65556 7.36393 8.5C6.80838 8.34444 6.2306 8.26667 5.6306 8.26667C5.04171 8.26667 4.45838 8.34444 3.8806 8.5C3.31393 8.65556 2.79171 8.87222 2.31393 9.15C2.26949 9.18333 2.22504 9.22778 2.1806 9.28333C2.14727 9.32778 2.1306 9.38333 2.1306 9.45V9.65ZM5.6306 4.2C5.9306 4.2 6.18615 4.09444 6.39727 3.88333C6.60838 3.67222 6.71393 3.41667 6.71393 3.11667C6.71393 2.81667 6.60282 2.56667 6.3806 2.36667C6.16949 2.15555 5.91949 2.05 5.6306 2.05C5.3306 2.05 5.06949 2.15555 4.84727 2.36667C4.63615 2.57778 4.5306 2.82778 4.5306 3.11667C4.5306 3.41667 4.63615 3.67222 4.84727 3.88333C5.06949 4.09444 5.3306 4.2 5.6306 4.2Z"
            fill="#007851"
          />
        </svg>
      </div>
      <div className={styles['success-layout']}>
        <svg className={styles['success-tick']}>
          <use href={`#success-tick`}></use>
        </svg>
        <h2 className={styles['success-title']}>{title}</h2>
        <p className={styles['success-desc']}>{desc}</p>
        <button className={styles['success-btn']} onClick={onClick}>
          <div className="dF align-center gap-4">
            <svg>
              <use href={`#${button_icon}`}></use>
            </svg>
            {button_name}
          </div>
        </button>
      </div>
    </>
  );
};

export default SuccessLayout;
