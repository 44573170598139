import { useTranslation } from 'react-i18next';
import NavCard from '../../shared/components/nav-card';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-24">
      <div className="tafe-card p-20 mb-20">
        <div className="dF gap-12 mb-12">
          <div className="two-column-width">
            <NavCard title={t('dashboard.billing_target')}>
              <p className="text-14 color-black h44 dF align-center">
                <span className="text-24 font-500 color-link">24</span> / 25
              </p>
            </NavCard>
          </div>
          <div className="two-column-width">
            <NavCard title={t('dashboard.deliveries_target')}>
              <p className="text-14 color-black h44 dF align-center">
                <span className="text-24 font-500 color-link">17</span> / 20
              </p>
            </NavCard>
          </div>
        </div>
        <div className="dF gap-12">
          <div className="two-column-width">
            <NavCard title={t('dashboard.collections_target')}>
              <p className="text-14 color-black h44 dF align-center">
                <span className="text-24 font-500 color-link">18</span> / 20
              </p>
            </NavCard>
          </div>
          <div className="two-column-width">
            <NavCard title={t('dashboard.retail')}>
              <p className="text-14 color-black h44 dF align-center">
                <span className="text-24 font-300 ">18</span>
              </p>
            </NavCard>
          </div>
        </div>
      </div>
      <div className="dF gap-12 mb-12">
        <div className="two-column-width">
          <NavCard title={t('dashboard.rct')}>
            <p className="text-14 color-black h44 dF align-center">
              <span className="text-24 font-300 ">45</span>
            </p>
          </NavCard>
        </div>
        <div className="two-column-width">
          <NavCard title={t('dashboard.stock')}>
            <p className="text-14 color-black h44 dF align-center">
              <span className="text-24 font-500 color-link">14</span> (47)
            </p>
          </NavCard>
        </div>
      </div>
      <div className="dF gap-12 mb-20">
        <div className="two-column-width">
          <NavCard title={t('dashboard.bank_guarantee')}>
            <p className="text-14 color-black h44 dF align-center">
              <span className="text-24 font-300">4,20,000</span>
            </p>
          </NavCard>
        </div>
        <div className="two-column-width">
          <NavCard title={t('dashboard.company_os')}>
            <p className="text-14 color-black h44 dF align-center">
              <span className="text-24 font-300">5,60,000</span>
            </p>
          </NavCard>
        </div>
      </div>
      <NavCard title={t('dashboard.market_share')}>
        <p className="text-14 color-black h44 dF align-center">
          <span className="text-24 font-300">12.30</span> / 13.50
        </p>
      </NavCard>
    </div>
  );
};

export default Dashboard;
