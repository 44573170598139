import React from 'react';
import { IIconProps } from '../../../types/global';

const FilterSvg: React.FC<IIconProps> = ({ fill }) => {
  fill = fill !== undefined ? fill : '#5F6062';
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.80281 16C7.51948 16 7.28198 15.9042 7.09031 15.7125C6.89865 15.5208 6.80281 15.2833 6.80281 15V9L1.00281 1.6C0.752815 1.26667 0.715315 0.916667 0.890315 0.55C1.06531 0.183333 1.36948 0 1.80281 0H15.8028C16.2361 0 16.5403 0.183333 16.7153 0.55C16.8903 0.916667 16.8528 1.26667 16.6028 1.6L10.8028 9V15C10.8028 15.2833 10.707 15.5208 10.5153 15.7125C10.3236 15.9042 10.0861 16 9.80281 16H7.80281ZM8.80281 8.3L13.7528 2H3.85281L8.80281 8.3Z"
        fill={fill}
      />
    </svg>
  );
};

export default FilterSvg;
