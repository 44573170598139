import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

import { Framework, Model } from '../../types/global';
import { ContactServiceParameters } from '../../types/parameters';

export default class ContactService {
  public static async getPagedContacts({
    page,
    signal,
    perPage,
    filters: { name, type, mobileNumber },
    additionalFields
  }: ContactServiceParameters.GetPagedContacts.Parameters): Promise<ContactServiceParameters.GetPagedContacts.ReturnType> {
    const handler = new RequestHandler('/api/v1/contacts', RequestMethod.GET);

    handler.addParam('page', page);
    handler.addParam('perPage', perPage);

    if (name) {
      handler.addParam('name', name);
    }
    if (type) {
      handler.addParam('type', type);
    }
    if (mobileNumber) {
      handler.addParam('mobile_number', mobileNumber);
    }

    if (additionalFields && additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    if (signal) {
      handler.setAbortSignal(signal);
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<Model.Contact>);
  }
}
