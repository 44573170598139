import { UserJcPlanningCalendarHandlerHook } from "../../types/hooks";
import JcPlanApprovalStatus from "../enums/JcPlanApprovalStatus";
import DateProcessor from "../processor/DateProcessor";
import JcPlanningUtil from "../utils/JcPlanningUtil";
import useEffectAfterMount from "./useEffectAfterMount";

export default function useJcPlanningCalenderHandler({
  endDate,
  startDate,
  jcPlannings,
  jcPlanApproval,
  setExcludedDates,
  setDateColorCodeMap
}: UserJcPlanningCalendarHandlerHook.Parameters) {
  useEffectAfterMount(() => {
    const allDates = DateProcessor.getDatesBetweenRange(startDate, endDate);
    if (
      jcPlanApproval &&
      jcPlanApproval.status !== JcPlanApprovalStatus.IN_DRAFT
    ) {
      setExcludedDates(allDates);
    } else {
      const currentDate = Date.now();
      const excludedDates = allDates.filter(
        (obj) => !DateProcessor.isDateAfter(obj.getTime(), currentDate)
      );
      setExcludedDates(excludedDates);
    }
  }, [endDate, startDate, jcPlanApproval]);

  useEffectAfterMount(() => {
    setDateColorCodeMap(JcPlanningUtil.getDateColorCodeMap(jcPlannings));
  }, [jcPlannings]);

  return null;
}
