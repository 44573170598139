import React from 'react'
import { useProfileContext } from '../../context/ProfileContext';
import DateProcessor from '../../processor/DateProcessor';
import { useTranslation } from 'react-i18next';

const Name: React.FC = () => {
    const {name} = useProfileContext();
    const {t} = useTranslation();
    return (
        <div>
            <p className="text-12 color-black ls-048">{t('common.welcome')}</p>
            <p className="text-18 color-primary mt-2">
                {name}
            </p>
            <p className="text-14 color-black-40 ls-028 mt-2">
                {DateProcessor.getFormattedDate()}
            </p>
        </div>
    )
}

export default Name;