export default class {
  public static readonly MY_DSP_REPORTEES = '/api/v1/user/my-dsp-reportees';
  public static readonly USER = '/api/v1/user/current-user';
  public static readonly PROSPECT_DATA = '/api/v1/prospects';
  public static readonly PROSPECT_DETAILS = '/api/v1/prospect/';
  public static readonly PROSPECT_STATS = '/api/v1/prospects/summary';
  public static readonly TUDB_STAT = '/api/v1/tractor-user-databases/stats';
  public static readonly FOLLOWUP_DATA = '/api/v1/follow-up-tasks';
  public static readonly JCPLANNING_DATA = '/api/v1/jc-plannings';
}
