import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Accordion from '../../../../../components/accordion';
import Loader from '../../../../../components/loader';
import ViewLayout from '../../../../../layout/view-layout';
import { getFullName } from '../../../../../utils/FullNameUtil';
import UserContext from '../../../../../context/UserContext';
import NavCard from '../../../../../components/nav-card';
import { useQueryStates } from 'nuqs';
import useEffectAfterMount from '../../../../../hooks/useEffectAfterMount';
import DateProcessor from '../../../../../processor/DateProcessor';
import DateFormat from '../../../../../enums/DateFormat';
import ServerConnectorUtil from '../../../../../utils/ServerConnectorUtil';

interface Role {
  id: string;
  name: string;
}

interface Stats {
  total_leads: number;
  total_prospects: number;
  total_deliveries: number;
  total_hot_prospects: number;
  total_delivery_target: number;
  total_jc_plannings_planned: number;
  total_tractor_user_database_added: number;
  total_jc_plannings_planned_till_date: number;
  total_jc_plannings_visited_till_date: number;
}

interface User {
  id: string;
  role: Role;
  zuid: string;
  stats: Stats;
  email: string;
  last_name: string;
  first_name: string;
  dealer_code: string;
  company_code: string;
}

const additionalFields = [
  'stats.total_jc_plannings_planned_till_date',
  'stats.total_jc_plannings_visited_till_date',
  'stats.total_jc_plannings_planned',
  'stats.total_tractor_user_database_added',
  'stats.total_prospects',
  'stats.total_leads',
  'stats.total_hot_prospects',
  'stats.total_deliveries'
];

const JcpReviewDetails: React.FC = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [
    {
      start_date_time,
      end_date_time,
      till_date_start_value,
      title,
      month,
      year,
      dealer_name,
      dealer_code
    }
  ] = useQueryStates(
    {
      start_date_time: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      end_date_time: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      till_date_start_value: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      title: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      dealer_name: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      dealer_code: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      month: {
        defaultValue: '',
        parse: (value) => value || ''
      },
      year: {
        defaultValue: '',
        parse: (value) => value || ''
      }
    },
    {
      history: 'replace'
    }
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);

  useEffectAfterMount(() => {
    setIsLoading(true);

    const params = new URLSearchParams();
    if (additionalFields.length > 0) {
      params.append('fields', additionalFields.join(','));
    }
    params.append(
      'start_date_time',
      DateProcessor.format(parseInt(start_date_time), DateFormat.ISO_DATE_TIME)
    );
    params.append(
      'end_date_time',
      DateProcessor.format(parseInt(end_date_time), DateFormat.ISO_DATE_TIME)
    );
    params.append(
      'till_date_start_value',
      DateProcessor.format(
        parseInt(till_date_start_value),
        DateFormat.ISO_DATE_TIME
      )
    );

    axios
      .get(`/api/v1/dsp-users-with-statistics?${params.toString()}`)
      .then((response) => {
        const { data } = response.data;
        setData(data);
      })
      .catch(ServerConnectorUtil.handleServerError)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const searchParams = new URLSearchParams();
      const id = event.currentTarget.getAttribute('data-id');
      searchParams.set('month', month);
      searchParams.set('year', year);
      searchParams.set('dealer_codes', dealer_code);
      navigate(
        {
          pathname: `/${user.user_role}/jcp/jcp-review/dsp-details/${id}`,
          search: searchParams.toString()
        },
        {}
      );
    },
    []
  );

  return (
    <ViewLayout title={title || ''}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="pl-20 pr-20">
          <div className="dF direction-column mt-20 gap-20">
            {data?.map((item: User) => (
              <NavCard
                key={item.id}
                title={getFullName(item.first_name, item.last_name)}
                showArrow={true}
                className="bp0"
                customAttributes={{
                  'data-id': item.id,
                  'data-name': item.first_name + ' ' + item.last_name
                }}
                onClick={navigateToDetails}
              >
                <table className="tafe-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_jc_plannings_planned}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Planned TD
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_jc_plannings_planned_till_date}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Visited TD
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_jc_plannings_visited_till_date}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Tractor Users Added
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_tractor_user_database_added}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          Enquiries Generated
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_leads + item.stats.total_prospects}
                        </p>
                      </td>
                      <td>
                        <p className="text-12 font-500 color-black-55">
                          HPs Generated
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_hot_prospects}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <p className="text-12 font-500 color-black-55">
                          Deliveries
                        </p>
                        <p className="text-18 color-black mt-5">
                          {item.stats.total_deliveries}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </NavCard>
            ))}
          </div>
        </div>
      )}
    </ViewLayout>
  );
};

export default JcpReviewDetails;
