import React, { useCallback } from 'react';

export interface RadioOption {
  label: string;
  value: string;
}

export interface RadioProps {
  label: string;
  name: string;
  selectedValue?: { label: string; value: string };
  mandatory?: boolean;
  options: RadioOption[];
  onChange?: (name: string, value: { label: string; value: string }) => void;
}

const InputRadio: React.FC<RadioProps> = ({
  label,
  name,
  options,
  mandatory = false,
  selectedValue,
  onChange
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        if (onChange) onChange(name, selectedOption);
      }
    },
    [onChange]
  );

  return (
    <div className="radio-group">
      <p className="text-14 font-500 mb-4">
        {label}
        {mandatory && <span className="color-error">*</span>}
      </p>
      <div className="dF align-center gap-10 vertical">
        {options.map((option) => (
          <div key={option.value} className="dF align-center">
            <input
              id={option.value}
              name={name}
              value={option.value}
              type="radio"
              checked={
                typeof selectedValue === 'object' && 'value' in selectedValue
                  ? option.value === selectedValue.value
                  : false
              }
              onChange={handleChange}
            />
            <label htmlFor={option.value} className="text-14 font-400 cP mt-5 text-capitalize">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputRadio;
