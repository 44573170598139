import { useCallback, useState } from 'react';
import { subMonths, startOfMonth, endOfMonth, startOfYear } from 'date-fns';

import DspView from '../dsp-view';
import Loader from '../../components/loader';
import DateFilter from '../../components/date-filter';
import usePagedDspPeerReportees from '../../hooks/usePagedDspReportee';
import DateProcessor from '../../processor/DateProcessor';

const filterOptions = ['This month', 'Last month', 'Last 3 months'];
const defaultOption = { label: 'This month', value: 'This month' };
const DspReportees: React.FC = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<{
    start: number;
    end: number;
  }>(() => DateProcessor.getThisMonth(Date.now()));

  const [previousMonthDateRange, setPreviousMonthDateRange] = useState<{
    start: number;
    end: number;
  }>(() => {
    const { start } = DateProcessor.getThisMonth(Date.now());
    return {
      start: startOfMonth(subMonths(start, 1)).getTime(),
      end: endOfMonth(subMonths(start, 1)).getTime()
    };
  });

  const handleDateChange = useCallback(
    (data: {
      dateRange: { start: number; end: number } | undefined;
      label: string;
    }) => {
      if (data.dateRange) {
        const { start, end } = data.dateRange;
        setSelectedDateRange({
          start,
          end
        });
        if (data.label === 'Last 3 months') {
          setPreviousMonthDateRange({
            start: startOfMonth(subMonths(start, 3)).getTime(),
            end: endOfMonth(subMonths(start, 1)).getTime()
          });
        } else {
          setPreviousMonthDateRange({
            start: startOfMonth(subMonths(start, 1)).getTime(),
            end: endOfMonth(subMonths(start, 1)).getTime()
          });
        }
      }
    },
    []
  );

  const [tillDateStartValue] = useState(() =>
    startOfYear(new Date()).getTime()
  );

  const { data, isLoading } = usePagedDspPeerReportees({
    current_start_date_time: selectedDateRange.start,
    current_end_date_time: selectedDateRange.end,
    previous_start_date_time: previousMonthDateRange.start,
    previous_end_date_time: previousMonthDateRange.end,
    till_date_start_value: tillDateStartValue
  });

  return (
    <>
      <div className="p-20">
        <DateFilter
          defaultValue={defaultOption}
          values={filterOptions}
          onChange={handleDateChange}
        />
        {isLoading ? (
          <div style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 218px)' }}>
            <Loader />
          </div>
        ) : (
          data && <DspView data={data} />
        )}
      </div>
    </>
  );
};

export default DspReportees;
