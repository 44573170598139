import { useCallback, useState } from 'react';
import { IOptions } from '../../../../types/global';
import { UsePagedAdvancesHook } from '../../../../types/hooks';
import RecordCollection from '../../../components/record-collection';
import usePagedAdvances from '../../../hooks/usePagedAdvances';
import useScroll from '../../../hooks/useScroll';
import ViewLayout from '../../../layout/view-layout';

const allDropDownOptions = [{ value: 'contact_name', label: 'Name' }];

const AdvanceMaster = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('contact_name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedAdvances({
    page: 1,
    perPage: 200,
    filters: {}
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(searchKey as keyof UsePagedAdvancesHook.Filters, value);
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue('');
      setSearchLabel(value.label.toLowerCase());
      setFilters({});
    },
    [setFilters]
  );

  return (
    <ViewLayout title="Advances">
      <RecordCollection>
        <RecordCollection.DropdownSearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={allDropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper key={item.id}>
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle title={item.contact.name} />
                  <RecordCollection.ListItemKeyValue
                    label="Model purchased"
                    value={item.interested_model_family || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Stage"
                    value={item.stage || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Mode of Payment"
                    value={item.payment_mode || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Total Deal Value (INR)"
                    value={
                      item.total_deal_value !== null
                        ? String(item.total_deal_value)
                        : '--'
                    }
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Margin Money (INR)"
                    value={
                      item.margin_money !== null
                        ? String(item.margin_money)
                        : '--'
                    }
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Exchange Tractor Value (INR)"
                    value={
                      item.exchange.value !== null
                        ? String(item.exchange.value)
                        : '--'
                    }
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Total Financed Amount - Loan (INR)"
                    value={
                      item.total_financed_amount !== null
                        ? String(item.total_financed_amount)
                        : '--'
                    }
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Delivery Date"
                    value={item.delivery_date || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Age"
                    value={item.age || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Bank"
                    value={item.bank.description || '--'}
                  />
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default AdvanceMaster;
