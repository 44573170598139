import React from 'react';
import classNames from 'classnames';
import Accordion from '.';
import { useAccordianContext } from '../../context/AccordianContext';

export interface AccordionHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  children,
  className
}) => {
  const { open, toggleOpen } = useAccordianContext();

  return (
    <div
      className={classNames('accordion-header', className, { open: open })}
      onClick={toggleOpen}
    >
      {children}
      <Accordion.Arrow />
    </div>
  );
};

export default AccordionHeader;
