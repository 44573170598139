import React from 'react'

export const TrophySvg:React.FC = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2048_20330)">
                <path d="M3.4149 3.83301H7.4149C8.1509 3.83301 8.66623 3.76367 8.66623 4.49967V3.16634C8.66623 2.43034 8.1509 1.83301 7.4149 1.83301H2.08156C0.748229 1.83301 -0.307104 3.24967 0.0815627 5.16634C0.0815627 5.16634 1.27556 11.4163 1.35956 11.805C1.58156 12.833 2.77556 13.833 4.05356 13.833H8.69223C9.4289 13.833 8.66623 12.569 8.66623 11.833V10.4997C8.66623 11.2357 8.1509 11.833 7.4149 11.833H4.74823C4.01223 11.833 3.52623 11.1383 3.4149 10.4997C3.30356 9.86101 2.3589 5.61101 2.3589 5.61101C2.1369 4.33301 2.67823 3.83301 3.4149 3.83301ZM20.6229 3.83301H16.6229C15.8869 3.83301 14.6662 3.76367 14.6662 4.49967V3.16634C14.6662 2.43034 15.8869 1.83301 16.6229 1.83301H21.9562C23.2896 1.83301 24.3456 3.24967 23.9562 5.16634C23.9562 5.16634 22.8676 11.4457 22.7756 11.833C22.5396 12.833 21.4142 13.833 20.1089 13.833H15.3449C14.6089 13.833 14.6662 12.569 14.6662 11.833V10.4997C14.6662 11.2357 15.8869 11.833 16.6229 11.833H19.2896C20.0256 11.833 20.5122 11.1383 20.6229 10.4997C20.7336 9.86101 21.6789 5.61101 21.6789 5.61101C21.9002 4.33301 21.3596 3.83301 20.6229 3.83301ZM13.8876 15.1663C13.8876 10.5277 12.0816 15.1663 12.0816 15.1663C12.0816 15.1663 10.0816 10.5277 10.0816 15.1663C10.0816 19.805 7.88756 21.833 7.88756 21.833H16.0822C16.0816 21.833 13.8876 19.805 13.8876 15.1663Z" fill="#FFAC33"/>
                <path d="M19.4157 4.88465C19.4157 9.40132 14.9024 17.0127 12.0824 17.0127C9.26169 17.0127 4.74902 9.40132 4.74902 4.88465C4.74902 1.42132 5.41569 0.671988 6.74902 0.671988C7.66502 0.671988 10.7657 0.653988 12.0824 0.653988L16.749 0.653321C18.6937 0.652654 19.4157 1.10932 19.4157 4.88465Z" fill="#FFCC4D"/>
                <path d="M18 22.5C18 23.236 18.1513 23.8333 17.4153 23.8333H6.74867C6.012 23.8333 6 23.236 6 22.5V21.8333C6 21.0973 6.776 20.5 7.47067 20.5H16.7487C17.4433 20.5 18 21.0973 18 21.8333V22.5Z" fill="#C1694F"/>
                <path d="M19.3337 23.5833C19.3337 24.09 19.4437 24.5 18.499 24.5H5.66566C4.80433 24.5 4.66699 24.09 4.66699 23.5833V23.4167C4.66699 22.91 5.15899 22.5 5.66566 22.5H18.499C19.005 22.5 19.3337 22.91 19.3337 23.4167V23.5833Z" fill="#C1694F"/>
            </g>
            <defs>
                <clipPath id="clip0_2048_20330">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
