import React from 'react';
import classNames from 'classnames';
import { useAccordianContext } from '../../context/AccordianContext';
import { AccordionArrowSvg } from '../../assets/icons/AccordionArrowSvg';

const AccordionArrow: React.FC = () => {
  const { open } = useAccordianContext();

  return (
    <div
      className={classNames('accordion-arrow', {
        open: open
      })}
    >
      <AccordionArrowSvg />
    </div>
  );
};

export default AccordionArrow;
