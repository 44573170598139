export function convertToRelativeDays(dateString: string): string {
  if (!dateString) return '';

  const inputDate = new Date(dateString);
  const today = new Date();

  const inputDateNormalized = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  );
  const todayNormalized = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const differenceInMilliseconds =
    todayNormalized.getTime() - inputDateNormalized.getTime();
  const differenceInDays = Math.round(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  if (differenceInDays === 0) {
    return 'today';
  } else if (differenceInDays === 1) {
    return 'yesterday';
  } else if (differenceInDays > 1) {
    return `${differenceInDays} days ago`;
  } else if (differenceInDays === -1) {
    return 'tomorrow';
  } else {
    return `in ${Math.abs(differenceInDays)} days`;
  }
}
