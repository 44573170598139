import { useEffect, useRef, useState } from 'react';
import { IOptions } from '../../types/global';
import classNames from 'classnames';
import ViewLayout from '../../shared/layout/view-layout';
import styles from './styles.module.css';
import NavArrowSvg from '../../shared/assets/icons/NavArrowSvg';

const MyVisits: React.FC = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const activeDateRef = useRef<HTMLDivElement | null>(null);

  const [month, setMonth] = useState<number>(currentMonth);
  const [year, setYear] = useState<number>(currentYear);
  const [selectedDate, setSelectedDate] = useState<Date>(currentDate);
  const [dates, setDates] = useState<Date[]>([]);
  const [filters, setFilters] = useState({
    checkedIn: false,
    dateStartValue: currentDate.getTime(),
    dateEndValue: currentDate.getTime()
  });

  const monthArray: IOptions[] = Array.from({ length: 12 }, (_, index) => ({
    label: new Date(0, index).toLocaleString('default', { month: 'long' }),
    value: new Date(0, index).toLocaleString('default', { month: 'long' })
  }));

  const yearArray: IOptions[] = Array.from(
    { length: currentYear - 2014 },
    (_, index) => ({
      label: (2015 + index).toString(),
      value: (2015 + index).toString()
    })
  );

  useEffect(() => {
    updateDates(year, month);
  }, [year, month]);

  const updateDates = (year: number, month: number) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const newDates = Array.from(
      { length: daysInMonth },
      (_, index) => new Date(year, month, index + 1)
    );
    setDates(newDates);
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateStartValue: new Date(year, month, 1).getTime(),
      dateEndValue: new Date(year, month, 1).getTime()
    }));
    return;
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateStartValue: date.getTime(),
      dateEndValue: date.getTime()
    }));
  };

  const handleMonthChange = (option: IOptions) => {
    const selectedMonth = monthArray.find((m) => m.value === option.value);
    if (selectedMonth) {
      const newMonth = monthArray.indexOf(selectedMonth);
      setMonth(newMonth);
      setSelectedDate(new Date(year, newMonth, 1));
    }
  };

  const handleYearChange = (option: IOptions) => {
    const newYear = Number(option.value);
    setYear(newYear);
    setSelectedDate(new Date(newYear, month, 1));
  };

  useEffect(() => {
    if (activeDateRef.current) {
      activeDateRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, [dates, selectedDate]);

  return (
    <ViewLayout title="My Visits">
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          gap: '20px',
          width: '50%',
          margin: '10px 15px 10px auto'
        }}
      >
        {/* <Dropdowntemplate
          options={monthArray}
          onSelect={handleMonthChange}
          placeholder="Select"
          defaultValue={{
            label: monthArray[month]?.label || '',
            value: monthArray[month]?.value || ''
          }}
        />
        <Dropdowntemplate
          options={yearArray}
          onSelect={handleYearChange}
          placeholder="Select"
          defaultValue={{
            label:
              yearArray.find((y) => y.value === year.toString())?.label || '',
            value:
              yearArray.find((y) => y.value === year.toString())?.value || ''
          }}
        /> */}
      </div>
      <div className={styles['visit-calendar']}>
        {dates.map((item) => (
          <div
            key={item.toISOString()}
            ref={
              selectedDate.toDateString() === item.toDateString()
                ? activeDateRef
                : null
            }
            className={classNames(
              styles['calendar-date'],
              selectedDate.toDateString() === item.toDateString() &&
                styles['active']
            )}
            onClick={() => handleDateChange(item)}
          >
            {item.getDate()}
          </div>
        ))}
      </div>

      <div className={styles['my-visit-container']}>
        <div className={styles['my-visit-card']}>
          <div className="dF justify-between">
            <div className="dF direction-column gap-8">
              <p className="text-14 font-600 color-black ls-028">
                Dealer visit
              </p>
              <div className="dF gap-6 align-center">
                <div className="dF align-center gap-4">
                  <p className="text-14 color-black-70 ls-028">From : </p>
                  <p className="text-14 font-600 color-black-70 ls-028">
                    20-05-2024
                  </p>
                </div>
                <div className="dF align-center gap-4">
                  <p className="text-14 color-black-70 ls-028">To : </p>
                  <p className="text-14 font-600 color-black-70 ls-028">
                    21-05-2024
                  </p>
                </div>
              </div>
              <div className="dF align-center gap-4">
                <p className="text-14 color-black-70 ls-028">Place : </p>
                <p className="text-14 font-600 color-black-70 ls-028">Agra</p>
              </div>
              <div className="dF align-center gap-4">
                <p className="text-14 color-black-70 ls-028">Purpose : </p>
                <p className="text-14 font-600 color-black-70 ls-028">
                  Dealer performance review
                </p>
              </div>
            </div>
            <NavArrowSvg />
          </div>
        </div>
      </div>

      <div className="pA bottom-90 right-15">
        <button className="primary-btn">
          <div className={styles['visit-icon']}></div>
          New Visits
        </button>
      </div>
    </ViewLayout>
  );
};

export default MyVisits;
