import { useState } from "react";
import useEffectAfterMount from "./useEffectAfterMount";
import GeographyService from "../services/GeographyServices";
import { UseAnchorVillagesHook } from "../../types/hooks";
import { VILLAGE } from "../../types/model";

export default function useAnchorVillages({
  page,
  perPage,
  onFailure,
  additionalFields
}: UseAnchorVillagesHook.Parameters): UseAnchorVillagesHook.Result {
  const [data, setData] = useState<Array<VILLAGE>>([]);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectAfterMount(() => {
    setIsLoading(true);
    GeographyService.getPagedAnchorVillages({
      page,
      perPage,
      additionalFields
    })
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        setError(err);
        if (onFailure) {
          onFailure.apply(null, [err]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, perPage, onFailure, additionalFields]);

  return {
    data,
    error,
    isLoading
  };
}
