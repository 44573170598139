import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DateFilter from '../../../../shared/components/date-filter';
import Loader from '../../../../shared/components/loader';
import NavCard from '../../../../shared/components/nav-card';
import TractorUserDatabase from '../../../../shared/enums/TractorUserDatabase';
import useTudbStat from '../../../../shared/hooks/useTudbStat';
import styles from './styles.module.css';
import UserContext from '../../../context/UserContext';

const TUDB: React.FC = () => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [totalTractorUserDatabases] = useState<number>(0);

  const [selectedDateRange, setSelectedDateRange] = useState<
    { start: number; end: number } | undefined
  >(undefined);

  const handleDateChange = (data: {
    dateRange: { start: number; end: number } | undefined;
    label: string;
  }) => {
    setSelectedDateRange(data.dateRange);
  };

  const { data, isLoading } = useTudbStat({
    startDateTime: selectedDateRange?.start,
    endDateTime: selectedDateRange?.end
  });

  const navigateToDetails = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const element = event.currentTarget as HTMLElement;
      const title = element.getAttribute('data-title');
      const type = element.getAttribute('data-type');
      const villageColorCode = element.getAttribute('data-village-color-code');
      const isSatisfiedWithHisTractor = element.getAttribute(
        'data-is-satisfied-with-his-tractor'
      );
      const tractorModel = element.getAttribute('data-tractor-model');
      const searchParams = new URLSearchParams({
        ...(type ? { type } : {}),
        ...(villageColorCode ? { village_color_code: villageColorCode } : {}),
        ...(isSatisfiedWithHisTractor
          ? { is_satisfied_with_his_tractor: isSatisfiedWithHisTractor }
          : {})
      });
      if (title) {
        searchParams.set('title', title);
      }
      if (tractorModel) {
        searchParams.set('tractor_model', tractorModel);
      }

      if (selectedDateRange) {
        if (selectedDateRange.start) {
          searchParams.set(
            'created_time_start_value',
            selectedDateRange.start.toString()
          );
        }
        if (selectedDateRange.end) {
          searchParams.set(
            'created_time_end_value',
            selectedDateRange.end.toString()
          );
        }
      }

      navigate(
        {
          pathname: `/${user.user_role}/tudb`,
          search: searchParams.toString()
        },
        {}
      );
    },
    [selectedDateRange, navigate]
  );

  return (
    <>
      <div className="p-20">
        <DateFilter
          values={[
            'All Time',
            'Today',
            'Yesterday',
            'This week',
            'Last week',
            'This month',
            'Last month',
            'Last 3 months',
            'This year',
            'Custom range'
          ]}
          onChange={handleDateChange}
        />
        {isLoading ? (
          <div style={{ height: 'calc(calc(var(--vh, 1vh) * 100) - 270px)' }}>
            <Loader />
          </div>
        ) : (
          <>
            <div className="tafe-card mt-20 mb-20">
              <div className="dF align-center gap-2 text-14 color-secondary font-600 ls-028 p-12 bb-black-10">
                Tractor User
                <span className="grey-dot"></span>
                <span
                  className="color-link"
                  data-title={TractorUserDatabase.TRACTOR_MASTER}
                  onClick={navigateToDetails}
                >
                  {totalTractorUserDatabases}
                </span>
              </div>
              <table className={styles['tudb-table']}>
                <thead>
                  <tr>
                    <th className="tractor-user-head">
                      {t('enquiries.villagetype_label')}
                    </th>
                    <th className="tractor-user-head">
                      {t('enquiries.competitionusatisfied_label')}
                    </th>
                    <th className="tractor-user-head">
                      {t('enquiries.competitionsatisfied_label')}
                    </th>
                    <th className="tractor-user-head">
                      {t('enquiries.mfusatisfied_label')}
                    </th>
                    <th className="tractor-user-head">
                      {t('enquiries.mfsatisfied_label')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.village_color_code_breakdowns.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div
                          className={classNames(`${item.color_code}-dot`)}
                        ></div>
                      </td>
                      <td
                        className="text-16 font-500 color-link"
                        data-title={TractorUserDatabase.COMP_UNSATISFIED}
                        data-type={TractorUserDatabase.COMPETITION_CUSTOMER}
                        data-village-color-code={item.color_code}
                        data-is-satisfied-with-his-tractor={
                          TractorUserDatabase.Satisfaction_NO
                        }
                        onClick={navigateToDetails}
                      >
                        {item.total_competition_customer_unsatisfied}
                      </td>
                      <td
                        className="text-16 font-500 color-link"
                        data-title={TractorUserDatabase.COMP_SATISFIED}
                        data-type={TractorUserDatabase.COMPETITION_CUSTOMER}
                        data-village-color-code={item.color_code}
                        data-is-satisfied-with-his-tractor={
                          TractorUserDatabase.Satisfaction_YES
                        }
                        onClick={navigateToDetails}
                      >
                        {item.total_competition_customer_satisfied}
                      </td>
                      <td
                        className="text-16 font-500 color-link"
                        data-title={TractorUserDatabase.MF_UNSATISFIED}
                        data-type={TractorUserDatabase.MF_CUSTOMER}
                        data-village-color-code={item.color_code}
                        data-is-satisfied-with-his-tractor={
                          TractorUserDatabase.Satisfaction_NO
                        }
                        onClick={navigateToDetails}
                      >
                        {item.total_mf_customer_unsatisfied}
                      </td>
                      <td
                        className="text-16 font-500 color-link"
                        data-title={TractorUserDatabase.MF_SATISFIED}
                        data-type={TractorUserDatabase.MF_CUSTOMER}
                        data-village-color-code={item.color_code}
                        data-is-satisfied-with-his-tractor={
                          TractorUserDatabase.Satisfaction_YES
                        }
                        onClick={navigateToDetails}
                      >
                        {item.total_mf_customer_satisfied}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="dF gap-12 mb-12">
              <div className="two-column-width">
                <NavCard
                  title="Top MF Dissatisfied model"
                  customAttributes={{
                    'data-title': 'Top MF Dissatisfied model',
                    'data-type': TractorUserDatabase.MF_CUSTOMER,
                    'data-is-satisfied-with-his-tractor':
                      TractorUserDatabase.Satisfaction_NO,
                    'data-tractor-model': data
                      ?.top_mf_customer_unsatisfied_model_owned_composition
                      .tractor_model as string
                  }}
                  showArrow={true}
                  onClick={navigateToDetails}
                >
                  <div className="w100p pt-10 pb-10">
                    <p className="text-12 color-black-55 ls-024">
                      Model family
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data
                          ?.top_mf_customer_unsatisfied_model_owned_composition
                          .tractor_model
                      }
                    </p>
                    <p className="text-12 color-black-55 ls-024 mt-10">
                      Customer %
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data
                          ?.top_mf_customer_unsatisfied_model_owned_composition
                          .percentage
                      }
                    </p>
                  </div>
                </NavCard>
              </div>
              <div className="two-column-width">
                <NavCard
                  title="Top MF Satisfied model"
                  customAttributes={{
                    'data-title': 'Top MF Satisfied model',
                    'data-type': TractorUserDatabase.MF_CUSTOMER,
                    'data-is-satisfied-with-his-tractor':
                      TractorUserDatabase.Satisfaction_YES,
                    'data-tractor-model': data
                      ?.top_mf_customer_satisfied_model_owned_composition
                      .tractor_model as string
                  }}
                  showArrow={true}
                  onClick={navigateToDetails}
                >
                  <div className="w100p pt-10 pb-10">
                    <p className="text-12 color-black-55 ls-024">
                      Model family
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data?.top_mf_customer_satisfied_model_owned_composition
                          .tractor_model
                      }
                    </p>
                    <p className="text-12 color-black-55 ls-024 mt-10">
                      Customer %
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data?.top_mf_customer_satisfied_model_owned_composition
                          .percentage
                      }
                    </p>
                  </div>
                </NavCard>
              </div>
            </div>
            <div className="dF gap-12">
              <div className="two-column-width">
                <NavCard
                  title="Top competitor Dissatisfied model"
                  customAttributes={{
                    'data-title': 'Top competitor Dissatisfied model',
                    'data-type': TractorUserDatabase.COMPETITION_CUSTOMER,
                    'data-is-satisfied-with-his-tractor':
                      TractorUserDatabase.Satisfaction_NO,
                    'data-tractor-model': data
                      ?.top_competition_customer_unsatisfied_model_owned_composition
                      .tractor_model as string
                  }}
                  showArrow={true}
                  onClick={navigateToDetails}
                >
                  <div className="w100p pt-10 pb-10">
                    <p className="text-12 color-black-55 ls-024">
                      Model family
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data
                          ?.top_competition_customer_unsatisfied_model_owned_composition
                          .tractor_model
                      }
                    </p>
                    <p className="text-12 color-black-55 ls-024 mt-10">
                      Customer %
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data
                          ?.top_competition_customer_unsatisfied_model_owned_composition
                          .percentage
                      }
                    </p>
                  </div>
                </NavCard>
              </div>
              <div className="two-column-width">
                <NavCard
                  title="Top competitor Satisfied model"
                  customAttributes={{
                    'data-title': 'Top competitor Satisfied model',
                    'data-type': TractorUserDatabase.COMPETITION_CUSTOMER,
                    'data-is-satisfied-with-his-tractor':
                      TractorUserDatabase.Satisfaction_YES,
                    'data-tractor-model': data
                      ?.top_competition_customer_satisfied_model_owned_composition
                      .tractor_model as string
                  }}
                  showArrow={true}
                  onClick={navigateToDetails}
                >
                  <div className="w100p pt-10 pb-10">
                    <p className="text-12 color-black-55 ls-024">
                      Model family
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data
                          ?.top_competition_customer_satisfied_model_owned_composition
                          .tractor_model
                      }
                    </p>
                    <p className="text-12 color-black-55 ls-024 mt-10">
                      Customer %
                    </p>
                    <p className="text-14 color-black font-400 mt-2">
                      {
                        data
                          ?.top_competition_customer_satisfied_model_owned_composition
                          .percentage
                      }
                    </p>
                  </div>
                </NavCard>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TUDB;
