import React from 'react';

export const MenuSvg: React.FC = () => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.1 13.3V11.025H18.5V13.3H0.1ZM0.1 8.15V5.85H18.5V8.15H0.1ZM0.1 2.975V0.699999H18.5V2.975H0.1Z"
        fill="white"
      />
    </svg>
  );
};
