import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

import { TaskServiceParameters } from '../../types/parameters';
export default class TaskService {
  protected static async getPagedTasks({
    url,
    page,
    signal,
    perPage,
    filters: { prospectName }
  }: TaskServiceParameters.GetPagedTasks.Parameters & {
    url: string;
  }): Promise<TaskServiceParameters.GetPagedTasks.ReturnType> {
    const handler = new RequestHandler(url, RequestMethod.GET);
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);

    if (prospectName) {
      handler.addParam('prospect_name', prospectName);
    }

    if (signal) {
      handler.setAbortSignal(signal);
    }

    return handler
      .execute()
      .then(
        (result) =>
          result.data as unknown as TaskServiceParameters.GetPagedTasks.ReturnType
      );
  }
}
