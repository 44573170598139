import React from 'react';

export const RedArrow: React.FC = () => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.43613 6.95204L0.692383 6.20829L4.7903 2.11037L7.00697 4.32704L10.0986 1.24995H8.39238V0.199951H11.8924V3.69995H10.8424V1.9937L7.00697 5.81454L4.7903 3.59787L1.43613 6.95204Z"
        fill="#B51213"
      />
    </svg>
  );
};
