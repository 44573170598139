import { Framework, IAdvance } from '../../types/global';
import { AdvanceServiceParameters } from '../../types/parameters';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

export default class AdvanceService {
  public static async getPagedAdvances({
    page,
    signal,
    perPage,
    filters: { contact_name },
    additionalFields = []
  }: AdvanceServiceParameters.GetPagedAdvance): Promise<
    Framework.PagedResult<IAdvance>
  > {
    const handler = new RequestHandler('/api/v1/advances', RequestMethod.GET);
    handler.addParam('page', page);
    handler.addParam('perPage', perPage);
    if (signal) {
      handler.setAbortSignal(signal);
    }
    if (contact_name) {
      handler.addParam('contact_name', contact_name);
    }

    if (additionalFields.length) {
      handler.addParam('additional_fields', additionalFields.join(','));
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<IAdvance>);
  }
}
