import React from 'react';
import { IIconProps } from '../../../types/global';

const WhatsappSvg: React.FC<IIconProps> = ({ className }) => {
  return (
    <svg
      className={`${className}__whatsapp`}
      width="25px"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 25 22"
    >
      <g>
        <g id="Layer_1">
          <g>
            <path
              style={{ fill: '#60d76a' }}
              d="M12.26,20.85c-1.77,0-3.5-.48-5.01-1.37h-.02s-4.49,1.16-4.49,1.16l1.2-4.38v-.02c-1-1.57-1.52-3.38-1.52-5.24,0-5.43,4.42-9.85,9.85-9.85,2.63,0,5.1,1.03,6.96,2.89,1.86,1.86,2.88,4.33,2.88,6.97,0,5.43-4.42,9.85-9.85,9.85h0ZM8.82,7.22l-.12.09c-.22.24-.59.63-.59,1.45,0,.58.32,1.36.84,2.07l.08.12c1.1,1.64,2.46,2.84,3.8,3.37,1.03.41,1.74.61,2.16.61.11,0,.2-.01.28-.02l.15-.02c.25-.04.87-.42.96-.63.06-.16.09-.3.11-.42h-.02c-.31-.17-1.01-.51-1.39-.69-.27.35-.63.76-.64.77-.31.36-.67.43-.91.43-.21,0-.42-.06-.65-.17l-.13-.06c-.38-.17-1.17-.51-2.06-1.31-.8-.72-1.35-1.59-1.55-1.93-.18-.31-.38-.91.24-1.54.06-.06.12-.14.19-.22l.14-.17s.03-.04.05-.07c-.05-.12-.13-.31-.21-.52-.15-.38-.35-.86-.47-1.15-.08,0-.15,0-.22,0v-.85l-.03.85Z"
            />
            <path
              style={{ fill: '#fff' }}
              d="M12.27,2c2.4,0,4.66.94,6.36,2.64,1.7,1.7,2.63,3.96,2.63,6.36,0,4.96-4.04,9-9,9h0c-1.62,0-3.2-.43-4.58-1.25l-.33-.19-3.41.89.91-3.32-.21-.34c-.9-1.43-1.38-3.09-1.38-4.79,0-4.96,4.04-9,9-9M14.99,15.78c.21,0,.36-.03.51-.04.46-.04,1.47-.6,1.68-1.19.21-.58.21-1.08.15-1.19-.06-.1-.23-.17-.48-.29-.25-.12-1.47-.73-1.7-.81-.1-.04-.19-.06-.27-.06-.11,0-.2.05-.29.19-.17.25-.64.81-.79.98-.08.1-.17.14-.27.14-.08,0-.16-.03-.27-.08-.25-.13-1.05-.39-2-1.24-.74-.66-1.24-1.48-1.38-1.73-.15-.25-.02-.38.11-.51.11-.11.25-.29.37-.44.12-.15.17-.25.25-.42.08-.17.04-.31-.02-.44-.06-.12-.55-1.36-.77-1.85-.19-.42-.38-.42-.56-.43-.13,0-.27,0-.41,0h-.06c-.17,0-.44.06-.66.31-.23.25-.87.85-.87,2.08s.89,2.41,1.02,2.58c.12.17,1.72,2.77,4.25,3.77,1.4.55,2.06.67,2.48.67M12.27.3h0C6.37.3,1.57,5.1,1.57,11c0,1.9.5,3.76,1.45,5.39l-.71,2.61-.78,2.84,2.85-.75,2.73-.71c1.57.87,3.35,1.33,5.16,1.33,5.9,0,10.7-4.8,10.7-10.7,0-2.86-1.11-5.55-3.13-7.57C17.82,1.42,15.13.3,12.27.3h0Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WhatsappSvg;
