import Loader from '../loader';

const RecordListLoader: React.FunctionComponent = () => {
  return (
    <div className="record-list-loader-container">
      <Loader />
    </div>
  );
};

export default RecordListLoader;
