import classNames from 'classnames';
import styles from './styles.module.css';

type ButtonProps = {
  label: string;
  size?: 'small' | 'large';
  disabled?: boolean;
  variant: 'primary' | 'secondary';
  isLoading?: boolean;
  appearance?: 'box';
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  label,
  size,
  variant,
  disabled,
  onClick,
  isLoading,
  appearance
}) => {
  return (
    <button
      className={classNames(
        styles[variant],
        isLoading && styles['loading'],
        size && styles[size],
        appearance && styles[appearance]
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      <div className={classNames(isLoading && 'vH')}>{label}</div>
      <div className={classNames('pA', !isLoading && 'vH')}>
        <div className={styles['dot-flashing']}></div>
      </div>
    </button>
  );
};

export default Button;
