import { useCallback, useState } from 'react';
import { IOptions } from '../../../../types/global';
import { UsePagedFieldActivitiesHook } from '../../../../types/hooks';
import RecordCollection from '../../../components/record-collection';
import usePagedFieldActivities from '../../../hooks/usePagedFieldActivities';
import useScroll from '../../../hooks/useScroll';
import ViewLayout from '../../../layout/view-layout';
import { getFullName } from '../../../utils/FullNameUtil';

const allDropDownOptions = [{ value: 'name', label: 'Name' }];

const FieldActivitiesMaster = () => {
  const [value, setValue] = useState('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchLabel, setSearchLabel] = useState<string>(
    allDropDownOptions[0].label.toLowerCase()
  );

  const {
    data,
    isLoading,
    setFilters,
    updateFilterKey,
    isLoadingMore,
    loadMoreRecords
  } = usePagedFieldActivities({
    page: 1,
    perPage: 200,
    filters: {}
  });

  const onScrollEventListener = useScroll(loadMoreRecords);

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(value);
      updateFilterKey(
        searchKey as keyof UsePagedFieldActivitiesHook.Filters,
        value
      );
    },
    [searchKey, updateFilterKey]
  );

  const onDropDownChange = useCallback(
    (value: IOptions) => {
      setSearchKey(value.value);
      setValue('');
      setSearchLabel(value.label.toLowerCase());
      setFilters({});
    },
    [setFilters]
  );

  return (
    <ViewLayout title="FA Planned">
      <RecordCollection>
        <RecordCollection.DropdownSearchBox
          value={value}
          onChange={onNameChange}
          placeholder={'Search by ' + searchLabel}
          initialDropDownValue={allDropDownOptions[0]}
          dropDownOptions={allDropDownOptions}
          onDropDownChange={onDropDownChange}
        />
        <RecordCollection.List onScroll={onScrollEventListener}>
          {isLoading ? (
            <RecordCollection.ListLoader />
          ) : data.length === 0 ? (
            <RecordCollection.ListEmpty />
          ) : (
            data.map((item) => (
              <RecordCollection.ListItemWrapper key={item.id}>
                <RecordCollection.ListItem>
                  <RecordCollection.ListItemTitle title={item.name} />
                  <RecordCollection.ListItemKeyValue
                    label="Model used"
                    value={item.model_used || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Village"
                    value={item.actual_village.name || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Planned date"
                    value={item.planned_date || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="DSP"
                    value={getFullName(
                      item.owner.first_name,
                      item.owner.last_name
                    )}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Field activity ID"
                    value={item.field_activity_id || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Status"
                    value={item.status || '--'}
                  />
                  <RecordCollection.ListItemKeyValue
                    label="Total visitors"
                    value={item.total_visitors.toString()}
                  />
                </RecordCollection.ListItem>
              </RecordCollection.ListItemWrapper>
            ))
          )}
          {!isLoading && isLoadingMore && <RecordCollection.LoadMoreLoader />}
        </RecordCollection.List>
      </RecordCollection>
    </ViewLayout>
  );
};

export default FieldActivitiesMaster;
