import { Framework, IJcpReviewDsp } from '../../types/global';
import { JcpPlanDspServicesParameters } from '../../types/parameters';
import RequestMethod from '../enums/RequestMethod';
import RequestHandler from '../handlers/RequestHandler';

export default class JcpPlanDspServices {
  public static async getPagedDsp({
    id,
    month,
    year,
    page,
    signal,
    perPage
  }: JcpPlanDspServicesParameters.GetPagedDsp): Promise<
    Framework.PagedResult<IJcpReviewDsp>
  > {
    const handler = new RequestHandler(
      `/api/v1/dsp-users/${id}/jc-plannings`,
      RequestMethod.GET
    );
    handler.addParam('page', page);
    handler.addParam('per_page', perPage);
    handler.addParam('month', month);
    handler.addParam('year', year);

    if (signal) {
      handler.setAbortSignal(signal);
    }

    return handler
      .execute()
      .then((result) => result.data as Framework.PagedResult<IJcpReviewDsp>);
  }
}
