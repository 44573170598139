enum TractorUserDatabase {
  TRACTOR_MASTER = 'tractor_master',
  TYPE = 'type',
  VILLAGE_COLOR_CODE = 'village_color_code',
  IS_SATISFIED_WITH_HIS_TRACTOR = 'is_satisfied_with_his_tractor',
  MF_CUSTOMER = 'MF Customer',
  COMPETITION_CUSTOMER = 'Competition Customer',
  Satisfaction_YES = 'Yes',
  Satisfaction_NO = 'No',
  COMP_UNSATISFIED = 'Competition Unsatisfied',
  COMP_SATISFIED = 'Competition Satisfied',
  MF_UNSATISFIED = 'MF Unsatisfied',
  MF_SATISFIED = 'MF Satisfied'
}

export default TractorUserDatabase;
