import moment from 'moment';
import DateFormat from '../enums/DateFormat';
import DateOperationResultType from '../enums/DateOperationResultType';

export default class DateProcessor {
  private static getResult(
    date: Date,
    resultType: DateOperationResultType
  ): Date | number {
    if (resultType === DateOperationResultType.MILLIS) {
      return date.getTime();
    } else {
      return date;
    }
  }

  public static format(
    date: number,
    format: DateFormat = DateFormat.ISO_DATE
  ): string {
    return moment(date).format(format);
  }

  public static getToday(date: number): { start: number; end: number } {
    const start = moment(date).startOf('day').toDate().getTime();
    const end = moment(date).toDate().getTime();
    return { start, end };
  }

  public static getDayBefore(date: number): { start: number; end: number } {
    const start = moment(date)
      .subtract(1, 'day')
      .startOf('day')
      .toDate()
      .getTime();
    const end = moment(date).subtract(1, 'day').endOf('day').toDate().getTime();
    return { start, end };
  }

  public static getSevenDaysBefore(date: number): {
    start: number;
    end: number;
  } {
    const start = moment(date)
      .subtract(7, 'days')
      .startOf('day')
      .toDate()
      .getTime();
    const end = moment(date).toDate().getTime();
    return { start, end };
  }

  public static getWeekStartOf(date: number): { start: number; end: number } {
    const start = moment(date).startOf('week').toDate().getTime();
    const end = moment(date).toDate().getTime();
    return { start, end };
  }

  public static getLastWeek(date: number): { start: number; end: number } {
    const givenDateWeekStartOf = this.getWeekStartOf(date).start;
    const start = moment(givenDateWeekStartOf)
      .subtract(7, 'days')
      .toDate()
      .getTime();
    const end = moment(givenDateWeekStartOf)
      .subtract(1, 'second')
      .toDate()
      .getTime();
    return { start, end };
  }

  public static getThisMonth(date: number): { start: number; end: number } {
    const start = moment(date).startOf('month').toDate().getTime();
    const end = moment(date).toDate().getTime();
    return { start, end };
  }

  public static getLastMonth(date: number): { start: number; end: number } {
    const start = moment(date)
      .subtract(1, 'month')
      .startOf('month')
      .toDate()
      .getTime();
    const end = moment(date)
      .subtract(1, 'month')
      .endOf('month')
      .toDate()
      .getTime();
    return { start, end };
  }

  public static getLastThreeMonths(date: number): {
    start: number;
    end: number;
  } {
    const start = moment(date)
      .subtract(3, 'months')
      .startOf('month')
      .toDate()
      .getTime();
    const end = moment(date)
      .subtract(1, 'month')
      .endOf('month')
      .toDate()
      .getTime();
    return { start, end };
  }

  public static getSpecificMonthRange(
    date: number,
    month: number
  ): { start: number; end: number } {
    const start = moment(date).month(month).startOf('month').toDate().getTime();
    const end = moment(date).month(month).endOf('month').toDate().getTime();
    return { start, end };
  }

  public static getThisYear(date: number): { start: number; end: number } {
    const start = moment(date).startOf('year').toDate().getTime();
    const end = moment().toDate().getTime();
    return { start, end };
  }
  public static getFormattedDate(): string {
    const today = new Date();

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-GB', options).format(today);
  }
  public static isDateAfter(a: number, b: number): boolean {
    return moment(a).isAfter(moment(b), "date");
  }
  public static getStartOFMonthByDate(
    date: Date | number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).startOf("month").toDate();

    return this.getResult(result, resultType);
  }
  public static getEndOFMonthByDate(
    date: Date | number,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(date).endOf("month").toDate();

    return this.getResult(result, resultType);
  }

  public static getStartOfByMonthAndYear(
    month: string,
    year: string,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(`${month} ${year}`, "MMMM YYYY")
      .startOf("month")
      .toDate();

    return this.getResult(result, resultType);
  }

  public static getEndOfByMonthAndYear(
    month: string,
    year: string,
    resultType: DateOperationResultType = DateOperationResultType.MILLIS
  ): Date | number {
    const result = moment(`${month} ${year}`, "MMMM YYYY")
      .endOf("month")
      .toDate();

    return this.getResult(result, resultType);
  }
  public static getDatesBetweenRange(start: Date, end: Date): Array<Date> {
    const result: Array<Date> = [];
    const startDate = moment(start)
    const endDate = moment(end);

    while (!startDate.isAfter(endDate, "date")) {
      result.push(startDate.toDate());
      startDate.add(1, "day");
    }

    return result;
  }

}
